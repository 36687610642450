import * as React from 'react';

import AdTypePill from '@Components/AdTypePill';
import AdvertImage from '@Components/Advert/AdvertImage';
import { AdvertType, AdvertServiceType } from '@Graphql/graphqlTypes.generated';

import styles from './AdvertPicture.module.scss';

type AdvertPictureProps = {
  url: string;
  advertType: AdvertType;
  serviceType: AdvertServiceType;
  alt: string;
};

const AdvertPicture = ({ url, advertType, serviceType, alt }: AdvertPictureProps) => (
  <div className={styles.container}>
    <AdvertImage url={url} className={styles.picture} alt={alt} />
    <div className={styles.adType}>
      <AdTypePill advertType={advertType} serviceType={serviceType} />
    </div>
  </div>
);

export default AdvertPicture;
