import { useSelector } from 'react-redux';

import { CURRENCY } from '@Config/constants';
import { getCurrentLanguage } from '@Store/app/app.selectors';

export const useCurrency = () => {
  const currentLanguage = useSelector(getCurrentLanguage);

  return currentLanguage === 'pl' ? CURRENCY.PLN : CURRENCY.EUR;
};
