// ToDo de-momentize: moment is required for isValidDate used in react-datetime, also it accepts Moment.js formats
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import DT from 'react-datetime';
import { useSelector } from 'react-redux';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import InputContainer from '@Components/forms/inputs/InputContainer';
import { DEFAULT_LANG } from '@Config/constants';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import { getDateFormatMoment } from '@Utils/dateMoment';
import { hasFieldError } from '@Utils/form';
import { MessageKeysWithoutParams, getTranslationValue } from '@Utils/translation';

export interface DateInputProps extends FormFieldRenderProps<string> {
  placeholder?: MessageKeysWithoutParams | string | undefined;
  className?: string;
  required?: boolean;
  label?: string;
  showError?: boolean;
  language?: string;
  showSubmit?: boolean;
  closeOnSelect?: boolean;
  viewDate?: Date;
  isValidDate?: (currentDate: Date) => boolean;
  showErrorIcon?: boolean;
  bottomSpacing?: boolean;
}

// @ts-expect-error works in dev, breaks in build, read more: https://www.bergqvist.it/blog/2022/10/25/minified-react-error-in-production-with-vite-js
const DateTime = DT.default ? DT.default : DT;

const DateInput = ({
  label,
  input,
  meta,
  required,
  closeOnSelect,
  viewDate,
  isValidDate,
  placeholder,
  bottomSpacing = true,
  className,
  showErrorIcon,
}: DateInputProps) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);

  const { value } = input;

  const translatedPlaceholder = getTranslationValue({
    value: placeholder || 'msg_label_default_date_input_placeholder',
    t,
  });

  const renderInput = (dateInputProps: DateInputProps) => <input {...dateInputProps} />;
  const dateFormat = getDateFormatMoment(currentLanguage);
  const hasError = hasFieldError(meta);

  const determineInputValue = (): string => {
    const isInputValueValid = moment(value, dateFormat, true).isValid();
    return isInputValueValid ? moment(value).format(dateFormat) : value;
  };

  return (
    <InputContainer
      meta={meta}
      label={label}
      required={required}
      className={className}
      bottomSpacing={bottomSpacing}
      fullWidth
      showErrorIcon={showErrorIcon}
    >
      <DateTime
        onChange={(newValue: { newValue: string | undefined | moment.Moment }) => {
          const returnValue = moment.isMoment(newValue) ? newValue.toDate() : newValue;
          return input.onChange(returnValue);
        }}
        value={determineInputValue()}
        renderInput={renderInput}
        dateFormat={dateFormat}
        locale={DEFAULT_LANG}
        closeOnSelect={closeOnSelect}
        initialViewDate={viewDate}
        timeFormat={false}
        inputProps={{
          placeholder: translatedPlaceholder,
          className: classNames('form-control', { 'with-error': hasError }),
        }}
        isValidDate={isValidDate && ((cur: moment.Moment) => isValidDate(cur.toDate()))}
      />
    </InputContainer>
  );
};

export default DateInput;
