import { CategoryKind } from '@Graphql/graphqlTypes.generated';
import { MapT } from '@Utils/types';

export const getColorByLetter = (letter: string) => {
  const colors: MapT<string> = {
    A: '#f36d1e',
    B: '#f29416',
    C: '#f1b20b',
    D: '#f2d30c',
    E: '#cdc810',
    F: '#87bd42',
    G: '#079d51',
    H: '#06a196',
    I: '#0aa9e1',
    J: '#0c85c3',
    K: '#0b62a6',
    L: '#b3bcbd',
    M: '#697a82',
    N: '#2a4350',
    O: '#233d8e',
    P: '#62388b',
    Q: '#8c3489',
    R: '#eb3972',
    S: '#ef3323',
    T: '#f06c1e',
    U: '#ee9114',
    V: '#eeaf09',
    W: '#eed10a',
    X: '#c7c30d',
    Y: '#82b83f',
    Z: '#049a4f',
  };

  return colors[letter];
};

export const getTheme = (appKind: CategoryKind) => (appKind === CategoryKind.Agro ? 'THEME1' : 'THEME2');
