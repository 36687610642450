import * as React from 'react';
import gtmParts from 'react-google-tag-manager';

import { isMobileApp } from '@Utils/mobileWebView';

export interface TagManagerProps {
  scriptId?: string;
  dataLayerName?: string;
  additionalEvents?: object;
  gtmId: string;
}

export default class GoogleTagManager extends React.PureComponent<TagManagerProps> {
  componentDidMount() {
    const scriptId = this.props.scriptId || 'react-google-tag-manager-gtm';
    const gtmScriptNode = document.getElementById(scriptId);
    if (!gtmScriptNode || !gtmScriptNode.textContent) {
      return;
    }
    // eslint-disable-next-line no-eval
    eval(gtmScriptNode.textContent);
  }

  render() {
    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName || 'dataLayer',
      additionalEvents: this.props.additionalEvents || {},
    });

    if (isMobileApp && !!window?.allowAnalyticsTracking) {
      return null;
    }

    return (
      <>
        <div>{gtm.noScriptAsReact()}</div>
        <div id={this.props.scriptId || 'react-google-tag-manager-gtm'}>{gtm.scriptAsReact()}</div>
      </>
    );
  }
}
