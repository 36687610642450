import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ServerError } from 'apollo-link-http-common';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';

import config from '@Config/config';
import { ApiErrorStatusCodes, ErrorCodes, LocalStorage } from '@Config/constants';
import { RouteConfig } from '@Config/routes';
import { Errors } from '@Graphql/graphqlTypes.generated';
import { removeUserToken } from '@Utils/auth';
import { getFromLocalStorage } from '@Utils/localStorage';
import { SejicoGraphqlError, UserUrlTypes } from '@Utils/types';

const wsLink = new WebSocketLink({
  uri: `${config.WS_BASE_URL}/graphql/`,
  options: {
    reconnect: true,
  },
});

const authLink = setContext((_, { headers }) => {
  const token = getFromLocalStorage(LocalStorage.userToken);
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const serverError = networkError as ServerError;

  if (graphQLErrors) {
    const noActiveCompanyError = (graphQLErrors as ReadonlyArray<SejicoGraphqlError>).find(
      data => data.code === ErrorCodes.noActiveCompany,
    );

    if (noActiveCompanyError) {
      window.location.replace(RouteConfig.User.buildLink({ type: UserUrlTypes.company }));
    }
    if (
      (serverError && serverError.statusCode === ApiErrorStatusCodes.forbidden) ||
      graphQLErrors.some(error => error.message === Errors.RefreshToken)
    ) {
      //TODO add functionality to handle token refresh
      removeUserToken();
      window.location.replace(RouteConfig.Authentication.buildLink({ authType: 'login' }));
    }
  }
});

const graphqlClient = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      authLink.concat(wsLink),
      createUploadLink({ uri: `${config.API_BASE_URL}/graphql/` }),
    ),
  ]),
  cache: new InMemoryCache({
    resultCaching: false,
    freezeResults: false,
  }),
});

export default graphqlClient;
