import classNames from 'classnames';
import React from 'react';

import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';

import styles from './PaperHeader.module.scss';

export interface PaperHeaderProps {
  title?: Messages | string;
  subtitle?: Messages | string;
  hidePoint?: boolean;
}

const PaperHeader: React.FunctionComponent<PaperHeaderProps> = ({ title, subtitle, hidePoint }) => {
  const headerClassName = classNames(styles.header, { [styles.point]: !hidePoint });
  return (
    <div className={headerClassName}>
      <div className={styles.title}>{title && <Typography tag="h3" color="primary" msg={title} />}</div>
      {subtitle && (
        <div className={styles.subtitle}>
          <Typography tag="p" size="s" msg={subtitle} />
        </div>
      )}
    </div>
  );
};

export default PaperHeader;
