import classNames from 'classnames';
import * as React from 'react';

import Icon, { IconName, IconSizes } from '@Components/Icon';

import styles from './FloatinActionButton.module.scss';

export interface FloatingActionButtonProps {
  onClick?: (e: React.SyntheticEvent) => any;
  disabled?: boolean;
  icon: IconName;
  className?: string;
  iconSize?: IconSizes;
}

const FloatingActionButton: React.FunctionComponent<FloatingActionButtonProps> = ({
  onClick,
  disabled = false,
  icon,
  className,
  iconSize = IconSizes.s,
}) => {
  return (
    <button onClick={onClick} type="button" disabled={disabled} className={classNames(styles.fab, className)}>
      <Icon icon={icon} size={iconSize} />
    </button>
  );
};

export default FloatingActionButton;
