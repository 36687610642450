import React from 'react';

import { META_DEFAULTS } from '@Config/constants';
import { useTranslations } from '@Hooks/useTranslations';

import MetaConsumer from './Consumer';
import { MetaContextProps, Provider as MetaProvider } from './Context';

interface MetaWrapperProps {
  meta?: MetaContextProps;
  children: React.ReactNode;
}

const MetaWrapper: React.FC<MetaWrapperProps> = ({ children, meta }) => {
  const t = useTranslations();

  const translatedDefaults = {
    ...META_DEFAULTS,
    title: t(META_DEFAULTS.title),
    description: t(META_DEFAULTS.description),
  };

  return (
    <MetaProvider value={{ ...translatedDefaults, ...meta }}>
      <MetaConsumer>{children}</MetaConsumer>
    </MetaProvider>
  );
};

export default MetaWrapper;
