import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import Checkbox from '@Components/forms/inputs/Checkbox';
import DateInput, { DateInputProps } from '@Components/forms/inputs/DateInput/DateInput';
import SelectInput from '@Components/forms/inputs/SelectInput';
import TextInput, { TextInputProps } from '@Components/forms/inputs/TextInput/TextInput';
import Tooltip from '@Components/Tooltip';
import { useGetCategoriesQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { AdFormFieldNames, FormRowItem } from '@Routes/ads/shared/AdForm/AdForm';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import { normalizeDate } from '@Utils/dateMoment';
import { normalizeNumber, validateRequired, Validator } from '@Utils/form';
import { MessageKeysWithoutParams } from '@Utils/translation';

import FormField from '../FormField';

import styles from './SchemaFields.module.scss';

export interface SchemaFieldsProps {
  categoryId: string;
  className?: string;
  name: AdFormFieldNames;
}

export enum TemplateSchemaPropertyType {
  string = 'string',
  integer = 'integer',
  number = 'number',
  boolean = 'boolean',
  array = 'array',
}

export enum SchemaFieldType {
  string = 'string',
  integer = 'integer',
  boolean = 'boolean',
  date = 'date',
  number = 'number',
  options = 'options',
  array = 'array',
}

export interface TemplateSchemaProperty {
  type: TemplateSchemaPropertyType;
  titleMsgKey: MessageKeysWithoutParams;
  tooltipMsgKey: MessageKeysWithoutParams;
  placeholderMsgKey: string;
  description: string;
  label: string;
  format: string;
  options: DynamicFieldsOptionItemProps[];
}

export interface TemplateSchema {
  required: string[];
  properties: Record<string, TemplateSchemaProperty>;
}

export interface RenderFieldProps {
  fieldName: string;
  label: string;
  placeholder: string;
  validation?: Validator;
  type?: TemplateSchemaPropertyType;
  options: DynamicFieldsOptionItemProps[];
}

export interface DynamicFieldsOptionItemProps {
  msgKey: string;
  value: string;
}

const determineSchemaFieldType = (
  type: TemplateSchemaPropertyType,
  format: string,
  options: DynamicFieldsOptionItemProps[],
) => {
  const defaultMapper = {
    [TemplateSchemaPropertyType.integer]: SchemaFieldType.integer,
    [TemplateSchemaPropertyType.string]: SchemaFieldType.string,
    [TemplateSchemaPropertyType.boolean]: SchemaFieldType.boolean,
    [TemplateSchemaPropertyType.number]: SchemaFieldType.number,
    [TemplateSchemaPropertyType.array]: SchemaFieldType.array,
  };

  if (type === TemplateSchemaPropertyType.string && format === 'date') {
    return SchemaFieldType.date;
  }

  if (type === TemplateSchemaPropertyType.string && options.length) {
    return SchemaFieldType.options;
  }

  return defaultMapper[type];
};

const SchemaFields: React.FC<SchemaFieldsProps> = ({ categoryId, className, name }) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);

  const { data: categoriesFields } = useGetCategoriesQuery({});

  const renderTextField = ({ fieldName, placeholder, validation }: RenderFieldProps) => {
    return (
      <FormField type="text" name={fieldName} component={TextInput} placeholder={placeholder} validate={validation} />
    );
  };

  const renderNumberField = ({ fieldName, placeholder, validation, type }: RenderFieldProps) => {
    return (
      <FormField<TextInputProps<string>>
        type="text"
        name={fieldName}
        component={TextInput}
        placeholder={placeholder}
        validate={validation}
        format={value => normalizeNumber(value, type as TemplateSchemaPropertyType)}
        formatOnBlur
      />
    );
  };

  const renderCheckboxField = ({ fieldName, placeholder }: RenderFieldProps) => {
    //TO DO: Check why is placeholder is passed as label
    return (
      <FormField type="checkbox" name={fieldName} component={Checkbox} label={placeholder} bottomSpacing={false} />
    );
  };

  const renderDateField = ({ fieldName, placeholder, validation }: RenderFieldProps) => {
    return (
      <FormField<DateInputProps>
        name={fieldName}
        component={DateInput}
        parse={date => normalizeDate(date, currentLanguage)}
        type="text"
        placeholder={placeholder}
        validate={validation}
        format={value => new Date(value)}
      />
    );
  };

  const renderSelectField = ({ fieldName, placeholder, validation, options }: RenderFieldProps) => {
    const optionsList =
      options.map(option => {
        return {
          label: option.msgKey,
          value: option.value,
        };
      }) || [];

    return (
      <FormField
        name={fieldName}
        component={SelectInput}
        type="text"
        placeholder={placeholder}
        validate={validation}
        options={optionsList}
        localizeSelectLabels
      />
    );
  };

  const renderMultipleSelectField = ({ fieldName, placeholder, validation, options }: RenderFieldProps) => {
    const optionsList =
      options?.map(option => {
        return {
          label: option.msgKey,
          value: option.value,
        };
      }) || [];

    return (
      <FormField
        name={fieldName}
        component={SelectInput}
        type="text"
        placeholder={placeholder}
        validate={validation}
        options={optionsList}
        isMulti
        localizeSelectLabels
      />
    );
  };

  const schemaFields = {
    [SchemaFieldType.string]: renderTextField,
    [SchemaFieldType.integer]: renderNumberField,
    [SchemaFieldType.number]: renderNumberField,
    [SchemaFieldType.boolean]: renderCheckboxField,
    [SchemaFieldType.date]: renderDateField,
    [SchemaFieldType.options]: renderSelectField,
    [SchemaFieldType.array]: renderMultipleSelectField,
  };

  if (categoryId && categoriesFields) {
    const categorySelected = categoriesFields.categories.find(category => {
      return category.id === categoryId;
    });

    if (categorySelected?.customFieldsSchema && categorySelected.customFieldsSchema !== '{}') {
      const schema: TemplateSchema = JSON.parse(categorySelected.customFieldsSchema);

      if (!schema.properties) {
        return null;
      }

      return (
        <>
          {Object.keys(schema.properties).map(propertyKey => {
            const property = schema.properties[propertyKey];
            const title = property.titleMsgKey || '';
            const placeholder = property.placeholderMsgKey || '';
            const description = property.description || '';
            const label = property.label || '';
            const type = property.type || '';
            const format = property.format || '';
            const validate = schema.required.includes(propertyKey);
            const options = property.options || [];
            const fieldName = `${name}.${propertyKey}`;
            const validation = validate ? validateRequired(t) : undefined;
            const tooltipTitle = property.tooltipMsgKey || undefined;

            const componentRenderFunction = schemaFields[determineSchemaFieldType(type, format, options)];

            if (!componentRenderFunction) {
              return;
            }

            return (
              <FormRowItem
                className={className}
                key={propertyKey}
                label={t(title)}
                description={description}
                textCenterMobile={false}
              >
                <Tooltip
                  title={t(tooltipTitle)}
                  className={{
                    tooltipContainer: classNames({
                      [styles.tooltipContainer]: type === TemplateSchemaPropertyType.boolean,
                    }),
                  }}
                >
                  {componentRenderFunction({
                    fieldName,
                    label,
                    placeholder,
                    validation,
                    type,
                    options,
                  })}
                </Tooltip>
              </FormRowItem>
            );
          })}
        </>
      );
    }
  }
  return null;
};

export default SchemaFields;
