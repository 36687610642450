import classNames from 'classnames';
import * as React from 'react';

import styles from './GridRow.module.scss';

export interface RowProps {
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  centerVertically?: boolean;
}

const GridRow: React.FunctionComponent<RowProps> = ({ children, className, fullWidth, centerVertically }) => {
  return (
    <div
      className={classNames(
        styles.gridRow,
        { [styles.fullWidth]: fullWidth, [styles.centerVertically]: centerVertically },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default GridRow;
