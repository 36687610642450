import { addDays } from 'date-fns';
import { stringOrDate } from 'react-big-calendar';

import { getDateFromDateTime, getDateDiffInDays } from '@Utils/dates';

export const rangeChangerFn = (range: Date[] | { start: stringOrDate; end: stringOrDate }) => {
  let startDate;
  let endDate;

  if (Array.isArray(range)) {
    [startDate] = range;
    endDate = range[range.length - 1];
  } else {
    startDate = range.start;
    endDate = range.end;
  }

  const diff = getDateDiffInDays(new Date(endDate), new Date(startDate));

  if (Math.abs(diff) > 31) {
    endDate = addDays(new Date(startDate), 29);
  }
  return { startDate: getDateFromDateTime(startDate), endDate: getDateFromDateTime(endDate) };
};

export const rangeChangeHandler =
  (setStartDate: (date: string) => void, setEndDate: (date: string) => void) =>
  (range: Date[] | { start: stringOrDate; end: stringOrDate }) => {
    const { startDate, endDate } = rangeChangerFn(range);

    setStartDate(startDate);
    setEndDate(endDate);
  };
