import * as React from 'react';

import Row from '@Components/layout/Row';
import Slide, { SlideItem } from '@Components/Slider/Slide';

import styles from './Slider.module.scss';

export interface SliderProps {
  slides: SlideItem[];
}

const Slider: React.FunctionComponent<SliderProps> = ({ slides }) => {
  const [active, setActive] = React.useState<number>(0);

  const onItemClick = (index: number) => {
    setActive(index);
  };

  const activeSlide = slides[active];

  return (
    <Row alignEnd column className={styles.sliderWrapper}>
      {activeSlide && <Slide activeSlide={activeSlide} active={active} onItemClick={onItemClick} slides={slides} />}
    </Row>
  );
};

export default Slider;
