import { LocalStorage } from '@Config/constants';
import { getFromLocalStorage } from '@Utils/localStorage';

import { RootState } from '../reducers';

import { UserActiveCompany, UserData } from './auth.types';

export const getUserIsLogged = (state: RootState): boolean => {
  const userData = getUserData(state);
  return userData.item !== null || getFromLocalStorage(LocalStorage.userToken) !== null;
};
export const getUserData = (state: RootState): UserData => state.auth.userData;

export const getUserCompanyData = (state: RootState): UserActiveCompany | undefined => {
  return state.auth.userData?.item?.info?.activeCompany;
};

export const isUserDataLoading = (state: RootState): boolean => {
  return state.auth.userData.isFetching;
};

export const getInvitationKey = (state: RootState) => state.auth.invitationKey;
