import axios from 'axios';
import FileSaver from 'file-saver';

import config from '@Config/config';
import { LocalStorage } from '@Config/constants';
import { getFromLocalStorage } from '@Utils/localStorage';

function getDefaultHeaders() {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: '',
  };

  const token = getFromLocalStorage(LocalStorage.userToken);
  if (token) {
    headers.Authorization = `JWT ${token}`;
  }
  return headers;
}

async function callApi(options: object) {
  const result = await axios({
    ...options,
    baseURL: config.API_BASE_URL,
    headers: getDefaultHeaders(),
  });

  return result.data;
}

export function callGet(url: string) {
  return callApi({
    url,
    method: 'GET',
  });
}

export function callPost(url: string, data: any) {
  return callApi({
    url,
    data,
    method: 'POST',
  });
}
export function callUpdate(url: string, data: any) {
  return callApi({
    url,
    data,
    method: 'PUT',
  });
}
export function callDelete(url: string) {
  return callApi({
    url,
    method: 'DELETE',
  });
}

export const handleFileDownload = async (url: string, fileName: string, extensionToAdd?: string) => {
  return axios({
    url,
    method: 'GET',
    responseType: 'blob',
    headers: getDefaultHeaders(),
  }).then(response => {
    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, extensionToAdd ? fileName + extensionToAdd : fileName);
  });
};
