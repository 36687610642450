import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Buttons/Button';
import ButtonLink from '@Components/Buttons/ButtonLink';
import AdditionalHeaderLinks from '@Components/Header/AdditionalHeaderLinks/AdditionalHeaderLinks';
import HeaderLinks from '@Components/Header/HeaderLinks';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Container from '@Components/layout/Container';
import Row from '@Components/layout/Row';
import { useAppKindContext } from '@Components/root';
import Typography from '@Components/Typography';
import config from '@Config/config';
import { MAIN_GREEN_COLOR_HEX, MediaQueries, WebViewMessagesTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import { CategoryKind } from '@Graphql/graphqlTypes.generated';
import useAppTheme from '@Hooks/useAppTheme';
import { usePhoneNumber } from '@Hooks/usePhoneNumber';
import { useTranslations } from '@Hooks/useTranslations';
import { getFeatureToggle, getFeatureToggleDisabledLinks } from '@Store/app/app.selectors';
import { logout } from '@Store/auth/auth.actions';
import { getUserCompanyData, getUserData, getUserIsLogged } from '@Store/auth/auth.selector';
import { UserActiveCompany, UserData } from '@Store/auth/auth.types';
import { locationChange } from '@Store/navigation/navigation.actions';
import { GAAction, GACategory, sendGaEvent } from '@Utils/gaEvents';
import { useWindowEvent } from '@Utils/hooks/useWindowEvent';
import { postMessageToWebView } from '@Utils/mobileWebView';
import { isDefined } from '@Utils/tools';
import { MessageKeysWithoutParams } from '@Utils/translation';
import { UserUrlTypes } from '@Utils/types';

import Hamburger from './Hamburger';
import styles from './Header.module.scss';

export enum HeaderBackgrounds {
  white = 'white',
  transparent = 'transparent',
  mobilePrimary = 'mobilePrimary',
}
export interface HeaderProps {
  background?: HeaderBackgrounds;
  user?: UserData;
}

export enum HeaderLinkStyleTypes {
  button = 'button',
  link = 'link',
}

export interface HeaderLink {
  titleKey: MessageKeysWithoutParams;
  link: string;
  type?: HeaderLinkStyleTypes;
}

const Header: React.FunctionComponent<HeaderProps> = ({ background = HeaderBackgrounds.white }) => {
  const t = useTranslations();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isUserLoggedIn = useSelector(getUserIsLogged);

  const disabledLinks = useSelector(getFeatureToggleDisabledLinks);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: MediaQueries.desktopS });
  const user = useSelector(getUserData);

  const userHasActiveCompany: UserActiveCompany | undefined = useSelector(getUserCompanyData);
  const oldOrderForm = useSelector(getFeatureToggle('oldOrderForm'));

  const { appKind } = useAppKindContext();
  const phoneNumber = usePhoneNumber();
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  useAppTheme();

  const secondLineHeaderLinksAuth = [
    oldOrderForm
      ? ({
          titleKey: Messages.myOrders,
          link: RouteConfig.MyOrders.buildLink(),
        } as const)
      : undefined,
  ].filter(isDefined);

  const notAuthBaseLinks = [
    {
      titleKey: Messages.generalOrderLink,
      link: RouteConfig.GeneralOrder.buildLink(),
    } as const,
    {
      titleKey: 'msg_create_contractor_page_link',
      link: RouteConfig.Contractor.buildLink(),
    } as const,
    {
      titleKey: Messages.faqPage,
      link: RouteConfig.Faq.buildLink(),
    } as const,
    {
      titleKey: Messages.contactPage,
      link: RouteConfig.Contact.buildLink(),
    } as const,
    {
      titleKey: 'msg_about_page_title',
      link: RouteConfig.About.buildLink(),
    } as const,
  ];

  const noAuthHeaderLinks = [
    ...notAuthBaseLinks,
    !isUserLoggedIn
      ? ({
          titleKey: Messages.headerLogin,
          link: RouteConfig.Authentication.buildLink({ authType: 'login' }),
        } as const)
      : undefined,
    isUserLoggedIn
      ? ({
          titleKey: Messages.myAds,
          link: RouteConfig.MyAdverts.buildLink(),
        } satisfies HeaderLink)
      : undefined,
    !isUserLoggedIn
      ? ({
          titleKey: Messages.registerPage,
          link: RouteConfig.Authentication.buildLink({ authType: 'register' }),
          type: HeaderLinkStyleTypes.button,
        } as const)
      : undefined,
  ].filter(isDefined);

  const userDropdownLinks: HeaderLink[] = [
    { titleKey: Messages.userPage, link: RouteConfig.User.buildLink({ type: UserUrlTypes.company }) },
  ];

  const adUploadButtonLink = {
    titleKey: Messages.goToNewAdPage,
    link: userHasActiveCompany
      ? RouteConfig.AdvertNew.buildLink()
      : RouteConfig.User.buildLink({ type: UserUrlTypes.company }),
    type: HeaderLinkStyleTypes.button,
  } as const;

  const firstLineHeaderLinks = noAuthHeaderLinks.filter(value => !disabledLinks.includes(value.link));

  const allAuthHeaderLinks = [...secondLineHeaderLinksAuth, adUploadButtonLink].filter(
    value => value.link && !disabledLinks.includes(value.link),
  );

  const onAppKindClick = () => {
    sendGaEvent({
      category: GACategory.appKindSelect,
      action: GAAction.click,
    });

    if (!isDesktop) {
      closeMobileMenu();
    }
  };

  const secondLineNotAuthButtons = [
    {
      titleKey: t('msg_sejico_agro_header_link'),
      onClick: () => {
        onAppKindClick();
        dispatch(locationChange({ path: RouteConfig.Home.buildLink({ appKind: 'agro' }) }));
      },
      kind: CategoryKind.Agro,
    },
    config.CONSTRUCTIONS_ENABLED
      ? {
          titleKey: t('msg_sejico_construction_header_link'),
          onClick: () => {
            onAppKindClick();
            dispatch(locationChange({ path: RouteConfig.Home.buildLink({ appKind: 'constructions' }) }));
          },
          kind: CategoryKind.Constructions,
        }
      : undefined,
    config.SEJICO_FINANCE_URL
      ? {
          titleKey: t('msg_header_link_sejico_finance_title'),
          onClick: () => {
            window.location.href = config.SEJICO_FINANCE_URL;
          },
        }
      : undefined,
  ].filter(isDefined);

  const secondLineHeaderLinks = allAuthHeaderLinks.filter(value =>
    isUserLoggedIn ? !disabledLinks.includes(value.link) : true,
  );

  useEffect(() => {
    if (isMobileMenuOpen) {
      postMessageToWebView(WebViewMessagesTypes.headerColor, MAIN_GREEN_COLOR_HEX);
      document.body.style.overflow = 'hidden';
    } else {
      postMessageToWebView(WebViewMessagesTypes.headerColor, 'transparent');
    }
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [isMobileMenuOpen]);

  useWindowEvent('resize', () => closeMobileMenu());

  const isMobileGreen = background === HeaderBackgrounds.mobilePrimary;
  const transparentHeader = background === HeaderBackgrounds.transparent;

  const logo = appKind === CategoryKind.Agro ? 'sejico-agro-logo' : 'sejico-construction-logo';

  const contactPhone = (
    <div className={classNames(styles.contactPhone)}>{t(Messages.contactPhone, { phone: phoneNumber })}</div>
  );

  return (
    <header className={classNames(styles.header, styles[background])}>
      <Container className={styles.upperHeader}>
        <Container className={styles.upperHeaderContainer}>
          <Row justifyEnd alignCenter tag="nav" className={styles.upperHeaderRow}>
            <HeaderLinks
              links={firstLineHeaderLinks}
              isMobileMenuOpen={isMobileMenuOpen}
              transparentHeader={transparentHeader}
              toggleNavigation={closeMobileMenu}
              className={styles.link}
            />
            <AdditionalHeaderLinks
              links={userDropdownLinks}
              transparentHeader={transparentHeader}
              isMobileMenuOpen={isMobileMenuOpen}
              displayAsDropDown
            />
          </Row>
        </Container>
      </Container>
      <Container className={styles.itemsContainer}>
        <div
          onClick={() => {
            sendGaEvent({
              category: GACategory.appKindModal,
              action: GAAction.click,
            });
            dispatch(locationChange({ path: RouteConfig.Home.buildLink({ appKind: appKind.toLocaleLowerCase() }) }));
          }}
          className={styles.logoWithSwitcher}
        >
          <div className={styles.homepageLink}>
            <Icon
              size={IconSizes.auto}
              className={classNames(styles.logo, { [styles.mobilePrimaryIcon]: isMobileGreen })}
              icon={logo}
            />
          </div>
        </div>

        <Hamburger isOpen={isMobileMenuOpen} handleClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
        <Row
          alignCenter
          justifyBetween
          className={classNames(styles.mobileMenu, styles.secondLineContainer, {
            [styles.openInMobile]: isMobileMenuOpen,
          })}
        >
          <Row alignCenter className={styles.mobilePositioning} tag="nav">
            {!isMobileMenuOpen && (
              <Row justifyBetween>
                <Row className={styles.secondLineButtonsContainer}>
                  {secondLineNotAuthButtons.map((item, index) => {
                    return (
                      <Button
                        style={ButtonStyles.withoutStyling}
                        type={ButtonTypes.button}
                        size={ButtonSizes.fill}
                        onClick={item.onClick}
                        key={index}
                        className={styles.button}
                      >
                        <Typography
                          msg={item.titleKey}
                          tag="div"
                          color={appKind === item.kind ? 'primary' : 'black'}
                          bold={appKind === item.kind}
                        />
                      </Button>
                    );
                  })}
                </Row>

                <Row className={styles.secondLineButtonsContainer}>
                  {isDesktop && isUserLoggedIn && contactPhone}

                  {isUserLoggedIn && (
                    <HeaderLinks
                      links={secondLineHeaderLinks}
                      isMobileMenuOpen={isMobileMenuOpen}
                      transparentHeader={transparentHeader}
                      toggleNavigation={setIsMobileMenuOpen}
                      className={styles.link}
                    />
                  )}
                  {isDesktop && !isUserLoggedIn && contactPhone}
                </Row>
              </Row>
            )}

            {isMobileMenuOpen && (
              <>
                <div className={styles.separator} />

                <div className={classNames(styles.mobileLinks, styles.linksContainer)}>
                  {notAuthBaseLinks.map(item => (
                    <Link key={item.titleKey} to={item.link} onClick={closeMobileMenu}>
                      <Typography msg={t(item.titleKey)} tag="div" color="gray" className={styles.linkTitle} />
                    </Link>
                  ))}
                </div>

                <div className={styles.separator} />

                <div className={classNames(styles.mobileLinks, styles.linksContainer)}>
                  {secondLineNotAuthButtons.map((item, index) => {
                    return (
                      <Button
                        style={ButtonStyles.withoutStyling}
                        type={ButtonTypes.button}
                        size={ButtonSizes.unset}
                        onClick={item.onClick}
                        key={index}
                        className={styles.button}
                      >
                        <Typography
                          msg={item.titleKey}
                          tag="div"
                          color={appKind === item.kind ? 'primary' : 'black'}
                          bold={appKind === item.kind}
                          className={styles.linkTitle}
                        />
                      </Button>
                    );
                  })}
                </div>

                <div className={styles.separator} />

                {isUserLoggedIn ? (
                  <>
                    <div className={classNames(styles.mobileLinks, styles.linksContainer)}>
                      <Row constant>
                        <Icon icon="user" size={IconSizes.m} className={styles.userIcon} />

                        <Col>
                          <Typography
                            msg={user.item?.info.username || ''}
                            tag="div"
                            color="black"
                            size="m"
                            className={styles.linkTitle}
                          />
                          <Typography msg={user.item?.info.email || ''} tag="div" color="gray" size="m" />
                        </Col>
                      </Row>

                      <Col className={styles.mobileLinks}>
                        <Link
                          to={
                            userHasActiveCompany
                              ? RouteConfig.AdvertNew.buildLink()
                              : RouteConfig.User.buildLink({ type: UserUrlTypes.company })
                          }
                          onClick={closeMobileMenu}
                        >
                          <Typography
                            msg={t('msg_page_go_to_new_ad')}
                            tag="div"
                            color="gray"
                            size="m"
                            className={styles.linkTitle}
                          />
                        </Link>

                        <Link to={RouteConfig.User.buildLink({ type: 'company' })} onClick={closeMobileMenu}>
                          <Typography
                            msg={t('msg_page_user')}
                            tag="div"
                            color="gray"
                            size="m"
                            className={styles.linkTitle}
                          />
                        </Link>

                        <Link to={RouteConfig.FavoriteAdverts.buildLink()} onClick={closeMobileMenu}>
                          <Typography
                            msg={t('msg_mobile_header_favorite_ads')}
                            tag="div"
                            color="gray"
                            size="m"
                            className={styles.linkTitle}
                          />
                        </Link>

                        <Link to={RouteConfig.Activity.buildLink()} onClick={closeMobileMenu}>
                          <Typography
                            msg={t('msg_mobile_header_activity')}
                            tag="div"
                            color="gray"
                            size="m"
                            className={styles.linkTitle}
                          />
                        </Link>
                      </Col>
                    </div>

                    <div className={styles.separator} />

                    <Link
                      to={RouteConfig.Authentication.buildLink({ authType: 'login' })}
                      onClick={() => dispatch(logout.started({}))}
                      className={styles.logoutLinkContainer}
                    >
                      <Typography
                        tag="div"
                        className={styles.linkTitle}
                        color="black"
                        size="s"
                        msg={t('msg_btn_logout')}
                      />
                    </Link>
                  </>
                ) : (
                  <div className={classNames(styles.mobileLinks, styles.mobileNotAuthButtons)}>
                    <Link to={RouteConfig.Authentication.buildLink({ authType: 'login' })} onClick={closeMobileMenu}>
                      <Typography
                        msg={t('msg_page_login')}
                        tag="div"
                        color="primary"
                        size="l"
                        className={styles.linkTitle}
                      />
                    </Link>
                    <ButtonLink
                      style={ButtonStyles.outlinedGreen}
                      size={ButtonSizes.fill}
                      link={RouteConfig.Authentication.buildLink({ authType: 'register' })}
                      onClick={closeMobileMenu}
                    >
                      <Typography
                        msg={t('msg_label_register')}
                        tag="div"
                        color="primary"
                        size="l"
                        className={styles.linkTitle}
                      />
                    </ButtonLink>
                  </div>
                )}
              </>
            )}
          </Row>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
