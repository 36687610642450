import classNames from 'classnames';
import * as React from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import InputContainer from '@Components/forms/inputs/InputContainer/InputContainer';
import TextInput from '@Components/forms/inputs/TextInput';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { areNotGrater, toFiniteNumberOrNull } from '@Utils/convertions';
import { validateOnlyWholeNumbers } from '@Utils/form';

import styles from './RangeInput.module.scss';

export enum RangeNames {
  from = 'from',
  to = 'to',
}

export type RangeInputValueProps = {
  [key in RangeNames]?: number | null;
};

export type InputProps = {
  [key in string]?: string;
};

export type StateValues = InputProps;

type StateError = {
  touched?: boolean;
  error?: string;
} | null;

export interface RangeInputProps extends FormFieldRenderProps<StateValues> {
  label?: string;
  required?: boolean;
  initialPriceGte?: number;
  initialPriceLte?: number;
  bottomSpacing?: boolean;
}

const RangeInput: React.FunctionComponent<RangeInputProps> = ({
  initialPriceGte,
  initialPriceLte,
  label,
  required,
  input,
  meta,
  bottomSpacing,
}) => {
  const t = useTranslations();

  const initialValues = {
    from: initialPriceGte?.toString(),
    to: initialPriceLte?.toString(),
  };

  const [values, setValues] = React.useState<StateValues>(initialValues);
  const [error, setError] = React.useState<StateError>();

  const handleValidations = () => {
    const errorDefaultData = { touched: true, error: t(Messages.errorSecondNumberShouldBeGrater) };
    const errorInvalidNumbers = { touched: true, error: t(Messages.errorOnlyNumbersAllowed) };

    const parsedValues: RangeInputValueProps = Object.entries(values).reduce((result: any, [key, value]) => {
      // eslint-disable-next-line no-param-reassign
      result[key] = toFiniteNumberOrNull(value);
      return result;
    }, {});

    if (values.from && values.to && areNotGrater(values.from, values.to)) {
      setError(errorDefaultData);
      return;
    }
    if (validateOnlyWholeNumbers(values.from) || validateOnlyWholeNumbers(values.to)) {
      setError(errorInvalidNumbers);
      return;
    }
    error && setError(null);
    input.onChange(parsedValues);
  };

  const onChangeFrom = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, from: ev.target.value });
  };

  const onChangeTo = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, to: ev.target.value });
  };

  const defaultFieldProps = {
    onBlur: () => handleValidations(),
    onFocus: () => {},
  };

  return (
    <InputContainer
      label={label}
      meta={error || meta}
      required={required}
      className={classNames(styles.rangeInput)}
      showErrorIcon
      bottomSpacing={bottomSpacing}
    >
      <TextInput
        label=""
        input={{
          onChange: onChangeFrom,
          value: values.from,
          type: 'text',
          name: 'start-range',
          ...defaultFieldProps,
        }}
        placeholder={Messages.filtersPriceRangeInputFromPlaceholder}
        className={styles.priceFrom}
        wrapperClassname={styles.inputWrapper}
        meta={{}}
        noMargin
      />
      <TextInput
        label=""
        input={{ onChange: onChangeTo, value: values.to, type: 'text', name: 'end-range', ...defaultFieldProps }}
        placeholder={Messages.filtersPriceRangeInputToPlaceholder}
        className={styles.priceTo}
        wrapperClassname={styles.inputWrapper}
        meta={{}}
        noMargin
      />
    </InputContainer>
  );
};

export default RangeInput;
