import classNames from 'classnames';
import * as React from 'react';

import styles from './LoadingSpinner.module.scss';

export interface LoadingSpinnerProps {
  fullscreen?: boolean;
  small?: boolean;
}

const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = ({ fullscreen, small }) => {
  const size = small ? '33px' : '86px';
  return (
    <div className={classNames(styles.spinner, { [styles.fullscreen]: fullscreen })}>
      <svg width={size} height={size} viewBox="0 0 86 86" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={styles.spinnerPath}
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="43"
          cy="43"
          r="30"
        />
      </svg>
    </div>
  );
};

export default LoadingSpinner;
