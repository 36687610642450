import * as React from 'react';

import CategorySelect, { CategorySelectProps } from '@Components/forms/inputs/selects/CategorySelect';
import { useGetCategoriesQuery } from '@Graphql/graphqlTypes.generated';

type AdCategorySelectProps = Omit<CategorySelectProps, 'categories' | 'isLoading'>;

const AdCategorySelect = (props: AdCategorySelectProps) => {
  const { loading, data } = useGetCategoriesQuery({});

  return <CategorySelect isLoading={loading} categories={data?.categories} {...props} />;
};

export default React.memo(AdCategorySelect);
