import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import lazyImport from '@Utils/lazyImport';

import { RouteInfo } from '../appRoutes';

const AuthenticationPage = lazyImport(() => import('./AuthenticationPage'));
const EmailVerificationPage = lazyImport(() => import('./EmailVerificationPage'));
const ForgotPasswordPage = lazyImport(() => import('./ForgotPasswordPage'));
const JoinCompanyPage = lazyImport(() => import('./JoinCompanyPage'));
const ResetPasswordPasswordPage = lazyImport(() => import('./ResetPasswordPage'));

const authRoutes: RouteInfo[] = [
  {
    path: RouteConfig.EmailVerification.template,
    component: EmailVerificationPage,
    title: Messages.emailVerificationPage,
  },
  {
    path: RouteConfig.Authentication.template,
    component: AuthenticationPage,
    title: Messages.loginPage,
  },
  {
    path: RouteConfig.ForgotPassword.template,
    component: ForgotPasswordPage,
    title: Messages.forgotPasswordPage,
  },
  {
    path: RouteConfig.ResetPassword.template,
    component: ResetPasswordPasswordPage,
    title: Messages.forgotPasswordPage,
  },
  {
    path: RouteConfig.JoinCompany.template,
    component: JoinCompanyPage,
    title: Messages.joinCompanyPage,
  },
];

export default authRoutes;
