import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { buildTitle } from '@Components/DocumentTitle/DocumentTitle';
import { META_DEFAULTS } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { getFbAppId } from '@Store/app/app.selectors';

import { Consumer as MetaConsumer } from './Context';

interface ConsumerProps {
  children?: React.ReactNode;
}

const Consumer: React.FC<ConsumerProps> = ({ children }) => {
  const t = useTranslations();
  const fbAppId: string = useSelector(getFbAppId);

  return (
    <MetaConsumer>
      {({ title, description, image, type, url, custom = [] }) => (
        <>
          <Helmet
            title={buildTitle(title || t(META_DEFAULTS.title), t)}
            meta={[
              { name: 'description', content: description },
              { property: 'og:url', content: url },
              { property: 'og:title', content: title },
              { property: 'og:description', content: description },
              { property: 'og:type', content: type },
              { property: 'og:image', content: image },
              { property: 'og:site_name', content: t(Messages.websiteTitle) },
              { property: 'fb:app_id', content: fbAppId },

              ...custom,
            ]}
          />
          {children}
        </>
      )}
    </MetaConsumer>
  );
};

export default Consumer;
