import * as React from 'react';

import { HOC, MapT } from '@Utils/types';

export interface LocaleContextType {
  localeContext: MapT<string>;
}
export const localeContext = React.createContext({});

export const AppContextProvider = localeContext.Provider;
export const AppContextConsumer = localeContext.Consumer;

export function withLocaleContext<P>(Component: HOC<P, LocaleContextType>) {
  return function ComponentBoundWithAppContext(props: P) {
    return <AppContextConsumer>{appContext => <Component {...props} localeContext={appContext} />}</AppContextConsumer>;
  };
}
