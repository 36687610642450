import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Row from '@Components/layout/Row';
import { ModalProviderProps } from '@Components/Modal';
import CalendarEventForm, {
  CalendarEventFormData,
} from '@Components/Modal/CalendarEventModal/CalendarEventForm/CalendarEventForm';
import { showSuccessModal } from '@Components/Modal/modalHelpers';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import {
  AdvertEventMutation,
  AdvertEventMutationInput,
  GetAdvertCalendarItemsDocument,
  GetMyAdvertAvailabilityDocument,
  GetMyAdvertScheduleDocument,
  useAdvertEventMutation,
  useDeleteAdvertEventMutationMutation,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import { buildDateAndTime, getDateFromDateTime, getTimeFromDateTime } from '@Utils/dates';
import { callMutationForForm, getOperationName } from '@Utils/graphql';
import { CalendarNode } from '@Utils/types';
import { noop } from '@Utils/util';

import modalStyles from '../Modal.module.scss';

import styles from './CalendarEventModal.module.scss';

export enum ActionsDisplayType {
  column = 'column',
  row = 'row',
}

export interface EventType {
  start: Date;
  end: Date;
  id?: string;
  title?: string;
  description?: string;
}

export interface CalendarEventModalData {
  actionsDisplayType?: ActionsDisplayType;
  advert: string;
  event: CalendarNode;
  onSuccessCallback?: () => void;
  isEventRemovalAllowed?: boolean;
}

export interface CalendarEventModalProps extends ModalProviderProps<CalendarEventModalData> {}

const CalendarEventModal: React.FunctionComponent<CalendarEventModalProps> = ({
  modalData: {
    actionsDisplayType = ActionsDisplayType.column,
    advert,
    event,
    onSuccessCallback = noop,
    isEventRemovalAllowed,
  },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const isEditForm = !!event?.id;

  const buildFormValues = (values: CalendarEventFormData): AdvertEventMutationInput => {
    const { title, description, startDate, endDate, timeFrom, timeTo } = values;
    return {
      advert,
      id: event?.id,
      title: title || t(Messages.calendarEventDefaultTitle),
      description: description || '',
      startDate: buildDateAndTime(startDate, timeFrom).toISOString(),
      endDate: buildDateAndTime(endDate, timeTo).toISOString(),
    };
  };

  const [createCalendarEvent] = useAdvertEventMutation({
    onCompleted: () => {
      dispatch(showSuccessModal(t(Messages.btnOk), closeModal, t(Messages.calendarEventCreateSuccess)));
      onSuccessCallback();
    },
    refetchQueries: [getOperationName(GetMyAdvertScheduleDocument), getOperationName(GetAdvertCalendarItemsDocument)],
  });

  const [editCalendarEvent] = useAdvertEventMutation({
    onCompleted: () => {
      dispatch(showSuccessModal(t(Messages.btnOk), closeModal, t(Messages.calendarEventUpdateSuccess)));
    },
    refetchQueries: [getOperationName(GetMyAdvertScheduleDocument)],
  });

  const handleEditSubmit = (values: CalendarEventFormData) => {
    return callMutationForForm<AdvertEventMutation>(
      editCalendarEvent({
        variables: {
          input: buildFormValues(values),
        },
      }),
    );
  };

  const handleCreateSubmit = (values: CalendarEventFormData) => {
    return callMutationForForm<AdvertEventMutation>(
      createCalendarEvent({
        variables: {
          input: buildFormValues(values),
        },
      }),
    );
  };

  const [deleteAdvertEvent] = useDeleteAdvertEventMutationMutation({
    refetchQueries: [getOperationName(GetMyAdvertAvailabilityDocument)],
  });

  const handleCancel = () => {
    if (isEventRemovalAllowed && event?.id) {
      deleteAdvertEvent({
        variables: {
          input: {
            id: event.id,
          },
        },
      });
    }
    closeModal();
  };

  const submitForm = (values: CalendarEventFormData) =>
    isEditForm ? handleEditSubmit(values) : handleCreateSubmit(values);

  const buildInitialValues = () => {
    return {
      startDate: getDateFromDateTime(event.startDate, currentLanguage),
      endDate: getDateFromDateTime(event.endDate, currentLanguage),
      timeFrom: getTimeFromDateTime(event.startDate),
      timeTo: getTimeFromDateTime(event.endDate),
      title: event.title,
      description: event.description,
    };
  };

  const buildInitialDefaultValues = () => {
    return {
      startDate: '',
      endDate: '',
      timeFrom: '',
      timeTo: '',
      title: '',
      description: '',
    };
  };

  const initialValues = isEditForm && event ? buildInitialValues() : buildInitialDefaultValues();
  const calendarTitle = isEditForm ? Messages.calendarEventModalEditTitle : Messages.calendarEventModalTitle;
  return (
    <div className={classNames(modalStyles.actions, modalStyles[actionsDisplayType])}>
      <div className={styles.container}>
        <Row justifyCenter>
          <Typography tag="h3" color="primary" msg={t(calendarTitle)} />
        </Row>
        <Row>
          <CalendarEventForm
            onSubmit={values => submitForm(values)}
            onCancel={handleCancel}
            initialValues={initialValues}
            isEditForm={isEditForm}
            isEventRemovalAllowed={isEventRemovalAllowed}
          />
        </Row>
      </div>
    </div>
  );
};

export default CalendarEventModal;
