import classNames from 'classnames';
import * as React from 'react';

import FormField from '@Components/forms/FormField';
import TextInput, { TextInputProps } from '@Components/forms/inputs/TextInput';
import { OldOrderFormFields } from '@Components/Order/OldOrderFormContainer/OldOrderForm';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { AdvertPricingType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { validate, validateMaxLength, validateNumber, validateRequiredNumber } from '@Utils/form';

import styles from '../../OldOrderForm.module.scss';

interface AmountFieldProps {
  advertPricingType: AdvertPricingType;
}

type FieldMessages = { label: Messages; placeholder: Messages };

const amountFieldMessages = {
  [AdvertPricingType.EurPerHectare]: {
    label: Messages.fieldSize,
    placeholder: Messages.createOrderHectareAmount,
  },
  [AdvertPricingType.EurPerItem]: {
    label: Messages.itemAmount,
    placeholder: Messages.createOrderItemAmount,
  },
  [AdvertPricingType.EurPerKilogram]: {
    label: Messages.kilogramAmount,
    placeholder: Messages.createOrderKilogramAmount,
  },
  [AdvertPricingType.EurPerMeter]: {
    label: Messages.meterAmount,
    placeholder: Messages.createOrderMeterAmount,
  },
  [AdvertPricingType.EurPerLiter]: {
    label: Messages.literAmount,
    placeholder: Messages.createOrderLiterAmount,
  },
  [AdvertPricingType.EurPerTon]: {
    label: Messages.tonAmount,
    placeholder: Messages.createOrderTonAmount,
  },
  [AdvertPricingType.NegotiablePrice]: {
    label: Messages.itemAmount,
    placeholder: Messages.createOrderItemAmount,
  },
  [AdvertPricingType.FreeItem]: {
    label: Messages.itemAmount,
    placeholder: Messages.createOrderItemAmount,
  },
} as const satisfies Partial<Record<AdvertPricingType, FieldMessages>>;

const AmountField: React.FunctionComponent<AmountFieldProps> = ({ advertPricingType }) => {
  const t = useTranslations();

  const messages =
    advertPricingType in amountFieldMessages
      ? amountFieldMessages[advertPricingType as keyof typeof amountFieldMessages]
      : undefined;

  if (!messages) {
    return null;
  }
  return (
    <div className={classNames(styles.orderSection, styles.noBorder, styles.spacingBetweenSections)}>
      <Typography msg={t(messages.label)} tag="h5" className={styles.createOrderSpacing} />
      <div className={styles.fields}>
        <FormField<TextInputProps<string>>
          name={OldOrderFormFields.amount}
          component={TextInput}
          type="text"
          placeholder={messages.placeholder}
          validate={validate(validateRequiredNumber(t), validateNumber(t), validateMaxLength(t, 8))}
        />
      </div>
    </div>
  );
};

export default AmountField;
