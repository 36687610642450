import { format } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showSuccessModal } from '@Components/Modal/modalHelpers';
import PreliminaryOrderForm from '@Components/Order/PreliminaryOrderFormContainer/PreliminaryOrderForm';
import { TimeFormat } from '@Config/constants';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import {
  CreatePreliminaryOrderMutationMutation,
  useCreatePreliminaryOrderMutationMutation,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUserData } from '@Store/auth/auth.selector';
import { locationChange } from '@Store/navigation/navigation.actions';
import { GAAction, GACategory, sendGaEvent } from '@Utils/gaEvents';
import { callMutationForForm } from '@Utils/graphql';
import { PreliminaryOrderFormData } from '@Utils/types';

const PreliminaryFormContainer = ({ advertId, onCancel }: { advertId?: string; onCancel?: () => void }) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const { item: user } = useSelector(getUserData);

  const initialValues: PreliminaryOrderFormData = {
    contactType: user ? 'platform' : 'phone',
    email: user?.info.email,
    phone: user?.info.activeCompany.company.phone,
    description: '',
    startDate: '',
  };

  const [createOrderAction] = useCreatePreliminaryOrderMutationMutation({
    onCompleted: () => {
      dispatch(
        showSuccessModal(
          t(Messages.btnOk),
          () => {
            dispatch(locationChange({ path: RouteConfig.Marketplace.buildLink() }));
          },
          t(Messages.preliminaryOrderCreateSuccess),
          t(Messages.preliminaryOrderCreateSuccessSubtext),
        ),
      );
      sendGaEvent({
        category: GACategory.preliminaryOrder,
        action: GAAction.click,
      });
    },
  });

  const handleSubmit = ({ email, startDate, phone, description, contactType }: PreliminaryOrderFormData) => {
    return callMutationForForm<CreatePreliminaryOrderMutationMutation>(
      createOrderAction({
        variables: {
          input: {
            advert: advertId,
            email,
            startDate: format(new Date(startDate), TimeFormat.date),
            phone,
            description,
            contactViaEmail: contactType === 'email',
            contactViaPhone: contactType === 'phone',
            contactViaPlatform: contactType === 'platform',
          },
        },
      }),
    );
  };

  return (
    <PreliminaryOrderForm
      advertId={advertId}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      initialValues={initialValues}
    />
  );
};

export default PreliminaryFormContainer;
