import React from 'react';

export const useScrolled = () => {
  const [scrolled, setScrolled] = React.useState(window.scrollY);
  React.useEffect(() => {
    const handleEvent = () => setScrolled(window.scrollY);
    document.addEventListener('scroll', handleEvent);
    return () => {
      document.removeEventListener('scroll', handleEvent);
    };
  }, [scrolled, setScrolled]);
  return scrolled;
};
