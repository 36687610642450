import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonSizes, ButtonStyles } from '@Components/Buttons/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Container from '@Components/layout/Container';
import Row from '@Components/layout/Row';
import { showCookieModal } from '@Components/Modal/modalHelpers';
import Typography from '@Components/Typography';
import { buildMessageKeyForEnum, Messages } from '@Config/messages';
import useAppTheme from '@Hooks/useAppTheme';
import { useTranslations } from '@Hooks/useTranslations';
import { getCookieBooleanValue, getCookieBooleanValueWithDefault, setCookie } from '@Utils/cookies';
import { isMobileApp } from '@Utils/mobileWebView';

import styles from './CookieBanner.module.scss';

export enum CookiesNames {
  mandatory = 'mandatory',
  analytics = 'analytics',
  functional = 'functional',
  targeted = 'targeted',
}

export interface CookiesFormData {
  name: CookiesNames;
  label: string;
}

export interface CookieBannerInitialValues {
  mandatory: boolean;
  analytics: boolean;
  functional: boolean;
  targeted: boolean;
}

export enum CookiesBarVisability {
  showCookieBanner = 'showCookieBanner',
}

// We need to set initial analytics cookie value, when page loads.
// It is needed because we use GA tracking based on this cookie value
const isCookieBannerVisible = () => {
  if (isMobileApp) {
    return false;
  }
  let isVisible = getCookieBooleanValueWithDefault(CookiesBarVisability.showCookieBanner, true);
  Object.keys(CookiesNames).forEach(key => {
    if (getCookieBooleanValue(CookiesNames[key as keyof typeof CookiesNames]) === null) {
      isVisible = true;
    }
  });
  return isVisible;
};

const CookieBanner: React.FC = () => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const [showCookieBanner, setCookieBannerVisibility] = React.useState<boolean>(isCookieBannerVisible());

  const cookies: CookiesFormData[] = [
    { name: CookiesNames.mandatory, label: buildMessageKeyForEnum(CookiesNames.mandatory) },
    { name: CookiesNames.analytics, label: buildMessageKeyForEnum(CookiesNames.analytics) },
    { name: CookiesNames.functional, label: buildMessageKeyForEnum(CookiesNames.functional) },
    { name: CookiesNames.targeted, label: buildMessageKeyForEnum(CookiesNames.targeted) },
  ];

  const initialValues: CookieBannerInitialValues = {
    mandatory: true,
    analytics: getCookieBooleanValueWithDefault(CookiesNames.analytics, true),
    functional: getCookieBooleanValueWithDefault(CookiesNames.functional, true),
    targeted: getCookieBooleanValueWithDefault(CookiesNames.targeted, true),
  };

  const onSubmit = (values: CookieBannerInitialValues) => {
    setCookie(CookiesNames.mandatory, values.mandatory);
    setCookie(CookiesNames.analytics, values.analytics);
    setCookie(CookiesNames.functional, values.functional);
    setCookie(CookiesNames.targeted, values.targeted);
    setCookie(CookiesBarVisability.showCookieBanner, false);
    setCookieBannerVisibility(false);
  };

  useEffect(() => {
    if (isMobileApp) {
      const allowAnalyticsTracking = !!window?.allowAnalyticsTracking;

      setCookie(CookiesBarVisability.showCookieBanner, false);
      setCookie(CookiesNames.mandatory, allowAnalyticsTracking);
      setCookie(CookiesNames.functional, allowAnalyticsTracking);
      setCookie(CookiesNames.targeted, allowAnalyticsTracking);
      setCookie(CookiesNames.analytics, allowAnalyticsTracking);
      return;
    }

    if (getCookieBooleanValue(CookiesNames.analytics) === null) {
      setCookie(CookiesNames.analytics, true);
    }
  }, []);

  useAppTheme();

  if (showCookieBanner) {
    return (
      <div className={styles.cookieBannerWrapper}>
        <Container>
          <Row alignCenter>
            <Col size={9}>
              <Row column>
                <Typography msg={t(Messages.cookiesHeader)} tag="h4" />
                <Typography msg={t(Messages.cookiesText)} tag="span" className={styles.text} html />
                <Button
                  onClick={() => dispatch(showCookieModal(onSubmit, cookies, initialValues))}
                  style={ButtonStyles.withoutStyling}
                >
                  <Row alignCenter constant>
                    <Icon icon="lock" size={IconSizes.m} className={styles.icon} />
                    <Typography msg={t(Messages.cookiesSettings)} tag="span" color="primary" />
                  </Row>
                </Button>
              </Row>
            </Col>
            <Col size={3}>
              <Row justifyEnd>
                <Button
                  label={t(Messages.cookiesBtnAcceptAll)}
                  size={ButtonSizes.l}
                  className={styles.button}
                  onClick={() => onSubmit({ mandatory: true, analytics: true, functional: true, targeted: true })}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  return null;
};

export default CookieBanner;
