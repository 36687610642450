import { ObservableQueryFields } from '@apollo/react-common';
import { ApolloError } from 'apollo-client';
import { getOperationName as getOperationNameOriginal } from 'apollo-link';
import * as dotProp from 'dot-prop-immutable';
import { FORM_ERROR } from 'final-form';
import { DocumentNode, ExecutionResult } from 'graphql';

import { convertErrorCodeToMessage } from '@Utils/errors';
import ApolloClient from '@Utils/graphqlClient';
import { CustomGraphqlError, Shape } from '@Utils/types';
import { mergeByUniqueId } from '@Utils/util';

import { isFileOrBlob } from './file';

export const handleSubmissionErrors = (error: { errors: { networkError: { result: any } } }) => {
  return error.errors.networkError.result;
};

export const addFormErrorForOnlyVirtualFieldError = (errors: Shape<unknown>, formValues: Shape<unknown>) => {
  const errorKeys = Object.keys(errors);
  if (!errorKeys.length) {
    return errors;
  }

  const containVirtualFieldError = errorKeys.some(errorKey => !(errorKey in formValues));
  if (containVirtualFieldError) {
    const containFieldError = errorKeys.some(errorKey => errorKey in formValues);
    const containsFormError = FORM_ERROR in errors;
    if (!containFieldError && !containsFormError) {
      return {
        ...errors,
        [FORM_ERROR]: convertErrorCodeToMessage('msg_error_unexpected'),
      };
    }
  }
  return errors;
};

export const handleSubmissionErrorsForGrapqlQuery = (errors: ApolloError, formData?: Shape<unknown>) => {
  const errorData: Shape<string | Shape<string>> = {};
  const { graphQLErrors } = errors;

  if (graphQLErrors && graphQLErrors.length) {
    graphQLErrors.forEach(errorItem => {
      const error = errorItem as CustomGraphqlError;
      if (error.field && error.customField) {
        errorData[error.field] = { [error.customField]: convertErrorCodeToMessage(error.message) };
      } else if (error.field) {
        errorData[error.field] = convertErrorCodeToMessage(error.message);
      }
    });
    if (!Object.keys(errorData).length) {
      errorData[FORM_ERROR] = convertErrorCodeToMessage(graphQLErrors[0].message);
    }
  }
  if (!formData) {
    return errorData;
  }
  return addFormErrorForOnlyVirtualFieldError(errorData, formData);
};

export const buildFormErrors = (error: unknown, formData?: Shape<unknown>) => {
  // todo double check if there are other same structure objects
  if (error instanceof ApolloError) {
    return handleSubmissionErrorsForGrapqlQuery(error, formData);
  }
  return { [FORM_ERROR]: convertErrorCodeToMessage('msg_error_unexpected') };
};

export const callMutationForForm = <T>(mutation: Promise<ExecutionResult<T>>, formData?: Shape<unknown>) => {
  return mutation.catch(e => {
    return buildFormErrors(e, formData);
  });
};

export const uploadImagesMutationPromises = <T, U>(
  mutation: DocumentNode,
  makeInput: (image: File | Blob) => U,
  images: (File | Blob | string)[],
): Promise<ExecutionResult<T>>[] =>
  images
    .filter((img): img is File | Blob => isFileOrBlob(img))
    .map(image => ApolloClient.mutate<T, U>({ mutation, variables: makeInput(image) }));

export function fetchPaginatedItems<TData, TVariables>(
  cursor: string,
  fetchMore: ObservableQueryFields<TData, TVariables>['fetchMore'],
  keyword: string,
  loadItemsFromNewest?: boolean,
) {
  const options = {
    variables: {
      cursor,
    },
    // TODO: Fix any types
    updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
      const newEdges = dotProp.get(fetchMoreResult, `${keyword}.edges`);
      const pageInfo = dotProp.get(fetchMoreResult, `${keyword}.pageInfo`);
      if (newEdges.length) {
        const oldEdges = dotProp.get(previousResult, `${keyword}.edges`);
        let newData = dotProp.set(previousResult, `${keyword}.pageInfo`, pageInfo);
        newData = dotProp.set(
          newData,
          `${keyword}.edges`,
          loadItemsFromNewest ? mergeByUniqueId(newEdges, oldEdges) : mergeByUniqueId(oldEdges, newEdges),
        );
        return newData;
      }
      return dotProp.set(previousResult, `${keyword}.pageInfo`, pageInfo);
    },
  };

  fetchMore(options);
}

export const hasData = (data: any, loading: boolean, dataKey: string) => {
  if (loading) {
    return true;
  }
  return dotProp.get(data, dataKey) !== null;
};

export const createDescendingQueryParam = (sortKey: string) => `-${sortKey}`;

export function getOperationName(doc: DocumentNode): string {
  return getOperationNameOriginal(doc) || '';
}
export function buildRefetchQuery<T>(doc: DocumentNode, variables: T): { variables: T; query: DocumentNode } {
  return { query: doc, variables };
}
