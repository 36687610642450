import React, { ComponentType, lazy } from 'react';

import { useTranslations } from '@Hooks/useTranslations';

const storageKey = 'lazyImport-force-reload';

const lazyImport = <P extends {}>(
  factory: () => Promise<{
    default: ComponentType<P>;
  }>,
) =>
  lazy(async () => {
    try {
      const component = await factory();
      window.sessionStorage.removeItem(storageKey);
      return component;
    } catch (e) {
      if (!window.sessionStorage.getItem(storageKey)) {
        window.sessionStorage.setItem(storageKey, 'true');
        window.location.reload();

        // eslint-disable-next-line react/jsx-no-useless-fragment
        return { default: () => <></> };
      }

      return {
        default: () => {
          const t = useTranslations();
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <h1>{t('msg_error_unexpected')}</h1>
              <button
                style={{
                  padding: '0.5rem 1rem',
                  marginTop: '1rem',
                  border: '1px solid #000',
                  cursor: 'pointer',
                  fontSize: '1rem',
                }}
                onClick={() => window.location.reload()}
              >
                {t('msg_app_action_reload')}
              </button>
            </div>
          );
        },
      };
    }
  });

export default lazyImport;
