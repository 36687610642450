import classNames from 'classnames';
import * as React from 'react';

import Icon, { IconSizes } from '@Components/Icon';
import Tooltip, { TooltipProps } from '@Components/Tooltip';
import Typography from '@Components/Typography';
import { OptionalProps } from '@Utils/types';

import styles from './ErrorDisplay.module.scss';

export interface ErrorDisplayProps {
  className?: string;
  msg: any;
  showErrorIcon?: boolean;
  showErrorIconTooltip?: boolean;
  tooltipProps?: OptionalProps<TooltipProps>;
}

const ErrorDisplay: React.FunctionComponent<ErrorDisplayProps> = ({
  className,
  msg,
  showErrorIcon,
  showErrorIconTooltip,
  tooltipProps,
}) => {
  if (!msg) {
    return null;
  }
  let msgToDisplay = msg;

  if (Array.isArray(msg)) {
    [msgToDisplay] = msg;
  }
  if (msg.message) {
    msgToDisplay = msg.message;
  }

  return (
    <div
      className={classNames(className, styles.error, {
        [styles.withErrorIcon]: showErrorIcon,
        [styles.errorTooltip]: showErrorIconTooltip,
      })}
    >
      {!showErrorIconTooltip && <Typography tag="span" msg={msgToDisplay} />}
      {showErrorIcon && !showErrorIconTooltip && (
        <div>
          <Icon icon="warning-sign" size={IconSizes.ss} />
        </div>
      )}
      {showErrorIcon && showErrorIconTooltip && (
        <Tooltip className={{ text: styles.tooltipText }} {...tooltipProps} message={msgToDisplay}>
          <Icon icon="warning-sign" size={IconSizes.ss} />
        </Tooltip>
      )}
    </div>
  );
};

export default ErrorDisplay;
