import classNames from 'classnames';
import React from 'react';

import styles from './PaperBody.module.scss';

export enum PaperBodySpacing {
  xs = 'spacingXs',
  s = 'spacingS',
  m = 'spacingM',
  l = 'spacingL',
  none = 'spacingNone',
}

export enum PaperBodyOverflow {
  auto = 'overflowAuto',
  hidden = 'overflowHidden',
  visible = 'overflowVisible',
}

interface PaperBodyProps {
  spacing?: PaperBodySpacing;
  overflow?: PaperBodyOverflow;
  className?: string;
  children?: React.ReactNode;
}

const PaperBody: React.FunctionComponent<PaperBodyProps> = ({ children, spacing, overflow, className }) => {
  return (
    <div
      className={classNames(
        styles.paperBody,
        { [styles[spacing as PaperBodySpacing]]: spacing, [styles[overflow as PaperBodyOverflow]]: overflow },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default PaperBody;
