import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Buttons/Button';
import FormField from '@Components/forms/FormField';
import DateInput from '@Components/forms/inputs/DateInput/DateInput';
import FormSliderInput from '@Components/forms/inputs/FormSliderInput';
import LocationInput from '@Components/forms/inputs/LocationInput';
import { AllCategoriesProps } from '@Components/forms/inputs/selects/CategorySelect';
import GeneralOrderCategorySelect from '@Components/forms/inputs/selects/GeneralOrderCategorySelect';
import GeneralOrderPaymentConditionSelect from '@Components/forms/inputs/selects/GeneralOrderPaymentConditionSelect';
import TextAreaInput from '@Components/forms/inputs/TextAreaInput';
import TextInput, { TextInputProps } from '@Components/forms/inputs/TextInput';
import Icon, { IconSizes } from '@Components/Icon';
import InfoMessage, { MessageTypes } from '@Components/InfoMessage';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import { getFinalPrice } from '@Components/Order/GeneralOrder/GeneralOrderForm/generalOrderUtils';
import PriceDisplay from '@Components/PriceDisplay';
import Tooltip, { TooltipPosition } from '@Components/Tooltip';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useGetGeneralOrderClassifiersQuery } from '@Graphql/graphqlTypes.generated';
import { useCurrency } from '@Hooks/useCurrency';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import { convertToFloatNumber, convertToIntNumber } from '@Utils/convertions';
import { normalizeDate } from '@Utils/dateMoment';
import { parseDate, selectedDayIsAfterYesterday, selectedDayIsNotBefore } from '@Utils/dates';
import {
  validate,
  validateEmail,
  validateFloat,
  validateFractionDigitsLength,
  validateMaxIntAmount,
  validateMaxSize,
  validateNumber,
  validatePhone,
  validateRequired,
  validateSelectedDayIsAfterYesterday,
  validateSelectedDayIsNotBefore,
} from '@Utils/form';
import { isDefined } from '@Utils/tools';
import { GeneralOrderFormData } from '@Utils/types';
import { validateDate } from '@Utils/validate';

import styles from './GeneralOrderForm.module.scss';

interface GeneralOrderFormProps {
  onSubmit: (values: GeneralOrderFormData) => Promise<{}>;
  onCancel?: () => void;
  initialValues: GeneralOrderFormData;
}

enum FormFields {
  category = 'category',
  amountType = 'amountType',
  amountParts = 'amountParts',
  numberOfFields = 'numberOfFields',
  marketPriceSelection = 'marketPriceSelection',
  paymentCondition = 'paymentCondition',
  startDate = 'startDate',
  endDate = 'endDate',
  name = 'name',
  surname = 'surname',
  description = 'description',
  email = 'email',
  phone = 'phone',
  address = 'address',
  coordinates = 'coordinates',
  terrainType = 'terrainType',
}

const MAX_AMOUNT = 9_999_999 as const;
const MAX_NUMBER_OF_FIEDLS = 10 as const;

const OrderForm = ({
  handleSubmit,
  submitting,
  submitError,
  values,
  onCancel,
  form,
}: FormRenderProps<GeneralOrderFormData> & { onCancel?: () => void }): JSX.Element => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const currency = useCurrency();
  const { data: categoriesData } = useGetGeneralOrderClassifiersQuery({});

  const numberOfFields = convertToIntNumber(values.numberOfFields) ?? 1;

  const marketPrices = useMemo(() => {
    if (!values.category || !categoriesData) {
      return null;
    }
    return categoriesData.appConfig.generalOrderCategories.find(category => category.id === values.category)
      ?.marketPrices;
  }, [values.category, categoriesData]);

  const isMarketPriceRange = marketPrices && marketPrices.marketPriceFrom !== marketPrices.marketPriceTo;

  const finalPrice = getFinalPrice({
    marketPrice: values.marketPriceSelection,
    paymentCondition: values.paymentCondition,
    amount: values.amountParts.reduce((acc, part) => acc + convertToFloatNumber(part), 0),
  });

  useEffect(() => {
    form.change(FormFields.marketPriceSelection, marketPrices?.marketPriceFrom || null);
  }, [marketPrices]);

  const validEndDate = useCallback(
    (value: Date) => {
      const dateFromValue = parseDate(values.startDate);
      if (!dateFromValue) {
        return selectedDayIsAfterYesterday(value);
      }
      return selectedDayIsNotBefore(value, dateFromValue);
    },
    [values.startDate],
  );

  return (
    <form onSubmit={handleSubmit} className={styles.orderContainer}>
      <p className={classNames(styles.spacingBetweenSections, styles.mainInfoBlock)}>
        <Typography msg={t('msg_general_order_min_info_part_1')} tag="span" />
        <br />
        <br />
        <Typography msg={t('msg_general_order_min_info_part_2')} tag="span" />
      </p>
      <InfoMessage type={MessageTypes.error} message={submitError} />
      <Typography msg={t('msg_general_offer_field_category_title')} tag="p" className={styles.marginBottom} />
      <FormField
        name={FormFields.category}
        component={GeneralOrderCategorySelect}
        type="text"
        serviceType={AllCategoriesProps.allCategories}
        placeholder={t('msg_general_offer_field_category_placeholder')}
        validate={validateRequired(t)}
      />
      <FormField<TextInputProps<string>>
        name={FormFields.numberOfFields}
        component={TextInput}
        type="text"
        placeholder={t('msg_general_order_field_number_of_fields_placeholder')}
        validate={validate(validateNumber(t), validateMaxIntAmount(t, MAX_NUMBER_OF_FIEDLS))}
      />
      <div className={styles.fieldGroupBlock}>
        {Array.from({ length: Math.min(10, numberOfFields) }).map((_, index) => (
          <div key={index}>
            <FormField<TextInputProps<string>>
              name={`${FormFields.amountParts}[${index}]`}
              component={TextInput}
              type="text"
              placeholder={t('msg_general_order_field_field_size_placeholder', { fieldNumber: index + 1 })}
              validate={validate(
                validateRequired(t),
                validateFloat(t),
                validateFractionDigitsLength(t, 1),
                validateMaxSize(t, MAX_AMOUNT),
              )}
            />
          </div>
        ))}
      </div>
      <Row justifyBetween size="sizeFull" className={styles.marginBottom}>
        <p>
          <Typography msg={t('msg_general_order_field_label_select_market_price')} tag="span" />{' '}
          <Tooltip
            title={t('msg_general_order_field_market_price_explainer')}
            position={TooltipPosition.right}
            className={{ tooltipContainer: styles.infoIcon }}
          >
            <Icon icon="info" size={IconSizes.s} />
          </Tooltip>
        </p>

        {isDefined(values.marketPriceSelection) && (
          <PriceDisplay
            tag="span"
            color="black"
            size="l"
            bold
            price={values.marketPriceSelection}
            pricingType={currency}
          />
        )}
      </Row>
      <Row justifyBetween size="sizeFull" className={styles.spacingBetweenSections}>
        <FormField
          name={FormFields.marketPriceSelection}
          disabled={!isMarketPriceRange}
          component={FormSliderInput}
          min={marketPrices?.marketPriceFrom || 0}
          max={marketPrices?.marketPriceTo || 0}
          step={marketPrices ? (marketPrices.marketPriceTo - marketPrices.marketPriceFrom) / 9 : 1}
          allowNull
        />
      </Row>
      <Row justifyBetween size="sizeFull" className={styles.marginBottom}>
        <p>
          <Typography msg={t('msg_general_order_field_payment_condition_label')} tag="span" />{' '}
          <Tooltip
            title={t('msg_general_order_field_payment_condition_explainer')}
            position={TooltipPosition.right}
            className={{ tooltipContainer: styles.infoIcon }}
          >
            <Icon icon="info" size={IconSizes.s} />
          </Tooltip>
        </p>
      </Row>
      <FormField
        name={FormFields.paymentCondition}
        component={GeneralOrderPaymentConditionSelect}
        type="text"
        placeholder={t('msg_general_order_field_payment_condition')}
        validate={validateRequired(t)}
      />
      <Row justifyBetween size="sizeFull" className={styles.spacingBetweenSections}>
        <Typography tag="span" color="black" size="m" msg={t('msg_general_order_full_price_display')} />
        {isDefined(finalPrice) && (
          <PriceDisplay tag="span" color="black" size="l" bold price={finalPrice} pricingType={currency} />
        )}
      </Row>
      <Typography msg={t('msg_general_order_field_location_title')} tag="p" className={styles.marginBottom} />
      <LocationInput
        addressFieldName={FormFields.address}
        coordinatesFieldName={FormFields.coordinates}
        bottomSpacing={true}
        preFillLocation={true}
        specifyLocation={true}
        placeholder={t('msg_general_order_field_location_placeholder')}
      />
      <GridRow className={styles.noRowGap}>
        <GridCol size={6}>
          <FormField
            name={FormFields.startDate}
            placeholder={t('msg_general_order_field_start_date')}
            component={DateInput}
            parse={date => normalizeDate(date, currentLanguage)}
            isValidDate={selectedDayIsAfterYesterday}
            validate={validate(
              validateRequired(t),
              validateDate(t, currentLanguage),
              validateSelectedDayIsAfterYesterday(t),
            )}
          />
        </GridCol>
        <GridCol size={6}>
          <FormField
            name={FormFields.endDate}
            placeholder={t('msg_general_order_field_end_date')}
            component={DateInput}
            parse={date => normalizeDate(date, currentLanguage)}
            isValidDate={validEndDate}
            validate={validate(
              validateSelectedDayIsAfterYesterday(t),
              validateSelectedDayIsNotBefore(t, values.startDate),
            )}
          />
        </GridCol>
      </GridRow>
      <GridRow className={styles.noRowGap}>
        <GridCol size={6}>
          <FormField<TextInputProps<string>>
            name={FormFields.name}
            component={TextInput}
            type="text"
            placeholder={t('msg_general_order_field_name')}
            validate={validate(validateRequired(t))}
          />
        </GridCol>
        <GridCol size={6}>
          <FormField<TextInputProps<string>>
            name={FormFields.surname}
            component={TextInput}
            type="text"
            placeholder={t('msg_general_order_field_surname')}
            validate={validate(validateRequired(t))}
          />
        </GridCol>
      </GridRow>
      <FormField<TextInputProps<string>>
        name={FormFields.phone}
        component={TextInput}
        placeholder={Messages.preliminaryOrderPhone}
        validate={validate(validatePhone(t, currentLanguage), validateRequired(t))}
      />
      <FormField<TextInputProps<string>>
        name={FormFields.email}
        component={TextInput}
        placeholder={Messages.preliminaryOrderEmail}
        validate={validate(validateEmail(t), validateRequired(t))}
      />
      <FormField
        name={FormFields.description}
        placeholder={t('msg_general_order_field_additional_info')}
        component={TextAreaInput}
      />
      <div className={styles.orderActions}>
        <Button
          loading={submitting}
          label={t(Messages.submitPreliminaryOrder)}
          size={ButtonSizes.fill}
          type={ButtonTypes.submit}
          disabled={submitting}
        />
        {onCancel && (
          <Button
            loading={submitting}
            label={t(Messages.cancelPreliminaryOrder)}
            size={ButtonSizes.fill}
            type={ButtonTypes.button}
            disabled={submitting}
            onClick={onCancel}
            style={ButtonStyles.outlinedGreen}
          />
        )}
      </div>
    </form>
  );
};

const GeneralOrderForm = ({ onSubmit, initialValues, onCancel }: GeneralOrderFormProps) => {
  return (
    <Form<GeneralOrderFormData>
      onSubmit={onSubmit}
      render={props => <OrderForm {...props} onCancel={onCancel} />}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    />
  );
};

export default GeneralOrderForm;
