import classNames from 'classnames';
import * as React from 'react';

import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';

import styles from './Pill.module.scss';

export interface PillProps {
  type?: 'gray' | 'green' | 'greenOutlined' | 'red' | 'blue' | 'yellow' | 'orange' | 'purple' | 'aqua';
  msg: Messages | string;
  width?: 'fixed' | 'auto';
  size?: 'm' | 'xs';
  className?: string;
}

const Pill: React.FunctionComponent<PillProps> = ({ type = 'gray', msg, width = 'auto', size = 'm', className }) => {
  return (
    <div className={classNames(styles.pill, styles[type], styles[width], styles[size], className)}>
      <Typography tag="span" msg={msg} size="s" />
    </div>
  );
};

export default Pill;
