import React, { HTMLProps } from 'react';
import ReactSlider from 'react-slider';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import { isDefined } from '@Utils/tools';
import { noop } from '@Utils/util';

import InputContainer from '../InputContainer';

import styles from './FormSliderInput.module.scss';

type FormSliderInputProps = {
  min: number;
  max: number;
  step: number;
  label?: string;
  disabled?: boolean;
  hideError?: boolean;
  onChange?: (value: number) => void;
} & FormFieldRenderProps<number | null>;

const FormSliderInput = ({
  min,
  max,
  step,
  label,
  disabled = false,
  onChange = noop,
  input,
  meta,
}: FormSliderInputProps) => {
  return (
    <InputContainer meta={meta} containerClassName={styles.container} bottomSpacing={false}>
      <Row alignCenter size="sizeFull" constant>
        <button
          type="button"
          className={styles.button}
          onClick={() => {
            if (!isDefined(input.value)) {
              return;
            }
            input.onChange(input.value - step);
            onChange(input.value - step);
          }}
          disabled={disabled || (input.value || 0) - step < min}
        >
          <Icon icon="minus-button" size={IconSizes.m} />
        </button>

        <ReactSlider
          value={input.value ?? undefined}
          onChange={value => {
            input.onChange(value);
            onChange(value);
          }}
          min={min}
          max={max}
          step={step}
          className={styles.slider}
          ariaLabel={label}
          disabled={disabled}
          renderTrack={(props, state) =>
            state.index === 0 ? <div {...(props as HTMLProps<HTMLDivElement>)} className={styles.track} /> : null
          }
          renderThumb={props => (
            <div {...(props as HTMLProps<HTMLDivElement>)} className={styles.thumbContainer}>
              <div className={styles.thumb} />
            </div>
          )}
        />

        <button
          type="button"
          className={styles.button}
          onClick={() => {
            if (!isDefined(input.value)) {
              return;
            }
            input.onChange(input.value + step);
            onChange(input.value + step);
          }}
          disabled={disabled || (input.value || 0) + step > max}
        >
          <Icon icon="plus-button" size={IconSizes.m} />
        </button>
      </Row>
    </InputContainer>
  );
};

export default FormSliderInput;
