import React, { useState } from 'react';

import Button, { ButtonStyles } from '@Components/Buttons/Button';
import Calendar, { CalendarViews } from '@Components/Calendar';
import Icon, { IconSizes } from '@Components/Icon';
import Container from '@Components/layout/Container';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { ModalProviderProps } from '@Components/Modal';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useGetAdvertScheduleQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { rangeChangeHandler } from '@Utils/calendar';
import { getInitialDates } from '@Utils/dates';
import { hasData } from '@Utils/graphql';
import { transformDataToCalendarEvents } from '@Utils/transformations';

import styles from './AvailabilityPreviewModal.module.scss';

export interface AvailabilityPreviewModalProps {
  id: string;
  onClose?: () => void;
}

const { initialStartDate, initialEndDate } = getInitialDates();

const AvailabilityPreviewModal: React.FunctionComponent<ModalProviderProps<AvailabilityPreviewModalProps>> = ({
  modalData: { id },
  closeModal,
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [currentView, setView] = useState<string>(CalendarViews.month);
  const t = useTranslations();

  const { loading, error, data } = useGetAdvertScheduleQuery({
    variables: { startDate, endDate, advert: id },
    fetchPolicy: 'network-only',
  });

  if (error || !hasData(data, loading, 'myAdvertSchedule')) {
    closeModal();
  }

  if (!data || !data.advertSchedule) {
    return (
      <Container className={styles.availabilityPreviewModalLoader}>
        <Row justifyCenter>
          <LoadingSpinner />
        </Row>
      </Container>
    );
  }

  const onRangeChange = rangeChangeHandler(setStartDate, setEndDate);
  const events = transformDataToCalendarEvents(data.advertSchedule, currentView);

  return (
    <div className={styles.container}>
      <Row justifyEnd>
        <Button onClick={closeModal} className={styles.closeButton} style={ButtonStyles.withIconWithoutBg}>
          <Icon icon="menu-close-button" size={IconSizes.s} />
          <Typography msg={t(Messages.btnModalClose)} tag="span" />
        </Button>
      </Row>
      <Calendar calendarProps={{ events, onRangeChange, onView: setView }} />
    </div>
  );
};

export default AvailabilityPreviewModal;
