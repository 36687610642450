import React, { FunctionComponent } from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import SelectInput, { SelectFieldProps } from '@Components/forms/inputs/SelectInput';
import { Messages } from '@Config/messages';
import { PaymentType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

export interface AvailablePaymentTypesProps {
  availablePaymentTypes: PaymentType[];
}

interface OrderPaymentTypeSelectProps extends FormFieldRenderProps<SelectFieldProps>, AvailablePaymentTypesProps {}

const OrderPaymentTypeSelect: FunctionComponent<OrderPaymentTypeSelectProps> = props => {
  const t = useTranslations();
  const { availablePaymentTypes, ...rest } = props;

  const translations = {
    [PaymentType.AgreedPrice]: Messages.labelAgreedPaymentType,
    [PaymentType.CreditCard]: Messages.labelCreditCardPaymentType,
    [PaymentType.BankTransfer]: Messages.labelBankTransferPaymentType,
  } as const;

  const options = availablePaymentTypes.map(paymentType => ({
    label: t(translations[paymentType]),
    value: paymentType,
  }));

  return <SelectInput options={options} placeholder={Messages.orderPaymentTypePlaceholder} {...rest} />;
};

export default OrderPaymentTypeSelect;
