import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Buttons/Button';
import FormField from '@Components/forms/FormField';
import { FieldSubscribe } from '@Components/forms/formUtils';
import Checkbox from '@Components/forms/inputs/Checkbox';
import SelectInput from '@Components/forms/inputs/SelectInput';
import TextInput, { TextInputProps } from '@Components/forms/inputs/TextInput';
import InfoMessage, { MessageTypes } from '@Components/InfoMessage';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import config from '@Config/config';
import { USER_TOURS, UserTourStates, UserTourSteps, VERSION } from '@Config/constants';
import { buildMeasurementTypes, buildMessageKeyForEnum, Messages } from '@Config/messages';
import {
  ActivitySizeMeasureType,
  CompanyCompanyType,
  CompanyMutationInput,
  useGetActivitiesQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showUserTour } from '@Store/app/app.actions';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import { getUserIsLogged } from '@Store/auth/auth.selector';
import {
  validate,
  validateMaxIntAmount,
  validateMaxLength,
  validateMaxSize,
  validateMinAmount,
  validatePhone,
  validateRequired,
} from '@Utils/form';
import { getFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { Translator } from '@Utils/translation';

import styles from './CompanyForm.module.scss';

interface CompanyFormProps {
  onSubmit: (values: CompanyMutationInput) => Promise<{}>;
  initialValues?: CompanyMutationInput;
  isEditForm?: boolean;
  onCancel?: () => void;
}

enum FormFields {
  name = 'name',
  phone = 'phone',
  companyType = 'companyType',

  // Farm fields
  activity = 'activity',
  size = 'size',
}
type MeasurementSelection = {
  label: string;
  value: string;
  measurementType: ActivitySizeMeasureType;
};

const validateAccordingToMeasurementType = (t: Translator, selected: MeasurementSelection | undefined) => {
  if ((selected && selected.measurementType === ActivitySizeMeasureType.Hectare) || !selected) {
    return validateMaxSize(t, 100000);
  }
  return validateMaxIntAmount(t, 100000);
};

const renderForm = (
  { handleSubmit, submitting, submitError, values, form }: FormRenderProps<CompanyMutationInput>,
  isEditForm?: boolean,
  onCancel?: CompanyFormProps['onCancel'],
): ReactNode => {
  const { loading, data } = useGetActivitiesQuery({});
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);

  const activities: MeasurementSelection[] =
    (!loading &&
      data &&
      data.activities.edges
        .sort((a, b) => a.node.orderNum - b.node.orderNum)
        .map(item => {
          const { node } = item;
          return {
            label: node.name,
            value: node.id,
            measurementType: node?.sizeMeasureType,
          };
        })) ||
    [];

  const selected = activities.find((item: { value: string }) => item.value === values.activity);

  const renderFarmFields = () => {
    return (
      <GridRow className={styles.fieldRow}>
        <GridCol size={6}>
          <FormField
            name={FormFields.activity}
            type="text"
            component={SelectInput}
            placeholder={Messages.companyFormFarmActivity}
            validate={validateRequired(t)}
            options={activities}
          />
        </GridCol>
        <GridCol size={6}>
          <FieldSubscribe
            when={FormFields.activity}
            onChange={() => {
              return form.change(FormFields.size, undefined);
            }}
            render={() => (
              <FormField<TextInputProps<CompanyMutationInput[FormFields.size]>>
                name={FormFields.size}
                component={TextInput}
                type="number"
                placeholder={selected ? buildMeasurementTypes(selected.measurementType) : Messages.companyFormHectare}
                validate={validate(validateMinAmount(t, 0), validateAccordingToMeasurementType(t, selected))}
              />
            )}
          />
        </GridCol>
      </GridRow>
    );
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <InfoMessage type={MessageTypes.error} message={submitError} />
      <Row className={styles.typeSelection}>
        <div data-tutorial-step={UserTourSteps.companyPage.companyStep1}>
          {Object.values(CompanyCompanyType).map(companyType => {
            return (
              <FormField
                key={companyType}
                name={FormFields.companyType}
                component={Checkbox}
                inline
                type="radio"
                validate={validateRequired(t)}
                value={companyType}
                label={buildMessageKeyForEnum(companyType)}
                className={styles.companyType}
                bottomSpacing={false}
              />
            );
          })}
        </div>
      </Row>
      <FormField<TextInputProps<CompanyMutationInput[FormFields.name]>>
        name={FormFields.name}
        type="text"
        component={TextInput}
        helperText={t(Messages.helperTextCompanyFormName)}
        placeholder={Messages.companyFormName}
        validate={validate(validateRequired(t), validateMaxLength(t, 50))}
      />
      <FormField<TextInputProps<CompanyMutationInput[FormFields.phone]>>
        name={FormFields.phone}
        component={TextInput}
        type="text"
        placeholder={Messages.companyFormPhone}
        validate={validate(validatePhone(t, currentLanguage), validateRequired(t))}
      />
      <FieldSubscribe
        when={FormFields.companyType}
        onChange={() => {
          form.change(FormFields.activity, '');
          form.change(FormFields.size, 0);
        }}
        render={(value: CompanyCompanyType): ReactNode | null =>
          (value === CompanyCompanyType.Farm && renderFarmFields()) || null
        }
      />
      <Row justifyBetween className={classNames(styles.updateAccountRow, { [styles.createActions]: !isEditForm })}>
        <div data-tutorial-step={UserTourSteps.companyPage.companyStep2}>
          <Button
            label={t(isEditForm ? Messages.updateForm : Messages.createCompanyButton)}
            loading={submitting}
            disabled={submitting}
            size={ButtonSizes.l}
            type={ButtonTypes.submit}
          />
        </div>
        {!isEditForm && onCancel && (
          <Button
            label={t(Messages.btnCancel)}
            disabled={submitting}
            size={ButtonSizes.l}
            type={ButtonTypes.button}
            style={ButtonStyles.outlinedGreen}
            onClick={onCancel}
          />
        )}
        {isEditForm && (
          <Col className={styles.deleteAccountSection}>
            <Typography tag="p" size="m" msg={t(Messages.createCompanyDeleteYourAccount)} />
            <Typography
              tag="p"
              size="m"
              color="primary"
              msg={t(Messages.createCompanyCustomerSupportEmail)}
              className={styles.emailAddress}
            />
            <Typography tag="p" size="xs" color="gray" msg={`Ver: ${config.NODE_ENV[0]}${VERSION.version}`} />
          </Col>
        )}
      </Row>
    </form>
  );
};

const CompanyForm: React.FC<CompanyFormProps> = ({ onSubmit, isEditForm, initialValues, onCancel }) => {
  const t = useTranslations();
  const isFirstLogin = !getFromLocalStorage(USER_TOURS.companyPageTour);
  const isLoggedIn = useSelector(getUserIsLogged);
  const dispatch = useDispatch();

  const userTourSteps = [
    {
      target: UserTourSteps.companyPage.companyStep1,
      content: t(`msg_label_company_tour_company_step1`),
    },
    {
      target: UserTourSteps.companyPage.companyStep2,
      content: t(`msg_label_company_tour_company_step2`),
    },
    {
      target: UserTourSteps.companyPage.companyStep3,
      content: t(`msg_label_company_tour_company_step3`),
    },
  ];

  React.useEffect(() => {
    if (isFirstLogin && isLoggedIn) {
      dispatch(showUserTour(userTourSteps));
      setToLocalStorage(USER_TOURS.companyPageTour, UserTourStates.off);
    }
    return () => {
      dispatch(showUserTour([]));
    };
  }, []);

  return (
    <Form<CompanyMutationInput>
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={props => renderForm(props, isEditForm, onCancel)}
    />
  );
};

export default CompanyForm;
