import React from 'react';
import { Field, FieldInputProps, FieldMetaState, UseFieldConfig } from 'react-final-form';

export interface FormFieldRenderProps<FieldValue, T extends HTMLElement = HTMLElement> {
  input: FieldInputProps<FieldValue, T>;
  meta: FieldMetaState<FieldValue>;
}

type RenderableProps<T> =
  | {
      children: ((props: T) => React.ReactNode) | React.ReactNode;
    }
  | {
      component: React.ComponentType<T>;
    }
  | {
      render: (props: T) => React.ReactNode;
    };

type FormFieldProps<TComponentProps extends FormFieldRenderProps<unknown>> = {
  name: string;
} & RenderableProps<TComponentProps> &
  UseFieldConfig<TComponentProps['input']['value']> &
  Omit<TComponentProps, keyof FormFieldRenderProps<unknown>>;

const FormField = <TComponentProps extends FormFieldRenderProps<unknown>>(props: FormFieldProps<TComponentProps>) => {
  /* This wraps react-final-form's <Field/> component.
   * parse ensures form values never get set to null,
   * but rather, empty strings.
   * See https://github.com/final-form/react-final-form/issues/130
   */
  return <Field parse={value => value} {...props} />;
};

export default FormField;
