import { LOCATION_CHANGE } from 'connected-react-router';
import { History } from 'history';
import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

interface CustomConnectedRouterProps {
  history: History;
  children: React.ReactNode;
  dispatch: Dispatch<AnyAction>;
}

const CustomConnectedRouter = ({ history, children, dispatch }: CustomConnectedRouterProps) => {
  const [location, setLocation] = useState(history.location);

  useLayoutEffect(() => {
    const unlisten = history.listen(({ location, action }) => {
      setLocation(location);

      dispatch({
        type: LOCATION_CHANGE,
        payload: {
          location,
          action,
        },
      });
    });

    return () => {
      unlisten();
    };
  }, [history, dispatch]);

  return (
    <Router location={location} navigator={history}>
      {children}
    </Router>
  );
};

export default connect()(CustomConnectedRouter);
