import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonStyles } from '@Components/Buttons/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Typography from '@Components/Typography/Typography';
import { getDefaultLanguage } from '@Config/localization';
import { LanguageNode, useChangeLanguageMutation } from '@Graphql/graphqlTypes.generated';
import { changeLanguage } from '@Store/app/app.actions';
import { getEnabledLanguages } from '@Store/app/app.selectors';
import { UserData } from '@Store/auth/auth.types';

import styles from './LanguageDropdown.module.scss';

export interface LanguageDropdownProps {
  position?: 'top' | 'bottom' | 'right' | 'left';
  transparentHeader?: boolean;
  user?: UserData;
}

const LanguageDropdown: React.FunctionComponent<LanguageDropdownProps> = ({
  position = 'right',
  transparentHeader,
  user,
}) => {
  const enabledLanguages = useSelector(getEnabledLanguages);
  const currentLanguage = useSelector(getDefaultLanguage);
  const dispatch = useDispatch();

  const [updateLanguage] = useChangeLanguageMutation({
    onCompleted: ({
      changeLanguage: {
        language: { code },
      },
    }) => {
      dispatch(changeLanguage({ lang: code }));
    },
  });

  const changeLang = (lang: string) => {
    if (!user?.item) {
      return dispatch(changeLanguage({ lang }));
    }
    return updateLanguage({ variables: { input: { languageCode: lang, clientMutationId: user.item.info.id } } });
  };

  const dropdownLanguages = (
    <ul className={classNames(styles.dropdownContent, styles[position])}>
      {/*TODO remove type cast*/}
      {(enabledLanguages as LanguageNode[]).map((lang, index: number) => (
        <li key={index} onClick={() => changeLang(lang.code)} className={styles.dropdownItem}>
          <Button style={ButtonStyles.plain}>
            <Typography tag="span" color="black" size="s" msg={lang.code.toUpperCase()} />
          </Button>
        </li>
      ))}
    </ul>
  );

  return (
    <div className={styles.languageDropdown}>
      <div className={classNames(styles.icon, transparentHeader ? styles.white : styles.black)}>
        <Typography tag="span" color={transparentHeader ? 'white' : 'black'} msg={currentLanguage.toUpperCase()} />
        <Icon icon="chevron-down" size={IconSizes.xs} />
      </div>
      {dropdownLanguages}
    </div>
  );
};

export default LanguageDropdown;
