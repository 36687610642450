import { GoogleMap, Marker } from '@react-google-maps/api';
import React from 'react';
import { Form, FormRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Buttons/Button';
import FormField from '@Components/forms/FormField';
import Checkbox from '@Components/forms/inputs/Checkbox';
import GeneralOfferPaymentConditionSelect from '@Components/forms/inputs/selects/GeneraOfferPaymentConditionSelect';
import TextAreaInput from '@Components/forms/inputs/TextAreaInput/TextAreaInput';
import TextInput, { TextInputProps } from '@Components/forms/inputs/TextInput';
import InfoMessage, { MessageTypes } from '@Components/InfoMessage';
import Row from '@Components/layout/Row';
import { getFinalPrice } from '@Components/Order/GeneralOrderAcceptance/generalOfferUtils';
import PriceDisplay from '@Components/PriceDisplay';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { GeneralOrderFetchResultFragment } from '@Graphql/graphqlTypes.generated';
import { useCurrency } from '@Hooks/useCurrency';
import { useTranslateObject } from '@Hooks/useTranslateObject';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import {
  validate,
  validateEmail,
  validateMaxAmount,
  validateNumber,
  validatePhone,
  validateRequired,
} from '@Utils/form';
import { isDefined } from '@Utils/tools';
import { GeneralOrderAcceptanceFormData } from '@Utils/types';

import styles from './GeneralOrderAcceptanceForm.module.scss';

type GeneralOrderAcceptanceFormProps = {
  onSubmit: (values: GeneralOrderAcceptanceFormData) => Promise<{}>;
  onCancel?: () => void;
  initialValues: GeneralOrderAcceptanceFormData;
  generalOrder: GeneralOrderFetchResultFragment;
};

type OfferFormProps = FormRenderProps<GeneralOrderAcceptanceFormData> &
  Pick<GeneralOrderAcceptanceFormProps, 'generalOrder' | 'onCancel'>;

enum FormFields {
  email = 'email',
  phone = 'phone',
  paymentCondition = 'paymentCondition',
  agreedWithPrice = 'agreedWithPrice',
  offeredPrice = 'offeredPrice',
  additionalInfo = 'additionalInfo',
}

const OfferForm = ({ generalOrder, handleSubmit, submitting, submitError, onCancel }: OfferFormProps): JSX.Element => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const currency = useCurrency();
  const selectTranslation = useTranslateObject();

  const agreedWithPriceField = useField(FormFields.agreedWithPrice);
  const paymentConditionField = useField(FormFields.paymentCondition);
  const offeredPriceField = useField(FormFields.offeredPrice);

  const finalPrice = getFinalPrice({
    orderPrice: generalOrder.totalPrice,
    paymentCondition: paymentConditionField.input.value,
    offeredPrice: offeredPriceField.input.value,
    agreedWithPrice: agreedWithPriceField.input.value,
  });

  const oderLatLng = {
    lat: generalOrder.coordinates.latitude,
    lng: generalOrder.coordinates.longitude,
  };

  return (
    <form onSubmit={handleSubmit} className={styles.orderContainer}>
      <InfoMessage type={MessageTypes.error} message={submitError} />
      <Row justifyBetween size="sizeFull" className={styles.spacingBetweenSections}>
        <Typography tag="span" bold color="black" size="l" msg={selectTranslation(generalOrder.category.name)} />
      </Row>
      <Row justifyBetween size="sizeFull" className={styles.spacingBetweenSections}>
        <Typography tag="span" color="black" size="m" msg={t('msg_general_offer_order_price_display')} />
        <PriceDisplay tag="span" color="black" size="l" bold price={generalOrder.totalPrice} pricingType={currency} />
      </Row>
      <Row justifyBetween size="sizeFull" className={styles.spacingBetweenSections}>
        <Typography tag="span" color="black" size="m" msg={t('msg_general_offer_offer_amount_display')} />
        <Typography tag="span" color="black" size="l" bold msg={`${generalOrder.amount} ha`} />
      </Row>

      {generalOrder.amountParts.length > 1 && (
        <Row justifyBetween size="sizeFull" className={styles.spacingBetweenSections}>
          <Typography tag="span" color="black" size="m" msg={t('msg_general_offer_offer_fields_display')} />
          <div style={{ display: 'inline-block' }}>
            {generalOrder.amountParts.map((part, index) => (
              <Typography
                key={index}
                tag="p"
                color="black"
                size="l"
                bold
                msg={`${part} ha`}
                className={styles.textDirection}
              />
            ))}
          </div>
        </Row>
      )}

      <div className={styles.spacingBetweenSections}>
        <GoogleMap
          zoom={8}
          center={oderLatLng}
          onLoad={map => {
            map.setZoom(8);
          }}
          mapContainerStyle={{ height: '25vh' }}
        >
          <Marker position={oderLatLng} />
        </GoogleMap>
      </div>

      <div className={styles.inlineContainer}>
        <FormField
          name={FormFields.agreedWithPrice}
          component={Checkbox}
          type="radio"
          label="msg_general_offer_field_radio_agree_with_price"
          value="YES"
          checkedDisabled={false}
        />
        <FormField
          name={FormFields.agreedWithPrice}
          component={Checkbox}
          type="radio"
          label="msg_general_offer_field_radio_offer_your_price"
          value="NO"
          checkedDisabled={false}
        />
      </div>
      {agreedWithPriceField.input.value === 'NO' && (
        <FormField<TextInputProps<string>>
          name={FormFields.offeredPrice}
          component={TextInput}
          placeholder="msg_general_offer_field_offered_price_placeholder"
          validate={validate(validateRequired(t), validateNumber(t), validateMaxAmount(t, 99999999999))}
        />
      )}
      <FormField
        name={FormFields.paymentCondition}
        component={GeneralOfferPaymentConditionSelect}
        type="text"
        placeholder={t('msg_general_order_field_payment_condition')}
        validate={validateRequired(t)}
      />
      <Row justifyBetween size="sizeFull" className={styles.spacingBetweenSections}>
        <Typography tag="span" color="black" size="m" msg={t('msg_general_offer_final_price_display')} />
        {isDefined(finalPrice) && (
          <PriceDisplay tag="span" color="black" size="l" bold price={finalPrice} pricingType={currency} />
        )}
      </Row>
      <FormField<TextInputProps<string>>
        name={FormFields.phone}
        component={TextInput}
        placeholder={Messages.preliminaryOrderPhone}
        validate={validate(validatePhone(t, currentLanguage), validateRequired(t))}
      />
      <FormField<TextInputProps<string>>
        name={FormFields.email}
        component={TextInput}
        placeholder={Messages.preliminaryOrderEmail}
        validate={validate(validateEmail(t), validateRequired(t))}
      />
      <FormField
        name={FormFields.additionalInfo}
        placeholder={t('msg_general_order_field_additional_info')}
        component={TextAreaInput}
      />
      <div className={styles.orderActions}>
        <Button
          loading={submitting}
          label={t(Messages.submitPreliminaryOrder)}
          size={ButtonSizes.fill}
          type={ButtonTypes.submit}
          disabled={submitting}
        />
        {onCancel && (
          <Button
            loading={submitting}
            label={t(Messages.cancelPreliminaryOrder)}
            size={ButtonSizes.fill}
            type={ButtonTypes.button}
            disabled={submitting}
            onClick={onCancel}
            style={ButtonStyles.outlinedGreen}
          />
        )}
      </div>
    </form>
  );
};

const GeneralOrderAcceptanceForm = ({
  onSubmit,
  initialValues,
  onCancel,
  generalOrder,
}: GeneralOrderAcceptanceFormProps) => {
  return (
    <Form<GeneralOrderAcceptanceFormData>
      onSubmit={onSubmit}
      render={props => <OfferForm {...props} onCancel={onCancel} generalOrder={generalOrder} />}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    />
  );
};

export default GeneralOrderAcceptanceForm;
