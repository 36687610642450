import 'core-js/features/array/at';
import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom/client';

import config from '@Config/config';
import { setMomentLocale } from '@Utils/dateMoment';
import ApolloClient from '@Utils/graphqlClient';
import '@Styles/index.scss';
import 'jodit/es2021/jodit.min.css';

import Root from './js/components/root';
import configureStore, { history } from './js/store/configureStore';

import '@Utils/i18n';

if (config.SENTRY_DSN) {
  Sentry.init({ dsn: config.SENTRY_DSN, environment: config.SENTRY_ENVIRONMENT });
}
setMomentLocale();
const store = configureStore();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ApolloProvider client={ApolloClient}>
    <Root store={store} history={history} />
  </ApolloProvider>,
);
