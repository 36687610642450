import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import lazyImport from '@Utils/lazyImport';

import { RouteInfo } from '../appRoutes';

const CategoryPage = lazyImport(() => import('@Routes/category/CategoryPage'));

const routes: RouteInfo[] = [
  {
    path: RouteConfig.Category.template,
    component: CategoryPage,
    title: Messages.categoryPage,
  },
];

export default routes;
