import classNames from 'classnames';
import React from 'react';

import Typography, { HeaderTags, TextColors, TextSizes, TextTags } from '@Components/Typography';
import { buildMessageKeyForEnum, Messages } from '@Config/messages';
import { AdvertPricingType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { convertCentsToMoney } from '@Utils/money';
import { MessageKeysWithoutParams } from '@Utils/translation';

import styles from './PriceDisplay.module.scss';

export interface PriceDisplayProps {
  color?: TextColors;
  tag: TextTags | HeaderTags;
  size?: TextSizes;
  bold?: boolean;
  price: number;
  pricingType: AdvertPricingType | string;
  className?: string;
  priceSize?: TextSizes;
  priceBold?: boolean;
  priceColor?: TextColors;
}

const PriceDisplay: React.FunctionComponent<PriceDisplayProps> = ({
  priceSize,
  priceBold,
  priceColor,
  price,
  pricingType,
  size,
  color,
  bold,
  tag,
  className,
}) => {
  const t = useTranslations();
  if (pricingType === AdvertPricingType.NegotiablePrice || price === 0) {
    return (
      <div className={className}>
        <Typography
          className={styles.priceDisplay}
          msg={t(Messages.adPricingTypeNegotiablePrice)}
          tag="span"
          bold={priceBold || bold}
          color={priceColor || color}
          size={priceSize}
        />
      </div>
    );
  }

  if (pricingType === AdvertPricingType.FreeItem) {
    return (
      <div className={className}>
        <Typography
          className={styles.priceDisplay}
          msg={t(Messages.adPricingTypeFreeItemPrice)}
          tag="span"
          bold={priceBold || bold}
          color={priceColor || color}
        />
      </div>
    );
  }
  return (
    <div className={classNames(styles.priceDisplayContainer, className)}>
      <Typography
        tag={tag}
        size={priceSize || size}
        color={priceColor || color}
        bold={priceBold || bold}
        msg={convertCentsToMoney(price).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
      />
      <Typography
        tag={tag}
        size={size}
        color={color}
        bold={bold}
        msg={`${t(buildMessageKeyForEnum(pricingType) as MessageKeysWithoutParams)}`}
      />
    </div>
  );
};

export default PriceDisplay;
