import classNames from 'classnames';
import { FieldValidator } from 'final-form';
import React from 'react';
import { useField, useFormState } from 'react-final-form';

import InputContainer from '@Components/forms/inputs/InputContainer/InputContainer';
import LocationSelect, { LocationValue } from '@Components/LocationSelect';
import { CoordinateType } from '@Graphql/graphqlTypes.generated';
import { hasFieldError } from '@Utils/form';
import { noop } from '@Utils/util';

import styles from './LocationInputStyles.module.scss';

interface LocationInputProps {
  addressFieldName: string;
  coordinatesFieldName?: string;
  validate?: FieldValidator<any>;
  bottomSpacing?: boolean;
  onLocationInputChange?: () => void;
  preFillLocation?: boolean;
  specifyLocation?: boolean;
  placeholder?: string;
}

const LocationInput: React.FC<LocationInputProps> = ({
  coordinatesFieldName = '',
  addressFieldName,
  bottomSpacing,
  validate,
  onLocationInputChange = noop,
  preFillLocation,
  specifyLocation,
  placeholder,
}) => {
  const formInfo = useFormState();
  const { values } = formInfo;
  const addressField = useField<string>(addressFieldName, { validate, defaultValue: values[addressFieldName] });

  const coordinatesField = useField<CoordinateType>(coordinatesFieldName, {
    defaultValue: values[coordinatesFieldName],
  });

  const hasError = hasFieldError(addressField.meta);

  const onChange = (value: LocationValue) => {
    addressField.input.onChange(value.address);

    coordinatesField.input.name !== '' &&
      coordinatesField.input.onChange({
        longitude: value.lng,
        latitude: value.lat,
      });
    onLocationInputChange();
  };

  return (
    <InputContainer
      containerClassName={classNames(styles.locationSelectContainer, { [styles.error]: hasError })}
      className={styles.locationSelectContainerWrapper}
      meta={addressField.meta}
      bottomSpacing={bottomSpacing}
      showErrorIcon
    >
      <div className={styles.locationSelectWrapper}>
        <LocationSelect
          error={hasError}
          onChange={onChange}
          inputName={addressField.input.name}
          addressValue={addressField.input.value}
          preFillLocation={preFillLocation}
          specifyLocation={specifyLocation}
          coordinates={{
            lat: coordinatesField.input.value.latitude,
            lng: coordinatesField.input.value.longitude,
          }}
          placeholder={placeholder}
        />
      </div>
    </InputContainer>
  );
};

export default LocationInput;
