import classNames from 'classnames';
import * as React from 'react';

import Button, { ButtonSizes, ButtonStyles } from '@Components/Buttons/Button';
import { ModalProviderProps } from '@Components/Modal';

import modalStyles from '../Modal.module.scss';

export enum ActionsDisplayType {
  column = 'column',
  row = 'row',
}

export interface SuccessModalData {
  onClose?: () => void;
  editRoute?: string;
  buttonText: string;
  secondButtonText?: string;
  secondButtonAction?: () => void;
  actionsDisplayType?: ActionsDisplayType;
}

const SuccessModalBody: React.FunctionComponent<ModalProviderProps<SuccessModalData>> = ({
  modalData: { buttonText, onClose, secondButtonText, secondButtonAction, actionsDisplayType = ActionsDisplayType.row },
  closeModal,
}) => {
  const onButtonClick = () => {
    onClose && onClose();
    closeModal();
  };

  return (
    <div className={classNames(modalStyles.actions, modalStyles[actionsDisplayType])}>
      <Button label={buttonText} size={ButtonSizes.fill} onClick={onButtonClick} />
      {secondButtonText && (
        <Button
          label={secondButtonText}
          size={ButtonSizes.fill}
          style={ButtonStyles.outlinedGreen}
          onClick={secondButtonAction}
        />
      )}
    </div>
  );
};

export default SuccessModalBody;
