import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import NotificationBox from '@Components/forms/NotificationBox';
import Icon, { IconSizes } from '@Components/Icon';
import { RouteConfig } from '@Config/routes';
import { useGetUnreadNotificationsCountQuery } from '@Graphql/graphqlTypes.generated';

import styles from './HeaderNotifications.module.scss';

interface HeaderNotifications {
  white?: boolean;
}

const HeaderNotifications: React.FC<HeaderNotifications> = ({ white = false }) => {
  const { data, loading } = useGetUnreadNotificationsCountQuery({
    fetchPolicy: 'network-only',
  });

  const count: number = data?.me.myNotifications.totalCount || 0;
  const showBadge: boolean = count > 0 && !loading;
  return (
    <Link to={RouteConfig.Activity.buildLink()} className={classNames(styles.container, { [styles.white]: white })}>
      <Icon icon="bell" size={IconSizes.s} />
      {showBadge && <NotificationBox count={count} className={styles.notificationBoxPosition} />}
    </Link>
  );
};

export default HeaderNotifications;
