import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { ButtonProps, ButtonSizes, ButtonStyles } from '@Components/Buttons/Button';
import styles from '@Components/Buttons/Button/Button.module.scss';
import Icon, { IconSizes } from '@Components/Icon';
import Typography from '@Components/Typography';

const ButtonLink: React.FunctionComponent<ButtonProps & { link: string }> = props => {
  const {
    onClick,
    disabled = false,
    loading = false,
    children = null,
    label = null,
    size = ButtonSizes.l,
    link,
    style = ButtonStyles.regular,
    className,
  } = props;

  return (
    <Link
      to={link}
      onClick={onClick}
      className={classNames(styles.button, styles[size], styles[style], { [styles.disabled]: disabled }, className)}
    >
      {loading ? (
        <Icon icon="spinner" spin size={IconSizes.s} />
      ) : (
        <span className={styles.childrenContainer}>
          {label && <Typography msg={label} tag="span" />}
          {children}
        </span>
      )}
    </Link>
  );
};

export default ButtonLink;
