import classNames from 'classnames';
import React, { ReactNode, useCallback } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useDispatch } from 'react-redux';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Buttons/Button';
import FormField from '@Components/forms/FormField';
import Checkbox from '@Components/forms/inputs/Checkbox';
import OrderPaymentTypeSelect from '@Components/forms/inputs/selects/OrderPaymentTypeSelect';
import TextAreaInput, { TextAreaInputProps } from '@Components/forms/inputs/TextAreaInput';
import InfoMessage, { MessageTypes } from '@Components/InfoMessage';
import { showAvailabilityPreviewModal } from '@Components/Modal/modalHelpers';
import { validationsByPricingType } from '@Components/Order/OldOrderFormContainer/OldOrderForm/oldOrderFormValidations';
import PriceDisplay from '@Components/PriceDisplay';
import Typography from '@Components/Typography/Typography';
import { UserTourSteps } from '@Config/constants';
import { Messages } from '@Config/messages';
import { AdvertPricingType, AdvertServiceType, DeliveryType, PaymentType } from '@Graphql/graphqlTypes.generated';
import { useCurrency } from '@Hooks/useCurrency';
import { useTranslations } from '@Hooks/useTranslations';
import { advertPricingTypeMessages } from '@Routes/orders/OrderPage/OrderSummary/messages';
import { convertToFloatNumber } from '@Utils/convertions';
import { buildDateAndTime } from '@Utils/dates';
import { validateRequired } from '@Utils/form';
import { calculateOrder, convertCentsToMoney } from '@Utils/money';
import { Translator } from '@Utils/translation';
import { OldOrderFormData } from '@Utils/types';

import styles from './OldOrderForm.module.scss';
import AmountField from './PricingTypeFields/AmountField/AmountField';
import TimeFrameField from './PricingTypeFields/TimeFrameField/TimeFrameField';

interface OldOrderFormProps {
  onSubmit: (values: OldOrderFormData) => Promise<{}>;
  initialValues?: OldOrderFormData;
  onCancelClick: () => void;
  isEditForm: boolean;
  advertId: string;
  deliveryType: DeliveryType;
  advertPrice: number;
  deliveryPrice: number;
  isScheduled: boolean;
  advertPricingType: AdvertPricingType;
  advertServiceType: AdvertServiceType;
  amount?: number;
  availablePaymentTypes: PaymentType[];
}

export enum OldOrderFormFields {
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
  timeFrom = 'timeFrom',
  timeTo = 'timeTo',
  description = 'description',
  amount = 'amount',
  paymentType = 'paymentType',
  deliveryIsRequired = 'deliveryIsRequired',
}

const getDeliveryFieldAdditionalProps = (
  deliveryPrice: number | string,
  deliveryTye: DeliveryType,
  currency: string,
) => {
  const disabled = deliveryTye !== DeliveryType.Optional;
  if (deliveryTye === DeliveryType.NoDelivery) {
    return {
      disabled,
      label: Messages.orderDeliveryOption,
    };
  }
  return {
    disabled,
    label: Messages.orderDeliveryOptionWithPrice,
    labelParams: { price: `(${deliveryPrice} ${currency})` },
  };
};

const validateOrderFormCreator = (pricingType: AdvertPricingType, t: Translator) => (values: OldOrderFormData) => {
  return validationsByPricingType[pricingType](values, t);
};

const renderActions = (submitting: boolean, onCancelClick: () => void, isEditForm: boolean, t: Translator) => {
  const submitMessage = isEditForm ? Messages.orderEditSubmitChanges : Messages.createOrder;
  return (
    <>
      <Button
        loading={submitting}
        label={t(submitMessage)}
        size={ButtonSizes.fill}
        type={ButtonTypes.submit}
        disabled={submitting}
      />
      {isEditForm && (
        <Button
          loading={submitting}
          label={t(Messages.orderEditCancelChanges)}
          size={ButtonSizes.fill}
          style={ButtonStyles.outlinedGreen}
          disabled={submitting}
          onClick={onCancelClick}
        />
      )}
    </>
  );
};

const formatAndCalculatePrice = (
  { dateFrom, timeFrom, dateTo, timeTo, deliveryIsRequired }: OldOrderFormData,
  price: number,
  adDeliveryPrice: number,
  type: AdvertPricingType,
  amount: number | undefined,
) => {
  const startDate = (dateFrom && timeFrom && buildDateAndTime(dateFrom, timeFrom)) || undefined;
  const endDate = (dateTo && timeTo && buildDateAndTime(dateTo, timeTo)) || undefined;
  const deliveryPrice = deliveryIsRequired ? adDeliveryPrice : 0;
  return {
    ...calculateOrder({ startDate, endDate, price, deliveryPrice, type, amount }),
    startDate,
    endDate,
  };
};

const renderForm = (
  { handleSubmit, submitting, submitError, values }: FormRenderProps<OldOrderFormData>,
  isEditForm: boolean,
  advertId: string,
  advertPrice: number,
  deliveryPrice: number,
  deliveryType: DeliveryType,
  isScheduled: boolean,
  advertPricingType: AdvertPricingType,
  onCancelClick: () => void,
  advertServiceType: AdvertServiceType,
  availablePaymentTypes: PaymentType[],
): ReactNode => {
  const dispatch = useDispatch();
  const t = useTranslations();

  // todo should be goods have default amount equal to 1?
  const amount = convertToFloatNumber(values.amount);
  const priceAndTime = formatAndCalculatePrice(values, advertPrice, deliveryPrice, advertPricingType, amount);

  const currency = useCurrency();

  const isNegotiablePrice = advertPricingType === AdvertPricingType.NegotiablePrice;
  const isFreeItem = advertPricingType === AdvertPricingType.FreeItem;

  const calendarIconClickHandler = () => {
    dispatch(showAvailabilityPreviewModal({ id: advertId }));
  };

  return (
    <form onSubmit={handleSubmit} className={styles.orderContainer}>
      <InfoMessage type={MessageTypes.error} message={submitError} />
      <Typography msg={t(Messages.orderFormulateOrder)} tag="h5" size="m" className={styles.createOrderSpacing} />
      <div data-tutorial-step={UserTourSteps.advertPage.adStep2}>
        {!(advertServiceType === AdvertServiceType.Service && advertPricingType === AdvertPricingType.FreeItem) && (
          <AmountField advertPricingType={advertPricingType} />
        )}
        {advertServiceType === AdvertServiceType.Service && (
          <div className={styles.spacingBetweenSections}>
            <TimeFrameField isScheduled={isScheduled} onClickCalendarIcon={calendarIconClickHandler} />
          </div>
        )}
        {!isNegotiablePrice && !isFreeItem && (
          <FormField
            name={OldOrderFormFields.paymentType}
            availablePaymentTypes={availablePaymentTypes}
            component={OrderPaymentTypeSelect}
            validate={validateRequired(t)}
          />
        )}
        <FormField
          name={OldOrderFormFields.deliveryIsRequired}
          component={Checkbox}
          inline
          type="checkbox"
          {...getDeliveryFieldAdditionalProps(convertCentsToMoney(deliveryPrice), deliveryType, currency)}
        />
      </div>
      <div data-tutorial-step={UserTourSteps.advertPage.adStep3}>
        <div className={styles.orderSection}>
          <div className={styles.orderInfo}>
            {!isNegotiablePrice && !isFreeItem && (
              <Typography tag="span" color="gray" size="s" msg={t(advertPricingTypeMessages[advertPricingType])} />
            )}
            {isNegotiablePrice || isFreeItem ? (
              <Typography
                tag="span"
                size="s"
                msg={t(isNegotiablePrice ? Messages.adPricingTypeNegotiablePrice : Messages.adPricingTypeFreeItemPrice)}
              />
            ) : (
              <PriceDisplay tag="span" color="black" size="s" price={priceAndTime.price} pricingType={currency} />
            )}
          </div>
        </div>
        <div className={styles.orderSection}>
          <div className={styles.orderInfo}>
            <Typography tag="span" color="gray" size="s" msg={t(Messages.orderDeliveryPrice)} />
            <PriceDisplay tag="span" color="black" size="s" price={priceAndTime.deliveryPrice} pricingType={currency} />
          </div>
        </div>
        {/*These fields are hidden temporarily
      <div className={styles.orderSection}>
        <div className={styles.orderInfo}>
          <Typography tag="span" color="gray" size="s" msg={t(Messages.orderSummaryAdministrationFee)} />
          <PriceDisplay tag="span" color="black" size="s" price={ADMINISTRATION_FEE} pricingType={currency} />
        </div>
      </div>
      <div className={styles.orderSection}>
        <div className={styles.orderInfo}>
          <Typography tag="span" color="gray" size="s" msg={t(Messages.orderSummaryVat)} />
          <Typography tag="span" color="black" size="s" msg="-" />
        </div>
      </div>*/}
        <div className={classNames(styles.orderSection, styles.noBorder)}>
          <div className={styles.orderInfo}>
            <Typography tag="span" bold color="black" size="l" msg={t(Messages.orderSummaryFinalPrice)} />
            <PriceDisplay
              tag="span"
              color="black"
              size="l"
              bold
              price={priceAndTime.totalPrice}
              pricingType={currency}
            />
          </div>
        </div>
        <div className={classNames(styles.orderSection, styles.noBorder)}>
          <div className={styles.orderInfo}>
            <Typography tag="span" color="gray" size="s" msg={t(Messages.orderSummarySubtitleAtTheBottom)} />
          </div>
        </div>
      </div>
      <div data-tutorial-step={UserTourSteps.advertPage.adStep4}>
        {!isEditForm && (
          <div className={styles.fields}>
            <FormField<TextAreaInputProps<string>>
              name={OldOrderFormFields.description}
              component={TextAreaInput}
              placeholder={Messages.orderEditDescription}
              fullWidth
            />
          </div>
        )}
        <div className={styles.orderActions}>{renderActions(submitting, onCancelClick, isEditForm, t)}</div>
      </div>
    </form>
  );
};

const OldOrderForm: React.FC<OldOrderFormProps> = ({
  onSubmit,
  advertId,
  advertPrice,
  deliveryPrice,
  deliveryType,
  isScheduled,
  advertPricingType,
  advertServiceType,
  initialValues,
  onCancelClick,
  isEditForm,
  availablePaymentTypes,
}) => {
  const t = useTranslations();
  const validateOrderForm = useCallback(validateOrderFormCreator(advertPricingType, t), [advertServiceType, t]);
  return (
    <Form<OldOrderFormData>
      onSubmit={onSubmit}
      render={props =>
        renderForm(
          props,
          isEditForm,
          advertId,
          advertPrice,
          deliveryPrice,
          deliveryType,
          isScheduled,
          advertPricingType,
          onCancelClick,
          advertServiceType,
          availablePaymentTypes,
        )
      }
      initialValues={initialValues}
      validate={validateOrderForm}
    />
  );
};

export default OldOrderForm;
