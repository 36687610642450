import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Location, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import DocumentTitle from '@Components/DocumentTitle/DocumentTitle';
import LoadingSpinner from '@Components/LoadingSpinner';
import { DEFAULT_HOMEPAGE } from '@Config/routes';
import { useTranslations } from '@Hooks/useTranslations';
import { RouteInfo } from '@Routes/appRoutes';
import routes from '@Routes/index';
import { getUserData } from '@Store/auth/auth.selector';
import lazyImport from '@Utils/lazyImport';
import { checkMissingPhone } from '@Utils/misc';

export interface RouterProperties {
  location: Location;
}

const UserPage = lazyImport(() => import('../routes/user/UserPage'));

export const RouteComponent = ({ route }: { route: RouteInfo }) => {
  const t = useTranslations();
  const user = useSelector(getUserData);
  const isMissingPhone = checkMissingPhone(user.item?.myCompanies);

  const routerProps: RouterProperties = {
    location: useLocation(),
  };

  if (!user) {
    return <LoadingSpinner />;
  }

  if (isMissingPhone) {
    // this is because of "withUser" decorator on Component.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <UserPage />;
  }

  return (
    <DocumentTitle title={t(route.title)}>
      <route.component {...routerProps} />
    </DocumentTitle>
  );
};

const RoutesRenderer = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        {routes.map(route => (
          <Route
            path={route.exact ? route.path : `${route.path}/*`}
            key={route.path}
            element={<RouteComponent route={route} />}
          />
        ))}
        <Route path="*" element={<Navigate to={DEFAULT_HOMEPAGE} replace />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesRenderer;
