import classNames from 'classnames';
import React from 'react';

import Typography from '@Components/Typography';
import { useTranslations } from '@Hooks/useTranslations';
import { MessageKeysWithoutParams } from '@Utils/translation';

import styles from './AdFormTab.module.scss';

export interface AdFormTabProps {
  tab: MessageKeysWithoutParams;
  num: number;
  onClick?: (e: React.SyntheticEvent) => void;
  activePage: number;
}

const AdFormTab: React.FunctionComponent<AdFormTabProps> = ({ activePage, tab, num, onClick }) => {
  const t = useTranslations();

  return (
    <button
      className={classNames(styles.adFormTabWrapper, { [styles.activePage]: activePage === num })}
      type="button"
      onClick={onClick}
    >
      <Typography bold msg={`${num + 1}.`} tag="span" size="s" className={styles.smallSpace} />
      <Typography msg={t(tab)} tag="span" size="s" />
    </button>
  );
};

export default AdFormTab;
