import classNames from 'classnames';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import CompaniesDisplay from '@Components/CompaniesDisplay';
import { HeaderLink } from '@Components/Header/Header';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import { useTranslations } from '@Hooks/useTranslations';
import { logout } from '@Store/auth/auth.actions';
import { UserData } from '@Store/auth/auth.types';

import styles from './UserMenu.module.scss';

export interface UserMenuProps {
  position?: 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'left';
  links: HeaderLink[];
  user: UserData;
  white?: boolean;
  refEl?: Element | null;
  displayAsDropDown?: boolean;
}

const UserMenu: React.FunctionComponent<UserMenuProps> = ({ displayAsDropDown, position, user, links, refEl }) => {
  const dispatch = useDispatch();
  const t = useTranslations();

  const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);

  const { attributes, styles: popperStyles } = usePopper(refEl, popperEl, {
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
    placement: position,
  });

  const onLogoutButtonOnClick = () => dispatch(logout.started({}));

  return (
    <ul
      ref={setPopperEl}
      className={classNames(displayAsDropDown ? styles.dropdownContent : styles.expandableElContent)}
      style={displayAsDropDown ? popperStyles.popper : undefined}
      {...attributes.popper}
    >
      <li className={classNames(styles.dropdownItem, styles.userEmail)}>{user?.item?.info.email}</li>
      {user?.item?.myCompanies && (
        <CompaniesDisplay displayAsDropDown={displayAsDropDown} companies={user.item.myCompanies} />
      )}
      {links.map((item, index) => (
        <li key={index} className={styles.dropdownItem}>
          <Link to={item.link}>
            <Typography tag="span" className={styles.dropdownText} color="black" size="s" msg={t(item.titleKey)} />
          </Link>
        </li>
      ))}
      <li className={styles.dropdownItem}>
        <Link to={RouteConfig.Authentication.buildLink({ authType: 'login' })}>
          <span onClick={onLogoutButtonOnClick}>
            <Typography tag="span" className={styles.dropdownText} color="black" size="s" msg={t(Messages.logout)} />
          </span>
        </Link>
      </li>
    </ul>
  );
};

export default UserMenu;
