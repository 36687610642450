// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Jodit } from 'jodit/es5/jodit.js';
import React, { useEffect, useRef } from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import { REGEX } from '@Utils/form';

import InputContainer from '../InputContainer';

import styles from './RichTextInput.module.scss';

export type RichTextInputProps<T> = {
  label?: string;
  placeholder?: string;
} & FormFieldRenderProps<T>;

const RichTextInput = <T extends string | null>(props: RichTextInputProps<T>) => {
  const { value, onChange } = props.input;
  const { label, meta, placeholder } = props;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const buttons = ['bold', 'italic', 'underline', 'strikethrough', 'ul', 'ol'];

  const config = {
    buttons,
    buttonsMD: buttons,
    buttonsSM: buttons,
    buttonsXS: buttons,
    showTooltip: false,
    height: 500,
    statusbar: false,
    disablePlugins: ['paste', 'add-new-line'],
    className: styles.editor,
    placeholder,
  };

  useEffect(() => {
    if (textAreaRef?.current) {
      const jodit = Jodit.make(textAreaRef.current, config);
      jodit.events.on('change', (value: string) => {
        // jodit returns <p><br></p> as empty value, so we need to check if the text value is empty inside the tags
        const cleanedHTML = value.replace(REGEX.HTML, '');
        onChange(cleanedHTML ? value : '');
      });
    }
  }, []);

  return (
    <InputContainer label={label} meta={meta} inline fullWidth showErrorIcon>
      <textarea ref={textAreaRef} value={value || ''} />
    </InputContainer>
  );
};

export default RichTextInput;
