import React, { FunctionComponent } from 'react';

import { ButtonSizes, ButtonStyles } from '@Components/Buttons/Button';
import { HeaderLink, HeaderLinkStyleTypes } from '@Components/Header';
import Col from '@Components/layout/Col';
import NavigationLink from '@Components/NavigationLink';
import { UserTourSteps } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

export interface HeaderLinksProps {
  links: HeaderLink[];
  isMobileMenuOpen: boolean;
  transparentHeader: boolean;
  toggleNavigation: (visible: boolean) => void;
  className?: string;
}

const HeaderLinks: FunctionComponent<HeaderLinksProps> = ({
  links,
  isMobileMenuOpen,
  transparentHeader,
  toggleNavigation,
  className,
}) => {
  const t = useTranslations();
  const buttonStyle = isMobileMenuOpen || transparentHeader ? ButtonStyles.outlined : ButtonStyles.outlinedGreen;
  const linkStyle = isMobileMenuOpen || transparentHeader ? ButtonStyles.linkTransparent : ButtonStyles.link;

  const navigationLinkClickHandler = () => {
    if (isMobileMenuOpen) {
      toggleNavigation(false);
    }
  };

  return (
    <>
      {links.map(({ titleKey, link, type }, key: number) => {
        const isButton = type === HeaderLinkStyleTypes.button;

        const headerLink = (
          <NavigationLink
            style={isButton ? buttonStyle : linkStyle}
            size={ButtonSizes.s}
            link={link}
            label={t(titleKey)}
            disabledActive={isButton}
            onClick={navigationLinkClickHandler}
          />
        );

        if (titleKey === Messages.marketPlace) {
          return (
            <div key={key} className={className} data-tutorial-step={UserTourSteps.companyPage.companyStep3}>
              {headerLink}
            </div>
          );
        }
        return (
          <Col key={key} className={className}>
            {headerLink}
          </Col>
        );
      })}
    </>
  );
};

export default HeaderLinks;
