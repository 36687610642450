import classNames from 'classnames';
import * as React from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import InputContainer from '@Components/forms/inputs/InputContainer';
import Select, { OptionItem, SelectSizes } from '@Components/Select';
import Tooltip, { TooltipPosition } from '@Components/Tooltip';
import Typography from '@Components/Typography';
import { useTranslations } from '@Hooks/useTranslations';
import { hasFieldError } from '@Utils/form';
import { MessageKeysWithoutParams } from '@Utils/translation';

import styles from './SelectInput.module.scss';

export interface SelectFieldProps {
  options?: OptionItem[];
  disabled?: boolean;
  defaultValue?: OptionItem;
  label?: MessageKeysWithoutParams;
  placeholder?: MessageKeysWithoutParams | string | undefined;
  onChange?: () => void;
  inline?: boolean;
  isMulti?: boolean;
  tooltipTitle?: string;
  tooltipMessage?: string;
  tooltipPosition?: TooltipPosition;
  localizeSelectLabels?: boolean;
  isClearable?: boolean;
  size?: SelectSizes;
  bottomSpacing?: boolean;
  description?: MessageKeysWithoutParams;
  required?: boolean;
  loading?: boolean;
  largeDropdown?: boolean;
}

export type SelectInputProps = SelectFieldProps & FormFieldRenderProps<any>;

const SelectInput: React.FunctionComponent<SelectInputProps> = ({
  input,
  label,
  options,
  description,
  meta,
  placeholder,
  inline,
  required,
  isMulti,
  disabled,
  bottomSpacing,
  tooltipTitle,
  tooltipMessage,
  tooltipPosition,
  loading,
  localizeSelectLabels,
  isClearable,
  size,
  largeDropdown,
}) => {
  const hasError = hasFieldError(meta);
  const t = useTranslations();

  return (
    <InputContainer
      label={label}
      meta={meta}
      required={required}
      className={classNames(styles.container)}
      inline={inline}
      showErrorIcon
      bottomSpacing={bottomSpacing}
    >
      {description && (
        <Typography className={styles.description} tag="div" msg={t(description)} size="s" color="gray" />
      )}
      <Tooltip title={tooltipTitle} message={tooltipMessage} position={tooltipPosition}>
        <>
          {label && <Typography tag="span" msg={label} bold />}
          <Select
            placeholder={placeholder}
            input={input}
            options={options}
            isLoading={loading}
            isMulti={isMulti}
            isClearable={isClearable}
            localizeSelectLabels={localizeSelectLabels}
            size={size}
            withError={hasError}
            disabled={disabled}
            largeDropdown={largeDropdown}
          />
        </>
      </Tooltip>
    </InputContainer>
  );
};

export default SelectInput;
