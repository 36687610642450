import { all, select, takeEvery } from 'redux-saga/effects';
import { Action, Success } from 'typescript-fsa';

import { ApplicationLanguages, Cookies, DEFAULT_LANG, LocalStorage } from '@Config/constants';
import { getAppConfig as getAppConfigFetch } from '@Graphql/config/config.queries';
import { AppConfigFragment, AppConfigViewNode } from '@Graphql/graphqlTypes.generated';
import { changeLanguage, getAppConfig } from '@Store/app/app.actions';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import { ChangeLanguagePayload } from '@Store/app/app.types';
import { setCookie } from '@Utils/cookies';
import { setMomentLocale } from '@Utils/dateMoment';
import { setObjectToLocalStorage } from '@Utils/localStorage';
import { callGraphql } from '@Utils/sagas';

export function* getApplicationConfigStarted(action: Action<{}>) {
  const lang: ApplicationLanguages = yield select(getCurrentLanguage) || DEFAULT_LANG;
  yield callGraphql<{}, AppConfigFragment>(() => getAppConfigFetch(lang), action, getAppConfig);
}

export function* changeLanguageSaga(action: Action<ChangeLanguagePayload>) {
  const { lang } = action.payload;
  setMomentLocale(lang);
  yield setCookie(Cookies.defaultLang, lang);
  location.reload();
}
export function* onConfigLoadSuccess(action: Action<Success<{}, AppConfigViewNode>>) {
  setObjectToLocalStorage(LocalStorage.appConfig, action.payload.result);
}

export function* appSagas() {
  yield all([
    //@ts-expect-error breaking changes after TS upgrade to v4.2^
    yield takeEvery(changeLanguage.type, changeLanguageSaga),
    //@ts-expect-error breaking changes after TS upgrade to v4.2^
    yield takeEvery(getAppConfig.done, onConfigLoadSuccess),
  ]);
}
