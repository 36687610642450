import { useSelector } from 'react-redux';

import { useAppKindContext } from '@Components/root';
import { getCurrentLanguage } from '@Store/app/app.selectors';

export const usePhoneNumber = () => {
  const { appKind } = useAppKindContext();
  const currentLanguage = useSelector(getCurrentLanguage);

  if (currentLanguage === 'pl') {
    return '+48791468141';
  }

  return appKind === 'CONSTRUCTIONS' ? '+37060744846' : '+37060020585';
};
