import classNames from 'classnames';
import * as React from 'react';

import styles from './Row.module.scss';

type sizes =
  | 'size1'
  | 'size2'
  | 'size3'
  | 'size4'
  | 'size5'
  | 'size6'
  | 'size7'
  | 'size8'
  | 'size9'
  | 'size10'
  | 'size11'
  | 'size12'
  | 'size13'
  | 'sizeauto'
  | 'sizeFull';
export interface RowProps {
  children: React.ReactNode;
  inline?: boolean;
  column?: boolean;
  columnMobile?: boolean;
  wrap?: boolean;
  justifyEnd?: boolean;
  justifyAround?: boolean;
  justifyBetween?: boolean;
  justifyCenter?: boolean;
  alignBaseline?: boolean;
  alignStart?: boolean;
  alignCenter?: boolean;
  alignEnd?: boolean;
  constant?: boolean;
  className?: string;
  size?: any;
  tag?: 'div' | 'p' | 'nav';
}

const Row: React.FunctionComponent<RowProps> = ({
  children,
  inline = false,
  column = false,
  columnMobile = false,
  wrap = false,
  justifyEnd = false,
  justifyAround = false,
  justifyBetween = false,
  justifyCenter = false,
  alignStart = false,
  alignBaseline = false,
  alignCenter = false,
  alignEnd = false,
  constant = false,
  size = undefined,
  className,
  tag: Tag = 'div',
}) => {
  return (
    <Tag
      className={classNames(
        styles.row,
        size && styles[`size${size}` as sizes],
        {
          [styles.justifyEnd]: justifyEnd,
          [styles.justifyAround]: justifyAround,
          [styles.justifyBetween]: justifyBetween,
          [styles.justifyCenter]: justifyCenter,
          [styles.alignStart]: alignStart,
          [styles.alignCenter]: alignCenter,
          [styles.alignBaseline]: alignBaseline,
          [styles.alignEnd]: alignEnd,
          [styles.inline]: inline,
          [styles.column]: column,
          [styles.columnMobile]: columnMobile,
          [styles.wrap]: wrap,
          [styles.constant]: constant,
        },
        className,
      )}
    >
      {children}
    </Tag>
  );
};

export default Row;
