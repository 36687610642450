import React from 'react';
import { useSelector } from 'react-redux';

import Container from '@Components/layout/Container';
import Layout from '@Components/layout/Layout';
import PreliminaryOrderFormContainer from '@Components/Order/PreliminaryOrderFormContainer/PreliminaryOrderFormContainer';
import Paper, { PaperDepth, PaperWidth } from '@Components/Paper';
import PaperBody from '@Components/PaperBody';
import Slider from '@Components/Slider';
import StaticPageContent from '@Components/StaticPageContent/StaticPageContent';
import ValtraTractor from '@Images/tractors/valtra.jpeg';
import ValtraTractor2 from '@Images/tractors/valtra1.jpeg';
import { getRentPage } from '@Store/app/app.selectors';

import styles from './RentInfoPage.module.scss';

const RentInfoPage: React.FC = () => {
  const rentPageData = useSelector(getRentPage);

  const slides = [
    {
      title: '',
      text: '',
      image: ValtraTractor,
    },

    {
      title: '',
      text: '',
      image: ValtraTractor2,
    },
  ];

  return (
    <Layout>
      <Container className={styles.pageWrapper}>
        <Slider slides={slides} />

        {rentPageData && rentPageData.slug && <StaticPageContent slug={rentPageData.slug} withTitle />}

        <div className={styles.formWrapper}>
          <Paper width={PaperWidth.m} depth={PaperDepth.one}>
            <PaperBody>
              <PreliminaryOrderFormContainer />
            </PaperBody>
          </Paper>
        </div>
      </Container>
    </Layout>
  );
};

export default RentInfoPage;
