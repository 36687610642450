import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getImageUrl } from '@Components/forms/inputs/ImageInput';
import Icon, { IconSizes, sizes } from '@Components/Icon';
import Image from '@Components/Image';
import Container from '@Components/layout/Container';
import Row from '@Components/layout/Row';
import PredefinedSearches from '@Components/PredefinedSearches';
import Typography from '@Components/Typography';
import { APPLE_STORE, FB, INSTAGRAM, LINKEDIN, PLAY_STORE } from '@Config/constants';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import { useTranslations } from '@Hooks/useTranslations';
import AppleStore from '@Images/appleStore.png';
import Facebook from '@Images/facebook.png';
import GooglePlay from '@Images/googlePlay.png';
import Instagram from '@Images/instagram.png';
import Linkedin from '@Images/linkedin.png';
import { getMainLogo, getSlugLinksData } from '@Store/app/app.selectors';
import { isMobileApp } from '@Utils/mobileWebView';

import styles from './Footer.module.scss';

const footerDefaultLinks = [
  {
    titleKey: Messages.faqPage,
    link: RouteConfig.Faq.buildLink(),
  },
  {
    titleKey: Messages.contactPage,
    link: RouteConfig.Contact.buildLink(),
  },
] as const;

type translatedLinkData = {
  titleKey: string;
  link: string;
};

const Footer: React.FunctionComponent = () => {
  const slugLinksData = useSelector(getSlugLinksData);
  const t = useTranslations();

  const mainLogo = useSelector(getMainLogo);
  const mainLogoUrl = mainLogo && getImageUrl(mainLogo);

  const translatedDefaultLinks = footerDefaultLinks.map(link => ({ ...link, titleKey: t(link.titleKey) }));

  const translatedFooterLinks: translatedLinkData[] = [
    ...translatedDefaultLinks,
    ...slugLinksData,
    {
      titleKey: t('msg_about_page_title'),
      link: RouteConfig.About.buildLink(),
    },
  ];

  const footerLinks = translatedFooterLinks.map(({ titleKey, link }) => (
    <Link key={link} to={link}>
      <Typography msg={titleKey} tag="span" color="black" size="s" />
    </Link>
  ));

  return (
    <footer className={styles.footerWrapper}>
      <Container>
        <Row justifyBetween alignCenter className={styles.footerRow}>
          <div className={styles.logoWrapper}>
            <div className={styles.logo}>
              {mainLogoUrl ? (
                <img src={mainLogoUrl} alt="logo" {...sizes[IconSizes.logoBig]} />
              ) : (
                <Icon size={IconSizes.logoBig} icon="green-sejico-logo" />
              )}
              <Icon icon="eu-logo" size={IconSizes.logo} />
            </div>
            <div className={styles.text}>
              <Typography msg={t(Messages.footerText)} size="s" tag="span" />
            </div>
          </div>
          <div>
            <nav className={styles.linksWrapper}>{footerLinks}</nav>
            {!isMobileApp && (
              <Row justifyCenter constant className={styles.stores}>
                <a href={PLAY_STORE} target="_blank" rel="noreferrer">
                  <Image className={styles.linkImage} src={GooglePlay} alt="Google play" />
                </a>
                <a href={APPLE_STORE} target="_blank" rel="noreferrer">
                  <Image className={styles.linkImage} src={AppleStore} alt="Apple store" />
                </a>
              </Row>
            )}
            <Row justifyCenter constant className={styles.stores}>
              <a href={FB} target="_blank" rel="noreferrer">
                <Image className={styles.linkImage} src={Facebook} alt="Facebook" />
              </a>
              <a href={INSTAGRAM} target="_blank" rel="noreferrer">
                <Image className={styles.linkImage} src={Instagram} alt="Instagram" />
              </a>
              <a href={LINKEDIN} target="_blank" rel="noreferrer">
                <Image className={styles.linkImage} src={Linkedin} alt="Linkedin" />
              </a>
            </Row>
          </div>
        </Row>
        <PredefinedSearches />
      </Container>
    </footer>
  );
};

export default Footer;
