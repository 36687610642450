import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import CreateContractorPage from '@Routes/static/CreateContractorPage';
import CreateGeneralOrderPage from '@Routes/static/CreateGeneralOrderPage';
import GeneralOrderAcceptancePage from '@Routes/static/GeneralOrderAcceptancePage';
import InvitationKeyNotFoundPage from '@Routes/static/InvitationKeyNotFoundPage';
import RentInfoPage from '@Routes/static/RentInfoPage';
import lazyImport from '@Utils/lazyImport';

import { RouteInfo } from '../appRoutes';

const FAQPage = lazyImport(() => import('./FAQPage'));
const IntroPage = lazyImport(() => import('./LandingPage/IntroPage'));
const NotFoundPage = lazyImport(() => import('./NotFoundPage'));
const StaticPageContainer = lazyImport(() => import('./StaticPage/StaticPageContainer'));
const AboutPage = lazyImport(() => import('./AboutPage'));

const staticRoutes: RouteInfo[] = [
  {
    path: RouteConfig.Home.template,
    component: IntroPage,
    title: Messages.introPage,
  },
  {
    path: RouteConfig.StaticPage.template,
    component: StaticPageContainer,
    title: Messages.staticPage,
    exact: false,
  },
  {
    path: RouteConfig.NotFound.template,
    component: NotFoundPage,
    title: Messages.notFoundPage,
    exact: true,
  },
  {
    path: RouteConfig.About.template,
    component: AboutPage,
    title: 'msg_about_page_title',
    exact: true,
  },
  {
    path: RouteConfig.GeneralOrder.template,
    component: CreateGeneralOrderPage,
    title: Messages.generalOrderLink,
    exact: true,
  },
  {
    path: RouteConfig.AcceptGeneralOrder.template,
    component: GeneralOrderAcceptancePage,
    title: Messages.generalOrderAcceptanceLink,
    exact: true,
  },
  {
    path: RouteConfig.Faq.template,
    component: FAQPage,
    title: Messages.faqPage,
    exact: true,
  },

  {
    path: RouteConfig.RentInfo.template,
    component: RentInfoPage,
    title: Messages.rentInfoPage,
    exact: true,
  },

  {
    path: RouteConfig.InvitationKeyNotFound.template,
    component: InvitationKeyNotFoundPage,
    title: Messages.invitationKeyNotFoundPage,
    exact: true,
  },
  {
    path: RouteConfig.Contractor.template,
    component: CreateContractorPage,
    title: 'msg_create_contractor_page_title',
    exact: true,
  },
];

export default staticRoutes;
