import React from 'react';

import Icon, { IconName, IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography, { TextSizes } from '@Components/Typography/Typography';

import styles from './DisplayLabelWithIcon.module.scss';

interface DisplayLabelWithIconProps {
  label?: string;
  icon: IconName;
  iconSize?: IconSizes;
  className?: string;
  textSize?: TextSizes;
  labelClassName?: string;
  children?: React.ReactNode;
}

const DisplayLabelWithIcon: React.FunctionComponent<DisplayLabelWithIconProps> = props => {
  const { label, icon, className, children, textSize = 'm', iconSize = IconSizes.ss, labelClassName } = props;
  return (
    <Row alignCenter className={className} constant>
      <Icon icon={icon} size={iconSize} className={styles.labelIcon} />
      {label && <Typography className={labelClassName} tag="span" size={textSize} msg={label} color="gray" />}
      {children}
    </Row>
  );
};

export default DisplayLabelWithIcon;
