import React from 'react';

import ContractorFormContainer from '@Components/Contractor/CreateContractor';
import DocumentTitle from '@Components/DocumentTitle/DocumentTitle';
import Container from '@Components/layout/Container';
import Layout, { LayoutBackgrounds } from '@Components/layout/Layout';
import Row from '@Components/layout/Row';
import PageHeader from '@Components/Page/PageHeader';
import PageTitle from '@Components/Page/PageTitle';
import Paper, { PaperDepth, PaperWidth } from '@Components/Paper';
import PaperBody from '@Components/PaperBody';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './CreateContractorPage.module.scss';

const CreateContractorPage = () => {
  const t = useTranslations();

  return (
    <DocumentTitle title={t('msg_create_contractor_page_title')}>
      <Layout background={LayoutBackgrounds.grayBackground} noTopPadding>
        <PageHeader>
          <Row column className={styles.title}>
            <PageTitle msg={t('msg_create_contractor_page_title')} color="primary" />
          </Row>
        </PageHeader>

        <Container className={styles.formContainer}>
          <Paper width={PaperWidth.m} depth={PaperDepth.one}>
            <PaperBody>
              <ContractorFormContainer />
            </PaperBody>
          </Paper>
        </Container>
      </Layout>
    </DocumentTitle>
  );
};

export default CreateContractorPage;
