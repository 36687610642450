import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import lazyImport from '@Utils/lazyImport';
import { authenticatedOnlyComponent } from '@Utils/router';

import { RouteInfo } from '../appRoutes';

const MyOrders = lazyImport(() => import('./MyOrders'));
const OrderPage = lazyImport(() => import('./OrderPage'));

const orderRoutes: RouteInfo[] = [
  {
    path: RouteConfig.MyOrders.template,
    component: MyOrders,
    title: Messages.companyOrdersPage,
  },
  {
    path: RouteConfig.Order.template,
    component: authenticatedOnlyComponent(OrderPage),
    title: Messages.companyAdsPage,
    exact: false,
  },
];

export default orderRoutes;
