import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Form, FormRenderProps } from 'react-final-form';

import Button, { ButtonStyles, ButtonTypes } from '@Components/Buttons/Button';
import { CookieBannerInitialValues, CookiesFormData, CookiesNames } from '@Components/CookieBanner/CookieBanner';
import FormField from '@Components/forms/FormField';
import Checkbox from '@Components/forms/inputs/Checkbox/Checkbox';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import { ModalProviderProps } from '@Components/Modal';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import modalStyles from '../Modal.module.scss';

import styles from './CookiesModal.module.scss';

export interface CookieModalData {
  onSubmit: () => void;
  cookies: CookiesFormData[];
  values: CookieBannerInitialValues;
}

const CookieModal: React.FunctionComponent<ModalProviderProps<CookieModalData>> = ({
  modalData: { cookies, onSubmit, values },
  closeModal,
}) => {
  const t = useTranslations();

  const renderForm = ({ handleSubmit, submitting }: FormRenderProps<CookieBannerInitialValues>): ReactNode => {
    return (
      <form
        onSubmit={() => {
          handleSubmit();
          closeModal();
        }}
      >
        <Row column>
          {cookies.map((cookie, index) => {
            const { name, label } = cookie;
            return (
              <FormField
                disabled={name === CookiesNames.mandatory}
                key={index}
                name={name}
                component={Checkbox}
                inline
                type="checkbox"
                label={label}
                checkedDisabled
              />
            );
          })}
        </Row>
        <Row>
          <Button label={t(Messages.cookiesModalButtonAccept)} type={ButtonTypes.submit} disabled={submitting} />
        </Row>
      </form>
    );
  };

  return (
    <div className={classNames(modalStyles.actions, styles.cookieModalWrapper)}>
      <Button className={styles.closeButton} style={ButtonStyles.plain} onClick={closeModal}>
        <Icon icon="menu-close-button" size={IconSizes.m} />
      </Button>
      <Row column>
        <Typography msg={t(Messages.cookiesModalHeader)} tag="h3" />
        <Typography msg={t(Messages.cookiesModalText)} tag="p" className={styles.modalText} html />
      </Row>
      <Form onSubmit={onSubmit} render={renderForm} initialValues={values} />
    </div>
  );
};

export default CookieModal;
