import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import Row from '@Components/layout/Row';
import { ModalProviderProps } from '@Components/Modal';
import { showSuccessModal } from '@Components/Modal/modalHelpers';
import { Messages } from '@Config/messages';
import {
  AdvertEventNode,
  CompanyMutationInput,
  CreateCompanyMutation,
  useChangeActiveCompanyMutation,
  useCreateCompanyMutation,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import CompanyForm from '@Routes/user/UserPage/components/CompanyForm';
import { authActions } from '@Store/actions';
import { callMutationForForm } from '@Utils/graphql';

import modalStyles from '../Modal.module.scss';

import styles from './CompanyFormModal.module.scss';

export enum ActionsDisplayType {
  column = 'column',
  row = 'row',
}

export interface CalendarEventModalData {
  actionsDisplayType?: ActionsDisplayType;
  advert: string;
  event: AdvertEventNode;
}

export interface CalendarEventModalProps extends ModalProviderProps<CalendarEventModalData> {}

const CompanyFormModal: React.FunctionComponent<CalendarEventModalProps> = ({
  modalData: { actionsDisplayType = ActionsDisplayType.column },
  closeModal,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const [changeCompany] = useChangeActiveCompanyMutation({
    onCompleted: () => {
      dispatch(authActions.getUserData.started({}));
      closeModal();
      window.location.reload();
    },
  });

  const [addCompany] = useCreateCompanyMutation({
    onCompleted: ({
      createCompany: {
        company: { id },
      },
    }) => {
      dispatch(
        showSuccessModal(
          t(Messages.btnOk),
          closeModal,
          t(Messages.companyCreated),
          undefined,
          t(Messages.companyMakeActive),
          () => changeCompany({ variables: { input: { company: id } } }),
        ),
      );
    },
  });

  const handleSubmit = (values: CompanyMutationInput) => {
    return callMutationForForm<CreateCompanyMutation>(addCompany({ variables: { input: values } }));
  };

  return (
    <div className={classNames(modalStyles.actions, modalStyles[actionsDisplayType])}>
      <div className={styles.container}>
        <Row>
          <CompanyForm onSubmit={handleSubmit} onCancel={closeModal} />
        </Row>
      </div>
    </div>
  );
};

export default CompanyFormModal;
