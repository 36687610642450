import EnTranslations from '@Utils/translations/en';

export type SlotKeysExtractor<Path extends string> = Path extends `${string}{{${infer Param}}}${infer Rest}`
  ? Param | SlotKeysExtractor<Rest>
  : never;

export type Translations = typeof EnTranslations;

export type SlotTagsExtractor<Path extends string> = Path extends `${string}</${infer Param}>${infer Rest}`
  ? Param | SlotTagsExtractor<Rest>
  : never;

type MessageKey = keyof Translations;

type MessagesParams = { [key in MessageKey]: SlotKeysExtractor<Translations[key]> };

type MessagesWithParams = {
  [key in MessageKey as MessagesParams[key] extends never ? never : key]: MessagesParams[key];
};
export type MessageKeysWithParams = keyof MessagesWithParams;
export type MessageKeysWithoutParams = Exclude<MessageKey, MessageKeysWithParams>;

type TranslatorWithParams = <Key extends MessageKeysWithParams>(
  key: Key,
  options: Record<MessagesWithParams[Key], string | number>,
) => string;

type TranslatorWithoutParams = <Key extends MessageKeysWithoutParams>(key: Key) => string;

export type Translator = TranslatorWithParams & TranslatorWithoutParams;

export const getTranslationValue = ({ value, t }: { value: MessageKeysWithoutParams | string; t: Translator }) =>
  value in EnTranslations ? t(value as MessageKeysWithoutParams) : value;
