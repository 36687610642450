import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getContractFormInput } from '@Components/Contractor/contractorUtils';
import ContractorForm from '@Components/Contractor/CreateContractor/ContractorForm';
import { showSuccessModal } from '@Components/Modal/modalHelpers';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import { useCreateContractorMutation } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUserData } from '@Store/auth/auth.selector';
import { locationChange } from '@Store/navigation/navigation.actions';
import { GAAction, GACategory, sendGaEvent } from '@Utils/gaEvents';
import { callMutationForForm } from '@Utils/graphql';
import { ContractFormData } from '@Utils/types';

const ContractorFormContainer = ({ onCancel }: { onCancel?: () => void }) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const { item: user } = useSelector(getUserData);

  // react final form contains the issue that it fails comparing initial values with nested objects
  const initialValues: ContractFormData = useMemo(
    () => ({
      name: '',
      surname: '',
      email: user?.info.email || '',
      phone: user?.info.activeCompany.company.phone || '',
      address: '',
      workingAreaRay: '',
      coordinates: { lat: undefined, lng: undefined },
      categories: [],
      categoriesDetails: {},
      additionalInfo: '',
    }),
    [user],
  );

  const [createContractorMutation] = useCreateContractorMutation({
    onCompleted: () => {
      dispatch(
        showSuccessModal(
          t(Messages.btnOk),
          () => {
            dispatch(locationChange({ path: RouteConfig.Marketplace.buildLink() }));
          },
          t('msg_contractor_create_success'),
          t('msg_contractor_create_success_subtext'),
        ),
      );
      sendGaEvent({
        category: GACategory.contractor,
        action: GAAction.click,
      });
    },
  });

  const handleSubmit = async (formData: ContractFormData) => {
    const { data, type } = getContractFormInput(formData, t);
    if (type === 'ERROR') {
      return data;
    }

    return callMutationForForm(
      createContractorMutation({
        variables: {
          input: data,
        },
      }),
    );
  };

  return <ContractorForm onSubmit={handleSubmit} onCancel={onCancel} initialValues={initialValues} />;
};

export default ContractorFormContainer;
