import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import InputContainer from '@Components/forms/inputs/InputContainer';
import Icon, { IconSizes } from '@Components/Icon';
import Tooltip, { TooltipPosition } from '@Components/Tooltip';
import Typography from '@Components/Typography';
import { useTranslations } from '@Hooks/useTranslations';
import { hasFieldError } from '@Utils/form';
import { getTranslationValue, MessageKeysWithoutParams } from '@Utils/translation';

import styles from './textInput.module.scss';

export interface TextInputProps<T> extends FormFieldRenderProps<T> {
  placeholder: MessageKeysWithoutParams | string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
  inline?: boolean;
  tooltipMessage?: string;
  tooltipPosition?: TooltipPosition;
  noMargin?: boolean;
  wrapperClassname?: string;
  className?: string;
  helperText?: string;
  bottomSpacing?: boolean;
  description?: MessageKeysWithoutParams;
  labelOnTop?: boolean;
  showPlaceholder?: boolean;
  tooltipTitle?: string;
}

const renderInputTypeToggleIcon = (
  changeInputType: { (value: any): void; (arg0: string): void },
  inputType: string,
) => {
  return (
    <Icon
      tabIndex={0}
      onClick={() => (inputType === 'password' ? changeInputType('text') : changeInputType('password'))}
      size={IconSizes.s}
      icon={inputType === 'password' ? 'eye-disabled' : 'eye'}
      className={styles.visibilityToggleIcon}
    />
  );
};

function textInput<T extends InputHTMLAttributes<'input'>['value']>(props: TextInputProps<T>): React.ReactElement {
  const {
    input,
    label,
    meta,
    required,
    disabled,
    placeholder,
    noMargin,
    inline,
    description,
    tooltipTitle,
    tooltipMessage,
    tooltipPosition,
    className,
    wrapperClassname,
    helperText,
    bottomSpacing,
    labelOnTop = true,
    showPlaceholder = false,
  } = props;

  const t = useTranslations();

  const [inputType, changeInputType] = React.useState<string>(input.type || 'text');

  const translatedPlaceholder = getTranslationValue({ value: placeholder, t });

  const hasError = hasFieldError(meta);

  return (
    <InputContainer
      label={label}
      meta={meta}
      required={required}
      className={classNames(styles.wrapper)}
      wrapperClassName={classNames(wrapperClassname)}
      inline={inline}
      noMargin={noMargin}
      showErrorIcon
      bottomSpacing={bottomSpacing}
    >
      {description && (
        <Typography tag="div" msg={t(description)} size="s" color="gray" className={styles.description} />
      )}
      <Tooltip title={tooltipTitle} message={tooltipMessage} position={tooltipPosition}>
        <>
          <input
            {...input}
            name={input.name}
            placeholder={translatedPlaceholder}
            aria-label={translatedPlaceholder}
            disabled={disabled}
            type={inputType}
            autoComplete="off"
            className={classNames(
              className,
              styles.input,
              { [styles.error]: hasError },
              { [styles.showPlaceholder]: showPlaceholder },
            )}
          />
          {translatedPlaceholder && (
            <label
              htmlFor={input.name}
              className={classNames(styles.label, { [styles.labelOnTop]: labelOnTop })}
              data-content={translatedPlaceholder}
            >
              <span className={styles.hidden}>{translatedPlaceholder}</span>
            </label>
          )}
          {helperText && <Typography msg={helperText} tag="span" size="s" color="gray" />}
          {input.type === 'password' && renderInputTypeToggleIcon(changeInputType, inputType)}
        </>
      </Tooltip>
    </InputContainer>
  );
}

export default textInput;
