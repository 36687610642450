import { ApolloError } from 'apollo-client';
import { FORM_ERROR } from 'final-form';
import { call, put } from 'redux-saga/effects';
import { Action, AsyncActionCreators } from 'typescript-fsa';

import { callDelete, callGet, callPost, callUpdate } from '@Utils/api';
import { buildFormErrors } from '@Utils/graphql';
import { ActionErrorType, PayloadWithPromises } from '@Utils/types';

export function* handleFormAction<Params extends PayloadWithPromises, Result, Error = {}>(
  url: string,
  action: Action<Params>,
  actionType: AsyncActionCreators<Params, Result, Error>,
  callAction: {
    (url: string, data: any): Promise<any>;
  },
) {
  const { resolve, reject, ...requestPayload } = action.payload;
  try {
    const result: Result = yield call(callAction, url, requestPayload);
    yield call(resolve, result);
    yield put(actionType.done({ result, params: action.payload }));
  } catch (e) {
    const {
      data: { non_field_errors, ...errorResponse },
      // @ts-expect-error undocumented error type
    } = e.response;

    if (non_field_errors) {
      errorResponse[FORM_ERROR] = non_field_errors;
    }
    yield call(reject, errorResponse);
    yield put(actionType.failed({ error: errorResponse as Error, params: action.payload }));
  }
}

export function* handleFormSubmit<Params extends PayloadWithPromises, Result, Error = {}>(
  url: string,
  action: Action<Params>,
  actionType: AsyncActionCreators<Params, Result, Error>,
) {
  yield handleFormAction(url, action, actionType, callPost);
}
export function* handleFormUpdate<Params extends PayloadWithPromises, Result, Error = {}>(
  url: string,
  action: Action<Params>,
  actionType: AsyncActionCreators<Params, Result, Error>,
) {
  yield handleFormAction(url, action, actionType, callUpdate);
}
export function* callApi<Params, Result, Error>(
  url: string,
  action: Action<Params>,
  actionType: AsyncActionCreators<Params, Result, Error>,
  caller: any,
) {
  const { payload } = action;

  try {
    const result: Result = yield call(caller, url, payload);
    yield put(actionType.done({ result, params: payload }));
  } catch (error) {
    // @ts-expect-error undocumented error type
    yield put(actionType.failed({ error: error.response, params: payload }));
  }
}

export function* callApiGet<Params, Result, Error>(
  url: string,
  action: Action<Params>,
  actionType: AsyncActionCreators<Params, Result, Error>,
) {
  yield callApi(url, action, actionType, callGet);
}
export function* callApiPost<Params, Result, Error>(
  url: string,
  action: Action<Params>,
  actionType: AsyncActionCreators<Params, Result, Error>,
) {
  yield callApi(url, action, actionType, callPost);
}

export function* callApiDelete<Params, Result, Error>(
  url: string,
  action: Action<Params>,
  actionType: AsyncActionCreators<Params, Result, Error>,
) {
  yield callApi(url, action, actionType, callDelete);
}

export function* callGraphql<Params, Result>(
  caller: any,
  action: Action<Params>,
  actionType: AsyncActionCreators<Params, Result, ActionErrorType>,
) {
  const { payload } = action;
  try {
    const result: Result = yield call(caller, payload);
    yield put(actionType.done({ result, params: payload }));
  } catch (err) {
    const { networkError } = err as ApolloError & { networkError: { statusCode: number; response: Record<any, any> } };

    const error: ActionErrorType = {
      status: networkError?.statusCode,
      response: networkError?.response,
    };

    yield put(actionType.failed({ error, params: payload }));
  }
}

export function* saveFormWithGraphql<Params extends PayloadWithPromises, Result, Error>(
  caller: any,
  action: Action<Params>,
  actionType: AsyncActionCreators<Params, Result, Error>,
) {
  const { resolve, ...requestPayload } = action.payload;
  try {
    const result: Result = yield call(caller, requestPayload);
    yield call(resolve, result);
    yield put(actionType.done({ result, params: action.payload }));
  } catch (error) {
    const errorResponse = buildFormErrors(error);
    yield call(resolve, errorResponse);
    yield put(actionType.failed({ error: errorResponse as Error, params: action.payload }));
  }
}
