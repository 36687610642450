import * as React from 'react';
import { useMemo } from 'react';

import CategorySelect, { CategorySelectProps } from '@Components/forms/inputs/selects/CategorySelect';
import { useGetGeneralOrderClassifiersQuery } from '@Graphql/graphqlTypes.generated';

type GeneralOrderCategorySelectProps = Omit<CategorySelectProps, 'categories' | 'isLoading'>;

const GeneralOrderCategorySelect = (props: GeneralOrderCategorySelectProps) => {
  const { loading, data } = useGetGeneralOrderClassifiersQuery({});

  const validCategories = useMemo(
    () =>
      (data?.appConfig.generalOrderCategories || []).filter(category => !category.parent || !!category.marketPrices),
    [data?.appConfig.generalOrderCategories],
  );

  return <CategorySelect isLoading={loading} categories={validCategories} {...props} />;
};

export default React.memo(GeneralOrderCategorySelect);
