import classNames from 'classnames';
import * as React from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { hasFieldError } from '@Utils/form';

import InputContainer from '../InputContainer';

import styles from './CheckboxBox.module.scss';

export interface CheckboxInputProps extends FormFieldRenderProps<any> {
  required?: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  inline?: boolean;
  bottomSpacing?: boolean;
  column?: boolean;
}

const CheckboxBox: React.FunctionComponent<CheckboxInputProps> = props => {
  const {
    input,
    input: { name, type, checked },
    label,
    meta,
    disabled,
    required,
    inline,
    bottomSpacing,
    column,
    className,
  } = props;

  const hasError = hasFieldError(meta);

  return (
    <InputContainer className={className} meta={meta} required={required} inline={inline} bottomSpacing={bottomSpacing}>
      <Row column className={classNames(styles.adTypeSelectionWrapper, checked && styles.selected)}>
        <label
          className={classNames(styles.checkbox, {
            [styles.error]: hasError,
            [styles.disabled]: disabled,
            [styles.column]: column,
          })}
        >
          <input tabIndex={0} {...input} name={name} disabled={disabled} type={type || 'radio'} checked={checked} />
          <Typography className={styles.label} html msg={label} tag="span" size="s" />
        </label>
      </Row>
    </InputContainer>
  );
};

export default CheckboxBox;
