import { authenticatedOnlyComponent } from '@Utils/router';

import { privateRoutes, privateRoutesWithBackNavigation, publicRoutes, RouteInfo } from './appRoutes';

const routes: RouteInfo[] = [
  ...publicRoutes,
  ...privateRoutes.map((item: RouteInfo) => {
    return {
      ...item,
      component: authenticatedOnlyComponent(item.component),
    };
  }),
  ...privateRoutesWithBackNavigation.map(item => {
    return {
      ...item,
      component: authenticatedOnlyComponent(item.component, { returnBack: true }),
    };
  }),
];

export default routes;
