import { useSelector } from 'react-redux';

import { getCurrentLanguage } from '@Store/app/app.selectors';
import { logError } from '@Utils/logger';
import { TranslationSelector } from '@Utils/types';

export const useTranslateObject = () => {
  const currentLanguage = useSelector(getCurrentLanguage);

  const selectTranslation: TranslationSelector = name => {
    if (!name) {
      return '';
    }

    if (!name[currentLanguage] || Object.keys(name[currentLanguage])?.length === 0) {
      logError(`Missing object translation: ${name[currentLanguage]}`);
      return '';
    }

    return name[currentLanguage];
  };

  return selectTranslation;
};
