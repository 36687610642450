import { DurationSelectOptionProps } from '@Components/forms/inputs/selects/DurationSelect';
import { TimeInputOptionProps } from '@Components/forms/inputs/selects/TimeSelect';
import { OptionItem } from '@Components/Select';
import { defaultStartTime, defaultEndTime, defaultMinutesToAdd } from '@Config/constants';

export const timeSelectOptions = ({
  startTime = defaultStartTime,
  endTime = defaultEndTime,
  minutesToAdd = defaultMinutesToAdd,
}: TimeInputOptionProps = {}) => {
  const timeStops: OptionItem[] = [];
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  const currentTime = new Date();
  currentTime.setHours(startHour, startMinute, 0, 0);

  const endTimeObj = new Date();
  endTimeObj.setHours(endHour, endMinute, 0, 0);

  while (currentTime <= endTimeObj) {
    const hour = currentTime.getHours();
    const minute = currentTime.getMinutes();

    const hourString = `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
    timeStops.push({ label: hourString, value: hourString });

    currentTime.setMinutes(currentTime.getMinutes() + minutesToAdd);
  }

  return timeStops;
};

export const durationSelectOptions = ({
  minDays = 1,
  maxDays = 10,
  daysToAdd = 1,
}: DurationSelectOptionProps = {}): OptionItem[] => {
  const timeStops = [];
  let i = minDays;

  while (i <= maxDays) {
    timeStops.push({
      value: i,
      label: `${i}`,
    });
    i += daysToAdd;
  }
  return timeStops;
};
