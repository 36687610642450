import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import Icon, { IconSizes } from '@Components/Icon';
import { RouteConfig } from '@Config/routes';

import styles from './HeaderFavorite.module.scss';

export interface HeaderFavoriteProps {
  white?: boolean;
}

const HeaderFavorite: FunctionComponent<HeaderFavoriteProps> = ({ white }) => {
  return (
    <Link
      to={RouteConfig.FavoriteAdverts.buildLink()}
      className={classNames(styles.container, { [styles.white]: white })}
    >
      <Icon icon="favorite" size={IconSizes.s} />
    </Link>
  );
};

export default HeaderFavorite;
