import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Typography from '@Components/Typography';
import { RouteConfig } from '@Config/routes';
import { getPredefinedSearches } from '@Store/app/app.selectors';

import styles from './PredefinedSearches.module.scss';

function PredefinedSearches() {
  const predefinedSearches = useSelector(getPredefinedSearches);

  return predefinedSearches?.length ? (
    <div className={styles.wrapper}>
      {predefinedSearches?.map(item => (
        <Link key={item?.id} to={`${RouteConfig.Marketplace.buildLink()}${item?.url}`} replace>
          <Typography msg={item?.translation?.title ?? item?.name ?? ''} tag="span" color="black" size="s" />
        </Link>
      ))}
    </div>
  ) : null;
}

export default PredefinedSearches;
