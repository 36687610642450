import classNames from 'classnames';
import React from 'react';

import Typography from '@Components/Typography';
import { buildMessageKeyForEnum } from '@Config/messages';
import { AdvertType, AdvertServiceType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './AdTypePill.module.scss';

interface AdServiceTypeDisplayProps {
  advertType: AdvertType;
  serviceType: AdvertServiceType;
}

const AdTypePill: React.FC<AdServiceTypeDisplayProps> = ({ advertType, serviceType }) => {
  const t = useTranslations();

  return (
    <div className={styles.container}>
      <Typography color="primary" msg={t(buildMessageKeyForEnum(advertType))} tag="span" size="s" align="center" />
      <div className={classNames(styles.separator)} />
      <Typography color="primary" msg={t(buildMessageKeyForEnum(serviceType))} tag="span" size="s" align="center" />
    </div>
  );
};

export default AdTypePill;
