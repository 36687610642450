import classNames from 'classnames';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';

import FormField from '@Components/forms/FormField';
import { FieldSubscribe } from '@Components/forms/formUtils';
import CheckboxBox from '@Components/forms/inputs/CheckboxBox/CheckboxBox';
import CheckboxWithImg from '@Components/forms/inputs/CheckboxWithImg/CheckboxWithImg';
import InfoMessage, { MessageTypes } from '@Components/InfoMessage';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { Messages } from '@Config/messages';
import { AdvertServiceType, AdvertType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { AdFormFieldNames, FormRowItem } from '@Routes/ads/shared/AdForm/AdForm';
import styles from '@Routes/ads/shared/AdForm/AdForm.module.scss';
import { validateRequired } from '@Utils/form';
import { AdFormValues } from '@Utils/types';

export interface AdFormFirstStepProps {
  initialValues?: AdFormValues;
}

const AdFormFirstStep: React.FC<AdFormFirstStepProps> = ({ initialValues }) => {
  const t = useTranslations();
  const formState = useFormState();
  const form = useForm();
  const { submitError } = formState;

  return (
    <>
      <Row>
        <InfoMessage type={MessageTypes.error} message={submitError} />
      </Row>
      <FormRowItem label={t(Messages.createAdServiceType)} marginTop>
        <Row className={styles.serviceTypeSpacing} constant justifyCenter>
          <FormField
            name={AdFormFieldNames.serviceType}
            component={CheckboxWithImg}
            type="radio"
            validate={validateRequired(t)}
            value={AdvertServiceType.Good}
            label={t(Messages.createAdServiceTypeGood)}
            iconTitle="ad-form-good"
          />
          <FormField
            name={AdFormFieldNames.serviceType}
            component={CheckboxWithImg}
            type="radio"
            validate={validateRequired(t)}
            value={AdvertServiceType.Service}
            label={t(Messages.createAdServiceTypeService)}
            iconTitle="ad-form-service"
          />
        </Row>
      </FormRowItem>
      <FormRowItem label={t(Messages.createAdType)}>
        <Row alignCenter column>
          <Col className={classNames(styles.borders, styles.bottomSpacingOnMobile)}>
            <FormField
              name={AdFormFieldNames.advertType}
              component={CheckboxBox}
              inline
              type="radio"
              validate={validateRequired(t)}
              value={AdvertType.Sale}
              label={t(Messages.createAdTypeSell)}
              bottomSpacing={false}
            />
            <FormField
              name={AdFormFieldNames.advertType}
              component={CheckboxBox}
              inline
              type="radio"
              validate={validateRequired(t)}
              value={AdvertType.Purchase}
              label={t(Messages.createAdTypeBuy)}
              bottomSpacing={false}
            />
          </Col>
        </Row>
      </FormRowItem>
      <FieldSubscribe
        when={AdFormFieldNames.serviceType}
        onChange={value => {
          form.change(AdFormFieldNames.pricingType, undefined);
          form.change(
            AdFormFieldNames.category,
            value === initialValues?.serviceType ? initialValues?.category : undefined,
          );
          form.change(
            AdFormFieldNames.isScheduled,
            value === AdvertServiceType.Service ? initialValues?.isScheduled : false,
          );
        }}
      />
    </>
  );
};

export default AdFormFirstStep;
