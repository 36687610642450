import * as React from 'react';
import Joyride, { EVENTS, STATUS, ACTIONS, CallBackProps } from 'react-joyride';
import { useSelector } from 'react-redux';

import { getUserTourState } from '@Store/app/app.selectors';
import { RootState } from '@Store/reducers';

import UserTourTooltip from './UserTourTooltip';

export interface UserTourProps {}

const UserTour: React.FunctionComponent<UserTourProps> = () => {
  const steps = useSelector((state: RootState) => getUserTourState(state));
  const [userTourStep, setUserTourStep] = React.useState(0);
  const [isUserTourEnabled, enableUserTour] = React.useState(false);

  React.useEffect(() => {
    setUserTourStep(0);
    /// setTimeout is used as in react-joyride document.querySelector is used and it and in the beggining it doesn't find step target in the DOM, some delay is needed
    /// https://github.com/gilbarbara/react-joyride/blob/master/src/components/Step.js
    if (steps.length) {
      setTimeout(() => enableUserTour(true), 4000);
    }
  }, [steps]);

  const modifiedSteps = steps.map(item => {
    return { ...item, disableBeacon: true, target: `[data-tutorial-step=${item.target}]` };
  });

  const handleCallback = (data: CallBackProps) => {
    const { action, status, type } = data;

    if (ACTIONS.CLOSE === action || STATUS.FINISHED === status || STATUS.SKIPPED === status) {
      enableUserTour(false);
    }

    if (EVENTS.STEP_AFTER === type) {
      setUserTourStep(userTourStep + (action === ACTIONS.PREV ? -1 : 1));
    }
  };

  return (
    <Joyride
      floaterProps={{ hideArrow: true }}
      steps={modifiedSteps}
      stepIndex={userTourStep}
      tooltipComponent={UserTourTooltip}
      callback={handleCallback}
      continuous
      run={isUserTourEnabled}
    />
  );
};

export default UserTour;
