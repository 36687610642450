import * as React from 'react';

import { GeneralOfferPaymentCondition } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { MessageKeysWithoutParams } from '@Utils/translation';

import SelectInput, { SelectInputProps } from '../SelectInput';

type GeneralOfferPaymentConditionSelectProps = SelectInputProps;

const conditions = {
  [GeneralOfferPaymentCondition.FullPayment]: 'msg_general_offer_condition_full_payment',
  [GeneralOfferPaymentCondition.FiftyFiftySplit]: 'msg_general_offer_condition_fifty_fifty_split',
  [GeneralOfferPaymentCondition.AfterThirtyDaysWithAdvance]:
    'msg_general_offer_condition_after_thirty_days_with_advance',
} satisfies Record<GeneralOfferPaymentCondition, MessageKeysWithoutParams>;

const GeneralOfferPaymentConditionSelect = (props: GeneralOfferPaymentConditionSelectProps) => {
  const t = useTranslations();

  const options = Object.entries(conditions).map(([value, msgKey]) => ({
    value,
    label: t(msgKey),
  }));

  return <SelectInput options={options} {...props} />;
};

export default GeneralOfferPaymentConditionSelect;
