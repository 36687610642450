import React from 'react';

import { META_DEFAULTS } from '@Config/constants';

type MetaProps = JSX.IntrinsicElements['meta'];

export interface MetaContextProps {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
  type?: string;
  custom?: MetaProps[];
}

export const { Provider, Consumer } = React.createContext<MetaContextProps>(META_DEFAULTS);
