import { useEffect } from 'react';

import { useAppKindContext } from '@Components/root';
import { getTheme } from '@Utils/colors';

const useAppTheme = () => {
  const { appKind } = useAppKindContext();

  useEffect(() => {
    document.body.dataset.theme = getTheme(appKind);
  }, [appKind]);
};

export default useAppTheme;
