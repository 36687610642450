import { Step } from 'react-joyride';
import typescriptFsa from 'typescript-fsa';

import { AppConfigFragment } from '@Graphql/graphqlTypes.generated';
import { ChangeLanguagePayload, ShowModalPayload } from '@Store/app/app.types';
import { ActionErrorType } from '@Utils/types';

const actionCreator = typescriptFsa('@APP');

export const getAppConfig = actionCreator.async<{}, AppConfigFragment, ActionErrorType>('GET_APP_CONFIG');
export const showModal = actionCreator<ShowModalPayload<any>>('SHOW_MODAL');
export const showUserTour = actionCreator<Step[]>('SHOW_USER_TOUR');
export const hideModal = actionCreator<{}>('HIDE_MODAL');
export const changeLanguage = actionCreator<ChangeLanguagePayload>('CHANGE_LANGUAGE');
