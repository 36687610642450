import * as Sentry from '@sentry/browser';
import { ExecutionResult } from 'apollo-link';

import {
  AcceptCompanyInvitationDocument,
  AcceptCompanyInvitationMutation,
  AcceptCompanyInvitationMutationVariables,
  AppleLoginInput,
  AuthWithAppleDocument,
  AuthWithAppleMutation,
  AuthWithAppleMutationVariables,
  AuthWithFbDocument,
  AuthWithFbMutation,
  AuthWithFbMutationVariables,
  AuthWithGoogleDocument,
  AuthWithGoogleMutation,
  AuthWithGoogleMutationVariables,
  LoginQueryDocument,
  LoginQueryMutation,
  LoginQueryMutationVariables,
  RegisterUserDocument,
  RegisterUserInput,
  RegisterUserMutation,
  RegisterUserMutationVariables,
} from '@Graphql/graphqlTypes.generated';
import {
  AcceptCompanyInvitationInputPayload,
  AppleLoginInputPayload,
  FacebookLoginInputPayload,
  GoogleLoginInputPayload,
  LoginActionPayload,
} from '@Store/auth/auth.types';
import ApolloClient from '@Utils/graphqlClient';

export const executeLoginQuery = async (input: LoginActionPayload) => {
  const loginResult: ExecutionResult<LoginQueryMutation> = await ApolloClient.mutate<
    LoginQueryMutation,
    LoginQueryMutationVariables
  >({
    mutation: LoginQueryDocument,
    variables: {
      input: {
        email: input.email,
        password: input.password,
      },
    },
  });

  return loginResult?.data?.tokenAuth;
};
export const executeRegisterQuery = async (input: RegisterUserInput) => {
  const response = await ApolloClient.mutate<RegisterUserMutation, RegisterUserMutationVariables>({
    mutation: RegisterUserDocument,
    variables: {
      input,
    },
  });

  return response.data;
};

export const authFacebook = async (input: FacebookLoginInputPayload) => {
  const fbAuth = await ApolloClient.mutate<AuthWithFbMutation, AuthWithFbMutationVariables>({
    mutation: AuthWithFbDocument,
    variables: {
      input: {
        userId: input.userId,
        accessToken: input.accessToken,
        clientMutationId: input.clientMutationId,
        addNaturalCompany: input.addNaturalCompany,
      },
    },
  });

  return fbAuth?.data?.facebookLogin;
};

export const authApple = async (input: AppleLoginInputPayload) => {
  const inputValuesSignIn: AppleLoginInput = {
    code: input.code,
    clientMutationId: input.clientMutationId,
    addNaturalCompany: input.addNaturalCompany,
    loginEnv: input.loginEnv,
  };

  const inputValuesSignUp = {
    ...inputValuesSignIn,
    name: input.name,
    email: input.email,
  };

  const valuesBasedOnType = input.email || input.name?.firstName ? inputValuesSignUp : inputValuesSignIn;

  let result;

  try {
    const appleAuth = await ApolloClient.mutate<AuthWithAppleMutation, AuthWithAppleMutationVariables>({
      mutation: AuthWithAppleDocument,
      variables: {
        input: valuesBasedOnType,
      },
    });

    result = appleAuth.data?.appleLogin;
  } catch (e) {
    Sentry.captureException(e);
  }

  return result;
};

export const authGoogle = async (input: GoogleLoginInputPayload) => {
  let result;

  try {
    const googleAuth = await ApolloClient.mutate<AuthWithGoogleMutation, AuthWithGoogleMutationVariables>({
      mutation: AuthWithGoogleDocument,
      variables: {
        input: {
          accessToken: input.accessToken,
          clientMutationId: input.clientMutationId,
          addNaturalCompany: input.addNaturalCompany,
        },
      },
    });

    result = googleAuth?.data?.googleLogin;
  } catch (e) {
    Sentry.captureException(e);
  }

  return result;
};

export const acceptCompanyInvitation = async (input: AcceptCompanyInvitationInputPayload) => {
  await ApolloClient.mutate<AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables>({
    mutation: AcceptCompanyInvitationDocument,
    variables: {
      input: {
        invitationKey: input.invitationKey,
      },
    },
  });
};
