import * as React from 'react';

type NodeConnection = { node: { id: string } };
export const mergeByUniqueId = <T extends NodeConnection, U extends NodeConnection>(A: T[], B: U[]) => {
  return [...A.filter(a => !B.some(b => b.node.id === a.node.id)), ...B];
};

export const noop = () => {};

export const generateHistoryArray = (dataArray: string[], id: string, maxLength = 10) => {
  return [id, ...dataArray.filter(value => value !== id)].slice(0, maxLength);
};

export const resetFocus = (e: React.SyntheticEvent) => {
  const { currentTarget } = e;
  if (currentTarget instanceof HTMLButtonElement) {
    currentTarget.blur();
  }
};

export const toLowercase = <Value extends string>(text: Value) => text.toLowerCase() as Lowercase<Value>;

export const testTranslator = (s: string) => s;
