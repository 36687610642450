import Slider, { Handle, HandleProps, Marks } from 'rc-slider';
import * as React from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import InputContainer from '@Components/forms/inputs/InputContainer/InputContainer';
import Icon, { IconSizes } from '@Components/Icon';

import style from './RangeSelectInput.module.scss';

const handle = (props: HandleProps & { value: string }) => {
  const { className, style: handleStyle, max, min, offset, value } = props;

  return (
    <div className="range-selection-slider__handle">
      {/*
       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
       // @ts-expect-error */}
      <Handle vertical={false} className={className} style={handleStyle} max={max} min={min} offset={offset}>
        <div className="range-selection-slider__value">{value}</div>
      </Handle>
    </div>
  );
};

export interface RangeInputProps extends FormFieldRenderProps<number> {
  min: number;
  startValue: number;
  max: number;
  stepLength: number;
  label?: string;
  required?: boolean;
}

interface RangeInputState {
  value: number;
}

export class RangeInput extends React.PureComponent<RangeInputProps, RangeInputState> {
  static numbersAsComponents = (...numbers: number[]): Marks => {
    const result: Marks = {};
    numbers.forEach(numberItem => {
      result[numberItem] = <span key={numberItem}>{numberItem}</span>;
    });
    return result;
  };

  constructor(props: RangeInputProps) {
    super(props);
    this.state = {
      value: props.startValue || 0,
    };
  }

  getMarks() {
    const { min, max } = this.props;
    return RangeInput.numbersAsComponents(min, max);
  }

  increase = () => {
    const { input, stepLength } = this.props;
    this.setState(
      oldState => ({ value: oldState.value + stepLength }),
      () => {
        input.onChange(this.state.value);
      },
    );
  };

  decrease = () => {
    const { input, stepLength } = this.props;
    this.setState(
      oldState => ({ value: oldState.value - stepLength }),
      () => {
        input.onChange(this.state.value);
      },
    );
  };

  onChange = (value: number) => {
    this.setState({ value });
  };

  onAfterChange = () => {
    const { input } = this.props;
    input.onChange(this.state.value);
  };

  render() {
    const { label, meta, required, min, max, stepLength } = this.props;

    const inputValue = this.state.value;
    const isFiniteValue = Number.isFinite(inputValue);
    const disabledIncrease = isFiniteValue && inputValue >= max;
    const disabledDecrease = isFiniteValue && inputValue <= min;

    return (
      <InputContainer label={label} meta={meta} required={required} showErrorIcon>
        <div className={style.rangeSelectionMenu}>
          <button className="buttonOfRangeSelector" onClick={this.decrease} disabled={disabledDecrease} type="button">
            <div className="range-selection-menu__range-selection">
              <Icon icon="arrow-left" size={IconSizes.ss} />
            </div>
          </button>
          <div className="sliderContainer">
            <Slider
              min={min}
              max={max}
              step={stepLength}
              handle={handle}
              value={this.state.value}
              onChange={this.onChange}
              onAfterChange={this.onAfterChange}
              marks={this.getMarks()}
            />
          </div>
          <button className="buttonOfRangeSelector" onClick={this.increase} disabled={disabledIncrease} type="button">
            <div className="range-selection-menu__range-selection">
              <Icon icon="arrow-left" size={IconSizes.ss} className="turnArrow" />
            </div>
          </button>
        </div>
      </InputContainer>
    );
  }
}

export default RangeInput;
