import React from 'react';

import Pill from '@Components/Pill';
import { buildOrderTypeMessage } from '@Config/messages';
import { OrderType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

interface OrderTypeDisplayProps {
  type: OrderType;
}

const OrderTypeDisplay: React.FunctionComponent<OrderTypeDisplayProps> = ({ type }) => {
  const t = useTranslations();

  return <Pill width="fixed" type="greenOutlined" msg={t(buildOrderTypeMessage(type))} />;
};

export default OrderTypeDisplay;
