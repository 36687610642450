import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Messages } from '@Config/messages';
import {
  InviteUserToCompanyMutationInput,
  InviteUserToMyCompanyMutation,
  useInviteUserToMyCompanyMutation,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { callMutationForForm } from '@Utils/graphql';
import { noop } from '@Utils/util';

import modalStyles from '../Modal.module.scss';
import { showSuccessModal } from '../modalHelpers';

import InviteUserForm from './InviteUserForm/InviteUserForm';
import styles from './InviteUserFormModal.module.scss';

export interface InviteUserFormModalProps {
  closeModal: () => void;
}

const InviteUserFormModal: React.FunctionComponent<InviteUserFormModalProps> = () => {
  const dispatch = useDispatch();
  const t = useTranslations();

  const [inviteUserToMyCompany] = useInviteUserToMyCompanyMutation({
    onCompleted: () => {
      dispatch(showSuccessModal(t(Messages.btnOk), noop, t(Messages.inviteUserSuccess)));
    },
  });

  const handleSubmit = (values: InviteUserToCompanyMutationInput) => {
    return callMutationForForm<InviteUserToMyCompanyMutation>(inviteUserToMyCompany({ variables: { input: values } }));
  };

  return (
    <div className={classNames(modalStyles.actions)}>
      <div className={styles.container}>
        <InviteUserForm onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default InviteUserFormModal;
