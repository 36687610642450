import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import lazyImport from '@Utils/lazyImport';

import { RouteInfo } from '../appRoutes';

const ActivityPage = lazyImport(() => import('@Routes/activity/ActivityPage'));

const routes: RouteInfo[] = [
  {
    path: RouteConfig.Activity.template,
    component: ActivityPage,
    title: Messages.activityPage,
    exact: true,
  },
];

export default routes;
