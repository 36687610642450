import * as React from 'react';

import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { SEARCH_ITEM_TITLE_LENGTH } from '@Config/constants';
import { AdvertFragment, AdvertStatus } from '@Graphql/graphqlTypes.generated';
import { truncate } from '@Utils/string';

import styles from './AdvertCardTitle.module.scss';

export interface AdvertCardTitleProps {
  advert: AdvertFragment;
}

const AdvertCardTitle: React.FunctionComponent<AdvertCardTitleProps> = ({ advert: { title, status } }) => {
  return (
    <Row alignCenter justifyBetween constant>
      {status === AdvertStatus.Private && (
        <Icon icon="lock-filled" size={IconSizes.s} className={styles.advertCardIcon} />
      )}
      <div className={styles.title}>
        <Typography
          tag="div"
          color="black"
          msg={truncate(title, SEARCH_ITEM_TITLE_LENGTH)}
          size="m"
          bold
          className={styles.title}
        />
      </div>
    </Row>
  );
};

export default AdvertCardTitle;
