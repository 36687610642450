import { RouteConfig } from '@Config/routes';
import { FeatureToggle } from '@Store/app/app.types';

export const linksRelatedToFeature = (featureToggle: keyof FeatureToggle): string[] => {
  switch (featureToggle) {
    case 'auction': {
      return [
        RouteConfig.AuctionEdit.template,
        RouteConfig.Auctions.template,
        RouteConfig.AuctionNew.template,
        RouteConfig.MyAuctions.template,
        RouteConfig.MyAuctionPreview.template,
        RouteConfig.AuctionPreview.template,
      ];
    }
    case 'forum': {
      return [
        RouteConfig.Forum.template,
        RouteConfig.ForumThreadList.template,
        RouteConfig.ForumThreadPage.template,
        RouteConfig.CreateForumThread.template,
        RouteConfig.ExpertsPage.template,
      ];
    }
    default: {
      return [];
    }
  }
};

export const getDisabledLinks = (featureToggles: FeatureToggle): string[] => {
  return (Object.keys(featureToggles) as [keyof typeof featureToggles])
    .filter(key => !featureToggles[key])
    .flatMap(val => linksRelatedToFeature(val));
};
