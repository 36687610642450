import React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes, ButtonTypes, ButtonStyles } from '@Components/Buttons/Button';
import FormField from '@Components/forms/FormField';
import Checkbox from '@Components/forms/inputs/Checkbox';
import DateInput from '@Components/forms/inputs/DateInput/DateInput';
import TextAreaInput from '@Components/forms/inputs/TextAreaInput';
import TextInput, { TextInputProps } from '@Components/forms/inputs/TextInput';
import InfoMessage, { MessageTypes } from '@Components/InfoMessage';
import Typography from '@Components/Typography/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import { getUserIsLogged } from '@Store/auth/auth.selector';
import { normalizeDate } from '@Utils/dateMoment';
import { selectedDayIsAfterYesterday } from '@Utils/dates';
import {
  validate,
  validateEmail,
  validatePhone,
  validateRequired,
  validateSelectedDayIsAfterYesterday,
} from '@Utils/form';
import { PreliminaryOrderFormData } from '@Utils/types';
import { validateDate } from '@Utils/validate';

import styles from './PreliminaryOrderForm.module.scss';

interface PreliminaryOrderFormProps {
  advertId?: string;
  onSubmit: (values: PreliminaryOrderFormData) => Promise<{}>;
  onCancel?: () => void;
  initialValues: PreliminaryOrderFormData;
}

enum FormFields {
  startDate = 'startDate',
  description = 'description',
  contactType = 'contactType',
  email = 'email',
  phone = 'phone',
}

const OrderForm = ({
  handleSubmit,
  submitting,
  submitError,
  values,
  advertId,
  onCancel,
}: FormRenderProps<PreliminaryOrderFormData> & { advertId?: string; onCancel?: () => void }): JSX.Element => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  const isLoggedIn = useSelector(getUserIsLogged);

  return (
    <form onSubmit={handleSubmit} className={styles.orderContainer}>
      <InfoMessage type={MessageTypes.error} message={submitError} />
      <FormField
        name={FormFields.startDate}
        placeholder={Messages.preliminaryOrderDate}
        component={DateInput}
        parse={date => normalizeDate(date, currentLanguage)}
        isValidDate={selectedDayIsAfterYesterday}
        validate={validate(
          validateRequired(t),
          validateDate(t, currentLanguage),
          validateSelectedDayIsAfterYesterday(t),
        )}
      />
      <FormField
        name={FormFields.description}
        placeholder={Messages.preliminaryOrderComment}
        component={TextAreaInput}
        validate={!advertId ? validateRequired(t) : undefined}
      />
      <Typography
        msg={t(Messages.preliminaryOrderContactTypeTitle)}
        tag="h5"
        size="s"
        className={styles.contactTypeTitle}
      />
      {isLoggedIn && (
        <FormField
          name={FormFields.contactType}
          component={Checkbox}
          inline
          type="radio"
          value="platform"
          label={Messages.preliminaryOrderContactViaPlatform}
          validate={validateRequired(t)}
        />
      )}
      <FormField
        name={FormFields.contactType}
        component={Checkbox}
        inline
        type="radio"
        value="phone"
        label={Messages.preliminaryOrderContactViaPhone}
        validate={validateRequired(t)}
      />
      <FormField
        name={FormFields.contactType}
        component={Checkbox}
        inline
        type="radio"
        value="email"
        label={Messages.preliminaryOrderContactViaEmail}
        validate={validateRequired(t)}
      />
      {values.contactType === 'phone' && (
        <FormField<TextInputProps<string>>
          name={FormFields.phone}
          component={TextInput}
          placeholder={Messages.preliminaryOrderPhone}
          validate={validate(validatePhone(t, currentLanguage), validateRequired(t))}
        />
      )}
      {values.contactType === 'email' && (
        <FormField<TextInputProps<string>>
          name={FormFields.email}
          component={TextInput}
          placeholder={Messages.preliminaryOrderEmail}
          validate={validate(validateEmail(t), validateRequired(t))}
        />
      )}
      <div className={styles.orderActions}>
        <Button
          loading={submitting}
          label={t(Messages.submitPreliminaryOrder)}
          size={ButtonSizes.fill}
          type={ButtonTypes.submit}
          disabled={submitting}
        />
        {onCancel && (
          <Button
            loading={submitting}
            label={t(Messages.cancelPreliminaryOrder)}
            size={ButtonSizes.fill}
            type={ButtonTypes.button}
            disabled={submitting}
            onClick={onCancel}
            style={ButtonStyles.outlinedGreen}
          />
        )}
      </div>
    </form>
  );
};

const PreliminaryOrderForm = ({ onSubmit, initialValues, advertId, onCancel }: PreliminaryOrderFormProps) => {
  return (
    <Form<PreliminaryOrderFormData>
      onSubmit={onSubmit}
      render={props => <OrderForm {...props} onCancel={onCancel} advertId={advertId} />}
      initialValues={initialValues}
    />
  );
};

export default PreliminaryOrderForm;
