import React from 'react';

import DocumentTitle from '@Components/DocumentTitle/DocumentTitle';
import Container from '@Components/layout/Container';
import Layout, { LayoutBackgrounds } from '@Components/layout/Layout';
import Row from '@Components/layout/Row';
import GeneralOrderAcceptance from '@Components/Order/GeneralOrderAcceptance';
import PageHeader from '@Components/Page/PageHeader';
import PageTitle from '@Components/Page/PageTitle';
import Paper, { PaperDepth, PaperWidth } from '@Components/Paper';
import PaperBody from '@Components/PaperBody';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './GeneralOrderAcceptancePage.module.scss';

const GeneralOrderAcceptancePage = () => {
  const t = useTranslations();

  return (
    <DocumentTitle title={t('msg_accept_general_order_page_title')}>
      <Layout background={LayoutBackgrounds.grayBackground} noTopPadding>
        <PageHeader>
          <Row column className={styles.title}>
            <PageTitle msg={t('msg_accept_general_order_page_title')} color="primary" />
          </Row>
        </PageHeader>

        <Container className={styles.formContainer}>
          <Paper width={PaperWidth.m} depth={PaperDepth.one}>
            <PaperBody>
              <GeneralOrderAcceptance />
            </PaperBody>
          </Paper>
        </Container>
      </Layout>
    </DocumentTitle>
  );
};

export default GeneralOrderAcceptancePage;
