import classNames from 'classnames';
import React from 'react';

import Image from '@Components/Image';
import PlaceholderImage from '@Images/placeholder.png';

import styles from './AdvertImage.module.scss';

const AdvertImage: React.FunctionComponent<{ url?: string; className?: string; alt: string }> = props => {
  const { url, className, alt } = props;
  const advertUrl = url || null;
  return <Image src={advertUrl || PlaceholderImage} className={classNames(className, styles.advertImage)} alt={alt} />;
};

export default AdvertImage;
