import { GoogleMap, Marker } from '@react-google-maps/api';
import React from 'react';

import { LocationValue } from '@Components/LocationSelect';
import bluePin from '@Images/icons/map-pin-blue.png';

export interface CoordinatesProps {
  lat: number;
  lng: number;
}

interface MapProps {
  location: CoordinatesProps;
  onChange: (values: LocationValue) => void;
}

const SpecifyLocationMap: React.FunctionComponent<MapProps> = ({ location, onChange }) => {
  const mapRef = React.createRef<GoogleMap>();
  const cords: google.maps.LatLngLiteral = location;
  const geocoder = new google.maps.Geocoder();
  const { lat, lng } = location;

  const markerIcon = {
    url: bluePin,
    scaledSize: new google.maps.Size(28, 50),
  };

  const handleDragMarker = (e: google.maps.MapMouseEvent) => {
    const coordinates = {
      lat: e.latLng?.lat() ?? 0,
      lng: e.latLng?.lng() ?? 0,
    };

    const dragLocation = new google.maps.LatLng(coordinates);

    geocoder.geocode({ location: dragLocation }, (results, status) => {
      if (status === 'OK' && results?.[0]) {
        onChange({ ...location, address: results[0].formatted_address });
      }
    });
  };

  return (
    <GoogleMap zoom={8} center={cords} ref={mapRef} mapContainerStyle={{ height: '80vh' }}>
      <Marker draggable position={{ lat, lng }} icon={markerIcon} onDragEnd={e => handleDragMarker(e)} />
    </GoogleMap>
  );
};

export default SpecifyLocationMap;
