import { LocalStorage, WebViewMessagesTypes } from '@Config/constants';
import { getFromLocalStorage } from '@Utils/localStorage';

export const postMessageToWebView = (message: WebViewMessagesTypes, data: any = null) => {
  if (window.ReactNativeWebView !== undefined) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ data, type: message }));
  }
};

export type WebViewMessagePayload = {
  type: WebViewMessagesTypes;
  data: object;
};

export const makeAppActionType = (type: string) => `APP_ACTION_${type}`;
export const isMobileApp = window.ReactNativeWebView !== undefined;

//Initial action to mobile app on load:
const token = getFromLocalStorage(LocalStorage.userToken);

if (token) {
  postMessageToWebView(WebViewMessagesTypes.userToken, {
    token,
  });
} else {
  postMessageToWebView(WebViewMessagesTypes.logout);
}
