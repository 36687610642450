import * as React from 'react';

import { useAppKindContext } from '@Components/root';
import { OptionItem } from '@Components/Select';
import { CategoryFragment, CategoryServiceType, CategoryKind } from '@Graphql/graphqlTypes.generated';
import { useTranslateObject } from '@Hooks/useTranslateObject';
import { transformToTree } from '@Utils/transformations';
import { TranslationSelector } from '@Utils/types';

import SelectInput, { SelectInputProps } from '../../SelectInput';

interface WrapperProps {
  serviceType: string;
  bottomSpacing?: boolean;
  largeDropdown?: boolean;
  categories: CategoryFragment[] | undefined;
  isLoading: boolean;
}

export type CategorySelectProps = SelectInputProps & WrapperProps;

export enum AllCategoriesProps {
  allCategories = 'allCategories',
}

const categorySelectOptions = (
  categories: CategoryFragment[],
  serviceType: string,
  selectTranslation: TranslationSelector,
  appKind: CategoryKind,
): OptionItem[] => {
  let categoriesList = categories.filter(cat => cat.kind === appKind);

  if (serviceType !== AllCategoriesProps.allCategories) {
    categoriesList = categories.filter(
      cat => cat.serviceType === serviceType || cat.serviceType === CategoryServiceType.Both,
    );
  }

  const categoriesTree = transformToTree(categoriesList, item => item.orderNum);

  return categoriesTree
    .filter(category => !!category)
    .map(category => ({
      value: category.element.id,
      label: selectTranslation(category.element.name),
      options: category.children.map(child => ({
        label: selectTranslation(child.element.name),
        value: child.element.id,
      })),
    }));
};

const CategorySelect: React.FunctionComponent<CategorySelectProps> = ({
  isLoading,
  categories,
  serviceType,
  largeDropdown,
  ...props
}) => {
  const { appKind } = useAppKindContext();
  const selectTranslation = useTranslateObject();

  const options =
    isLoading || !categories ? [] : categorySelectOptions(categories, serviceType, selectTranslation, appKind);

  return (
    <SelectInput
      {...props}
      options={options}
      loading={isLoading}
      isClearable={false}
      localizeSelectLabels={false}
      largeDropdown={largeDropdown}
    />
  );
};

export default React.memo(CategorySelect);
