import React, { FunctionComponent } from 'react';

import FloatingActionButton from '@Components/Buttons/FloatingActionButton';
import { SCROLL_THRESHOLD_PX } from '@Config/constants';
import { useScrolled } from '@Hooks/useScrolled';

import styles from './DefaultFloatingNavigationContent.module.scss';

const DefaultFloatingNavigationContent: FunctionComponent = () => {
  const scrolled = useScrolled();
  const isScrolledToThreshold = scrolled > SCROLL_THRESHOLD_PX;
  if (!isScrolledToThreshold) {
    return null;
  }
  return (
    <FloatingActionButton
      className={styles.icon}
      icon="scroll-top"
      onClick={() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    />
  );
};

export default DefaultFloatingNavigationContent;
