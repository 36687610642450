import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import Button, { ButtonSizes, ButtonStyles } from '@Components/Buttons/Button';
import { ModalProviderProps } from '@Components/Modal';
import modalStyles from '@Components/Modal/Modal.module.scss';
import { ActionsDisplayType } from '@Components/Modal/SuccessModalBody';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

export interface ConfirmationModalProps {
  actionsDisplayType?: ActionsDisplayType;
  onConfirmButtonClick?: () => void;
}

const ConfirmationModal: FunctionComponent<ModalProviderProps<ConfirmationModalProps>> = ({
  modalData: { actionsDisplayType = ActionsDisplayType.row, onConfirmButtonClick = () => {} },
  closeModal,
}) => {
  const t = useTranslations();

  const confirmButtonHandler = () => {
    onConfirmButtonClick();
    closeModal();
  };

  const cancelButtonHandler = () => {
    closeModal();
  };

  return (
    <div className={classNames(modalStyles.actions, modalStyles[actionsDisplayType])}>
      <Button label={t(Messages.btnOk)} size={ButtonSizes.fill} onClick={confirmButtonHandler} />
      <Button
        label={t(Messages.btnCancel)}
        size={ButtonSizes.fill}
        style={ButtonStyles.outlinedGreen}
        onClick={cancelButtonHandler}
      />
    </div>
  );
};

export default ConfirmationModal;
