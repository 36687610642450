import { useEffect } from 'react';

const useWebViewListener = (messageHandler: (event: MessageEvent) => void) => {
  if (window.ReactNativeWebView === undefined) {
    return [() => {}];
  }

  const removeListener = () => {
    window.removeEventListener('message', messageHandler);
  };

  useEffect(() => {
    window.addEventListener('message', messageHandler);
    return removeListener;
  }, []);
  return [removeListener];
};

export default useWebViewListener;
