import * as React from 'react';

import { timeSelectOptions } from '@Utils/options';

import SelectInput, { SelectInputProps } from '../../SelectInput';

export interface TimeInputOptionProps {
  startTime?: string;
  endTime?: string;
  minutesToAdd?: number;
  bottomSpacing?: boolean;
}

export type TimeSelectProps = TimeInputOptionProps & SelectInputProps;

const TimeSelect: React.FunctionComponent<TimeSelectProps> = ({
  startTime,
  endTime,
  minutesToAdd,
  ...selectInputProps
}) => {
  const options = React.useMemo(
    () => timeSelectOptions({ startTime, endTime, minutesToAdd }),
    [startTime, endTime, minutesToAdd],
  );

  return <SelectInput size="s" {...selectInputProps} options={options} placeholder={undefined} />;
};

export default TimeSelect;
