import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppLoader from '@Components/AppLoader';
import CookieBanner from '@Components/CookieBanner';
import ErrorWrapper from '@Components/ErrorWrapper';
import { MetaConsumer } from '@Components/Meta';
import Modal from '@Components/Modal';
import RoutesRenderer from '@Components/RoutesRenderer';
import ScrollToTop from '@Components/ScrollToTop';
import StripeWrapper from '@Components/StripeWrapper';
import GoogleTagManager from '@Components/TagManager';
import UserTour from '@Components/UserTour';
import { AppContextProvider } from '@Config/localeContext';
import { getGoogleAppId, getGoogleTagManagerId, isApiDead, isAppReady } from '@Store/app/app.selectors';
import useWebViewListener from '@Utils/hooks/useWebViewListener';
import { makeAppActionType } from '@Utils/mobileWebView';

const app: React.FunctionComponent = () => {
  const appReady = useSelector(isAppReady);
  const apiDead = useSelector(isApiDead);
  const googleTagManagerId = useSelector(getGoogleTagManagerId);
  const googleAppId = useSelector(getGoogleAppId);

  const dispatch = useDispatch();

  const handleAppMessages = (event: MessageEvent) => {
    const { type, data } = event.data;
    dispatch({ type: makeAppActionType(type), payload: data });
  };

  useWebViewListener(handleAppMessages);

  if ((!appReady && !apiDead) || (!appReady && apiDead)) {
    return <AppLoader />;
  }

  return (
    <StripeWrapper>
      <GoogleOAuthProvider clientId={googleAppId}>
        <AppContextProvider value={{}}>
          <MetaConsumer />
          <Modal />
          <CookieBanner />
          <ScrollToTop />
          <RoutesRenderer />
          <UserTour />
          {googleTagManagerId && <GoogleTagManager gtmId={googleTagManagerId} />}
        </AppContextProvider>
      </GoogleOAuthProvider>
    </StripeWrapper>
  );
};

export default ErrorWrapper(app);
