import { LOCATION_CHANGE, push } from 'connected-react-router';
import { all, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { getGoogleTagManagerId } from '@Store/app/app.selectors';

import { locationChange } from './navigation.actions';
import { NavigationActionPayload } from './navigation.types';

function* locationRedirect(action: Action<NavigationActionPayload>) {
  yield put(push(action.payload.path));
}

function* onNavigate() {
  const googleTagManagerId = select(getGoogleTagManagerId);
  if (googleTagManagerId) {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: 'pageview', 'content-name': window.location.pathname });
  }
}

export function* navigationSaga() {
  const redirect: ForkEffect<never> = yield takeEvery(locationChange, locationRedirect);
  const navigate: ForkEffect<never> = yield takeEvery(LOCATION_CHANGE, onNavigate);
  yield all([redirect, navigate]);
}
