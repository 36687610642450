import { GetUserDocument, GetUserQuery, GetUserQueryVariables } from '@Graphql/graphqlTypes.generated';
import ApolloClient from '@Utils/graphqlClient';

export const getUser = async () => {
  const response = await ApolloClient.query<GetUserQuery, GetUserQueryVariables>({
    query: GetUserDocument,
    fetchPolicy: 'network-only',
  });

  return response.data;
};
