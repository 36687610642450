import * as React from 'react';
import { useSelector } from 'react-redux';

import { getImageUrl } from '@Components/forms/inputs/ImageInput';
import Icon, { IconSizes, sizes } from '@Components/Icon';
import Loading from '@Components/LoadingSpinner';
import { WebViewMessagesTypes } from '@Config/constants';
import { getMainLogo } from '@Store/app/app.selectors';
import { postMessageToWebView } from '@Utils/mobileWebView';

import styles from './AppLoader.module.scss';

const AppLoader: React.FunctionComponent = () => {
  React.useEffect(() => {
    postMessageToWebView(WebViewMessagesTypes.headerColor, 'transparent');
  });

  const mainLogo = useSelector(getMainLogo);
  const mainLogoUrl = mainLogo && getImageUrl(mainLogo);

  return (
    <div className={styles.appLoader}>
      <div className={styles.container}>
        {mainLogoUrl ? (
          <img src={mainLogoUrl} alt="logo" {...sizes[IconSizes.logo]} />
        ) : (
          <Icon size={IconSizes.logo} icon="green-sejico-logo" />
        )}
        <Loading />
      </div>
    </div>
  );
};

export default React.memo(AppLoader);
