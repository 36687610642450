import { FormApi } from 'final-form';

import { CookiesFormData, CookiesNames } from '@Components/CookieBanner/CookieBanner';
import { AdFilterFields, AdFilterFormSubmitValues, OnAdFiltersChange } from '@Components/forms/Filter/AdFilters';
import { LocationValue } from '@Components/LocationSelect';
import { CoordinatesProps } from '@Components/Map/SpecifyLocationMap';
import { AvailabilityPreviewModalProps } from '@Components/Modal/AvailabilityPreviewModal';
import { ConfirmationModalProps } from '@Components/Modal/ConfirmationModal';
import { FiltersModalData } from '@Components/Modal/FiltersModal';
import { ModalParams, ModalType } from '@Components/Modal/index';
import { ActionsDisplayType, SuccessModalData } from '@Components/Modal/SuccessModalBody';
import { PaperWidth } from '@Components/Paper';
import { PaperBodyOverflow, PaperBodySpacing } from '@Components/PaperBody';
import { SearchBarData } from '@Components/Search/SearchBar';
import { AdvertFragment, OrderType, OrderStatus } from '@Graphql/graphqlTypes.generated';
import { showModal } from '@Store/app/app.actions';
import { CalendarNode } from '@Utils/types';

export const showSuccessModal = (
  buttonMessage: string,
  onClose?: () => void,
  title?: string,
  subtitle?: string,
  secondButtonText?: string,
  secondButtonAction?: () => void,
  actionsDisplayType?: ActionsDisplayType,
) => {
  const data: SuccessModalData = {
    onClose,
    secondButtonText,
    secondButtonAction,
    actionsDisplayType,
    buttonText: buttonMessage,
  };

  return showModal({
    type: ModalType.successModal,
    params: {
      data,
      title,
      subtitle,
      modalSize: PaperWidth.m,
    },
  });
};

export const showAvailabilityPreviewModal = (data: AvailabilityPreviewModalProps) => {
  return showModal({
    type: ModalType.availabilityPreviewModal,
    params: {
      data,
      modalSize: PaperWidth.full,
      modalSpacing: PaperBodySpacing.none,
    },
  });
};

export const showSearchResultsModal = (items: AdvertFragment[], location: SearchBarData) => {
  return showModal({
    type: ModalType.searchResultsModal,
    params: {
      data: { items, location },
      modalSize: PaperWidth.full,
      modalSpacing: PaperBodySpacing.s,
    },
  });
};

export const showOrderResultsModal = (status: OrderStatus | undefined, orderType?: OrderType) => {
  return showModal({
    type: ModalType.orderResultsModal,
    params: {
      data: { status, orderType },
      modalSize: PaperWidth.full,
      modalSpacing: PaperBodySpacing.s,
    },
  });
};

export const showSpecifyLocationModal = (location: CoordinatesProps, onChange: (values: LocationValue) => void) => {
  return showModal({
    type: ModalType.specifyLocationModal,
    params: {
      data: { location, onChange },
      modalSize: PaperWidth.full,
      modalSpacing: PaperBodySpacing.s,
    },
  });
};

interface ShowFiltersModalProps {
  onFiltersChange: OnAdFiltersChange;
  onFiltersClear: (values: FormApi<AdFilterFormSubmitValues>) => void;
  filtersInitialValues: AdFilterFields;
  showDistanceField?: boolean;
  onMobileOnly?: boolean;
}

export const showFiltersModal = (props: ShowFiltersModalProps) => {
  const { onFiltersChange, onFiltersClear, filtersInitialValues, showDistanceField, onMobileOnly } = props;

  const data: FiltersModalData = {
    onFiltersChange,
    onFiltersClear,
    filtersInitialValues,
    showDistanceField,
  };

  return showModal({
    type: ModalType.filtersModal,
    params: {
      data,
      modalSize: PaperWidth.full,
      modalSpacing: PaperBodySpacing.none,
      onMobileOnly,
    },
  });
};

export const showCalendarEventFormModal = (
  id: string,
  event?: CalendarNode,
  onSuccessCallback?: () => void,
  isEventRemovalAllowed?: boolean,
) => {
  return showModal({
    type: ModalType.calendarEventModal,
    params: {
      modalSize: PaperWidth.m,
      modalSpacing: PaperBodySpacing.none,
      data: {
        onSuccessCallback,
        isEventRemovalAllowed,
        event,
        actionsDisplayType: ActionsDisplayType.column,
        advert: id,
      },
    },
  });
};

export const showCompanyFormModal = (title: string) => {
  return showModal({
    type: ModalType.companyFormModal,
    params: {
      title,
      modalSize: PaperWidth.m,
      modalSpacing: PaperBodySpacing.none,
      data: {
        actionsDisplayType: ActionsDisplayType.column,
      },
    },
  });
};

export const showCookieModal = (
  onSubmit: any,
  cookies: CookiesFormData[],
  values: { [key in CookiesNames]: boolean },
) => {
  return showModal({
    type: ModalType.cookieModal,
    params: {
      aboveCookieBar: true,
      modalOverflow: PaperBodyOverflow.visible,
      modalSize: PaperWidth.m,
      modalSpacing: PaperBodySpacing.none,
      data: {
        cookies,
        values,
        onSubmit,
        actionsDisplayType: ActionsDisplayType.column,
      },
    },
  });
};

export const showConfirmationModal = (params: ModalParams<ConfirmationModalProps>) => {
  return showModal({
    type: ModalType.confirmationModal,
    params: {
      ...params,
      modalSize: params.modalSize || PaperWidth.m,
    },
  });
};

// TO DO: Do custom modal on anoher task
export const showInviteUserFormModal = (title: string) => {
  return showModal({
    type: ModalType.inviteUserFormModal,
    params: {
      title,
      modalSize: PaperWidth.m,
      modalSpacing: PaperBodySpacing.none,
      withCloseButton: true,
    },
  });
};
