import { Messages } from '@Config/messages';
import { CreateContractorMutationInput, GeneralContractorPriceType } from '@Graphql/graphqlTypes.generated';
import { Translator } from '@Utils/translation';
import { ContractFormData, FieldError, MutationInputResult } from '@Utils/types';

export const getContractFormInput = (
  formData: ContractFormData,
  t: Translator,
): MutationInputResult<CreateContractorMutationInput, ContractFormData> => {
  const toErrorResult = (errors: FieldError<ContractFormData>) => ({ type: 'ERROR' as const, data: errors });

  const categoriesDetailsInput = Object.entries(formData.categoriesDetails).map(([categoryId, details]) => ({
    id: categoryId,
    value: details.details,
    price: {
      value: Number(details.price.value),
      type: details.price.type as GeneralContractorPriceType,
    },
  }));

  const workingArea = Number.parseFloat(formData.workingAreaRay);
  if (Number.isNaN(workingArea) || workingArea <= 0) {
    return toErrorResult({ workingAreaRay: t(Messages.errorInvalidAmountValue) });
  }

  return {
    type: 'INPUT' as const,
    data: {
      name: formData.name,
      surname: formData.surname,
      email: formData.email,
      phone: formData.phone,
      coordinates: formData.coordinates,
      address: formData.address,
      workingArea,
      categories: formData.categories,
      categoriesDetails: categoriesDetailsInput,
      additionalInfo: formData.additionalInfo,
    } satisfies CreateContractorMutationInput,
  };
};
