import { ORDER_DATE_DIFF } from '@Config/constants';
import { AdvertPricingType } from '@Graphql/graphqlTypes.generated';
import { Optional } from '@Utils/types';

import { getDateDiffInDays, getDateDiffInHours } from './dates';

export const convertCentsToMoney = (cents: number): string => {
  return (cents / 100).toFixed(2);
};

export const convertMoneyToCents = (money: number) => {
  return money * 100;
};

export type Calculation = { amount: number; price: number; deliveryPrice: number; totalPrice: number };

const defaultCalculation: Calculation = { amount: 0, price: 0, deliveryPrice: 0, totalPrice: 0 };
Object.freeze(defaultCalculation);

export type CalculateOrderInput = {
  startDate: Optional<Date>;
  endDate: Optional<Date>;
  price: number;
  deliveryPrice: number;
  type: AdvertPricingType;
  amount?: number | undefined;
};

export type CalculationProcessor = (input: CalculateOrderInput, calculation?: Optional<Calculation>) => Calculation;

export const calculateOrder: (input: CalculateOrderInput) => Calculation = input =>
  calculateAdditionalExpenses(input, calculateBaseOrder(input));

const calculateBaseOrder: CalculationProcessor = (input, calculation = defaultCalculation) => {
  const calculationsTypeBased = calculationsByPricingType[input.type](input, calculation);
  return {
    ...calculationsTypeBased,
    totalPrice: calculationsTypeBased.price,
  };
};

const calculateByHour: CalculationProcessor = ({ startDate, endDate, price }, calculation = defaultCalculation) => {
  const timeCalc = (startDate && endDate && getDateDiffInHours(startDate, endDate)) || null;
  const time = !timeCalc || isNaN(timeCalc) || timeCalc < ORDER_DATE_DIFF ? 0 : timeCalc;
  const priceCalc = time * price;
  return {
    ...calculation,
    amount: time,
    price: priceCalc,
  };
};

const calculateByNight: CalculationProcessor = ({ startDate, endDate, price }, calculation = defaultCalculation) => {
  const timeCalc = (startDate && endDate && getDateDiffInDays(startDate, endDate)) || null;
  const time = !timeCalc || isNaN(timeCalc) || timeCalc < 0 ? 0 : timeCalc;
  return {
    ...calculation,
    amount: time,
    price: time * price,
  };
};

const calculateByItem: CalculationProcessor = ({ amount = 1, price }, calculation = defaultCalculation) => ({
  ...calculation,
  amount,
  price: amount * price,
});

const calculateByHe: CalculationProcessor = ({ amount = 0, price }, calculation = defaultCalculation) => ({
  ...calculation,
  amount,
  price: amount * price,
});

const calculateByKilogram: CalculationProcessor = ({ amount = 1, price }, calculation = defaultCalculation) => ({
  ...calculation,
  amount,
  price: amount * price,
});

export type CalculationsByPricingType = { [key in AdvertPricingType]: CalculationProcessor };

export const calculationsByPricingType: CalculationsByPricingType = {
  [AdvertPricingType.EurPerMeter]: calculateByItem,
  [AdvertPricingType.EurPerLiter]: calculateByItem,
  [AdvertPricingType.EurPerTon]: calculateByItem,
  [AdvertPricingType.EurPerNight]: calculateByNight,
  [AdvertPricingType.EurPerHectare]: calculateByHe,
  [AdvertPricingType.EurPerHour]: calculateByHour,
  [AdvertPricingType.EurPerItem]: calculateByItem,
  [AdvertPricingType.EurPerKilogram]: calculateByKilogram,
  [AdvertPricingType.NegotiablePrice]: calculateByItem,
  [AdvertPricingType.FreeItem]: calculateByItem,
};

const calculateAdditionalExpenses: CalculationProcessor = ({ deliveryPrice }, calculation = defaultCalculation) => ({
  ...calculation,
  deliveryPrice,
  totalPrice: calculation.totalPrice + deliveryPrice,
});

export const getTotalLoanCost = ({
  monthlyPayment,
  amount,
  interest,
}: {
  monthlyPayment: number;
  amount: number;
  interest: number;
}) => {
  if (!monthlyPayment || monthlyPayment < 0 || !amount || amount < 0) {
    return 0;
  }

  const monthsInYear = 12;
  const termInMonths = Math.round(amount / monthlyPayment);
  const monthlyInterestRate = interest / monthsInYear;

  const monthlyPaymentWithInterest = (amount * monthlyInterestRate) / (1 - (1 + monthlyInterestRate) ** -termInMonths);
  const totalCost = Math.round(monthlyPaymentWithInterest * termInMonths);

  return isNaN(totalCost) ? 0 : totalCost;
};
