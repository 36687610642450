// ToDo de-momentize: utilities for moment-loving DateInput and CalendarRenderer (using 'react-datetime' and 'react-big-calendar') only
import moment from 'moment';

import {
  ApplicationLanguages,
  TimeFormat,
  DateFormats,
  DEFAULT_LANG,
  languageBasedFormatsMoment,
} from '@Config/constants';
import { getDefaultLanguage } from '@Config/localization';

export const setMomentLocale = (language: string = getDefaultLanguage()): void => {
  moment.locale(language);
};

export const getDateFormatMoment = (currentLanguage: ApplicationLanguages, dateFormatted?: DateFormats) => {
  const language = currentLanguage || DEFAULT_LANG;
  const format = dateFormatted || DateFormats.shortDate;
  return languageBasedFormatsMoment[format][language];
};

export const normalizeDate = (date: moment.Moment, currentLanguage: ApplicationLanguages) => {
  const dateFormat = currentLanguage ? getDateFormatMoment(currentLanguage) : TimeFormat.dateFormatMoment;
  return moment.isMoment(date) ? date.format(dateFormat) : date;
};
