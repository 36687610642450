import { Messages } from '@Config/messages';
import { AdvertPricingType } from '@Graphql/graphqlTypes.generated';

export const advertPricingTypeMessages = {
  [AdvertPricingType.EurPerHour]: Messages.orderSummaryPrice,
  [AdvertPricingType.EurPerItem]: Messages.orderSummaryPriceItem,
  [AdvertPricingType.EurPerHectare]: Messages.orderSummaryPriceHe,
  [AdvertPricingType.EurPerKilogram]: Messages.orderSummaryPriceKg,
  [AdvertPricingType.EurPerMeter]: Messages.orderSummaryPriceMeter,
  [AdvertPricingType.EurPerLiter]: Messages.orderSummaryPriceLiter,
  [AdvertPricingType.EurPerTon]: Messages.orderSummaryPriceTon,
  [AdvertPricingType.EurPerNight]: Messages.orderSummaryPriceNight,
  [AdvertPricingType.NegotiablePrice]: Messages.orderSummaryNegotiablePrice,
  [AdvertPricingType.FreeItem]: Messages.orderSummaryFreeItemPrice,
} as const;

export const orderStatusMessages = {
  ACCEPTED: {
    title: Messages.orderAcceptConfirmationTitle,
    subtitle: Messages.orderAcceptConfirmationSubtitle,
    successTitle: Messages.orderSummaryOrderAccepted,
    button: Messages.orderSummaryAcceptOrder,
  },
  CANCELED: {
    title: Messages.orderCancelConfirmationTitle,
    subtitle: Messages.orderCancelConfirmationSubtitle,
    successTitle: Messages.orderSummaryOrderCancelled,
    button: Messages.orderSummaryCancelOrder,
  },
  DECLINED: {
    title: Messages.orderDeclineConfirmationTitle,
    subtitle: Messages.orderDeclineConfirmationSubtitle,
    successTitle: Messages.orderSummaryOrderDeclined,
    button: Messages.orderDeclineConfirmationBtn,
  },

  FINISHED: {
    title: Messages.orderFinishConfirmationTitle,
    subtitle: Messages.orderFinishConfirmationSubtitle,
    successTitle: Messages.orderSummaryOrderFinished,
    button: Messages.orderFinishConfirmationBtn,
  },
  UNFINISHED: {
    title: Messages.serviceIsNotReceivedHeader,
    subtitle: Messages.serviceIsNotReceivedSubheader,
    successTitle: Messages.serviceIsNotReceivedModalButton,
    button: Messages.serviceIsNotReceivedModalButton,
  },
  PAYMENT_PENDING: {
    title: Messages.orderPaymentPendingConfirmationTitle,
    subtitle: Messages.orderPaymentPendingConfirmationSubtitle,
    successTitle: Messages.orderPaymentPendingConfirmationFinished,
    button: Messages.orderPaymentPendingConfirmationBtn,
  },
} as const satisfies {
  [key: string]: { title: Messages; subtitle: Messages; successTitle: Messages; button: Messages };
};
