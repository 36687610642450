export function setToLocalStorage(name: string, token: any) {
  localStorage.setItem(name, token);
}

export function deleteFromLocalStorage(name: string): void {
  localStorage.removeItem(name);
}

export function getFromLocalStorage(name: string): string | null {
  return localStorage.getItem(name) || null;
}
export function setObjectToLocalStorage(name: string, data: any): void {
  localStorage.setItem(name, JSON.stringify(data));
}
export function getObjectFromLocalStorage<T>(name: string): T | null {
  const data = getFromLocalStorage(name);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }
  return null;
}
