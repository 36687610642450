import classNames from 'classnames';
import * as React from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import Typography, { TextColors } from '@Components/Typography';
import { useTranslations } from '@Hooks/useTranslations';
import { hasFieldError } from '@Utils/form';
import { MessageKeysWithParams } from '@Utils/translation';
import { MapT } from '@Utils/types';

import InputContainer from '../InputContainer';

import styles from './Checkbox.module.scss';

export type CheckboxStyle = 'primary' | 'orange' | 'turquoise' | 'purple';

export interface CheckboxInputProps extends FormFieldRenderProps<any> {
  required?: boolean;
  className?: string;
  disabled?: boolean;
  label?: string;
  labelParams?: MapT<string | number>;
  color?: TextColors;
  style?: CheckboxStyle;
  inline?: boolean;
  bottomSpacing?: boolean;
  column?: boolean;
  checkedDisabled?: boolean;
  errorDisplayClassName?: string;
  onChange?: () => void;
}

const Checkbox: React.FC<CheckboxInputProps> = props => {
  const {
    input,
    label,
    labelParams,
    color = 'gray',
    style = 'primary',
    meta,
    disabled,
    required,
    checkedDisabled,
    inline,
    bottomSpacing,
    column,
    errorDisplayClassName,
    className,
    onChange,
  } = props;

  const hasError = hasFieldError(meta);
  const t = useTranslations();

  return (
    <InputContainer
      meta={meta}
      required={required}
      inline={inline}
      bottomSpacing={bottomSpacing}
      errorDisplayClassName={errorDisplayClassName}
      showErrorIcon
      className={className}
    >
      <label
        className={classNames(styles.checkbox, {
          [styles.disabled]: disabled,
          [styles.column]: column,
          [styles.inline]: inline,
        })}
      >
        {label && (
          <Typography
            className={styles.label}
            html
            msg={t(label as MessageKeysWithParams, labelParams as any)}
            tag="span"
            size="s"
            color={color}
          />
        )}
        <input
          tabIndex={0}
          {...input}
          disabled={disabled}
          type={input.type || 'radio'}
          onChange={value => {
            input.onChange(value);
            if (onChange) {
              onChange();
            }
          }}
        />
        <span
          className={classNames(
            styles.inputBox,
            { [styles.error]: hasError, [styles.checkedDisabled]: checkedDisabled },
            style && styles[style],
          )}
        />
      </label>
    </InputContainer>
  );
};

export default Checkbox;
