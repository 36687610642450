import { FormApi } from 'final-form';
import * as React from 'react';

import Button, { ButtonStyles } from '@Components/Buttons/Button';
import AdFilters, {
  AdFilterFields,
  AdFilterFormSubmitValues,
  OnAdFiltersChange,
} from '@Components/forms/Filter/AdFilters';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import { ModalProviderProps } from '@Components/Modal';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './FiltersModal.module.scss';

export interface FiltersModalData {
  onFiltersChange: OnAdFiltersChange;
  onClose?: () => void;
  filtersInitialValues?: AdFilterFields;
  showDistanceField?: boolean;
  onFiltersClear?: (values: FormApi<AdFilterFormSubmitValues>) => void;
}

export interface FiltersModalProps extends ModalProviderProps<FiltersModalData> {}

const FiltersModal: React.FunctionComponent<FiltersModalProps> = ({
  modalData: { onFiltersClear, onFiltersChange, onClose, filtersInitialValues, showDistanceField },
  closeModal,
}) => {
  const t = useTranslations();

  const onCloseClick = () => {
    onClose && onClose();
    closeModal();
  };

  return (
    <div className={styles.filtersWrapper}>
      <Row constant justifyEnd>
        <Button onClick={onCloseClick} className={styles.closeButton} style={ButtonStyles.withIconWithoutBg}>
          <Icon icon="menu-close-button" size={IconSizes.s} />
          <Typography msg={t(Messages.btnModalClose)} tag="span" />
        </Button>
      </Row>
      <AdFilters
        showDistanceField={showDistanceField}
        onClick={onFiltersClear}
        onChange={onFiltersChange}
        initialValues={filtersInitialValues}
      />
    </div>
  );
};

export default FiltersModal;
