import classNames from 'classnames';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import FieldArrayError from '@Components/forms/FieldArrayError';
import FormField from '@Components/forms/FormField';
import ImageField, { ImageStyle } from '@Components/forms/inputs/ImageInput';
import { MAX_ADVERT_IMAGES } from '@Config/constants';
import { Messages } from '@Config/messages';
import { Scalars } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { validate, validateImgFormat, validateMaxImgSize } from '@Utils/form';

import styles from './ImageUploadField.module.scss';

export interface AddMoreButtonProps {
  onAddMore: () => void;
}

interface ImageUploadField {
  fieldName: string;
  addMoreButton?: React.FunctionComponent<AddMoreButtonProps>;
  className?: string;
  style?: ImageStyle;
  displayAsGrid?: boolean;
  bottomSpacing?: boolean;
  labelOnFile?: Messages;
  description?: Messages;
}

const ImageUploadField: React.FunctionComponent<ImageUploadField> = ({
  fieldName,
  addMoreButton,
  className,
  style,
  displayAsGrid,
  bottomSpacing,
  labelOnFile,
  description,
}) => {
  const t = useTranslations();

  const renderAddMoreButton = (addValues: () => void, fieldsLength: number) => {
    if (addMoreButton && fieldsLength < MAX_ADVERT_IMAGES) {
      return addMoreButton({ onAddMore: addValues });
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <FieldArray name={fieldName}>
        {({ fields, meta }) => {
          const addValues = () => {
            fields.push('');
          };

          return (
            <>
              <FieldArrayError meta={meta} />
              <div className={classNames(styles.wrapper, { [styles.displayAsGrid]: displayAsGrid })}>
                {fields.map(name => (
                  <FormField<Scalars['UploadType']>
                    name={name}
                    key={name}
                    component={ImageField}
                    className={className}
                    validate={validate(validateMaxImgSize(t), validateImgFormat(t))}
                    style={style}
                    bottomSpacing={bottomSpacing}
                    labelOnFile={labelOnFile}
                    description={description}
                  />
                ))}
              </div>
              {addMoreButton && renderAddMoreButton(addValues, fields.value.length)}
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export default ImageUploadField;
