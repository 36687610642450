import classNames from 'classnames';
import * as React from 'react';

import Typography from '@Components/Typography';

import styles from './HorizontalRule.module.scss';

const HorizontalRule: React.FunctionComponent<{
  msg?: string;
  marginTop?: boolean;
  marginBottom?: boolean;
  className?: string;
}> = ({ msg, marginBottom, marginTop, className }) => {
  return msg ? (
    <div
      className={classNames(styles.hrWithCenteredtext, className, {
        [styles.marginBottom]: marginBottom,
        [styles.marginTop]: marginTop,
      })}
    >
      <Typography tag="span" msg={msg} />
    </div>
  ) : (
    <div
      className={classNames(styles.hr, className, {
        [styles.marginBottom]: marginBottom,
        [styles.marginTop]: marginTop,
      })}
    />
  );
};

export default HorizontalRule;
