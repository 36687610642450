import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import config from '@Config/config';
import { ApplicationLanguages } from '@Config/constants';
import { getDefaultLanguage } from '@Config/localization';
import { logError } from '@Utils/logger';

import LtTranslations from './translations/lt';
import PlTranslations from './translations/pl';

const isDefaultLangLT = config.DEFAULT_LANGUAGE === 'lt';

i18next.use(initReactI18next).init({
  returnNull: false,
  resources: isDefaultLangLT
    ? {
        [ApplicationLanguages.LT]: {
          translation: LtTranslations,
        },
      }
    : {
        [ApplicationLanguages.PL]: {
          translation: PlTranslations,
        },
      },
  lng: getDefaultLanguage(),
  saveMissing: true,
  missingKeyHandler: (_lngs, _ns, key) => {
    logError(`Missing translation key: ${key}`);
  },
  interpolation: {
    escapeValue: false,
  },
  missingInterpolationHandler: key => {
    logError(`Missing translation param: ${key}`);
  },
});

const validateTranslationsKeys = (translations: { [key in keyof typeof LtTranslations]: string }) => translations;

async function loadTranslation() {
  if (!isDefaultLangLT) {
    return;
  }

  const EnTranslations = (await import('./translations/en')).default;
  const PlTranslations = (await import('./translations/pl')).default;

  validateTranslationsKeys(EnTranslations);
  validateTranslationsKeys(PlTranslations);

  i18next.addResources(ApplicationLanguages.EN, 'translation', EnTranslations);
  i18next.addResources(ApplicationLanguages.PL, 'translation', PlTranslations);
}

loadTranslation();
