import { FEATURED_FETCH_LIMIT } from '@Config/constants';
import { GetAppConfigDocument, GetAppConfigQuery, GetAppConfigQueryVariables } from '@Graphql/graphqlTypes.generated';
import ApolloClient from '@Utils/graphqlClient';

export const getAppConfig = async (lang: string) => {
  const response = await ApolloClient.query<GetAppConfigQuery, GetAppConfigQueryVariables>({
    query: GetAppConfigDocument,
    fetchPolicy: 'network-only',
    variables: { lang, adAmount: FEATURED_FETCH_LIMIT, auctionAmount: FEATURED_FETCH_LIMIT },
  });

  return response.data.appConfig;
};
