import classNames from 'classnames';
import * as React from 'react';

import AdvertCardAddressRow from '@Components/Advert/AdvertCardAddressRow';
import AdvertCardPriceRow from '@Components/Advert/AdvertCardPriceRow';
import AdvertCardTitle from '@Components/Advert/AdvertCardTitle';
import AdvertPicture from '@Components/Advert/AdvertPicture';
import { AdvertFragment, AdvertStatus } from '@Graphql/graphqlTypes.generated';

import styles from './AdvertMapCard.module.scss';

export interface AdvertCardProps {
  advert: AdvertFragment;
}

const AdvertMapCard: React.FunctionComponent<AdvertCardProps> = ({ advert }) => {
  const { title, advertType, serviceType, status, mainImageThumbnail, validUntil } = advert;

  const isAdExpired = validUntil && new Date(validUntil) < new Date();

  if (isAdExpired) {
    return null;
  }

  return (
    <div
      className={classNames({
        [styles.privateStatus]: status === AdvertStatus.Private,
      })}
    >
      <AdvertPicture url={mainImageThumbnail} advertType={advertType} serviceType={serviceType} alt={title} />
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <AdvertCardTitle advert={advert} />
          <AdvertCardAddressRow advert={advert} />
          <AdvertCardPriceRow advert={advert} />
        </div>
      </div>
    </div>
  );
};

export default AdvertMapCard;
