import classNames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { HeaderLink } from '@Components/Header/Header';
import Icon, { IconSizes } from '@Components/Icon';
import UserMenu from '@Components/UserMenu/UserMenu';
import { MediaQueries } from '@Config/constants';
import { getUserData } from '@Store/auth/auth.selector';
import { UserData } from '@Store/auth/auth.types';

import styles from './UserHeaderDropdown.module.scss';

export interface UserHeaderDropdownProps {
  position: 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'left';
  links: HeaderLink[];
  white?: boolean;
  onClick?: () => void;
  displayAsDropDown?: boolean;
}

const UserHeaderDropdown: React.FunctionComponent<UserHeaderDropdownProps> = ({
  onClick,
  position,
  white,
  links,
  displayAsDropDown,
}) => {
  const isDesktop = useMediaQuery({ minWidth: MediaQueries.desktopS });

  const [open, setOpen] = useState(false);
  const [refEl, setRefEl] = useState<HTMLElement | null>(null);
  const user: UserData = useSelector(getUserData);

  return (
    <div
      className={styles.userDropdown}
      ref={setRefEl}
      onMouseEnter={() => isDesktop && setOpen(true)}
      onMouseLeave={() => isDesktop && setOpen(false)}
      onClick={onClick}
    >
      <div className={classNames(styles.icon, { [styles.white]: white })}>
        <Icon icon="user" size={IconSizes.s} />
        <Icon icon="chevron-down" size={IconSizes.xs} />
      </div>
      {open && (
        <UserMenu displayAsDropDown={displayAsDropDown} links={links} user={user} position={position} refEl={refEl} />
      )}
    </div>
  );
};

export default UserHeaderDropdown;
