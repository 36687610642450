import { ScheduleFields } from '@Components/../routes/ads/shared/AdForm/AdForm';
import { isBefore, startOfDay, isAfter, isSameMinute, isSameDay, startOfHour, parse, isValid } from 'date-fns';
import { ARRAY_ERROR } from 'final-form';

import { OldOrderFormFields } from '@Components/Order/OldOrderFormContainer/OldOrderForm';
import { ApplicationLanguages, ORDER_DATE_DIFF, TimeFormat } from '@Config/constants';
import { Messages } from '@Config/messages';
import { buildDateAndTime, getDateDiffInDays, getDateDiffInHours, getDateFormat } from '@Utils/dates';
import { Translator } from '@Utils/translation';
import { AdFormValues, CalendarFilterFields, DateRange, FormFieldErrors } from '@Utils/types';

export const validateDate =
  (t: Translator, currentLanguage?: ApplicationLanguages) => (date: string | Date | undefined) => {
    if (date === '') {
      return t(Messages.errorFieldRequired);
    }

    if (!date) {
      return undefined;
    }

    const dateFormat = currentLanguage ? getDateFormat(currentLanguage) : TimeFormat.date;
    const parsedDate = typeof date === 'string' ? parse(date, dateFormat, new Date()) : date;

    if (!isValid(parsedDate)) {
      return t(Messages.errorInvalidDate);
    }

    return undefined;
  };

export const validateStartDateTimeIsBeforeEndDateTime = (
  dateTimeFrom: Date,
  dateTimeTo: Date,
  errorOnField: string,
  t: Translator,
): FormFieldErrors | undefined => {
  if (isBefore(dateTimeTo, dateTimeFrom)) {
    return {
      [errorOnField]: t(Messages.errorEndTimeIsBeforeStartTime),
    };
  }
  return undefined;
};

export const validateStartIsBeforeEndValidAmount = (
  dateTimeFrom: Date,
  dateTimeTo: Date,
  errorOnField: string,
  t: Translator,
  shouldValidateDiff?: boolean,
): FormFieldErrors | undefined => {
  if (isBefore(startOfDay(dateTimeTo), startOfDay(dateTimeFrom))) {
    return {
      [errorOnField]: t(Messages.errorEndTimeIsBeforeStartTime),
    };
  }
  if (isBefore(startOfHour(dateTimeTo), startOfHour(dateTimeFrom))) {
    return {
      [errorOnField]: t(Messages.errorTimeIsBeforeStartTime),
    };
  }
  if (shouldValidateDiff) {
    return {
      ...validateDateDiffCorrectInHours(dateTimeFrom, dateTimeTo, ORDER_DATE_DIFF, OldOrderFormFields.timeTo, t),
    };
  }
  return undefined;
};

export const validateIfDateAndTimeIsEqual = (
  dateTimeFrom: Date,
  dateTimeTo: Date,
  errorOnField: string,
  t: Translator,
): FormFieldErrors | undefined => {
  if (isSameMinute(dateTimeTo, dateTimeFrom)) {
    return {
      [errorOnField]: t(Messages.errorEndTimeIsEqualToStartTime),
    };
  }
  return undefined;
};

export const validateDateRange = (
  dateTimeFrom: Date,
  dateTimeTo: Date,
  dateToName: string,
  timeToName: string,
  t: Translator,
): FormFieldErrors => {
  const endDateIsBeforeStartDateErrors = validateStartDateTimeIsBeforeEndDateTime(
    dateTimeFrom,
    dateTimeTo,
    dateToName,
    t,
  );

  const endTimeIsBeforeStartTimeErrors = validateStartDateTimeIsBeforeEndDateTime(
    dateTimeFrom,
    dateTimeTo,
    timeToName,
    t,
  );

  const endTimeOrEndDateFieldErrors = isSameDay(dateTimeFrom, dateTimeTo)
    ? endTimeIsBeforeStartTimeErrors
    : endDateIsBeforeStartDateErrors;

  return {
    ...endTimeOrEndDateFieldErrors,
    ...validateIfDateAndTimeIsEqual(dateTimeFrom, dateTimeTo, timeToName, t),
  };
};

const sortDateRanges = (a: DateRange, b: DateRange) => a.startDate.getTime() - b.startDate.getTime();

export const isThereOverlappingDates = (values: DateRange[]) => {
  let overlaps = false;
  const sortedDates = values.sort(sortDateRanges);
  sortedDates.forEach((date: DateRange, index: number) => {
    if (index === sortedDates.length - 1) return;
    if (
      isBefore(date.startDate, sortedDates[index + 1].endDate) &&
      isBefore(sortedDates[index + 1].startDate, date.endDate)
    ) {
      overlaps = true;
    }
    if (isBefore(sortedDates[index + 1].startDate, date.endDate)) {
      overlaps = true;
    }
  });

  return overlaps;
};

export const validateAdForm = (t: Translator) => (values: AdFormValues) => {
  const errors = {
    schedules: [],
  } as any;

  if (values.isScheduled) {
    if (!values.schedules || !values.schedules.length) {
      errors.schedules[ARRAY_ERROR] = t(Messages.errorFieldRequired);
    } else {
      const scheduleDates: DateRange[] = [];
      values.schedules.forEach((scheduleItem, index) => {
        if (!scheduleItem) {
          return;
        }
        const dateTimeFrom = buildDateAndTime(scheduleItem.startDate, scheduleItem.startTime);
        const dateTimeTo = buildDateAndTime(scheduleItem.endDate, scheduleItem.endTime);
        errors.schedules[index] =
          validateDateDiffCorrectInHours(dateTimeFrom, dateTimeTo, ORDER_DATE_DIFF, ScheduleFields.endTime, t) ||
          validateDateRange(dateTimeFrom, dateTimeTo, ScheduleFields.startDate, ScheduleFields.startTime, t);
        scheduleDates.push({
          startDate: dateTimeFrom,
          endDate: dateTimeTo,
        });
      });
      if (isThereOverlappingDates(scheduleDates)) {
        errors.schedules[ARRAY_ERROR] = t(Messages.errorDatesIsOverlapping);
      }
    }
  }
  return errors;
};

export const validateDateDiffCorrectInHours = (
  startDate: Date,
  endDate: Date,
  hoursDiff: number,
  fieldName: string,
  t: Translator,
  errorMessage?: string,
) => {
  const diff = getDateDiffInHours(startDate, endDate);
  if (diff < 0 || diff < hoursDiff) {
    return {
      [fieldName]: errorMessage || t(Messages.errorInvalidDateDiff),
    };
  }
  return undefined;
};

export const validateDateDiffCorrectInNights = (startDate: Date, endDate: Date, t: Translator) => {
  const diff = getDateDiffInDays(startDate, endDate);
  if (diff === 0) {
    return {
      [OldOrderFormFields.dateTo]: t(Messages.errorOrderDateDiffTooShort),
    };
  }
  return undefined;
};

export const validateEndDatePosition =
  (startDateParam: Date | string, t: Translator, couldBeTheSameDate = true) =>
  (endDateParam: string | Date): string | undefined => {
    if (!endDateParam || !startDateParam) {
      return undefined;
    }
    const startDate = new Date(startDateParam);
    const endDate = new Date(endDateParam);

    const isValid = couldBeTheSameDate
      ? !isBefore(startOfDay(endDate), startOfDay(startDate))
      : isAfter(startOfDay(endDate), startOfDay(startDate));

    return isValid ? undefined : t(Messages.errorEndTimeIsBeforeStartTime);
  };

export const validateCalendarFilterFields = (values: CalendarFilterFields, t: Translator) => {
  return {
    endDate: validateEndDatePosition(values.startDate, t)(values.endDate),
  };
};

// TODO refactor this function, It just need to compare dates, try to select
// dates from some uknown object
export const validateDateAndTimeFieldsValues = (
  values: any,
  fields: { dateFrom: string; dateTo: string; timeFrom: string; timeTo: string },
  t: Translator,
  shouldValidateDiff = true,
): FormFieldErrors => {
  const { dateFrom, dateTo, timeFrom, timeTo } = fields;

  const dateTimeFrom = buildDateAndTime(values[dateFrom], values[timeFrom]);
  const dateTimeTo = buildDateAndTime(values[dateTo], values[timeTo]);

  const endDateIsBeforeStartDateErrors = validateStartIsBeforeEndValidAmount(
    dateTimeFrom,
    dateTimeTo,
    dateTo,
    t,
    shouldValidateDiff,
  );

  const endTimeIsBeforeStartTimeErrors = validateStartIsBeforeEndValidAmount(
    dateTimeFrom,
    dateTimeTo,
    timeTo,
    t,
    shouldValidateDiff,
  );

  const endTimeOrEndDateFieldErrors = isSameDay(dateTimeFrom, dateTimeTo)
    ? endTimeIsBeforeStartTimeErrors
    : endDateIsBeforeStartDateErrors;

  return {
    ...endTimeOrEndDateFieldErrors,
    ...validateIfDateAndTimeIsEqual(dateTimeFrom, dateTimeTo, timeTo, t),
  };
};
