import classNames from 'classnames';
import * as React from 'react';

import Button, { ButtonSizes, ButtonStyles } from '@Components/Buttons/Button';
import Row from '@Components/layout/Row';
import NavigationButton from '@Components/Slider/NavigationButton';
import { SlideItem } from '@Components/Slider/Slide';

import styles from './Navigation.module.scss';

export interface NavigationProps {
  className?: string;
  navigationItemClick: (index: number) => void;
  slides: SlideItem[];
  active: number;
}

const Navigation: React.FunctionComponent<NavigationProps> = ({ className, navigationItemClick, slides, active }) => {
  const textOnLeft = active !== 0 ? slides[active - 1]?.navigationTitle : slides[slides.length - 1]?.navigationTitle;
  const textOnRight = active !== slides.length - 1 ? slides[active + 1]?.navigationTitle : slides[0]?.navigationTitle;

  return (
    <Row constant justifyBetween alignCenter className={classNames(styles.navigationWrapper, className)}>
      <NavigationButton
        icon="arrow-back"
        text={textOnLeft}
        iconOnLeft
        onClick={() => navigationItemClick(active === 0 ? slides.length - 1 : active - 1)}
      />
      <div className={styles.navigationDots}>
        {slides.map((_, index: number) => {
          return (
            <Button
              key={index}
              size={ButtonSizes.unset}
              style={ButtonStyles.withoutStyling}
              className={classNames(styles.navigationDot, { [styles.active]: index === active })}
              onClick={() => navigationItemClick(index)}
            />
          );
        })}
      </div>
      <NavigationButton
        icon="arrow-forward"
        text={textOnRight}
        iconOnLeft={false}
        onClick={() => navigationItemClick(active === slides.length - 1 ? 0 : active + 1)}
      />
    </Row>
  );
};

export default Navigation;
