/* tslint:disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONString: any;
  GenericScalar: any;
  Decimal: any;
  Date: any;
  UUID: any;
  Coordinate: any;
  UploadType: any;
  Upload: any;
};

export type AcceptCompanyInvitationInput = {
  invitationKey: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AcceptCompanyInvitationPayload = {
   __typename?: 'AcceptCompanyInvitationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  myCompany: Maybe<MyCompanyNode>;
  user: Maybe<UserNode>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type ActivityNode = Node & {
   __typename?: 'ActivityNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  sizeMeasureType: ActivitySizeMeasureType;
  orderNum: Scalars['Int'];
};

export type ActivityNodeConnection = {
   __typename?: 'ActivityNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ActivityNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type ActivityNodeEdge = {
   __typename?: 'ActivityNodeEdge';
  node: Maybe<ActivityNode>;
  cursor: Scalars['String'];
};

export enum ActivitySizeMeasureType {
  Hectare = 'HECTARE',
  Cattle = 'CATTLE'
}

export type AddCardInput = {
  paymentMethodId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddCardPayload = {
   __typename?: 'AddCardPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  stripeCard: Maybe<StripeCardNode>;
};

export type AdvertCalendarItemNode = Node & {
   __typename?: 'AdvertCalendarItemNode';
  id: Scalars['ID'];
  advert: AdvertNode;
  events: Maybe<Array<Maybe<AdvertEventNode>>>;
  orders: Maybe<Array<Maybe<OrderNode>>>;
};

export type AdvertCalendarItemNodeConnection = {
   __typename?: 'AdvertCalendarItemNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<AdvertCalendarItemNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type AdvertCalendarItemNodeEdge = {
   __typename?: 'AdvertCalendarItemNodeEdge';
  node: Maybe<AdvertCalendarItemNode>;
  cursor: Scalars['String'];
};

export type AdvertCalendarItemTypeFilter = {
  customEvents?: Maybe<Scalars['Boolean']>;
  orderStates?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AdvertEventMutationInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  advert: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AdvertEventMutationPayload = {
   __typename?: 'AdvertEventMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  advertEvent: Maybe<AdvertEventNode>;
};

export type AdvertEventNode = Node & {
   __typename?: 'AdvertEventNode';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  advert: AdvertNode;
};

export type AdvertImageNode = Node & {
   __typename?: 'AdvertImageNode';
  id: Scalars['ID'];
  thumbnail: Scalars['String'];
  image: Scalars['String'];
};

export type AdvertLoanMutationInput = {
  id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  advert: Scalars['String'];
  amount: Scalars['Int'];
  cost: Scalars['Int'];
  monthlyPayment: Scalars['Int'];
  duration?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AdvertLoanMutationPayload = {
   __typename?: 'AdvertLoanMutationPayload';
  id: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  advert: Maybe<Scalars['String']>;
  amount: Maybe<Scalars['Int']>;
  cost: Maybe<Scalars['Int']>;
  monthlyPayment: Maybe<Scalars['Int']>;
  duration: Maybe<Scalars['Int']>;
  errors: Maybe<Array<Maybe<ErrorType>>>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type AdvertMutationInput = {
  schedules?: Maybe<Array<Maybe<DateTimeRange>>>;
  coordinates?: Maybe<Scalars['Coordinate']>;
  advertType: AdvertType;
  pricingType: AdvertPricingType;
  serviceType: AdvertServiceType;
  deliveryType: DeliveryType;
  status?: Maybe<AdvertStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  price: Scalars['Int'];
  deliveryPrice: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  customFieldsData?: Maybe<Scalars['JSONString']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  category: Scalars['ID'];
  isScheduled?: Maybe<Scalars['Boolean']>;
  mainImage?: Maybe<Scalars['UploadType']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AdvertMutationPayload = {
   __typename?: 'AdvertMutationPayload';
  errors: Maybe<Array<Maybe<ErrorTypeWithCustomField>>>;
  clientMutationId: Maybe<Scalars['String']>;
  advert: Maybe<AdvertNode>;
};

export type AdvertNode = Node & {
   __typename?: 'AdvertNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  category: CategoryNode;
  title: Scalars['String'];
  description: Scalars['String'];
  company: CompanyNode;
  slug: Scalars['String'];
  price: Scalars['Int'];
  deliveryPrice: Scalars['Int'];
  isScheduled: Scalars['Boolean'];
  advertId: Scalars['String'];
  address: Scalars['String'];
  coordinates: Maybe<CoordinateType>;
  viewCount: Scalars['Int'];
  customFieldsData: Maybe<Scalars['JSONString']>;
  validUntil: Maybe<Scalars['DateTime']>;
  status: AdvertStatus;
  advertType: AdvertType;
  pricingType: AdvertPricingType;
  serviceType: AdvertServiceType;
  deliveryType: DeliveryType;
  schedules: Maybe<Array<Maybe<DateTimeRangeNode>>>;
  isFavorited: Scalars['Boolean'];
  mainImage: Maybe<Scalars['String']>;
  mainImageThumbnail: Maybe<Scalars['String']>;
  images: Maybe<Array<Maybe<AdvertImageNode>>>;
  phone: Maybe<Scalars['String']>;
};

export type AdvertNodeConnection = {
   __typename?: 'AdvertNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<AdvertNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type AdvertNodeEdge = {
   __typename?: 'AdvertNodeEdge';
  node: Maybe<AdvertNode>;
  cursor: Scalars['String'];
};

export enum AdvertPricingType {
  EurPerHour = 'EUR_PER_HOUR',
  EurPerItem = 'EUR_PER_ITEM',
  EurPerHectare = 'EUR_PER_HECTARE',
  EurPerKilogram = 'EUR_PER_KILOGRAM',
  EurPerMeter = 'EUR_PER_METER',
  EurPerLiter = 'EUR_PER_LITER',
  EurPerTon = 'EUR_PER_TON',
  EurPerNight = 'EUR_PER_NIGHT',
  NegotiablePrice = 'NEGOTIABLE_PRICE',
  FreeItem = 'FREE_ITEM'
}

export enum AdvertServiceType {
  Service = 'SERVICE',
  Good = 'GOOD'
}

export enum AdvertStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Private = 'PRIVATE'
}

export enum AdvertType {
  Sale = 'SALE',
  Purchase = 'PURCHASE'
}

export type AppConfigViewNode = {
   __typename?: 'AppConfigViewNode';
  version: Maybe<Scalars['String']>;
  translations: Maybe<Scalars['GenericScalar']>;
  facebookAppId: Maybe<Scalars['String']>;
  googleClientId: Maybe<Scalars['String']>;
  appleClientId: Maybe<Scalars['String']>;
  appleRedirectUri: Scalars['String'];
  enabledLanguages: Maybe<Scalars['GenericScalar']>;
  defaultLanguage: Maybe<Scalars['GenericScalar']>;
  contact: Maybe<Scalars['GenericScalar']>;
  aboutUs: Maybe<Scalars['GenericScalar']>;
  rentPage: Maybe<Scalars['GenericScalar']>;
  terms: Maybe<Scalars['GenericScalar']>;
  privacy: Maybe<Scalars['GenericScalar']>;
  googleTagManagerId: Maybe<Scalars['String']>;
  isAuctionEnabled: Maybe<Scalars['Boolean']>;
  isForumEnabled: Maybe<Scalars['Boolean']>;
  isPaymentEnabled: Maybe<Scalars['Boolean']>;
  isOldOrderFormEnabled: Maybe<Scalars['Boolean']>;
  predefinedSearch: Maybe<Array<Maybe<PredefinedSearchNode>>>;
  landingPageImages: Maybe<Array<Maybe<LandingPageImageNode>>>;
  landingPageBanners: Maybe<Array<Maybe<LandingPageBannerNode>>>;
  stripePublishableKey: Maybe<Scalars['String']>;
  mainLogo: Maybe<Scalars['String']>;
  advertLoanInterestRate: Maybe<Scalars['Decimal']>;
  maxAdvertLoanAmount: Maybe<Scalars['Int']>;
  maxAdvertLoanDuration: Maybe<Scalars['Int']>;
  parentCategories: Maybe<Array<CategoryNode>>;
  generalOrderCategories: Maybe<Array<CategoryNode>>;
  contactPhone: Maybe<Scalars['String']>;
  terrainTypes: Maybe<Array<TerrainTypeNode>>;
};


export type AppConfigViewNodeParentCategoriesArgs = {
  slug: Maybe<Scalars['String']>;
  ids: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type AppConfigViewNodeGeneralOrderCategoriesArgs = {
  slug: Maybe<Scalars['String']>;
  ids: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type AppleLoginInput = {
  name?: Maybe<AppleNameNode>;
  email?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  loginEnv: AppleLoginType;
  addNaturalCompany: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AppleLoginPayload = {
   __typename?: 'AppleLoginPayload';
  errors: Maybe<Array<MutationErrorType>>;
  token: Maybe<Scalars['String']>;
  clientMutationId: Maybe<Scalars['String']>;
};

export enum AppleLoginType {
  Web = 'WEB',
  App = 'APP'
}

export type AppleNameNode = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export enum AuctionAuctionType {
  Sale = 'SALE',
  Purchase = 'PURCHASE'
}

export type AuctionImageNode = Node & {
   __typename?: 'AuctionImageNode';
  id: Scalars['ID'];
  thumbnail: Scalars['String'];
  image: Scalars['String'];
};

export type AuctionNode = Node & {
   __typename?: 'AuctionNode';
  id: Scalars['ID'];
  auctionType: AuctionAuctionType;
  category: CategoryNode;
  status: AuctionStatus;
  company: CompanyNode;
  title: Scalars['String'];
  description: Scalars['String'];
  address: Scalars['String'];
  coordinates: CoordinateType;
  initialPrice: Scalars['Int'];
  duration: Scalars['Int'];
  expirationDate: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  auctionId: Scalars['String'];
  customFieldsData: Maybe<Scalars['JSONString']>;
  mainImage: Maybe<Scalars['String']>;
  mainImageThumbnail: Maybe<Scalars['String']>;
  images: Maybe<Array<Maybe<AuctionImageNode>>>;
  totalBidCount: Scalars['Int'];
  lastBids: Maybe<Array<Maybe<BidNode>>>;
};

export type AuctionNodeConnection = {
   __typename?: 'AuctionNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<AuctionNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type AuctionNodeEdge = {
   __typename?: 'AuctionNodeEdge';
  node: Maybe<AuctionNode>;
  cursor: Scalars['String'];
};

export enum AuctionStatus {
  Created = 'CREATED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Ended = 'ENDED',
  Expired = 'EXPIRED'
}

export type BankAccountMutationInput = {
  iban: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type BankAccountMutationPayload = {
   __typename?: 'BankAccountMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  bankAccount: Maybe<BankAccountNode>;
};

export type BankAccountNode = Node & {
   __typename?: 'BankAccountNode';
  id: Scalars['ID'];
  iban: Scalars['String'];
  companyName: Scalars['String'];
  bankName: Scalars['String'];
};

export type BidNode = Node & {
   __typename?: 'BidNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  auction: AuctionNode;
  amount: Scalars['Int'];
  company: Scalars['String'];
};

export enum CategoryKind {
  Agro = 'AGRO',
  Constructions = 'CONSTRUCTIONS'
}

export type CategoryNode = Node & {
   __typename?: 'CategoryNode';
  id: Scalars['ID'];
  parent: Maybe<CategoryNode>;
  orderNum: Scalars['Int'];
  serviceType: CategoryServiceType;
  kind: CategoryKind;
  customFieldsSchema: Scalars['JSONString'];
  slug: Scalars['String'];
  icon: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  advertCount: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['GenericScalar']>;
  marketPrices: Maybe<MarketPriceQueries>;
  loanCalculatorEnabled: Maybe<Scalars['Boolean']>;
};

export type CategoryNodeConnection = {
   __typename?: 'CategoryNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CategoryNodeEdge>>;
};

export type CategoryNodeEdge = {
   __typename?: 'CategoryNodeEdge';
  node: Maybe<CategoryNode>;
  cursor: Scalars['String'];
};

export enum CategoryServiceType {
  Service = 'SERVICE',
  Good = 'GOOD',
  Both = 'BOTH'
}

export type ChangeActiveCompanyInput = {
  company: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangeActiveCompanyPayload = {
   __typename?: 'ChangeActiveCompanyPayload';
  errors: Maybe<Array<MutationErrorType>>;
  user: Maybe<UserNode>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type ChangeLanguageInput = {
  languageCode: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangeLanguagePayload = {
   __typename?: 'ChangeLanguagePayload';
  errors: Maybe<Array<MutationErrorType>>;
  user: Scalars['ID'];
  language: LanguageNode;
  clientMutationId: Maybe<Scalars['String']>;
};

export type ChangePasswordInput = {
  oldPassword: Scalars['String'];
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangePasswordPayload = {
   __typename?: 'ChangePasswordPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type ChatMessageFileUploadMutationInput = {
  attachedFileOriginalName: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['ID']>;
  attachedFile: Scalars['UploadType'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChatMessageFileUploadMutationPayload = {
   __typename?: 'ChatMessageFileUploadMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  chatMessage: Maybe<ChatMessageNode>;
};

export type ChatMessageMutationInput = {
  message: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChatMessageMutationPayload = {
   __typename?: 'ChatMessageMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  chatMessage: Maybe<ChatMessageNode>;
};

export type ChatMessageNode = Node & {
   __typename?: 'ChatMessageNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  order: OrderNode;
  senderCompany: CompanyNode;
  message: Scalars['String'];
  attachedFileOriginalName: Scalars['String'];
  attachedFile: Maybe<Scalars['String']>;
};

export type ChatMessageSubscriptionInput = {
  userToken: Scalars['String'];
  orderId: Scalars['ID'];
};

export enum CompanyCompanyType {
  Natural = 'NATURAL',
  Legal = 'LEGAL',
  Farm = 'FARM'
}

export type CompanyMutationInput = {
  name: Scalars['String'];
  companyType?: Maybe<CompanyCompanyType>;
  websiteUrl?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  phoneIsPublic?: Maybe<Scalars['Boolean']>;
  activity?: Maybe<Scalars['ID']>;
  stripeId?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  privateGroups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyMutationPayload = {
   __typename?: 'CompanyMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  company: Maybe<CompanyNode>;
};

export type CompanyNode = Node & {
   __typename?: 'CompanyNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  companyType: CompanyCompanyType;
  websiteUrl: Scalars['String'];
  size: Maybe<Scalars['Float']>;
  activity: Maybe<ActivityNode>;
  slug: Scalars['String'];
  overallRating: Scalars['Float'];
  phone: Maybe<Scalars['String']>;
  availablePaymentTypes: Array<Maybe<PaymentType>>;
  invoicingInfo: Maybe<InvoicingInfoNode>;
  bankAccount: Maybe<BankAccountNode>;
  logo: Maybe<Scalars['String']>;
  logoThumbnail: Maybe<Scalars['String']>;
};

export type CompanyNodeConnection = {
   __typename?: 'CompanyNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<CompanyNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type CompanyNodeEdge = {
   __typename?: 'CompanyNodeEdge';
  node: Maybe<CompanyNode>;
  cursor: Scalars['String'];
};


export type CoordinateType = {
   __typename?: 'CoordinateType';
  longitude: Maybe<Scalars['Float']>;
  latitude: Maybe<Scalars['Float']>;
};

export type CreateAuctionMutationInput = {
  coordinates: Scalars['Coordinate'];
  mainImage?: Maybe<Scalars['UploadType']>;
  auctionType: AuctionAuctionType;
  title: Scalars['String'];
  description: Scalars['String'];
  address: Scalars['String'];
  initialPrice: Scalars['Int'];
  duration: Scalars['Int'];
  customFieldsData?: Maybe<Scalars['JSONString']>;
  category: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAuctionMutationPayload = {
   __typename?: 'CreateAuctionMutationPayload';
  errors: Maybe<Array<Maybe<ErrorTypeWithCustomField>>>;
  clientMutationId: Maybe<Scalars['String']>;
  auction: Maybe<AuctionNode>;
};

export type CreateBidMutationInput = {
  amount: Scalars['Int'];
  auction: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBidMutationPayload = {
   __typename?: 'CreateBidMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  bid: Maybe<BidNode>;
};

export type CreateCompanyMutationInput = {
  name: Scalars['String'];
  companyType?: Maybe<CompanyCompanyType>;
  websiteUrl?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  phoneIsPublic?: Maybe<Scalars['Boolean']>;
  activity?: Maybe<Scalars['ID']>;
  availablePaymentTypes?: Maybe<Array<Scalars['String']>>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['UploadType']>;
  privateGroups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCompanyMutationPayload = {
   __typename?: 'CreateCompanyMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  company: Maybe<CompanyNode>;
};

export type CreateContractorMutationInput = {
  coordinates: Scalars['Coordinate'];
  categories: Array<Maybe<Scalars['ID']>>;
  categoriesDetails: Array<Maybe<GeneralContractorCategoriesDetailsInput>>;
  name: Scalars['String'];
  surname: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  workingArea: Scalars['Int'];
  additionalInfo?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateContractorMutationPayload = {
   __typename?: 'CreateContractorMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  generalOrderContractor: Maybe<GeneralOrderContractorType>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type CreateGeneralOfferMutationInput = {
  generalOrder: Scalars['ID'];
  email: Scalars['String'];
  phone: Scalars['String'];
  additionalInfo?: Maybe<Scalars['String']>;
  totalPrice: Scalars['Int'];
  paymentCondition: GeneralOfferPaymentCondition;
  priceDecision?: Maybe<GeneralOfferPriceDecision>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateGeneralOfferMutationPayload = {
   __typename?: 'CreateGeneralOfferMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  generalOffer: Maybe<GeneralOfferType>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type CreateGeneralOrderMutationInput = {
  amountType: GeneralOrderAmountType;
  paymentCondition: GeneralOrderPaymentCondition;
  email: Scalars['String'];
  coordinates: Scalars['Coordinate'];
  phone: Scalars['String'];
  amountParts?: Maybe<Array<Scalars['Decimal']>>;
  amount: Scalars['Decimal'];
  totalPrice: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  surname: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  category: Scalars['ID'];
  terrainType?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateGeneralOrderMutationPayload = {
   __typename?: 'CreateGeneralOrderMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  category: Maybe<CategoryNode>;
  terrainType: Maybe<TerrainTypeNode>;
  coordinates: Maybe<CoordinateType>;
  clientMutationId: Maybe<Scalars['String']>;
  generalOrder: Maybe<GeneralOrderType>;
};

export type CreateOrderMutationInput = {
  paymentType: PaymentType;
  includeDelivery: Scalars['Boolean'];
  advert: Scalars['ID'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOrderMutationPayload = {
   __typename?: 'CreateOrderMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  order: Maybe<OrderNode>;
};

export type CreatePreliminaryOrderMutationInput = {
  startDate: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  advert?: Maybe<Scalars['ID']>;
  contactViaPlatform?: Maybe<Scalars['Boolean']>;
  contactViaPhone?: Maybe<Scalars['Boolean']>;
  contactViaEmail?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePreliminaryOrderMutationPayload = {
   __typename?: 'CreatePreliminaryOrderMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  preliminaryOrder: Maybe<PreliminaryOrderNode>;
};

export type CreateStripePaymentInput = {
  paymentMethodId: Scalars['String'];
  saveCard: Scalars['Boolean'];
  order: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStripePaymentPayload = {
   __typename?: 'CreateStripePaymentPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientSecret: Maybe<Scalars['String']>;
  clientMutationId: Maybe<Scalars['String']>;
  stripePayment: Maybe<StripePaymentNode>;
};



export type DateTimeRange = {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type DateTimeRangeNode = {
   __typename?: 'DateTimeRangeNode';
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type DeleteAdvertEventInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAdvertEventPayload = {
   __typename?: 'DeleteAdvertEventPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  advertEvent: Maybe<AdvertEventNode>;
};

export type DeleteAdvertImageInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAdvertImagePayload = {
   __typename?: 'DeleteAdvertImagePayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  advertImage: Maybe<AdvertImageNode>;
};

export type DeleteAuctionImageInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAuctionImagePayload = {
   __typename?: 'DeleteAuctionImagePayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  auctionImage: Maybe<AuctionImageNode>;
};

export type DeleteAuctionMutationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAuctionMutationPayload = {
   __typename?: 'DeleteAuctionMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  auction: Maybe<AuctionNode>;
};

export type DeleteUserInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteUserPayload = {
   __typename?: 'DeleteUserPayload';
  errors: Maybe<Array<MutationErrorType>>;
  success: Maybe<Scalars['Boolean']>;
  clientMutationId: Maybe<Scalars['String']>;
};

export enum DeliveryType {
  Required = 'REQUIRED',
  Optional = 'OPTIONAL',
  NoDelivery = 'NO_DELIVERY'
}

export enum Errors {
  WrongImageFormat = 'wrong_image_format',
  ImageSizeTooLarge = 'image_size_too_large',
  TooManyImages = 'too_many_images',
  WrongCoordinatesFormat = 'wrong_coordinates_format',
  WrongCompaniesDetailsFormat = 'wrong_companies_details_format',
  WrongDistance = 'wrong_distance',
  WrongAddressSize = 'wrong_address_size',
  InvalidAdvertPrice = 'invalid_advert_price',
  InvalidAdvertPricingType = 'invalid_advert_pricing_type',
  InvalidAdvertCategory = 'invalid_advert_category',
  SizeMustBeWholeNumber = 'size_must_be_whole_number',
  CompanyActivityRequired = 'company_activity_required',
  WrongCompanyInput = 'wrong_company_input',
  CannotSendCompanyCodeForNaturalPerson = 'cannot_send_company_code_for_natural_person',
  OverlappingDates = 'overlapping_dates',
  DatesDifferenceTooSmall = 'dates_difference_too_small',
  StartDateMustBeBeforeEndDate = 'start_date_must_be_before_end_date',
  InvalidSchedules = 'invalid_schedules',
  EndDateTooBig = 'end_date_too_big',
  CannotHaveSchedulesForGoods = 'cannot_have_schedules_for_goods',
  CannotSetDeliveryPriceOnNoDelivery = 'cannot_set_delivery_price_on_no_delivery',
  EndDateCannotBeEarlierStartDate = 'end_date_cannot_be_earlier_start_date',
  InvalidCategory = 'invalid_category',
  InvalidGeneralOrder = 'invalid_general_order',
  InvalidTerrainType = 'invalid_terrain_type',
  TooBigAmountValue = 'too_big_amount_value',
  InvalidAmountValue = 'invalid_amount_value',
  InvalidTotalPriceValue = 'invalid_total_price_value',
  TotalPriceMarketMismatch = 'total_price_market_mismatch',
  InvalidStatus = 'invalid_status',
  OrderInformationMismatch = 'order_information_mismatch',
  ChatFileExtensionNotAllowed = 'chat_file_extension_not_allowed',
  ChatFileSizeTooLarge = 'chat_file_size_too_large',
  NoActiveCompany = 'no_active_company',
  InvalidGlobalId = 'invalid_global_id',
  AdvertIdsMessageInvalidIdSpecifiedCode = 'advert_ids_message_invalid_id_specified_code',
  AuctionsAreDisabled = 'auctions_are_disabled',
  PaymentFeatureIsDisabled = 'payment_feature_is_disabled',
  OrderFormIsDisabled = 'order_form_is_disabled',
  InvalidOrderDates = 'invalid_order_dates',
  CannotPlaceOrder = 'cannot_place_order',
  InvalidPaymentType = 'invalid_payment_type',
  OrderDatesOutOfSchedule = 'order_dates_out_of_schedule',
  InvalidScheduleDates = 'invalid_schedule_dates',
  AdvertDoesNotHaveDelivery = 'advert_does_not_have_delivery',
  AdvertRequiresDeliveryToBeIncluded = 'advert_requires_delivery_to_be_included',
  OrderRatingWithThisOrderAndReportedCompanyAlreadyExists = 'order_rating_with_this_order_and_reported_company_already_exists',
  CannotCalculateTotalPrice = 'cannot_calculate_total_price',
  InvalidSlug = 'invalid_slug',
  PreliminaryOrderStartDateOlderToday = 'preliminary_order_start_date_older_today',
  PreliminaryOrderEmailNotValid = 'preliminary_order_email_not_valid',
  PreliminaryFormContactMethodMissing = 'preliminary_form_contact_method_missing',
  PreliminaryFormContactOnlyOneMethod = 'preliminary_form_contact_only_one_method',
  PreliminaryFormPhoneNotProvided = 'preliminary_form_phone_not_provided',
  PreliminaryFormEmailNotProvided = 'preliminary_form_email_not_provided',
  PreliminaryFormContactPlatformNoUser = 'preliminary_form_contact_platform_no_user',
  OrderHasPayment = 'order_has_payment',
  StripeCardWithThisIdAlreadyExists = 'stripe_card_with_this_id_already_exists',
  InvalidStripePayment = 'invalid_stripe_payment',
  PaymentMethodDeclined = 'payment_method_declined',
  CouldNotWithdrawPayment = 'could_not_withdraw_payment',
  NoSuchPaymentMethod = 'no_such_payment_method',
  NoPaymentInfoProvided = 'no_payment_info_provided',
  PaymentIsMissingForInvoiceGeneration = 'payment_is_missing_for_invoice_generation',
  ErrorWhileCreatingPdf = 'error_while_creating_pdf',
  InvoiceTemplateMissingInAdmin = 'invoice_template_missing_in_admin',
  GoogleErrorMessage = 'google_error_message',
  FacebookErrorMessage = 'facebook_error_message',
  FacebookLetShareEmail = 'facebook_let_share_email',
  AppleErrorMessage = 'apple_error_message',
  AlreadyVerified = 'already_verified',
  InvalidInvitationKey = 'invalid_invitation_key',
  UserAlreadyAddedToCompany = 'user_already_added_to_company',
  UserWithThisEmailAlreadyExists = 'user_with_this_email_already_exists',
  UsernameExists = 'username_exists',
  InvalidUsername = 'invalid_username',
  ConditionsNotAccepted = 'conditions_not_accepted',
  InvalidCredentials = 'invalid_credentials',
  InvalidToken = 'invalid_token',
  InvalidPassword = 'invalid_password',
  PasswordKeyExpired = 'password_key_expired',
  InvalidLanguageCode = 'invalid_language_code',
  InvalidPermission = 'invalid_permission',
  YouDoNotHavePermissionToPerformThisAction = 'you_do_not_have_permission_to_perform_this_action',
  InvalidLookupField = 'invalid_lookup_field',
  RefreshToken = 'refresh_token',
  InvitedUserAlreadyExistsInCompany = 'invited_user_already_exists_in_company',
  InvitedUserAlreadyHasBeenInvited = 'invited_user_already_has_been_invited',
  ValuesIsNotAValidUuid = 'values_is_not_a_valid_uuid',
  ThePasswordMustBeAlphabetical = 'the_password_must_be_alphabetical',
  ThisPasswordIsTooCommon = 'this_password_is_too_common',
  EnterAValidEmailAddress = 'enter_a_valid_email_address',
  ThisPasswordIsEntirelyNumeric = 'this_password_is_entirely_numeric',
  ThisPasswordIsTooShortItMustContainAtLeast_5Characters = 'this_password_is_too_short_it_must_contain_at_least_5_characters',
  ThisFieldCannotBeBlank = 'this_field_cannot_be_blank',
  InvalidField = 'invalid_field',
  ContentIsTooLong = 'content_is_too_long',
  InvalidModel = 'invalid_model',
  InternalServerError = 'internal_server_error',
  CustomFieldsUnknown = 'custom_fields_unknown',
  CustomFieldsRequiredField = 'custom_fields_required_field',
  CustomFieldsUnexpectedField = 'custom_fields_unexpected_field',
  CustomFieldsInvalidString = 'custom_fields_invalid_string',
  CustomFieldsInvalidInteger = 'custom_fields_invalid_integer',
  CustomFieldsInvalidFloatingPoint = 'custom_fields_invalid_floating_point',
  CustomFieldsInvalidBoolean = 'custom_fields_invalid_boolean',
  CustomFieldsMinLength = 'custom_fields_min_length',
  CustomFieldsMaxLength = 'custom_fields_max_length',
  CustomFieldsLessThanMin = 'custom_fields_less_than_min',
  CustomFieldsMoreThanMax = 'custom_fields_more_than_max',
  CustomFieldsLessThanExclusiveMin = 'custom_fields_less_than_exclusive_min',
  CustomFieldsMoreThanExclusiveMax = 'custom_fields_more_than_exclusive_max',
  CustomFieldsShouldBeEmptyBecauseNoSchema = 'custom_fields_should_be_empty_because_no_schema',
  CsvImportInvalidFileFormat = 'csv_import_invalid_file_format',
  CsvImportInvalidValue = 'csv_import_invalid_value',
  LoanAmountCannotBeGreaterThanMaxLoanAmount = 'loan_amount_cannot_be_greater_than_max_loan_amount',
  LoanDurationCannotBeGreaterThanMaxLoanDuration = 'loan_duration_cannot_be_greater_than_max_loan_duration'
}

export type ErrorType = {
   __typename?: 'ErrorType';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

export type ErrorTypeWithCustomField = {
   __typename?: 'ErrorTypeWithCustomField';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
  customField: Maybe<Scalars['String']>;
};

export type FacebookLoginInput = {
  userId: Scalars['String'];
  accessToken: Scalars['String'];
  addNaturalCompany: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type FacebookLoginPayload = {
   __typename?: 'FacebookLoginPayload';
  errors: Maybe<Array<MutationErrorType>>;
  token: Maybe<Scalars['String']>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type FaqCategoryNode = {
   __typename?: 'FAQCategoryNode';
  name: Scalars['String'];
  icon: Scalars['String'];
  orderNum: Scalars['Int'];
};

export type FaqItemNode = {
   __typename?: 'FAQItemNode';
  title: Scalars['String'];
  text: Scalars['String'];
  orderNum: Scalars['Int'];
};

export type FaqNode = {
   __typename?: 'FAQNode';
  category: FaqCategoryNode;
  items: Maybe<Array<Maybe<FaqItemNode>>>;
};

export type FavoriteAdvertMutationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type FavoriteAdvertMutationPayload = {
   __typename?: 'FavoriteAdvertMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  advert: Maybe<AdvertNode>;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ForgotPasswordPayload = {
   __typename?: 'ForgotPasswordPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type ForumAuthorNode = Node & {
   __typename?: 'ForumAuthorNode';
  id: Scalars['ID'];
  isExpert: Scalars['Boolean'];
  expertDescription: Scalars['String'];
  name: Scalars['String'];
  image: Maybe<Scalars['String']>;
  thumbnail: Maybe<Scalars['String']>;
};

export type ForumAuthorNodeConnection = {
   __typename?: 'ForumAuthorNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ForumAuthorNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type ForumAuthorNodeEdge = {
   __typename?: 'ForumAuthorNodeEdge';
  node: Maybe<ForumAuthorNode>;
  cursor: Scalars['String'];
};

export type ForumCategoryNode = Node & {
   __typename?: 'ForumCategoryNode';
  id: Scalars['ID'];
  title: Scalars['String'];
  orderNum: Scalars['Int'];
};

export type ForumCategoryWithForumNode = Node & {
   __typename?: 'ForumCategoryWithForumNode';
  id: Scalars['ID'];
  title: Scalars['String'];
  orderNum: Scalars['Int'];
  forums: Maybe<Array<Maybe<ForumNode>>>;
};

export type ForumNode = Node & {
   __typename?: 'ForumNode';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  slug: Scalars['String'];
  category: Maybe<ForumCategoryNode>;
  postCount: Scalars['Int'];
  lastPost: Maybe<ForumReplyNode>;
  threadCount: Scalars['Int'];
};

export type ForumNodeConnection = {
   __typename?: 'ForumNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ForumNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type ForumNodeEdge = {
   __typename?: 'ForumNodeEdge';
  node: Maybe<ForumNode>;
  cursor: Scalars['String'];
};

export type ForumReplyMutationInput = {
  content: Scalars['String'];
  thread: Scalars['ID'];
  replyTo?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ForumReplyMutationPayload = {
   __typename?: 'ForumReplyMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  forumReply: Maybe<ForumReplyNode>;
};

export type ForumReplyNode = Node & {
   __typename?: 'ForumReplyNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  author: ForumAuthorNode;
  content: Scalars['String'];
  replyTo: Maybe<ForumReplyNode>;
  images: Array<Maybe<ReplyImageNode>>;
};

export type ForumReplyNodeConnection = {
   __typename?: 'ForumReplyNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ForumReplyNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type ForumReplyNodeEdge = {
   __typename?: 'ForumReplyNodeEdge';
  node: Maybe<ForumReplyNode>;
  cursor: Scalars['String'];
};

export type ForumThreadMutationInput = {
  content: Scalars['String'];
  title: Scalars['String'];
  forum: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ForumThreadMutationPayload = {
   __typename?: 'ForumThreadMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  forumThread: Maybe<ForumThreadNode>;
};

export type ForumThreadNode = Node & {
   __typename?: 'ForumThreadNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  content: Scalars['String'];
  forum: ForumNode;
  title: Scalars['String'];
  lastModified: Scalars['DateTime'];
  lastReply: Maybe<ForumReplyNode>;
  replyCount: Scalars['Int'];
  slug: Scalars['String'];
  author: ForumAuthorNode;
  images: Array<Maybe<ThreadImageNode>>;
};

export type ForumThreadNodeConnection = {
   __typename?: 'ForumThreadNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<ForumThreadNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type ForumThreadNodeEdge = {
   __typename?: 'ForumThreadNodeEdge';
  node: Maybe<ForumThreadNode>;
  cursor: Scalars['String'];
};

export type GeneralContractorCategoriesDetailsInput = {
  id: Scalars['ID'];
  value: Scalars['String'];
  price: GeneralContractorCategoriesPriceInput;
};

export type GeneralContractorCategoriesDetailsNode = {
   __typename?: 'GeneralContractorCategoriesDetailsNode';
  id: Maybe<Scalars['ID']>;
  value: Maybe<Scalars['String']>;
};

export type GeneralContractorCategoriesDetailsType = {
   __typename?: 'GeneralContractorCategoriesDetailsType';
  id: Scalars['ID'];
  value: Scalars['String'];
  price: GeneralContractorCategoryPriceType;
};

export type GeneralContractorCategoriesPriceInput = {
  type: GeneralContractorPriceType;
  value: Scalars['Int'];
};

export type GeneralContractorCategoryPriceType = {
   __typename?: 'GeneralContractorCategoryPriceType';
  type: GeneralContractorPriceType;
  value: Scalars['Int'];
};

export enum GeneralContractorPriceType {
  EurPerHour = 'EUR_PER_HOUR',
  EurPerHectare = 'EUR_PER_HECTARE'
}

export enum GeneralOfferPaymentCondition {
  FullPayment = 'FULL_PAYMENT',
  FiftyFiftySplit = 'FIFTY_FIFTY_SPLIT',
  AfterThirtyDaysWithAdvance = 'AFTER_THIRTY_DAYS_WITH_ADVANCE'
}

export enum GeneralOfferPriceDecision {
  Accepted = 'ACCEPTED',
  Suggested = 'SUGGESTED'
}

export type GeneralOfferType = Node & {
   __typename?: 'GeneralOfferType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  generalOrder: Maybe<GeneralOrderType>;
  email: Scalars['String'];
  phone: Scalars['String'];
  additionalInfo: Scalars['String'];
  totalPrice: Scalars['Int'];
  paymentCondition: GeneralOfferPaymentCondition;
  priceDecision: Maybe<GeneralOfferPriceDecision>;
};

export type GeneralOfferTypeConnection = {
   __typename?: 'GeneralOfferTypeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<GeneralOfferTypeEdge>>;
};

export type GeneralOfferTypeEdge = {
   __typename?: 'GeneralOfferTypeEdge';
  node: Maybe<GeneralOfferType>;
  cursor: Scalars['String'];
};

export enum GeneralOrderAmountType {
  Hectare = 'HECTARE'
}

export type GeneralOrderContractorNode = Node & {
   __typename?: 'GeneralOrderContractorNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  surname: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  address: Scalars['String'];
  coordinates: CoordinateType;
  workingArea: Scalars['Int'];
  additionalInfo: Scalars['String'];
  categories: CategoryNodeConnection;
  categoriesDetails: Array<Maybe<GeneralContractorCategoriesDetailsNode>>;
};


export type GeneralOrderContractorNodeCategoriesArgs = {
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type GeneralOrderContractorType = Node & {
   __typename?: 'GeneralOrderContractorType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  surname: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  address: Scalars['String'];
  coordinates: CoordinateType;
  workingArea: Scalars['Int'];
  additionalInfo: Scalars['String'];
  categories: Maybe<Array<Maybe<CategoryNode>>>;
  categoriesDetails: Array<Maybe<GeneralContractorCategoriesDetailsType>>;
};

export type GeneralOrderNode = Node & {
   __typename?: 'GeneralOrderNode';
  category: CategoryNode;
  amount: Scalars['Decimal'];
  amountParts: Array<Scalars['Decimal']>;
  amountType: GeneralOrderAmountType;
  totalPrice: Scalars['Int'];
  terrainType: Maybe<TerrainTypeNode>;
  paymentCondition: GeneralOrderPaymentCondition;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  coordinates: Maybe<CoordinateType>;
};

export enum GeneralOrderPaymentCondition {
  FullPayment = 'FULL_PAYMENT',
  FiftyFiftySplit = 'FIFTY_FIFTY_SPLIT',
  AfterThirtyDaysWithAdvance = 'AFTER_THIRTY_DAYS_WITH_ADVANCE'
}

export type GeneralOrderType = Node & {
   __typename?: 'GeneralOrderType';
  category: CategoryNode;
  amount: Scalars['Decimal'];
  amountParts: Array<Scalars['Decimal']>;
  amountType: GeneralOrderAmountType;
  totalPrice: Scalars['Int'];
  terrainType: Maybe<TerrainTypeNode>;
  paymentCondition: GeneralOrderPaymentCondition;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  surname: Scalars['String'];
  address: Scalars['String'];
  email: Scalars['String'];
  additionalInfo: Scalars['String'];
  coordinates: CoordinateType;
  phone: Scalars['String'];
  sentAt: Maybe<Scalars['DateTime']>;
  toSend: Scalars['Boolean'];
  offers: GeneralOfferTypeConnection;
};


export type GeneralOrderTypeOffersArgs = {
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type GeneralOrderTypeConnection = {
   __typename?: 'GeneralOrderTypeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<GeneralOrderTypeEdge>>;
};

export type GeneralOrderTypeEdge = {
   __typename?: 'GeneralOrderTypeEdge';
  node: Maybe<GeneralOrderType>;
  cursor: Scalars['String'];
};


export type GoogleLoginInput = {
  accessToken: Scalars['String'];
  addNaturalCompany: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GoogleLoginPayload = {
   __typename?: 'GoogleLoginPayload';
  errors: Maybe<Array<MutationErrorType>>;
  token: Maybe<Scalars['String']>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type ImportAdvertsError = {
   __typename?: 'ImportAdvertsError';
  code: Scalars['String'];
  lineNumber: Maybe<Scalars['Int']>;
  columnTitle: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type ImportAdvertsFromCsvMutation = {
   __typename?: 'ImportAdvertsFromCsvMutation';
  importedLines: Maybe<Scalars['Int']>;
  errors: Maybe<Array<Maybe<ImportAdvertsError>>>;
};

export type IncrementAdvertViewCountInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IncrementAdvertViewCountPayload = {
   __typename?: 'IncrementAdvertViewCountPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  advert: Maybe<AdvertNode>;
};

export type InvitationKeyNode = Node & {
   __typename?: 'InvitationKeyNode';
  invitationKey: Scalars['UUID'];
  email: Scalars['String'];
  id: Scalars['ID'];
};

export type InviteUserToCompanyMutationInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type InviteUserToCompanyMutationPayload = {
   __typename?: 'InviteUserToCompanyMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  invitationKey: Maybe<InvitationKeyNode>;
};

export type InvoiceNode = Node & {
   __typename?: 'InvoiceNode';
  createdAt: Scalars['DateTime'];
  number: Scalars['String'];
  id: Scalars['ID'];
  downloadUrl: Scalars['String'];
};

export type InvoiceNodeConnection = {
   __typename?: 'InvoiceNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<InvoiceNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type InvoiceNodeEdge = {
   __typename?: 'InvoiceNodeEdge';
  node: Maybe<InvoiceNode>;
  cursor: Scalars['String'];
};

export type InvoicingInfoMutationInput = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  vatCode?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type InvoicingInfoMutationPayload = {
   __typename?: 'InvoicingInfoMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  invoicingInfo: Maybe<InvoicingInfoNode>;
};

export type InvoicingInfoNode = Node & {
   __typename?: 'InvoicingInfoNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  vatCode: Scalars['String'];
  companyCode: Scalars['String'];
};


export type LandingPageBannerNode = {
   __typename?: 'LandingPageBannerNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  buttonUrl: Maybe<Scalars['String']>;
  buttonText: Maybe<Scalars['String']>;
  imageWeb: Maybe<Scalars['String']>;
  imageMobile: Maybe<Scalars['String']>;
  kind: Maybe<Scalars['String']>;
};

export type LandingPageImageNode = Node & {
   __typename?: 'LandingPageImageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  text: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  navigationTitle: Maybe<Scalars['String']>;
  imageWeb: Maybe<Scalars['String']>;
  imageWebThumbnail: Maybe<Scalars['String']>;
  imageMobile: Maybe<Scalars['String']>;
  imageMobileThumbnail: Maybe<Scalars['String']>;
};

export type LanguageNode = Node & {
   __typename?: 'LanguageNode';
  code: Scalars['String'];
  name: Scalars['String'];
  flag: Scalars['String'];
  id: Scalars['ID'];
};

export type MarketPriceQueries = {
   __typename?: 'MarketPriceQueries';
  marketPriceFrom: Maybe<Scalars['Int']>;
  marketPriceTo: Maybe<Scalars['Int']>;
};

export type Mutation = {
   __typename?: 'Mutation';
  tokenAuth: Maybe<ObtainJsonWebTokenPayload>;
  verifyToken: Maybe<VerifyPayload>;
  refreshToken: Maybe<RefreshPayload>;
  registerUser: Maybe<RegisterUserPayload>;
  deleteUser: Maybe<DeleteUserPayload>;
  appleLogin: Maybe<AppleLoginPayload>;
  facebookLogin: Maybe<FacebookLoginPayload>;
  googleLogin: Maybe<GoogleLoginPayload>;
  changePassword: Maybe<ChangePasswordPayload>;
  forgotPassword: Maybe<ForgotPasswordPayload>;
  resetPassword: Maybe<ResetPasswordPayload>;
  verifyUser: Maybe<VerifyUserPayload>;
  changeLanguage: Maybe<ChangeLanguagePayload>;
  updateUserData: Maybe<UpdateUserDataPayload>;
  changeActiveCompany: Maybe<ChangeActiveCompanyPayload>;
  acceptCompanyInvitation: Maybe<AcceptCompanyInvitationPayload>;
  createStripePayment: Maybe<CreateStripePaymentPayload>;
  addCard: Maybe<AddCardPayload>;
  removeCard: Maybe<RemoveCardPayload>;
  fetchPaymentSuccessStatus: Maybe<StripePaymentSuccessStatusPayload>;
  createPreliminaryOrder: Maybe<CreatePreliminaryOrderMutationPayload>;
  updateOrderStatus: Maybe<UpdateOrderStatusPayload>;
  createOrder: Maybe<CreateOrderMutationPayload>;
  updateOrder: Maybe<UpdateOrderMutationPayload>;
  orderRating: Maybe<OrderRatingMutationPayload>;
  createGeneralOrder: Maybe<CreateGeneralOrderMutationPayload>;
  createGeneralOffer: Maybe<CreateGeneralOfferMutationPayload>;
  createContractor: Maybe<CreateContractorMutationPayload>;
  registerDevice: Maybe<RegisterDeviceMutationPayload>;
  unregisterDevice: Maybe<UnregisterDeviceMutationPayload>;
  markRead: Maybe<ReadNotificationMutationPayload>;
  markReadOrderNotifications: Maybe<ReadOrderNotificationsMutationPayload>;
  markReadAuctionNotifications: Maybe<ReadAuctionNotificationsMutationPayload>;
  createForumThread: Maybe<ForumThreadMutationPayload>;
  createForumReply: Maybe<ForumReplyMutationPayload>;
  uploadReplyImage: Maybe<ReplyImageMutationPayload>;
  uploadThreadImage: Maybe<ThreadImageMutationPayload>;
  company: Maybe<CompanyMutationPayload>;
  createCompany: Maybe<CreateCompanyMutationPayload>;
  favoriteAdvert: Maybe<FavoriteAdvertMutationPayload>;
  myCompanyInvoicingInfo: Maybe<InvoicingInfoMutationPayload>;
  myCompanyBankAccount: Maybe<BankAccountMutationPayload>;
  inviteUserToMyCompany: Maybe<InviteUserToCompanyMutationPayload>;
  chatMessage: Maybe<ChatMessageMutationPayload>;
  chatMessageFileUpload: Maybe<ChatMessageFileUploadMutationPayload>;
  createAuction: Maybe<CreateAuctionMutationPayload>;
  updateAuction: Maybe<UpdateAuctionMutationPayload>;
  deleteAuction: Maybe<DeleteAuctionMutationPayload>;
  uploadAuctionImage: Maybe<UploadAuctionImagePayload>;
  deleteAuctionImage: Maybe<DeleteAuctionImagePayload>;
  createBid: Maybe<CreateBidMutationPayload>;
  advert: Maybe<AdvertMutationPayload>;
  updateAdvertStatus: Maybe<UpdateAdvertStatusPayload>;
  advertViewCount: Maybe<IncrementAdvertViewCountPayload>;
  uploadAdvertImage: Maybe<UploadAdvertImagePayload>;
  deleteAdvertImage: Maybe<DeleteAdvertImagePayload>;
  advertEvent: Maybe<AdvertEventMutationPayload>;
  advertLoan: Maybe<AdvertLoanMutationPayload>;
  deleteAdvertEvent: Maybe<DeleteAdvertEventPayload>;
  importAdverts: Maybe<ImportAdvertsFromCsvMutation>;
};


export type MutationTokenAuthArgs = {
  input: ObtainJsonWebTokenInput;
};


export type MutationVerifyTokenArgs = {
  input: VerifyInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationAppleLoginArgs = {
  input: AppleLoginInput;
};


export type MutationFacebookLoginArgs = {
  input: FacebookLoginInput;
};


export type MutationGoogleLoginArgs = {
  input: GoogleLoginInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationVerifyUserArgs = {
  input: VerifyUserInput;
};


export type MutationChangeLanguageArgs = {
  input: ChangeLanguageInput;
};


export type MutationUpdateUserDataArgs = {
  input: UpdateUserDataInput;
};


export type MutationChangeActiveCompanyArgs = {
  input: ChangeActiveCompanyInput;
};


export type MutationAcceptCompanyInvitationArgs = {
  input: AcceptCompanyInvitationInput;
};


export type MutationCreateStripePaymentArgs = {
  input: CreateStripePaymentInput;
};


export type MutationAddCardArgs = {
  input: AddCardInput;
};


export type MutationRemoveCardArgs = {
  input: RemoveCardInput;
};


export type MutationFetchPaymentSuccessStatusArgs = {
  input: StripePaymentSuccessStatusInput;
};


export type MutationCreatePreliminaryOrderArgs = {
  input: CreatePreliminaryOrderMutationInput;
};


export type MutationUpdateOrderStatusArgs = {
  input: UpdateOrderStatusInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderMutationInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderMutationInput;
};


export type MutationOrderRatingArgs = {
  input: OrderRatingMutationInput;
};


export type MutationCreateGeneralOrderArgs = {
  input: CreateGeneralOrderMutationInput;
};


export type MutationCreateGeneralOfferArgs = {
  input: CreateGeneralOfferMutationInput;
};


export type MutationCreateContractorArgs = {
  input: CreateContractorMutationInput;
};


export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceMutationInput;
};


export type MutationUnregisterDeviceArgs = {
  input: UnregisterDeviceMutationInput;
};


export type MutationMarkReadArgs = {
  input: ReadNotificationMutationInput;
};


export type MutationMarkReadOrderNotificationsArgs = {
  input: ReadOrderNotificationsMutationInput;
};


export type MutationMarkReadAuctionNotificationsArgs = {
  input: ReadAuctionNotificationsMutationInput;
};


export type MutationCreateForumThreadArgs = {
  input: ForumThreadMutationInput;
};


export type MutationCreateForumReplyArgs = {
  input: ForumReplyMutationInput;
};


export type MutationUploadReplyImageArgs = {
  input: ReplyImageMutationInput;
};


export type MutationUploadThreadImageArgs = {
  input: ThreadImageMutationInput;
};


export type MutationCompanyArgs = {
  input: CompanyMutationInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyMutationInput;
};


export type MutationFavoriteAdvertArgs = {
  input: FavoriteAdvertMutationInput;
};


export type MutationMyCompanyInvoicingInfoArgs = {
  input: InvoicingInfoMutationInput;
};


export type MutationMyCompanyBankAccountArgs = {
  input: BankAccountMutationInput;
};


export type MutationInviteUserToMyCompanyArgs = {
  input: InviteUserToCompanyMutationInput;
};


export type MutationChatMessageArgs = {
  input: ChatMessageMutationInput;
};


export type MutationChatMessageFileUploadArgs = {
  input: ChatMessageFileUploadMutationInput;
};


export type MutationCreateAuctionArgs = {
  input: CreateAuctionMutationInput;
};


export type MutationUpdateAuctionArgs = {
  input: UpdateAuctionMutationInput;
};


export type MutationDeleteAuctionArgs = {
  input: DeleteAuctionMutationInput;
};


export type MutationUploadAuctionImageArgs = {
  input: UploadAuctionImageInput;
};


export type MutationDeleteAuctionImageArgs = {
  input: DeleteAuctionImageInput;
};


export type MutationCreateBidArgs = {
  input: CreateBidMutationInput;
};


export type MutationAdvertArgs = {
  input: AdvertMutationInput;
};


export type MutationUpdateAdvertStatusArgs = {
  input: UpdateAdvertStatusInput;
};


export type MutationAdvertViewCountArgs = {
  input: IncrementAdvertViewCountInput;
};


export type MutationUploadAdvertImageArgs = {
  input: UploadAdvertImageInput;
};


export type MutationDeleteAdvertImageArgs = {
  input: DeleteAdvertImageInput;
};


export type MutationAdvertEventArgs = {
  input: AdvertEventMutationInput;
};


export type MutationAdvertLoanArgs = {
  input: AdvertLoanMutationInput;
};


export type MutationDeleteAdvertEventArgs = {
  input: DeleteAdvertEventInput;
};


export type MutationImportAdvertsArgs = {
  csvFile: Scalars['Upload'];
  public: Scalars['Boolean'];
};

export type MutationErrorType = {
   __typename?: 'MutationErrorType';
  field: Maybe<Scalars['String']>;
  message: Maybe<Scalars['String']>;
};

export type MyAdvertScheduleNode = {
   __typename?: 'MyAdvertScheduleNode';
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type MyAuctionNode = Node & {
   __typename?: 'MyAuctionNode';
  id: Scalars['ID'];
  auction: Maybe<AuctionNode>;
  winnerBid: Maybe<MyBidNode>;
  lastBids: Maybe<Array<Maybe<MyBidNode>>>;
  isDeletable: Scalars['Boolean'];
};

export type MyAuctionNodeConnection = {
   __typename?: 'MyAuctionNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MyAuctionNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type MyAuctionNodeEdge = {
   __typename?: 'MyAuctionNodeEdge';
  node: Maybe<MyAuctionNode>;
  cursor: Scalars['String'];
};

export type MyBidNode = Node & {
   __typename?: 'MyBidNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  auction: AuctionNode;
  amount: Scalars['Int'];
  company: CompanyNode;
};

export type MyBidNodeConnection = {
   __typename?: 'MyBidNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<MyBidNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type MyBidNodeEdge = {
   __typename?: 'MyBidNodeEdge';
  node: Maybe<MyBidNode>;
  cursor: Scalars['String'];
};

export type MyCompanyNode = Node & {
   __typename?: 'MyCompanyNode';
  id: Scalars['ID'];
  company: Maybe<CompanyNode>;
  unreadNotificationsCount: Scalars['Int'];
  isActive: Scalars['Boolean'];
  myCards: Maybe<Array<Maybe<StripeCardNode>>>;
  inPrivateGroup: Scalars['Boolean'];
};

export type Node = {
  id: Scalars['ID'];
};

export enum NotificationLevel {
  Success = 'SUCCESS',
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR'
}

export type NotificationNode = Node & {
   __typename?: 'NotificationNode';
  id: Scalars['ID'];
  level: NotificationLevel;
  unread: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  verb: NotificationVerbs;
  relatedObjectSlug: Maybe<Scalars['String']>;
  relatedObjectGraphqlId: Maybe<Scalars['String']>;
};

export type NotificationNodeConnection = {
   __typename?: 'NotificationNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<NotificationNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type NotificationNodeEdge = {
   __typename?: 'NotificationNodeEdge';
  node: Maybe<NotificationNode>;
  cursor: Scalars['String'];
};

export enum NotificationVerbs {
  NewChatMessage = 'NEW_CHAT_MESSAGE',
  OrderCreated = 'ORDER_CREATED',
  OrderAccepted = 'ORDER_ACCEPTED',
  OrderEnded = 'ORDER_ENDED',
  OrderCanceled = 'ORDER_CANCELED',
  OrderFinished = 'ORDER_FINISHED',
  OrderExpired = 'ORDER_EXPIRED',
  OrderActive = 'ORDER_ACTIVE',
  OrderUnfinished = 'ORDER_UNFINISHED',
  OrderDeclined = 'ORDER_DECLINED',
  OrderUpdatedStatus = 'ORDER_UPDATED_STATUS',
  OrderNeedsPayment = 'ORDER_NEEDS_PAYMENT',
  OrderPaymentDone = 'ORDER_PAYMENT_DONE',
  AuctionApproved = 'AUCTION_APPROVED',
  AuctionDeclined = 'AUCTION_DECLINED',
  AuctionNewBidInformCreator = 'AUCTION_NEW_BID_INFORM_CREATOR',
  AuctionNewBidInformBidders = 'AUCTION_NEW_BID_INFORM_BIDDERS',
  AuctionEndedInformCreator = 'AUCTION_ENDED_INFORM_CREATOR',
  AuctionEndedInformBidders = 'AUCTION_ENDED_INFORM_BIDDERS',
  AuctionEndedInformWinner = 'AUCTION_ENDED_INFORM_WINNER'
}

export type ObtainJsonWebTokenInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ObtainJsonWebTokenPayload = {
   __typename?: 'ObtainJSONWebTokenPayload';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  clientMutationId: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type OrderNode = Node & {
   __typename?: 'OrderNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  company: CompanyNode;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  status: OrderStatus;
  advert: AdvertNode;
  description: Scalars['String'];
  totalPrice: Scalars['Int'];
  initialDeliveryPrice: Scalars['Int'];
  initialPrice: Scalars['Int'];
  amount: Maybe<Scalars['Int']>;
  orderId: Scalars['String'];
  paymentType: Scalars['String'];
  includeDelivery: Scalars['Boolean'];
  orderType: OrderType;
  initialPricingType: AdvertPricingType;
  phone: Maybe<Scalars['String']>;
  unreadNotificationCount: Scalars['Int'];
};

export type OrderNodeConnection = {
   __typename?: 'OrderNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OrderNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type OrderNodeEdge = {
   __typename?: 'OrderNodeEdge';
  node: Maybe<OrderNode>;
  cursor: Scalars['String'];
};

export type OrderRatingMutationInput = {
  rating: Scalars['Decimal'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  order: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type OrderRatingMutationPayload = {
   __typename?: 'OrderRatingMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  orderRating: Maybe<OrderRatingNode>;
};

export type OrderRatingNode = Node & {
   __typename?: 'OrderRatingNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  order: OrderNode;
  reportedCompany: CompanyNode;
  rating: Scalars['Decimal'];
  description: Scalars['String'];
};

export type OrderRatingNodeConnection = {
   __typename?: 'OrderRatingNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<OrderRatingNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type OrderRatingNodeEdge = {
   __typename?: 'OrderRatingNodeEdge';
  node: Maybe<OrderRatingNode>;
  cursor: Scalars['String'];
};

export enum OrderStatus {
  Created = 'CREATED',
  Canceled = 'CANCELED',
  PaymentPending = 'PAYMENT_PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Active = 'ACTIVE',
  Ended = 'ENDED',
  Finished = 'FINISHED',
  Unfinished = 'UNFINISHED'
}

export type OrderSubscriptionInput = {
  userToken: Scalars['String'];
  orderId: Scalars['ID'];
};

export enum OrderType {
  Requested = 'REQUESTED',
  Received = 'RECEIVED'
}

export type PageInfo = {
   __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['String']>;
  endCursor: Maybe<Scalars['String']>;
};

export type PageTranslationNode = Node & {
   __typename?: 'PageTranslationNode';
  id: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
  text: Scalars['String'];
  language: LanguageNode;
};

export enum PaymentType {
  CreditCard = 'CREDIT_CARD',
  AgreedPrice = 'AGREED_PRICE',
  BankTransfer = 'BANK_TRANSFER'
}

export type PredefinedSearchNode = Node & {
   __typename?: 'PredefinedSearchNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  translation: Maybe<PredefinedSearchTranslationNode>;
};

export type PredefinedSearchTranslationNode = Node & {
   __typename?: 'PredefinedSearchTranslationNode';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  language: LanguageNode;
};

export type PreliminaryOrderNode = Node & {
   __typename?: 'PreliminaryOrderNode';
  user: Maybe<UserNode>;
  startDate: Scalars['Date'];
  status: PreliminaryOrderStatus;
  advert: Maybe<AdvertNode>;
  description: Scalars['String'];
  orderId: Scalars['String'];
  contactViaPlatform: Scalars['Boolean'];
  contactViaEmail: Scalars['Boolean'];
  contactViaPhone: Scalars['Boolean'];
  email: Scalars['String'];
  phone: Scalars['String'];
  id: Scalars['ID'];
};

export enum PreliminaryOrderStatus {
  Created = 'CREATED',
  Canceled = 'CANCELED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED'
}

export type Query = {
   __typename?: 'Query';
  me: Maybe<UserDetailsNode>;
  verifyUser: Maybe<VerifyUserNode>;
  resetPassword: Maybe<ResetPasswordNode>;
  verifyInvitationKey: Maybe<InvitationKeyNode>;
  myAdvertSchedule: Maybe<Array<MyAdvertScheduleNode>>;
  advertSchedule: Maybe<Array<Maybe<DateTimeRangeNode>>>;
  myCards: Maybe<StripeCardNodeConnection>;
  pageTranslation: Maybe<PageTranslationNode>;
  order: Maybe<OrderNode>;
  advertOrders: Maybe<Array<OrderNode>>;
  orderRating: Maybe<OrderRatingNode>;
  orderRatings: Maybe<OrderRatingNodeConnection>;
  generalOrder: Maybe<GeneralOrderNode>;
  generalOrders: Maybe<Array<GeneralOrderNode>>;
  generalOrderContractors: Maybe<Array<GeneralOrderContractorNode>>;
  language: Maybe<LanguageNode>;
  forumCategories: Maybe<Array<Maybe<ForumCategoryWithForumNode>>>;
  forums: Maybe<ForumNodeConnection>;
  forum: Maybe<ForumNode>;
  forumThread: Maybe<ForumThreadNode>;
  forumThreads: Maybe<ForumThreadNodeConnection>;
  forumThreadReplies: Maybe<ForumReplyNodeConnection>;
  experts: Maybe<ForumAuthorNodeConnection>;
  company: Maybe<CompanyNode>;
  companyBySlug: Maybe<CompanyNode>;
  chatMessages: Maybe<Array<Maybe<ChatMessageNode>>>;
  category: Maybe<CategoryNode>;
  categories: Maybe<Array<CategoryNode>>;
  myAuction: Maybe<MyAuctionNode>;
  myAuctionBySlug: Maybe<MyAuctionNode>;
  auction: Maybe<AuctionNode>;
  auctionBySlug: Maybe<AuctionNode>;
  auctions: Maybe<AuctionNodeConnection>;
  auctionBids: Maybe<MyBidNodeConnection>;
  featuredAuctions: Maybe<Array<Maybe<AuctionNode>>>;
  appConfig: Maybe<AppConfigViewNode>;
  faqs: Maybe<Array<Maybe<FaqNode>>>;
  errors: Maybe<Errors>;
  advert: Maybe<AdvertNode>;
  advertBySlug: Maybe<AdvertNode>;
  allAdverts: Maybe<AdvertNodeConnection>;
  relatedAdverts: Maybe<Array<Maybe<AdvertNode>>>;
  featuredAds: Maybe<Array<Maybe<AdvertNode>>>;
  topicalAds: Maybe<Array<Maybe<AdvertNode>>>;
  tabFeaturedAds: Maybe<Array<Maybe<AdvertNode>>>;
  activity: Maybe<ActivityNode>;
  activities: Maybe<ActivityNodeConnection>;
};


export type QueryVerifyUserArgs = {
  activationKey: Scalars['String'];
};


export type QueryResetPasswordArgs = {
  passwordKey: Scalars['String'];
};


export type QueryVerifyInvitationKeyArgs = {
  invitationKey: Scalars['String'];
};


export type QueryMyAdvertScheduleArgs = {
  advert: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryAdvertScheduleArgs = {
  advert: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryMyCardsArgs = {
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
  fullName: Maybe<Scalars['String']>;
  gateway: Maybe<Scalars['String']>;
  expMonth: Maybe<Scalars['Int']>;
  expYear: Maybe<Scalars['Int']>;
  last4: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
};


export type QueryPageTranslationArgs = {
  slug: Scalars['String'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryAdvertOrdersArgs = {
  advert: Scalars['ID'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  status: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryOrderRatingArgs = {
  orderId: Scalars['ID'];
};


export type QueryOrderRatingsArgs = {
  company: Scalars['ID'];
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
  order: Maybe<Scalars['ID']>;
  reportedCompany: Maybe<Scalars['ID']>;
  rating: Maybe<Scalars['Decimal']>;
  description: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
};


export type QueryGeneralOrderArgs = {
  id: Scalars['ID'];
};


export type QueryLanguageArgs = {
  id: Scalars['ID'];
};


export type QueryForumsArgs = {
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  title: Maybe<Scalars['String']>;
  category: Maybe<Scalars['ID']>;
};


export type QueryForumArgs = {
  slug: Scalars['String'];
};


export type QueryForumThreadArgs = {
  slug: Scalars['String'];
};


export type QueryForumThreadsArgs = {
  forum: Scalars['ID'];
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type QueryForumThreadRepliesArgs = {
  thread: Scalars['ID'];
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type QueryExpertsArgs = {
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryChatMessagesArgs = {
  orderId: Scalars['ID'];
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryCategoriesArgs = {
  slug: Maybe<Scalars['String']>;
  ids: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryMyAuctionArgs = {
  id: Scalars['ID'];
};


export type QueryMyAuctionBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryAuctionArgs = {
  id: Scalars['ID'];
};


export type QueryAuctionBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryAuctionsArgs = {
  filterOnlyUserAttending: Maybe<Scalars['Boolean']>;
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  auctionType: Maybe<AuctionAuctionType>;
  company: Maybe<Scalars['ID']>;
  title: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  initialPrice: Maybe<Scalars['Int']>;
  slug: Maybe<Scalars['String']>;
  auctionId: Maybe<Scalars['String']>;
  status: Maybe<AuctionStatus>;
  expirationDate: Maybe<Scalars['DateTime']>;
};


export type QueryAuctionBidsArgs = {
  auction: Scalars['ID'];
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  amount: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['DateTime']>;
};


export type QueryFeaturedAuctionsArgs = {
  auctionAmount: Scalars['Int'];
};


export type QueryAppConfigArgs = {
  language: Maybe<Scalars['String']>;
  adAmount: Maybe<Scalars['Int']>;
  auctionAmount: Maybe<Scalars['Int']>;
};


export type QueryFaqsArgs = {
  language: Scalars['String'];
};


export type QueryAdvertArgs = {
  id: Scalars['ID'];
};


export type QueryAdvertBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryAllAdvertsArgs = {
  categories: Maybe<Array<Maybe<Scalars['ID']>>>;
  advertType: Maybe<Array<Maybe<AdvertType>>>;
  priceGte: Maybe<Scalars['Int']>;
  pricingType: Maybe<Array<Maybe<AdvertPricingType>>>;
  priceLte: Maybe<Scalars['Int']>;
  geoSearch: Maybe<Scalars['Coordinate']>;
  serviceType: Maybe<Array<Maybe<AdvertServiceType>>>;
  dateSearch: Maybe<DateTimeRange>;
  categorySlug: Maybe<Scalars['String']>;
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  status: Maybe<AdvertStatus>;
  title: Maybe<Scalars['String']>;
  company: Maybe<Scalars['ID']>;
  address: Maybe<Scalars['String']>;
  price: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdAtGte: Maybe<Scalars['String']>;
  createdAtLte: Maybe<Scalars['String']>;
  advertIds: Maybe<Array<Maybe<Scalars['ID']>>>;
  searchBy: Maybe<Scalars['String']>;
  kind: Maybe<Scalars['String']>;
};


export type QueryRelatedAdvertsArgs = {
  advert: Scalars['ID'];
  amount: Scalars['Int'];
};


export type QueryFeaturedAdsArgs = {
  adAmount: Scalars['Int'];
  advertIds: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryTopicalAdsArgs = {
  adAmount: Scalars['Int'];
  advertIds: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type QueryTabFeaturedAdsArgs = {
  tabFeaturedType: TabFeaturedType;
  kind: Maybe<Scalars['String']>;
};


export type QueryActivityArgs = {
  id: Scalars['ID'];
};


export type QueryActivitiesArgs = {
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  sizeMeasureType: Maybe<ActivitySizeMeasureType>;
  orderNum: Maybe<Scalars['Int']>;
};

export type ReadAuctionNotificationsMutationInput = {
  auction: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReadAuctionNotificationsMutationPayload = {
   __typename?: 'ReadAuctionNotificationsMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  auction: Maybe<AuctionNode>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type ReadNotificationMutationInput = {
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReadNotificationMutationPayload = {
   __typename?: 'ReadNotificationMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  notification: Maybe<NotificationNode>;
};

export type ReadOrderNotificationsMutationInput = {
  order: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReadOrderNotificationsMutationPayload = {
   __typename?: 'ReadOrderNotificationsMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  order: Maybe<OrderNode>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type RefreshInput = {
  token?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RefreshPayload = {
   __typename?: 'RefreshPayload';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  clientMutationId: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type RegisterDeviceMutationInput = {
  deviceId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterDeviceMutationPayload = {
   __typename?: 'RegisterDeviceMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  deviceId: Maybe<Scalars['Int']>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type RegisterUserInput = {
  conditionsAccepted: Scalars['Boolean'];
  addNaturalCompany: Scalars['Boolean'];
  username: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  receiveMarketingMaterial: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterUserPayload = {
   __typename?: 'RegisterUserPayload';
  errors: Maybe<Array<MutationErrorType>>;
  token: Maybe<Scalars['String']>;
  clientMutationId: Maybe<Scalars['String']>;
  user: Maybe<UserNode>;
};

export type RemoveCardInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveCardPayload = {
   __typename?: 'RemoveCardPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  stripeCard: Maybe<StripeCardNode>;
};

export type ReplyImageMutationInput = {
  image: Scalars['UploadType'];
  reply: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReplyImageMutationPayload = {
   __typename?: 'ReplyImageMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  forumReplyImage: Maybe<ReplyImageNode>;
};

export type ReplyImageNode = Node & {
   __typename?: 'ReplyImageNode';
  id: Scalars['ID'];
  thumbnail: Scalars['String'];
  image: Scalars['String'];
};

export type ResetPasswordInput = {
  passwordKey: Scalars['String'];
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordNode = {
   __typename?: 'ResetPasswordNode';
  passwordKey: Maybe<Scalars['String']>;
};

export type ResetPasswordPayload = {
   __typename?: 'ResetPasswordPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type StripeCardNode = Node & {
   __typename?: 'StripeCardNode';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  fullName: Scalars['String'];
  gateway: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  last4: Scalars['String'];
  paymentMethodId: Scalars['String'];
};

export type StripeCardNodeConnection = {
   __typename?: 'StripeCardNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<StripeCardNodeEdge>>;
};

export type StripeCardNodeEdge = {
   __typename?: 'StripeCardNodeEdge';
  node: Maybe<StripeCardNode>;
  cursor: Scalars['String'];
};

export type StripePaymentNode = Node & {
   __typename?: 'StripePaymentNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  company: CompanyNode;
  order: OrderNode;
  status: StripePaymentStatus;
};

export enum StripePaymentStatus {
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  RequiresConfirmation = 'REQUIRES_CONFIRMATION',
  RequiresAction = 'REQUIRES_ACTION',
  Processing = 'PROCESSING',
  Succeeded = 'SUCCEEDED',
  Canceled = 'CANCELED',
  RequiresCapture = 'REQUIRES_CAPTURE',
  Refund = 'REFUND',
  ChargeFailed = 'CHARGE_FAILED',
  PaymentFailed = 'PAYMENT_FAILED'
}

export type StripePaymentSuccessStatusInput = {
  paymentMethodId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StripePaymentSuccessStatusPayload = {
   __typename?: 'StripePaymentSuccessStatusPayload';
  errors: Maybe<Array<MutationErrorType>>;
  order: Maybe<OrderNode>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type Subscription = {
   __typename?: 'Subscription';
  orderStatusUpdated: Maybe<OrderNode>;
  chatMessageCreated: Maybe<ChatMessageNode>;
};


export type SubscriptionOrderStatusUpdatedArgs = {
  input: Maybe<OrderSubscriptionInput>;
};


export type SubscriptionChatMessageCreatedArgs = {
  input: Maybe<ChatMessageSubscriptionInput>;
};

export enum TabFeaturedType {
  MostPopular = 'MOST_POPULAR',
  MostRecent = 'MOST_RECENT',
  MostExpensive = 'MOST_EXPENSIVE',
  DailyPromotion = 'DAILY_PROMOTION'
}

export type TerrainTypeNode = {
   __typename?: 'TerrainTypeNode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Maybe<Scalars['GenericScalar']>;
  generalOrders: GeneralOrderTypeConnection;
};


export type TerrainTypeNodeGeneralOrdersArgs = {
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type ThreadImageMutationInput = {
  image: Scalars['UploadType'];
  thread: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ThreadImageMutationPayload = {
   __typename?: 'ThreadImageMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  forumThreadImage: Maybe<ThreadImageNode>;
};

export type ThreadImageNode = Node & {
   __typename?: 'ThreadImageNode';
  id: Scalars['ID'];
  thumbnail: Scalars['String'];
  image: Scalars['String'];
};

export type UnregisterDeviceMutationInput = {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnregisterDeviceMutationPayload = {
   __typename?: 'UnregisterDeviceMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type UpdateAdvertStatusInput = {
  status: AdvertStatus;
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAdvertStatusPayload = {
   __typename?: 'UpdateAdvertStatusPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  advert: Maybe<AdvertNode>;
};

export type UpdateAuctionMutationInput = {
  coordinates: Scalars['Coordinate'];
  mainImage?: Maybe<Scalars['UploadType']>;
  auctionType?: Maybe<AuctionAuctionType>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  initialPrice?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  customFieldsData?: Maybe<Scalars['JSONString']>;
  id: Scalars['ID'];
  category?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAuctionMutationPayload = {
   __typename?: 'UpdateAuctionMutationPayload';
  errors: Maybe<Array<Maybe<ErrorTypeWithCustomField>>>;
  clientMutationId: Maybe<Scalars['String']>;
  auction: Maybe<AuctionNode>;
};

export type UpdateOrderMutationInput = {
  paymentType?: Maybe<PaymentType>;
  includeDelivery: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrderMutationPayload = {
   __typename?: 'UpdateOrderMutationPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  order: Maybe<OrderNode>;
};

export type UpdateOrderStatusInput = {
  orderId: Scalars['ID'];
  status: OrderStatus;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrderStatusPayload = {
   __typename?: 'UpdateOrderStatusPayload';
  errors: Maybe<Array<MutationErrorType>>;
  order: Maybe<OrderNode>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type UpdateUserDataInput = {
  username?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserDataPayload = {
   __typename?: 'UpdateUserDataPayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  user: Maybe<UserNode>;
};


export type UploadAdvertImageInput = {
  image: Scalars['UploadType'];
  advert: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UploadAdvertImagePayload = {
   __typename?: 'UploadAdvertImagePayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  advertImage: Maybe<AdvertImageNode>;
};

export type UploadAuctionImageInput = {
  image: Scalars['UploadType'];
  auction: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UploadAuctionImagePayload = {
   __typename?: 'UploadAuctionImagePayload';
  errors: Maybe<Array<MutationErrorType>>;
  clientMutationId: Maybe<Scalars['String']>;
  auctionImage: Maybe<AuctionImageNode>;
};


export type UserDetailsNode = {
   __typename?: 'UserDetailsNode';
  info: Maybe<UserNode>;
  myAdverts: Maybe<AdvertNodeConnection>;
  myAdvertEvents: Maybe<Array<AdvertEventNode>>;
  myAuctions: Maybe<MyAuctionNodeConnection>;
  myOrders: Maybe<OrderNodeConnection>;
  myReceivedOrders: Maybe<OrderNodeConnection>;
  myRequestedOrders: Maybe<OrderNodeConnection>;
  myCompanies: Maybe<Array<Maybe<MyCompanyNode>>>;
  myOrderRatings: Maybe<OrderRatingNodeConnection>;
  myFavoriteAdverts: Maybe<AdvertNodeConnection>;
  myNotifications: Maybe<NotificationNodeConnection>;
  myAdvertCalendarItems: Maybe<AdvertCalendarItemNodeConnection>;
  myPayments: Maybe<Array<Maybe<StripePaymentNode>>>;
  myInvoices: Maybe<InvoiceNodeConnection>;
  myCompanyUsers: Maybe<UserNodeConnection>;
};


export type UserDetailsNodeMyAdvertsArgs = {
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  status: Maybe<AdvertStatus>;
  title: Maybe<Scalars['String']>;
  company: Maybe<Scalars['ID']>;
  address: Maybe<Scalars['String']>;
  price: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdAtGte: Maybe<Scalars['String']>;
  createdAtLte: Maybe<Scalars['String']>;
  advertIds: Maybe<Array<Maybe<Scalars['ID']>>>;
  searchBy: Maybe<Scalars['String']>;
  kind: Maybe<Scalars['String']>;
};


export type UserDetailsNodeMyAdvertEventsArgs = {
  advert: Scalars['ID'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type UserDetailsNodeMyAuctionsArgs = {
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  auctionType: Maybe<AuctionAuctionType>;
  company: Maybe<Scalars['ID']>;
  title: Maybe<Scalars['String']>;
  address: Maybe<Scalars['String']>;
  initialPrice: Maybe<Scalars['Int']>;
  slug: Maybe<Scalars['String']>;
  auctionId: Maybe<Scalars['String']>;
  status: Maybe<AuctionStatus>;
  expirationDate: Maybe<Scalars['DateTime']>;
};


export type UserDetailsNodeMyOrdersArgs = {
  orderType: Maybe<OrderType>;
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  company: Maybe<Scalars['ID']>;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  status: Maybe<OrderStatus>;
  advert: Maybe<Scalars['ID']>;
  createdAt: Maybe<Scalars['DateTime']>;
  totalPrice: Maybe<Scalars['Int']>;
  initialPrice: Maybe<Scalars['Int']>;
};


export type UserDetailsNodeMyReceivedOrdersArgs = {
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  company: Maybe<Scalars['ID']>;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  status: Maybe<OrderStatus>;
  advert: Maybe<Scalars['ID']>;
  createdAt: Maybe<Scalars['DateTime']>;
  totalPrice: Maybe<Scalars['Int']>;
  initialPrice: Maybe<Scalars['Int']>;
};


export type UserDetailsNodeMyRequestedOrdersArgs = {
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  company: Maybe<Scalars['ID']>;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  status: Maybe<OrderStatus>;
  advert: Maybe<Scalars['ID']>;
  createdAt: Maybe<Scalars['DateTime']>;
  totalPrice: Maybe<Scalars['Int']>;
  initialPrice: Maybe<Scalars['Int']>;
};


export type UserDetailsNodeMyOrderRatingsArgs = {
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
  order: Maybe<Scalars['ID']>;
  reportedCompany: Maybe<Scalars['ID']>;
  rating: Maybe<Scalars['Decimal']>;
  description: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
};


export type UserDetailsNodeMyFavoriteAdvertsArgs = {
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  status: Maybe<AdvertStatus>;
  title: Maybe<Scalars['String']>;
  company: Maybe<Scalars['ID']>;
  address: Maybe<Scalars['String']>;
  price: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdAtGte: Maybe<Scalars['String']>;
  createdAtLte: Maybe<Scalars['String']>;
  advertIds: Maybe<Array<Maybe<Scalars['ID']>>>;
  searchBy: Maybe<Scalars['String']>;
  kind: Maybe<Scalars['String']>;
};


export type UserDetailsNodeMyNotificationsArgs = {
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  unread: Maybe<Scalars['Boolean']>;
};


export type UserDetailsNodeMyAdvertCalendarItemsArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  itemTypeFilter: Maybe<AdvertCalendarItemTypeFilter>;
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type UserDetailsNodeMyInvoicesArgs = {
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type UserDetailsNodeMyCompanyUsersArgs = {
  orderby: Maybe<Array<Maybe<Scalars['String']>>>;
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  email: Maybe<Scalars['String']>;
};

export type UserNode = Node & {
   __typename?: 'UserNode';
  username: Scalars['String'];
  email: Scalars['String'];
  language: LanguageNode;
  companies: CompanyNodeConnection;
  id: Scalars['ID'];
  activeCompany: Maybe<MyCompanyNode>;
};


export type UserNodeCompaniesArgs = {
  offset: Maybe<Scalars['Int']>;
  before: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  companyType: Maybe<CompanyCompanyType>;
  size: Maybe<Scalars['Float']>;
  activity: Maybe<Scalars['ID']>;
  createdAt: Maybe<Scalars['DateTime']>;
};

export type UserNodeConnection = {
   __typename?: 'UserNodeConnection';
  pageInfo: PageInfo;
  edges: Array<Maybe<UserNodeEdge>>;
  totalCount: Maybe<Scalars['Int']>;
};

export type UserNodeEdge = {
   __typename?: 'UserNodeEdge';
  node: Maybe<UserNode>;
  cursor: Scalars['String'];
};


export type VerifyInput = {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type VerifyPayload = {
   __typename?: 'VerifyPayload';
  errors: Maybe<Array<MutationErrorType>>;
  payload: Scalars['GenericScalar'];
  clientMutationId: Maybe<Scalars['String']>;
};

export type VerifyUserInput = {
  activationKey: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type VerifyUserNode = {
   __typename?: 'VerifyUserNode';
  activationKey: Maybe<Scalars['String']>;
};

export type VerifyUserPayload = {
   __typename?: 'VerifyUserPayload';
  errors: Maybe<Array<MutationErrorType>>;
  activationKey: Maybe<Scalars['String']>;
  clientMutationId: Maybe<Scalars['String']>;
};

export type GetActivitiesQueryVariables = {};


export type GetActivitiesQuery = { __typename?: 'Query', activities: Maybe<{ __typename?: 'ActivityNodeConnection', edges: Array<Maybe<{ __typename?: 'ActivityNodeEdge', node: Maybe<{ __typename?: 'ActivityNode', id: string, name: string, code: string, sizeMeasureType: ActivitySizeMeasureType, orderNum: number }> }>> }> };

export type AdvertFragment = { __typename?: 'AdvertNode', customFieldsData: Maybe<any>, status: AdvertStatus, title: string, description: string, id: string, slug: string, advertType: AdvertType, pricingType: AdvertPricingType, serviceType: AdvertServiceType, isScheduled: boolean, isFavorited: boolean, mainImage: Maybe<string>, mainImageThumbnail: Maybe<string>, advertId: string, address: string, phone: Maybe<string>, deliveryType: DeliveryType, price: number, deliveryPrice: number, viewCount: number, validUntil: Maybe<any>, schedules: Maybe<Array<Maybe<{ __typename?: 'DateTimeRangeNode', startDate: any, endDate: any }>>>, company: (
    { __typename?: 'CompanyNode' }
    & CompanyFragment
  ), category: (
    { __typename?: 'CategoryNode' }
    & CategoryFragment
  ), images: Maybe<Array<Maybe<{ __typename?: 'AdvertImageNode', id: string, thumbnail: string, image: string }>>>, coordinates: Maybe<{ __typename?: 'CoordinateType', latitude: Maybe<number>, longitude: Maybe<number> }> };

export type AdvertErrorFragment = { __typename?: 'ImportAdvertsError', code: string, lineNumber: Maybe<number>, columnTitle: Maybe<string>, value: Maybe<string> };

export type AdvertEventMutationVariables = {
  input: AdvertEventMutationInput;
};


export type AdvertEventMutation = { __typename?: 'Mutation', advertEvent: Maybe<{ __typename?: 'AdvertEventMutationPayload', advertEvent: Maybe<{ __typename?: 'AdvertEventNode', id: string, title: string, description: string, startDate: any, endDate: any, advert: { __typename?: 'AdvertNode', id: string } }> }> };

export type AdvertLoanMutationVariables = {
  input: AdvertLoanMutationInput;
};


export type AdvertLoanMutation = { __typename?: 'Mutation', advertLoan: Maybe<{ __typename?: 'AdvertLoanMutationPayload', clientMutationId: Maybe<string> }> };

export type AdvertMutationVariables = {
  input: AdvertMutationInput;
};


export type AdvertMutation = { __typename?: 'Mutation', advert: Maybe<{ __typename?: 'AdvertMutationPayload', advert: Maybe<{ __typename?: 'AdvertNode', id: string, status: AdvertStatus, title: string }> }> };

export type AdvertViewCountMutationVariables = {
  input: IncrementAdvertViewCountInput;
};


export type AdvertViewCountMutation = { __typename?: 'Mutation', advertViewCount: Maybe<{ __typename?: 'IncrementAdvertViewCountPayload', advert: Maybe<{ __typename?: 'AdvertNode', id: string, viewCount: number }> }> };

export type DeleteAdvertEventMutationMutationVariables = {
  input: DeleteAdvertEventInput;
};


export type DeleteAdvertEventMutationMutation = { __typename?: 'Mutation', deleteAdvertEvent: Maybe<{ __typename?: 'DeleteAdvertEventPayload', clientMutationId: Maybe<string> }> };

export type DeleteAdvertImageMutationVariables = {
  input: DeleteAdvertImageInput;
};


export type DeleteAdvertImageMutation = { __typename?: 'Mutation', deleteAdvertImage: Maybe<{ __typename?: 'DeleteAdvertImagePayload', clientMutationId: Maybe<string> }> };

export type FavoriteAdvertMutationVariables = {
  input: FavoriteAdvertMutationInput;
};


export type FavoriteAdvertMutation = { __typename?: 'Mutation', favoriteAdvert: Maybe<{ __typename?: 'FavoriteAdvertMutationPayload', advert: Maybe<{ __typename?: 'AdvertNode', id: string, isFavorited: boolean }> }> };

export type ImportAdvertsMutationVariables = {
  csvFile: Scalars['Upload'];
  public: Scalars['Boolean'];
};


export type ImportAdvertsMutation = { __typename?: 'Mutation', importAdverts: Maybe<{ __typename?: 'ImportAdvertsFromCsvMutation', importedLines: Maybe<number>, errors: Maybe<Array<Maybe<(
      { __typename?: 'ImportAdvertsError' }
      & AdvertErrorFragment
    )>>> }> };

export type UpdateAdvertStatusMutationVariables = {
  input: UpdateAdvertStatusInput;
};


export type UpdateAdvertStatusMutation = { __typename?: 'Mutation', updateAdvertStatus: Maybe<{ __typename?: 'UpdateAdvertStatusPayload', advert: Maybe<{ __typename?: 'AdvertNode', id: string, status: AdvertStatus }> }> };

export type UploadAdvertImageMutationVariables = {
  input: UploadAdvertImageInput;
};


export type UploadAdvertImageMutation = { __typename?: 'Mutation', uploadAdvertImage: Maybe<{ __typename?: 'UploadAdvertImagePayload', advertImage: Maybe<{ __typename?: 'AdvertImageNode', image: string }> }> };

export type GetTopicalAdvertsQueryVariables = {
  amount: Scalars['Int'];
};


export type GetTopicalAdvertsQuery = { __typename?: 'Query', topicalAds: Maybe<Array<Maybe<(
    { __typename?: 'AdvertNode' }
    & AdvertFragment
  )>>> };

export type GetAdsByCategoryQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  categorySlug?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  priceGte?: Maybe<Scalars['Int']>;
  priceLte?: Maybe<Scalars['Int']>;
  advertType?: Maybe<Array<Maybe<AdvertType>>>;
  serviceType?: Maybe<Array<Maybe<AdvertServiceType>>>;
  searchBy?: Maybe<Scalars['String']>;
  geoSearch?: Maybe<Scalars['Coordinate']>;
};


export type GetAdsByCategoryQuery = { __typename?: 'Query', allAdverts: Maybe<{ __typename?: 'AdvertNodeConnection', totalCount: Maybe<number>, edges: Array<Maybe<{ __typename?: 'AdvertNodeEdge', node: Maybe<(
        { __typename?: 'AdvertNode' }
        & AdvertFragment
      )> }>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ) }> };

export type GetAdvertQueryVariables = {
  id: Scalars['ID'];
};


export type GetAdvertQuery = { __typename?: 'Query', advert: Maybe<(
    { __typename?: 'AdvertNode' }
    & AdvertFragment
  )> };

export type GetAdvertScheduleQueryVariables = {
  advert: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type GetAdvertScheduleQuery = { __typename?: 'Query', advertSchedule: Maybe<Array<Maybe<{ __typename?: 'DateTimeRangeNode', startDate: any, endDate: any }>>> };

export type GetCompanyAdsByIdQueryVariables = {
  id?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  advertIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type GetCompanyAdsByIdQuery = { __typename?: 'Query', allAdverts: Maybe<{ __typename?: 'AdvertNodeConnection', totalCount: Maybe<number>, edges: Array<Maybe<{ __typename?: 'AdvertNodeEdge', node: Maybe<(
        { __typename?: 'AdvertNode' }
        & AdvertFragment
      )> }>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ) }> };

export type GetFeaturedAdvertsQueryVariables = {
  amount: Scalars['Int'];
  advertIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type GetFeaturedAdvertsQuery = { __typename?: 'Query', featuredAds: Maybe<Array<Maybe<(
    { __typename?: 'AdvertNode' }
    & AdvertFragment
  )>>> };

export type GetFeaturedTabAdvertsQueryVariables = {
  tabFeaturedType: TabFeaturedType;
  kind?: Maybe<Scalars['String']>;
};


export type GetFeaturedTabAdvertsQuery = { __typename?: 'Query', tabFeaturedAds: Maybe<Array<Maybe<(
    { __typename?: 'AdvertNode' }
    & AdvertFragment
  )>>> };

export type GetMarketplaceAdsQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  priceGte?: Maybe<Scalars['Int']>;
  priceLte?: Maybe<Scalars['Int']>;
  pricingType?: Maybe<Array<Maybe<AdvertPricingType>>>;
  advertType?: Maybe<Array<Maybe<AdvertType>>>;
  serviceType?: Maybe<Array<Maybe<AdvertServiceType>>>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  searchBy?: Maybe<Scalars['String']>;
  geoSearch?: Maybe<Scalars['Coordinate']>;
  company?: Maybe<Scalars['ID']>;
  kind?: Maybe<Scalars['String']>;
};


export type GetMarketplaceAdsQuery = { __typename?: 'Query', allAdverts: Maybe<{ __typename?: 'AdvertNodeConnection', totalCount: Maybe<number>, edges: Array<Maybe<{ __typename?: 'AdvertNodeEdge', node: Maybe<(
        { __typename?: 'AdvertNode' }
        & AdvertFragment
      )> }>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ) }> };

export type GetMyAdvertScheduleQueryVariables = {
  advert: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type GetMyAdvertScheduleQuery = { __typename?: 'Query', myAdvertSchedule: Maybe<Array<{ __typename?: 'MyAdvertScheduleNode', startDate: any, endDate: any }>> };

export type GetMyAdvertAvailabilityQueryVariables = {
  advert: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  startDateTime: Scalars['DateTime'];
  endDateTime: Scalars['DateTime'];
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GetMyAdvertAvailabilityQuery = { __typename?: 'Query', myAdvertSchedule: Maybe<Array<{ __typename?: 'MyAdvertScheduleNode', startDate: any, endDate: any }>>, advertOrders: Maybe<Array<{ __typename?: 'OrderNode', id: string, startDate: Maybe<any>, endDate: Maybe<any>, advert: { __typename?: 'AdvertNode', id: string, title: string } }>>, me: Maybe<{ __typename?: 'UserDetailsNode', myAdvertEvents: Maybe<Array<{ __typename?: 'AdvertEventNode', id: string, startDate: any, endDate: any, title: string, description: string, advert: { __typename?: 'AdvertNode', id: string } }>> }> };

export type GetCompanyAdsQueryVariables = {
  status?: Maybe<AdvertStatus>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GetCompanyAdsQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myAdverts: Maybe<{ __typename?: 'AdvertNodeConnection', edges: Array<Maybe<{ __typename?: 'AdvertNodeEdge', cursor: string, node: Maybe<(
          { __typename?: 'AdvertNode' }
          & AdvertFragment
        )> }>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ) }> }> };

export type GetFavoriteAdsQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GetFavoriteAdsQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myFavoriteAdverts: Maybe<{ __typename?: 'AdvertNodeConnection', edges: Array<Maybe<{ __typename?: 'AdvertNodeEdge', cursor: string, node: Maybe<(
          { __typename?: 'AdvertNode', company: (
            { __typename?: 'CompanyNode' }
            & CompanyFragment
          ) }
          & AdvertFragment
        )> }>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ) }> }> };

export type GetRelatedAdvertsQueryVariables = {
  advertId: Scalars['ID'];
  amount: Scalars['Int'];
};


export type GetRelatedAdvertsQuery = { __typename?: 'Query', relatedAdverts: Maybe<Array<Maybe<(
    { __typename?: 'AdvertNode' }
    & AdvertFragment
  )>>> };

export type LoadAdForDisplayQueryVariables = {
  slug: Scalars['String'];
};


export type LoadAdForDisplayQuery = { __typename?: 'Query', advertBySlug: Maybe<(
    { __typename?: 'AdvertNode' }
    & AdvertFragment
  )> };

export type AuctionBidFragment = { __typename?: 'BidNode', id: string, createdAt: any, amount: number, company: string };

export type AuctionFragment = { __typename?: 'AuctionNode', id: string, auctionType: AuctionAuctionType, status: AuctionStatus, title: string, description: string, address: string, initialPrice: number, duration: number, mainImage: Maybe<string>, slug: string, totalBidCount: number, expirationDate: Maybe<any>, customFieldsData: Maybe<any>, mainImageThumbnail: Maybe<string>, coordinates: { __typename?: 'CoordinateType', latitude: Maybe<number>, longitude: Maybe<number> }, category: (
    { __typename?: 'CategoryNode' }
    & CategoryFragment
  ), lastBids: Maybe<Array<Maybe<(
    { __typename?: 'BidNode' }
    & AuctionBidFragment
  )>>>, company: (
    { __typename?: 'CompanyNode' }
    & CompanyFragment
  ), images: Maybe<Array<Maybe<(
    { __typename?: 'AuctionImageNode' }
    & AuctionImageFragment
  )>>> };

export type AuctionImageFragment = { __typename?: 'AuctionImageNode', id: string, thumbnail: string, image: string };

export type MyAuctionBidFragment = { __typename?: 'MyBidNode', id: string, createdAt: any, amount: number, company: (
    { __typename?: 'CompanyNode' }
    & CompanyFragment
  ) };

export type MyAuctionFragment = { __typename?: 'MyAuctionNode', id: string, isDeletable: boolean, winnerBid: Maybe<(
    { __typename?: 'MyBidNode' }
    & MyAuctionBidFragment
  )>, lastBids: Maybe<Array<Maybe<(
    { __typename?: 'MyBidNode' }
    & MyAuctionBidFragment
  )>>>, auction: Maybe<(
    { __typename?: 'AuctionNode' }
    & AuctionFragment
  )> };

export type CreateAuctionMutationVariables = {
  input: CreateAuctionMutationInput;
};


export type CreateAuctionMutation = { __typename?: 'Mutation', createAuction: Maybe<{ __typename?: 'CreateAuctionMutationPayload', auction: Maybe<{ __typename?: 'AuctionNode', id: string }> }> };

export type CreateAuctionBidMutationVariables = {
  input: CreateBidMutationInput;
};


export type CreateAuctionBidMutation = { __typename?: 'Mutation', createBid: Maybe<{ __typename?: 'CreateBidMutationPayload', bid: Maybe<{ __typename?: 'BidNode', id: string }> }> };

export type DeleteAuctionMutationVariables = {
  input: DeleteAuctionMutationInput;
};


export type DeleteAuctionMutation = { __typename?: 'Mutation', deleteAuction: Maybe<{ __typename?: 'DeleteAuctionMutationPayload', auction: Maybe<(
      { __typename?: 'AuctionNode' }
      & AuctionFragment
    )> }> };

export type DeleteAuctionImageMutationVariables = {
  input: DeleteAuctionImageInput;
};


export type DeleteAuctionImageMutation = { __typename?: 'Mutation', deleteAuctionImage: Maybe<{ __typename?: 'DeleteAuctionImagePayload', auctionImage: Maybe<{ __typename?: 'AuctionImageNode', image: string }> }> };

export type UpdateAuctionMutationVariables = {
  input: UpdateAuctionMutationInput;
};


export type UpdateAuctionMutation = { __typename?: 'Mutation', updateAuction: Maybe<{ __typename?: 'UpdateAuctionMutationPayload', auction: Maybe<(
      { __typename?: 'AuctionNode' }
      & AuctionFragment
    )> }> };

export type UploadAuctionImageMutationVariables = {
  input: UploadAuctionImageInput;
};


export type UploadAuctionImageMutation = { __typename?: 'Mutation', uploadAuctionImage: Maybe<{ __typename?: 'UploadAuctionImagePayload', auctionImage: Maybe<{ __typename?: 'AuctionImageNode', image: string }> }> };

export type GetAuctionBidsQueryVariables = {
  auction: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GetAuctionBidsQuery = { __typename?: 'Query', auctionBids: Maybe<{ __typename?: 'MyBidNodeConnection', totalCount: Maybe<number>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), edges: Array<Maybe<{ __typename?: 'MyBidNodeEdge', cursor: string, node: Maybe<(
        { __typename?: 'MyBidNode' }
        & MyAuctionBidFragment
      )> }>> }> };

export type GetAuctionBySlugQueryVariables = {
  slug: Scalars['String'];
};


export type GetAuctionBySlugQuery = { __typename?: 'Query', auctionBySlug: Maybe<(
    { __typename?: 'AuctionNode' }
    & AuctionFragment
  )> };

export type GetAuctionsQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterOnlyUserAttending?: Maybe<Scalars['Boolean']>;
};


export type GetAuctionsQuery = { __typename?: 'Query', auctions: Maybe<{ __typename?: 'AuctionNodeConnection', edges: Array<Maybe<{ __typename?: 'AuctionNodeEdge', cursor: string, node: Maybe<(
        { __typename?: 'AuctionNode' }
        & AuctionFragment
      )> }>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ) }> };

export type GetFeaturedAuctionsQueryVariables = {
  amount: Scalars['Int'];
};


export type GetFeaturedAuctionsQuery = { __typename?: 'Query', featuredAuctions: Maybe<Array<Maybe<(
    { __typename?: 'AuctionNode' }
    & AuctionFragment
  )>>> };

export type GetMyAuctionQueryVariables = {
  slug: Scalars['String'];
};


export type GetMyAuctionQuery = { __typename?: 'Query', myAuctionBySlug: Maybe<(
    { __typename?: 'MyAuctionNode' }
    & MyAuctionFragment
  )> };

export type GetMyAuctionsQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GetMyAuctionsQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myAuctions: Maybe<{ __typename?: 'MyAuctionNodeConnection', edges: Array<Maybe<{ __typename?: 'MyAuctionNodeEdge', cursor: string, node: Maybe<(
          { __typename?: 'MyAuctionNode' }
          & MyAuctionFragment
        )> }>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ) }> }> };

export type AcceptCompanyInvitationMutationVariables = {
  input: AcceptCompanyInvitationInput;
};


export type AcceptCompanyInvitationMutation = { __typename?: 'Mutation', acceptCompanyInvitation: Maybe<{ __typename?: 'AcceptCompanyInvitationPayload', clientMutationId: Maybe<string> }> };

export type AuthWithAppleMutationVariables = {
  input: AppleLoginInput;
};


export type AuthWithAppleMutation = { __typename?: 'Mutation', appleLogin: Maybe<{ __typename?: 'AppleLoginPayload', token: Maybe<string> }> };

export type AuthWithFbMutationVariables = {
  input: FacebookLoginInput;
};


export type AuthWithFbMutation = { __typename?: 'Mutation', facebookLogin: Maybe<{ __typename?: 'FacebookLoginPayload', token: Maybe<string> }> };

export type AuthWithGoogleMutationVariables = {
  input: GoogleLoginInput;
};


export type AuthWithGoogleMutation = { __typename?: 'Mutation', googleLogin: Maybe<{ __typename?: 'GoogleLoginPayload', token: Maybe<string> }> };

export type ForgotPasswordMutationVariables = {
  input: ForgotPasswordInput;
};


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: Maybe<{ __typename?: 'ForgotPasswordPayload', clientMutationId: Maybe<string> }> };

export type LoginQueryMutationVariables = {
  input: ObtainJsonWebTokenInput;
};


export type LoginQueryMutation = { __typename?: 'Mutation', tokenAuth: Maybe<{ __typename?: 'ObtainJSONWebTokenPayload', token: string }> };

export type RegisterUserMutationVariables = {
  input: RegisterUserInput;
};


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser: Maybe<{ __typename?: 'RegisterUserPayload', token: Maybe<string> }> };

export type ResetPasswordMutationVariables = {
  input: ResetPasswordInput;
};


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: Maybe<{ __typename?: 'ResetPasswordPayload', errors: Maybe<Array<{ __typename?: 'MutationErrorType', field: Maybe<string>, message: Maybe<string> }>> }> };

export type ChangePasswordMutationVariables = {
  input: ChangePasswordInput;
};


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: Maybe<{ __typename?: 'ChangePasswordPayload', errors: Maybe<Array<{ __typename?: 'MutationErrorType', field: Maybe<string>, message: Maybe<string> }>> }> };

export type VerifyUserMutationVariables = {
  input: VerifyUserInput;
};


export type VerifyUserMutation = { __typename?: 'Mutation', verifyUser: Maybe<{ __typename?: 'VerifyUserPayload', activationKey: Maybe<string> }> };

export type GetUserQueryVariables = {};


export type GetUserQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', info: Maybe<(
      { __typename?: 'UserNode' }
      & UserFragmentFragment
    )>, myCompanies: Maybe<Array<Maybe<(
      { __typename?: 'MyCompanyNode' }
      & MyCompanyFragment
    )>>> }> };

export type ValidateResetPasswordQueryVariables = {
  passwordKey: Scalars['String'];
};


export type ValidateResetPasswordQuery = { __typename?: 'Query', resetPassword: Maybe<{ __typename?: 'ResetPasswordNode', passwordKey: Maybe<string> }> };

export type VerifyInvitationKeyQueryVariables = {
  invitationKey: Scalars['String'];
};


export type VerifyInvitationKeyQuery = { __typename?: 'Query', verifyInvitationKey: Maybe<{ __typename?: 'InvitationKeyNode', invitationKey: any }> };

export type BankAccountFragment = { __typename?: 'BankAccountNode', id: string, iban: string, companyName: string, bankName: string };

export type MyCompanyBankAccountMutationVariables = {
  input: BankAccountMutationInput;
};


export type MyCompanyBankAccountMutation = { __typename?: 'Mutation', myCompanyBankAccount: Maybe<{ __typename?: 'BankAccountMutationPayload', bankAccount: Maybe<{ __typename?: 'BankAccountNode', id: string }> }> };

export type GetAdvertCalendarItemsQueryVariables = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  customEvents?: Maybe<Scalars['Boolean']>;
  orderStates?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type GetAdvertCalendarItemsQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myAdvertCalendarItems: Maybe<{ __typename?: 'AdvertCalendarItemNodeConnection', edges: Array<Maybe<{ __typename?: 'AdvertCalendarItemNodeEdge', cursor: string, node: Maybe<(
          { __typename?: 'AdvertCalendarItemNode' }
          & AdvertCalendarItemFragment
        )> }>>, pageInfo: { __typename?: 'PageInfo', startCursor: Maybe<string>, endCursor: Maybe<string>, hasNextPage: boolean, hasPreviousPage: boolean } }> }> };

export type AdvertCalendarItemFragment = { __typename?: 'AdvertCalendarItemNode', id: string, advert: { __typename?: 'AdvertNode', id: string, title: string }, events: Maybe<Array<Maybe<{ __typename?: 'AdvertEventNode', id: string, title: string, description: string, startDate: any, endDate: any }>>>, orders: Maybe<Array<Maybe<{ __typename?: 'OrderNode', id: string, status: OrderStatus, startDate: Maybe<any>, endDate: Maybe<any>, company: { __typename?: 'CompanyNode', id: string, name: string } }>>> };

export type ParentCategoryTreeFragment = { __typename?: 'CategoryNode', id: string, customFieldsSchema: any, name: Maybe<any>, orderNum: number, serviceType: CategoryServiceType, kind: CategoryKind, advertCount: Maybe<number>, icon: Maybe<string>, image: Maybe<string>, slug: string, loanCalculatorEnabled: Maybe<boolean>, marketPrices: Maybe<{ __typename?: 'MarketPriceQueries', marketPriceFrom: Maybe<number>, marketPriceTo: Maybe<number> }> };

export type CategoryFragment = { __typename?: 'CategoryNode', id: string, customFieldsSchema: any, name: Maybe<any>, orderNum: number, serviceType: CategoryServiceType, kind: CategoryKind, advertCount: Maybe<number>, icon: Maybe<string>, image: Maybe<string>, slug: string, loanCalculatorEnabled: Maybe<boolean>, parent: Maybe<(
    { __typename?: 'CategoryNode' }
    & ParentCategoryTreeFragment
  )>, marketPrices: Maybe<{ __typename?: 'MarketPriceQueries', marketPriceFrom: Maybe<number>, marketPriceTo: Maybe<number> }> };

export type ParentCategoryFragment = { __typename?: 'CategoryNode', id: string, name: Maybe<any>, orderNum: number, serviceType: CategoryServiceType, kind: CategoryKind, icon: Maybe<string>, image: Maybe<string>, slug: string };

export type GetCategoriesQueryVariables = {
  id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type GetCategoriesQuery = { __typename?: 'Query', categories: Maybe<Array<(
    { __typename?: 'CategoryNode' }
    & CategoryFragment
  )>> };

export type GetCategoryTitleBySlugQueryVariables = {
  slug?: Maybe<Scalars['String']>;
};


export type GetCategoryTitleBySlugQuery = { __typename?: 'Query', categories: Maybe<Array<{ __typename?: 'CategoryNode', name: Maybe<any> }>> };

export type OrderMessageFragment = { __typename?: 'ChatMessageNode', id: string, message: string, createdAt: any, attachedFile: Maybe<string>, attachedFileOriginalName: string, senderCompany: { __typename?: 'CompanyNode', id: string, name: string }, order: (
    { __typename?: 'OrderNode' }
    & OrderFragment
  ) };

export type AddOrderChatMessageFileMutationVariables = {
  input: ChatMessageFileUploadMutationInput;
};


export type AddOrderChatMessageFileMutation = { __typename?: 'Mutation', chatMessageFileUpload: Maybe<{ __typename?: 'ChatMessageFileUploadMutationPayload', chatMessage: Maybe<(
      { __typename?: 'ChatMessageNode' }
      & OrderMessageFragment
    )> }> };

export type AddOrderMessageMutationVariables = {
  input: ChatMessageMutationInput;
};


export type AddOrderMessageMutation = { __typename?: 'Mutation', chatMessage: Maybe<{ __typename?: 'ChatMessageMutationPayload', chatMessage: Maybe<(
      { __typename?: 'ChatMessageNode' }
      & OrderMessageFragment
    )> }> };

export type GetOrderMessagesQueryVariables = {
  orderId: Scalars['ID'];
};


export type GetOrderMessagesQuery = { __typename?: 'Query', chatMessages: Maybe<Array<Maybe<(
    { __typename?: 'ChatMessageNode' }
    & OrderMessageFragment
  )>>> };

export type OnChatMessageAddedSubscriptionVariables = {
  input: ChatMessageSubscriptionInput;
};


export type OnChatMessageAddedSubscription = { __typename?: 'Subscription', chatMessageCreated: Maybe<(
    { __typename?: 'ChatMessageNode' }
    & OrderMessageFragment
  )> };

export type CompanyFragment = { __typename?: 'CompanyNode', id: string, name: string, logo: Maybe<string>, logoThumbnail: Maybe<string>, companyType: CompanyCompanyType, createdAt: any, phone: Maybe<string>, websiteUrl: string, size: Maybe<number>, overallRating: number, slug: string, availablePaymentTypes: Array<Maybe<PaymentType>>, activity: Maybe<{ __typename?: 'ActivityNode', id: string, name: string }>, invoicingInfo: Maybe<(
    { __typename?: 'InvoicingInfoNode' }
    & InvoiceFragment
  )>, bankAccount: Maybe<(
    { __typename?: 'BankAccountNode' }
    & BankAccountFragment
  )> };

export type MyCompanyFragment = { __typename?: 'MyCompanyNode', id: string, unreadNotificationsCount: number, isActive: boolean, inPrivateGroup: boolean, company: Maybe<(
    { __typename?: 'CompanyNode' }
    & CompanyFragment
  )> };

export type CreateCompanyMutationVariables = {
  input: CreateCompanyMutationInput;
};


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: Maybe<{ __typename?: 'CreateCompanyMutationPayload', company: Maybe<{ __typename?: 'CompanyNode', id: string }> }> };

export type InviteUserToMyCompanyMutationVariables = {
  input: InviteUserToCompanyMutationInput;
};


export type InviteUserToMyCompanyMutation = { __typename?: 'Mutation', inviteUserToMyCompany: Maybe<{ __typename?: 'InviteUserToCompanyMutationPayload', invitationKey: Maybe<{ __typename?: 'InvitationKeyNode', email: string }> }> };

export type CompanyMutationVariables = {
  input: CompanyMutationInput;
};


export type CompanyMutation = { __typename?: 'Mutation', company: Maybe<{ __typename?: 'CompanyMutationPayload', company: Maybe<{ __typename?: 'CompanyNode', id: string }> }> };

export type GetCompanyBySlugQueryVariables = {
  slug: Scalars['String'];
};


export type GetCompanyBySlugQuery = { __typename?: 'Query', companyBySlug: Maybe<(
    { __typename?: 'CompanyNode' }
    & CompanyFragment
  )> };

export type GetCompanyInfoQueryVariables = {
  id: Scalars['ID'];
};


export type GetCompanyInfoQuery = { __typename?: 'Query', company: Maybe<(
    { __typename?: 'CompanyNode' }
    & CompanyFragment
  )> };

export type GetCompanyReviewsQueryVariables = {};


export type GetCompanyReviewsQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myOrderRatings: Maybe<{ __typename?: 'OrderRatingNodeConnection', totalCount: Maybe<number>, edges: Array<Maybe<{ __typename?: 'OrderRatingNodeEdge', cursor: string, node: Maybe<(
          { __typename?: 'OrderRatingNode' }
          & OrderRatingFragment
        )> }>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ) }> }> };

export type GetCompanyReviewsByIdQueryVariables = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};


export type GetCompanyReviewsByIdQuery = { __typename?: 'Query', orderRatings: Maybe<{ __typename?: 'OrderRatingNodeConnection', totalCount: Maybe<number>, edges: Array<Maybe<{ __typename?: 'OrderRatingNodeEdge', cursor: string, node: Maybe<(
        { __typename?: 'OrderRatingNode' }
        & OrderRatingFragment
      )> }>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ) }> };

export type GetMyCompanyUsersQueryVariables = {};


export type GetMyCompanyUsersQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myCompanyUsers: Maybe<{ __typename?: 'UserNodeConnection', totalCount: Maybe<number>, edges: Array<Maybe<{ __typename?: 'UserNodeEdge', node: Maybe<{ __typename?: 'UserNode', email: string }> }>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ) }> }> };

export type AppConfigFragment = { __typename?: 'AppConfigViewNode', version: Maybe<string>, facebookAppId: Maybe<string>, googleClientId: Maybe<string>, appleClientId: Maybe<string>, googleTagManagerId: Maybe<string>, appleRedirectUri: string, enabledLanguages: Maybe<any>, defaultLanguage: Maybe<any>, contact: Maybe<any>, rentPage: Maybe<any>, aboutUs: Maybe<any>, terms: Maybe<any>, privacy: Maybe<any>, isAuctionEnabled: Maybe<boolean>, isPaymentEnabled: Maybe<boolean>, isOldOrderFormEnabled: Maybe<boolean>, isForumEnabled: Maybe<boolean>, advertLoanInterestRate: Maybe<any>, maxAdvertLoanAmount: Maybe<number>, maxAdvertLoanDuration: Maybe<number>, stripePublishableKey: Maybe<string>, mainLogo: Maybe<string>, parentCategories: Maybe<Array<(
    { __typename?: 'CategoryNode' }
    & ParentCategoryFragment
  )>>, predefinedSearch: Maybe<Array<Maybe<(
    { __typename?: 'PredefinedSearchNode' }
    & PredefinedSearchFragment
  )>>>, landingPageImages: Maybe<Array<Maybe<(
    { __typename?: 'LandingPageImageNode' }
    & LandingPageImagesFragment
  )>>>, landingPageBanners: Maybe<Array<Maybe<(
    { __typename?: 'LandingPageBannerNode' }
    & LandingPageBannersFragment
  )>>> };

export type LandingPageBannersFragment = { __typename?: 'LandingPageBannerNode', id: string, name: string, title: Maybe<string>, description: Maybe<string>, buttonUrl: Maybe<string>, buttonText: Maybe<string>, imageWeb: Maybe<string>, imageMobile: Maybe<string>, kind: Maybe<string> };

export type LandingPageImagesFragment = { __typename?: 'LandingPageImageNode', imageWeb: Maybe<string>, imageMobile: Maybe<string>, text: Maybe<string>, title: Maybe<string>, navigationTitle: Maybe<string>, url: string };

export type LanguageFragment = { __typename?: 'LanguageNode', id: string, code: string, name: string, flag: string };

export type PredefinedSearchFragment = { __typename?: 'PredefinedSearchNode', id: string, name: string, url: string, translation: Maybe<(
    { __typename?: 'PredefinedSearchTranslationNode' }
    & PredefinedSearchTranslationFragment
  )> };

export type PredefinedSearchTranslationFragment = { __typename?: 'PredefinedSearchTranslationNode', id: string, title: string, description: string, language: (
    { __typename?: 'LanguageNode' }
    & LanguageFragment
  ) };

export type TerrainTypeFragment = { __typename?: 'TerrainTypeNode', id: string, name: Maybe<any> };

export type GetAppConfigQueryVariables = {
  lang: Scalars['String'];
  adAmount: Scalars['Int'];
  auctionAmount: Scalars['Int'];
};


export type GetAppConfigQuery = { __typename?: 'Query', appConfig: Maybe<(
    { __typename?: 'AppConfigViewNode' }
    & AppConfigFragment
  )> };

export type GetGeneralOrderClassifiersQueryVariables = {
  language?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type GetGeneralOrderClassifiersQuery = { __typename?: 'Query', appConfig: Maybe<{ __typename?: 'AppConfigViewNode', generalOrderCategories: Maybe<Array<(
      { __typename?: 'CategoryNode' }
      & CategoryFragment
    )>>, terrainTypes: Maybe<Array<(
      { __typename?: 'TerrainTypeNode' }
      & TerrainTypeFragment
    )>> }> };

export type ContractorInputFragment = { __typename?: 'GeneralOrderContractorType', additionalInfo: string, address: string, createdAt: any, email: string, name: string, phone: string, surname: string, workingArea: number, categories: Maybe<Array<Maybe<{ __typename?: 'CategoryNode', id: string }>>>, categoriesDetails: Array<Maybe<{ __typename?: 'GeneralContractorCategoriesDetailsType', id: string, value: string, price: { __typename?: 'GeneralContractorCategoryPriceType', type: GeneralContractorPriceType, value: number } }>>, coordinates: { __typename?: 'CoordinateType', latitude: Maybe<number>, longitude: Maybe<number> } };

export type CreateContractorMutationVariables = {
  input: CreateContractorMutationInput;
};


export type CreateContractorMutation = { __typename?: 'Mutation', createContractor: Maybe<{ __typename?: 'CreateContractorMutationPayload', generalOrderContractor: Maybe<(
      { __typename?: 'GeneralOrderContractorType' }
      & ContractorInputFragment
    )> }> };

export type GetExpertsQueryVariables = {};


export type GetExpertsQuery = { __typename?: 'Query', experts: Maybe<{ __typename?: 'ForumAuthorNodeConnection', pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), edges: Array<Maybe<{ __typename?: 'ForumAuthorNodeEdge', node: Maybe<{ __typename?: 'ForumAuthorNode', id: string, name: string, image: Maybe<string>, thumbnail: Maybe<string>, expertDescription: string, isExpert: boolean }> }>> }> };

export type FaqCategoryFragment = { __typename?: 'FAQCategoryNode', name: string, orderNum: number, icon: string };

export type GetFaqQueryVariables = {
  language: Scalars['String'];
};


export type GetFaqQuery = { __typename?: 'Query', faqs: Maybe<Array<Maybe<{ __typename?: 'FAQNode', category: (
      { __typename?: 'FAQCategoryNode' }
      & FaqCategoryFragment
    ), items: Maybe<Array<Maybe<{ __typename?: 'FAQItemNode', title: string, text: string, orderNum: number }>>> }>>> };

export type ForumCategoriesFragment = { __typename?: 'ForumCategoryWithForumNode', id: string, title: string, orderNum: number, forums: Maybe<Array<Maybe<(
    { __typename?: 'ForumNode' }
    & ForumFragmentFragment
  )>>> };

export type ForumFragmentFragment = { __typename?: 'ForumNode', id: string, title: string, slug: string, threadCount: number, description: string, lastPost: Maybe<{ __typename?: 'ForumReplyNode', id: string, content: string, createdAt: any, author: { __typename?: 'ForumAuthorNode', id: string, name: string } }> };

export type ForumInfoFragmentFragment = { __typename?: 'ForumNode', id: string, title: string, slug: string, description: string, category: Maybe<{ __typename?: 'ForumCategoryNode', title: string, id: string, orderNum: number }> };

export type ForumReplyNodeFragmentFragment = { __typename?: 'ForumReplyNode', id: string, content: string, createdAt: any, author: { __typename?: 'ForumAuthorNode', name: string, id: string } };

export type ForumThreadFragment = { __typename?: 'ForumThreadNode', createdAt: any, content: string, slug: string, lastModified: any, replyCount: number, id: string, title: string, lastReply: Maybe<{ __typename?: 'ForumReplyNode', content: string, id: string, createdAt: any, author: { __typename?: 'ForumAuthorNode', name: string, id: string } }>, author: { __typename?: 'ForumAuthorNode', name: string, id: string, isExpert: boolean, expertDescription: string }, forum: (
    { __typename?: 'ForumNode' }
    & ForumInfoFragmentFragment
  ), images: Array<Maybe<{ __typename?: 'ThreadImageNode', id: string, image: string, thumbnail: string }>> };

export type ForumThreadListFragmentFragment = { __typename?: 'ForumThreadNode', createdAt: any, content: string, id: string, title: string, slug: string, lastModified: any, replyCount: number, author: { __typename?: 'ForumAuthorNode', id: string, name: string }, lastReply: Maybe<{ __typename?: 'ForumReplyNode', id: string, createdAt: any, content: string, author: { __typename?: 'ForumAuthorNode', id: string, name: string } }> };

export type ForumThreadReplyFragment = { __typename?: 'ForumReplyNode', createdAt: any, content: string, id: string, author: { __typename?: 'ForumAuthorNode', name: string, id: string, isExpert: boolean, expertDescription: string }, replyTo: Maybe<(
    { __typename?: 'ForumReplyNode' }
    & ForumReplyNodeFragmentFragment
  )>, images: Array<Maybe<{ __typename?: 'ReplyImageNode', id: string, image: string, thumbnail: string }>> };

export type CreateForumThreadMutationVariables = {
  input: ForumThreadMutationInput;
};


export type CreateForumThreadMutation = { __typename?: 'Mutation', createForumThread: Maybe<{ __typename?: 'ForumThreadMutationPayload', forumThread: Maybe<{ __typename?: 'ForumThreadNode', id: string, slug: string }> }> };

export type CreateThreadReplyMutationVariables = {
  input: ForumReplyMutationInput;
};


export type CreateThreadReplyMutation = { __typename?: 'Mutation', createForumReply: Maybe<{ __typename?: 'ForumReplyMutationPayload', forumReply: Maybe<(
      { __typename?: 'ForumReplyNode' }
      & ForumThreadReplyFragment
    )> }> };

export type UploadReplyImageMutationVariables = {
  input: ReplyImageMutationInput;
};


export type UploadReplyImageMutation = { __typename?: 'Mutation', uploadReplyImage: Maybe<{ __typename?: 'ReplyImageMutationPayload', forumReplyImage: Maybe<{ __typename?: 'ReplyImageNode', thumbnail: string, image: string, id: string }> }> };

export type UploadThreadImageMutationVariables = {
  input: ThreadImageMutationInput;
};


export type UploadThreadImageMutation = { __typename?: 'Mutation', uploadThreadImage: Maybe<{ __typename?: 'ThreadImageMutationPayload', forumThreadImage: Maybe<{ __typename?: 'ThreadImageNode', thumbnail: string, image: string, id: string }> }> };

export type ForumQueryVariables = {
  slug: Scalars['String'];
};


export type ForumQuery = { __typename?: 'Query', forum: Maybe<(
    { __typename?: 'ForumNode' }
    & ForumInfoFragmentFragment
  )> };

export type GetForumCategoriesQueryVariables = {};


export type GetForumCategoriesQuery = { __typename?: 'Query', forumCategories: Maybe<Array<Maybe<(
    { __typename?: 'ForumCategoryWithForumNode' }
    & ForumCategoriesFragment
  )>>> };

export type GetForumThreadQueryVariables = {
  slug: Scalars['String'];
};


export type GetForumThreadQuery = { __typename?: 'Query', forumThread: Maybe<(
    { __typename?: 'ForumThreadNode' }
    & ForumThreadFragment
  )> };

export type ForumThreadsQueryVariables = {
  forum: Scalars['ID'];
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type ForumThreadsQuery = { __typename?: 'Query', forumThreads: Maybe<{ __typename?: 'ForumThreadNodeConnection', totalCount: Maybe<number>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), edges: Array<Maybe<{ __typename?: 'ForumThreadNodeEdge', node: Maybe<(
        { __typename?: 'ForumThreadNode' }
        & ForumThreadListFragmentFragment
      )> }>> }> };

export type ForumThreadRepliesQueryVariables = {
  thread: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};


export type ForumThreadRepliesQuery = { __typename?: 'Query', forumThreadReplies: Maybe<{ __typename?: 'ForumReplyNodeConnection', totalCount: Maybe<number>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ), edges: Array<Maybe<{ __typename?: 'ForumReplyNodeEdge', node: Maybe<(
        { __typename?: 'ForumReplyNode' }
        & ForumThreadReplyFragment
      )> }>> }> };

export type GeneralOrderFragment = { __typename?: 'GeneralOrderType', id: string, amount: any, amountType: GeneralOrderAmountType, amountParts: Array<any>, paymentCondition: GeneralOrderPaymentCondition, name: string, surname: string, phone: string, email: string, address: string, additionalInfo: string, totalPrice: number, updatedAt: any, createdAt: any, category: (
    { __typename?: 'CategoryNode' }
    & CategoryFragment
  ), coordinates: { __typename?: 'CoordinateType', latitude: Maybe<number>, longitude: Maybe<number> } };

export type GeneralOrderFetchResultFragment = { __typename?: 'GeneralOrderNode', id: string, amount: any, amountType: GeneralOrderAmountType, amountParts: Array<any>, paymentCondition: GeneralOrderPaymentCondition, totalPrice: number, category: (
    { __typename?: 'CategoryNode' }
    & CategoryFragment
  ), coordinates: Maybe<{ __typename?: 'CoordinateType', latitude: Maybe<number>, longitude: Maybe<number> }>, terrainType: Maybe<{ __typename?: 'TerrainTypeNode', id: string, name: Maybe<any> }> };

export type CreateGeneralOrderMutationVariables = {
  input: CreateGeneralOrderMutationInput;
};


export type CreateGeneralOrderMutation = { __typename?: 'Mutation', createGeneralOrder: Maybe<{ __typename?: 'CreateGeneralOrderMutationPayload', generalOrder: Maybe<(
      { __typename?: 'GeneralOrderType' }
      & GeneralOrderFragment
    )> }> };

export type GetGeneralOrderQueryVariables = {
  id: Scalars['ID'];
};


export type GetGeneralOrderQuery = { __typename?: 'Query', generalOrder: Maybe<(
    { __typename?: 'GeneralOrderNode' }
    & GeneralOrderFetchResultFragment
  )> };

export type GeneralOfferInputFragment = { __typename?: 'GeneralOfferType', email: string, phone: string, totalPrice: number, paymentCondition: GeneralOfferPaymentCondition, generalOrder: Maybe<{ __typename?: 'GeneralOrderType', id: string }> };

export type CreateGeneralOfferMutationVariables = {
  input: CreateGeneralOfferMutationInput;
};


export type CreateGeneralOfferMutation = { __typename?: 'Mutation', createGeneralOffer: Maybe<{ __typename?: 'CreateGeneralOfferMutationPayload', generalOffer: Maybe<(
      { __typename?: 'GeneralOfferType' }
      & GeneralOfferInputFragment
    )> }> };

export type InvoiceFragment = { __typename?: 'InvoicingInfoNode', id: string, name: string, address: string, vatCode: string, companyCode: string };

export type MyInvoiceFragment = { __typename?: 'InvoiceNode', id: string, number: string, downloadUrl: string, createdAt: any };

export type MyCompanyInvoicingInfoMutationVariables = {
  input: InvoicingInfoMutationInput;
};


export type MyCompanyInvoicingInfoMutation = { __typename?: 'Mutation', myCompanyInvoicingInfo: Maybe<{ __typename?: 'InvoicingInfoMutationPayload', invoicingInfo: Maybe<{ __typename?: 'InvoicingInfoNode', id: string }> }> };

export type GetInvoiceQueryVariables = {};


export type GetInvoiceQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myCompanies: Maybe<Array<Maybe<{ __typename?: 'MyCompanyNode', company: Maybe<{ __typename?: 'CompanyNode', invoicingInfo: Maybe<(
          { __typename?: 'InvoicingInfoNode' }
          & InvoiceFragment
        )> }> }>>> }> };

export type GetMyInvoicesQueryVariables = {};


export type GetMyInvoicesQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myInvoices: Maybe<{ __typename?: 'InvoiceNodeConnection', edges: Array<Maybe<{ __typename?: 'InvoiceNodeEdge', node: Maybe<(
          { __typename?: 'InvoiceNode' }
          & MyInvoiceFragment
        )> }>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ) }> }> };

export type ChangeLanguageMutationVariables = {
  input: ChangeLanguageInput;
};


export type ChangeLanguageMutation = { __typename?: 'Mutation', changeLanguage: Maybe<{ __typename?: 'ChangeLanguagePayload', user: string, language: { __typename?: 'LanguageNode', id: string, code: string } }> };

export type NotificationFragment = { __typename?: 'NotificationNode', id: string, level: NotificationLevel, unread: boolean, verb: NotificationVerbs, description: Maybe<string>, timestamp: any, relatedObjectSlug: Maybe<string>, relatedObjectGraphqlId: Maybe<string> };

export type MarkReadMutationVariables = {
  input: ReadNotificationMutationInput;
};


export type MarkReadMutation = { __typename?: 'Mutation', markRead: Maybe<{ __typename?: 'ReadNotificationMutationPayload', notification: Maybe<{ __typename?: 'NotificationNode', description: Maybe<string>, unread: boolean }> }> };

export type GetNotificationsQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};


export type GetNotificationsQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myNotifications: Maybe<{ __typename?: 'NotificationNodeConnection', edges: Array<Maybe<{ __typename?: 'NotificationNodeEdge', cursor: string, node: Maybe<(
          { __typename?: 'NotificationNode' }
          & NotificationFragment
        )> }>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ) }> }> };

export type GetUnreadNotificationsCountQueryVariables = {};


export type GetUnreadNotificationsCountQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myNotifications: Maybe<{ __typename?: 'NotificationNodeConnection', totalCount: Maybe<number> }> }> };

export type OrderFragment = { __typename?: 'OrderNode', id: string, createdAt: any, updatedAt: any, totalPrice: number, initialPrice: number, initialDeliveryPrice: number, initialPricingType: AdvertPricingType, startDate: Maybe<any>, endDate: Maybe<any>, status: OrderStatus, description: string, orderType: OrderType, phone: Maybe<string>, amount: Maybe<number>, paymentType: string, orderId: string, includeDelivery: boolean, unreadNotificationCount: number, company: (
    { __typename?: 'CompanyNode' }
    & CompanyFragment
  ), advert: (
    { __typename?: 'AdvertNode' }
    & AdvertFragment
  ) };

export type OrderRatingFragment = { __typename?: 'OrderRatingNode', id: string, createdAt: any, rating: any, description: string, order: { __typename?: 'OrderNode', id: string, advert: { __typename?: 'AdvertNode', id: string, advertType: AdvertType, company: { __typename?: 'CompanyNode', id: string, name: string } }, company: { __typename?: 'CompanyNode', id: string, name: string } }, reportedCompany: { __typename?: 'CompanyNode', id: string, name: string } };

export type CreateOrderMutationMutationVariables = {
  input: CreateOrderMutationInput;
};


export type CreateOrderMutationMutation = { __typename?: 'Mutation', createOrder: Maybe<{ __typename?: 'CreateOrderMutationPayload', order: Maybe<(
      { __typename?: 'OrderNode' }
      & OrderFragment
    )> }> };

export type CreateOrderRatingMutationVariables = {
  input: OrderRatingMutationInput;
};


export type CreateOrderRatingMutation = { __typename?: 'Mutation', orderRating: Maybe<{ __typename?: 'OrderRatingMutationPayload', orderRating: Maybe<(
      { __typename?: 'OrderRatingNode' }
      & OrderRatingFragment
    )> }> };

export type MarkReadOrderNotificationsMutationVariables = {
  input: ReadOrderNotificationsMutationInput;
};


export type MarkReadOrderNotificationsMutation = { __typename?: 'Mutation', markReadOrderNotifications: Maybe<{ __typename?: 'ReadOrderNotificationsMutationPayload', order: Maybe<{ __typename?: 'OrderNode', id: string }> }> };

export type UpdateOrderMutationMutationVariables = {
  input: UpdateOrderMutationInput;
};


export type UpdateOrderMutationMutation = { __typename?: 'Mutation', updateOrder: Maybe<{ __typename?: 'UpdateOrderMutationPayload', order: Maybe<(
      { __typename?: 'OrderNode' }
      & OrderFragment
    )> }> };

export type UpdateOrderStatusMutationVariables = {
  input: UpdateOrderStatusInput;
};


export type UpdateOrderStatusMutation = { __typename?: 'Mutation', updateOrderStatus: Maybe<{ __typename?: 'UpdateOrderStatusPayload', order: Maybe<{ __typename?: 'OrderNode', id: string, status: OrderStatus }> }> };

export type MyOrdersQueryVariables = {
  status?: Maybe<OrderStatus>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderType?: Maybe<OrderType>;
};


export type MyOrdersQuery = { __typename?: 'Query', me: Maybe<{ __typename?: 'UserDetailsNode', myOrders: Maybe<{ __typename?: 'OrderNodeConnection', edges: Array<Maybe<{ __typename?: 'OrderNodeEdge', node: Maybe<(
          { __typename?: 'OrderNode' }
          & OrderFragment
        )> }>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & PageInfoFragment
      ) }> }> };

export type GetOrderInfoQueryVariables = {
  id: Scalars['ID'];
};


export type GetOrderInfoQuery = { __typename?: 'Query', order: Maybe<(
    { __typename?: 'OrderNode' }
    & OrderFragment
  )> };

export type GetOrderRatingQueryVariables = {
  id: Scalars['ID'];
};


export type GetOrderRatingQuery = { __typename?: 'Query', orderRating: Maybe<(
    { __typename?: 'OrderRatingNode' }
    & OrderRatingFragment
  )> };

export type StripeCardFragment = { __typename?: 'StripeCardNode', id: string, gateway: string, paymentMethodId: string, last4: string };

export type AddCardMutationVariables = {
  input: AddCardInput;
};


export type AddCardMutation = { __typename?: 'Mutation', addCard: Maybe<{ __typename?: 'AddCardPayload', stripeCard: Maybe<(
      { __typename?: 'StripeCardNode' }
      & StripeCardFragment
    )> }> };

export type CreateStripePaymentMutationVariables = {
  input: CreateStripePaymentInput;
};


export type CreateStripePaymentMutation = { __typename?: 'Mutation', createStripePayment: Maybe<{ __typename?: 'CreateStripePaymentPayload', clientSecret: Maybe<string>, clientMutationId: Maybe<string>, stripePayment: Maybe<{ __typename?: 'StripePaymentNode', status: StripePaymentStatus, order: { __typename?: 'OrderNode', id: string } }> }> };

export type FetchPaymentSuccessStatusMutationVariables = {
  input: StripePaymentSuccessStatusInput;
};


export type FetchPaymentSuccessStatusMutation = { __typename?: 'Mutation', fetchPaymentSuccessStatus: Maybe<{ __typename?: 'StripePaymentSuccessStatusPayload', order: Maybe<(
      { __typename?: 'OrderNode' }
      & OrderFragment
    )> }> };

export type RemoveCardMutationVariables = {
  input: RemoveCardInput;
};


export type RemoveCardMutation = { __typename?: 'Mutation', removeCard: Maybe<{ __typename?: 'RemoveCardPayload', stripeCard: Maybe<{ __typename?: 'StripeCardNode', id: string }> }> };

export type GetMyCardsQueryVariables = {
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};


export type GetMyCardsQuery = { __typename?: 'Query', myCards: Maybe<{ __typename?: 'StripeCardNodeConnection', edges: Array<Maybe<{ __typename?: 'StripeCardNodeEdge', node: Maybe<(
        { __typename?: 'StripeCardNode' }
        & StripeCardFragment
      )> }>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & PageInfoFragment
    ) }> };

export type PreliminaryOrderFragment = { __typename?: 'PreliminaryOrderNode', startDate: any, status: PreliminaryOrderStatus, description: string, orderId: string, contactViaPlatform: boolean, contactViaEmail: boolean, contactViaPhone: boolean, email: string, phone: string, id: string, user: Maybe<(
    { __typename?: 'UserNode' }
    & UserFragmentFragment
  )>, advert: Maybe<(
    { __typename?: 'AdvertNode' }
    & AdvertFragment
  )> };

export type CreatePreliminaryOrderMutationMutationVariables = {
  input: CreatePreliminaryOrderMutationInput;
};


export type CreatePreliminaryOrderMutationMutation = { __typename?: 'Mutation', createPreliminaryOrder: Maybe<{ __typename?: 'CreatePreliminaryOrderMutationPayload', preliminaryOrder: Maybe<(
      { __typename?: 'PreliminaryOrderNode' }
      & PreliminaryOrderFragment
    )> }> };

export type PageInfoFragment = { __typename?: 'PageInfo', startCursor: Maybe<string>, endCursor: Maybe<string>, hasNextPage: boolean, hasPreviousPage: boolean };

export type GetStaticPageQueryVariables = {
  slug: Scalars['String'];
};


export type GetStaticPageQuery = { __typename?: 'Query', pageTranslation: Maybe<{ __typename?: 'PageTranslationNode', slug: string, text: string, title: string }> };

export type UserFragmentFragment = { __typename?: 'UserNode', id: string, email: string, username: string, activeCompany: Maybe<(
    { __typename?: 'MyCompanyNode' }
    & MyCompanyFragment
  )> };

export type ChangeActiveCompanyMutationVariables = {
  input: ChangeActiveCompanyInput;
};


export type ChangeActiveCompanyMutation = { __typename?: 'Mutation', changeActiveCompany: Maybe<{ __typename?: 'ChangeActiveCompanyPayload', user: Maybe<(
      { __typename?: 'UserNode' }
      & UserFragmentFragment
    )> }> };

export type DeleteUserMutationVariables = {
  input: DeleteUserInput;
};


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: Maybe<{ __typename?: 'DeleteUserPayload', clientMutationId: Maybe<string> }> };

export type UpdateUserDataMutationVariables = {
  input: UpdateUserDataInput;
};


export type UpdateUserDataMutation = { __typename?: 'Mutation', updateUserData: Maybe<{ __typename?: 'UpdateUserDataPayload', user: Maybe<(
      { __typename?: 'UserNode' }
      & UserFragmentFragment
    )> }> };

export const AdvertErrorFragmentDoc = gql`
    fragment AdvertError on ImportAdvertsError {
  code
  lineNumber
  columnTitle
  value
}
    `;
export const InvoiceFragmentDoc = gql`
    fragment Invoice on InvoicingInfoNode {
  id
  name
  address
  vatCode
  companyCode
}
    `;
export const BankAccountFragmentDoc = gql`
    fragment BankAccount on BankAccountNode {
  id
  iban
  companyName
  bankName
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on CompanyNode {
  id
  name
  logo
  logoThumbnail
  companyType
  createdAt
  phone
  websiteUrl
  size
  activity {
    id
    name
  }
  overallRating
  slug
  availablePaymentTypes
  invoicingInfo {
    ...Invoice
  }
  bankAccount {
    ...BankAccount
  }
}
    ${InvoiceFragmentDoc}
${BankAccountFragmentDoc}`;
export const MyAuctionBidFragmentDoc = gql`
    fragment MyAuctionBid on MyBidNode {
  id
  createdAt
  amount
  company {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export const ParentCategoryTreeFragmentDoc = gql`
    fragment ParentCategoryTree on CategoryNode {
  id
  customFieldsSchema
  name
  orderNum
  serviceType
  kind
  advertCount
  icon
  image
  slug
  loanCalculatorEnabled
  marketPrices {
    marketPriceFrom
    marketPriceTo
  }
}
    `;
export const CategoryFragmentDoc = gql`
    fragment Category on CategoryNode {
  id
  customFieldsSchema
  name
  orderNum
  serviceType
  kind
  advertCount
  parent {
    ...ParentCategoryTree
  }
  icon
  image
  slug
  loanCalculatorEnabled
  marketPrices {
    marketPriceFrom
    marketPriceTo
  }
}
    ${ParentCategoryTreeFragmentDoc}`;
export const AuctionBidFragmentDoc = gql`
    fragment AuctionBid on BidNode {
  id
  createdAt
  amount
  company
}
    `;
export const AuctionImageFragmentDoc = gql`
    fragment AuctionImage on AuctionImageNode {
  id
  thumbnail
  image
}
    `;
export const AuctionFragmentDoc = gql`
    fragment Auction on AuctionNode {
  id
  auctionType
  status
  title
  description
  address
  coordinates {
    latitude
    longitude
  }
  initialPrice
  duration
  category {
    ...Category
  }
  mainImage
  slug
  totalBidCount
  lastBids {
    ...AuctionBid
  }
  company {
    ...Company
  }
  images {
    ...AuctionImage
  }
  expirationDate
  customFieldsData
  mainImageThumbnail
}
    ${CategoryFragmentDoc}
${AuctionBidFragmentDoc}
${CompanyFragmentDoc}
${AuctionImageFragmentDoc}`;
export const MyAuctionFragmentDoc = gql`
    fragment MyAuction on MyAuctionNode {
  id
  winnerBid {
    ...MyAuctionBid
  }
  lastBids {
    ...MyAuctionBid
  }
  isDeletable
  auction {
    ...Auction
  }
}
    ${MyAuctionBidFragmentDoc}
${AuctionFragmentDoc}`;
export const AdvertCalendarItemFragmentDoc = gql`
    fragment AdvertCalendarItem on AdvertCalendarItemNode {
  advert {
    id
    title
  }
  events {
    id
    title
    description
    startDate
    endDate
  }
  orders {
    id
    status
    startDate
    endDate
    company {
      id
      name
    }
  }
  id
}
    `;
export const AdvertFragmentDoc = gql`
    fragment Advert on AdvertNode {
  customFieldsData
  status
  title
  description
  id
  slug
  advertType
  pricingType
  serviceType
  isScheduled
  isFavorited
  mainImage
  mainImageThumbnail
  advertId
  address
  phone
  deliveryType
  schedules {
    startDate
    endDate
  }
  company {
    ...Company
  }
  price
  deliveryPrice
  category {
    ...Category
  }
  viewCount
  images {
    id
    thumbnail
    image
  }
  coordinates {
    latitude
    longitude
  }
  validUntil
}
    ${CompanyFragmentDoc}
${CategoryFragmentDoc}`;
export const OrderFragmentDoc = gql`
    fragment Order on OrderNode {
  id
  createdAt
  updatedAt
  company {
    ...Company
  }
  totalPrice
  initialPrice
  initialDeliveryPrice
  initialPricingType
  startDate
  endDate
  status
  description
  advert {
    ...Advert
  }
  orderType
  phone
  amount
  paymentType
  orderId
  includeDelivery
  unreadNotificationCount
  paymentType
}
    ${CompanyFragmentDoc}
${AdvertFragmentDoc}`;
export const OrderMessageFragmentDoc = gql`
    fragment OrderMessage on ChatMessageNode {
  id
  senderCompany {
    id
    name
  }
  order {
    ...Order
  }
  message
  createdAt
  attachedFile
  attachedFileOriginalName
}
    ${OrderFragmentDoc}`;
export const ParentCategoryFragmentDoc = gql`
    fragment ParentCategory on CategoryNode {
  id
  name
  orderNum
  serviceType
  kind
  icon
  image
  slug
}
    `;
export const LanguageFragmentDoc = gql`
    fragment Language on LanguageNode {
  id
  code
  name
  flag
}
    `;
export const PredefinedSearchTranslationFragmentDoc = gql`
    fragment PredefinedSearchTranslation on PredefinedSearchTranslationNode {
  id
  title
  description
  language {
    ...Language
  }
}
    ${LanguageFragmentDoc}`;
export const PredefinedSearchFragmentDoc = gql`
    fragment PredefinedSearch on PredefinedSearchNode {
  id
  name
  url
  translation {
    ...PredefinedSearchTranslation
  }
}
    ${PredefinedSearchTranslationFragmentDoc}`;
export const LandingPageImagesFragmentDoc = gql`
    fragment LandingPageImages on LandingPageImageNode {
  imageWeb
  imageMobile
  text
  title
  navigationTitle
  url
}
    `;
export const LandingPageBannersFragmentDoc = gql`
    fragment LandingPageBanners on LandingPageBannerNode {
  id
  name
  title
  description
  buttonUrl
  buttonText
  imageWeb
  imageMobile
  kind
}
    `;
export const AppConfigFragmentDoc = gql`
    fragment AppConfig on AppConfigViewNode {
  version
  facebookAppId
  googleClientId
  appleClientId
  googleTagManagerId
  appleRedirectUri
  enabledLanguages
  defaultLanguage
  contact
  rentPage
  aboutUs
  terms
  privacy
  isAuctionEnabled
  isPaymentEnabled
  isOldOrderFormEnabled
  isForumEnabled
  advertLoanInterestRate
  maxAdvertLoanAmount
  maxAdvertLoanDuration
  parentCategories {
    ...ParentCategory
  }
  predefinedSearch {
    ...PredefinedSearch
  }
  landingPageImages {
    ...LandingPageImages
  }
  stripePublishableKey
  landingPageBanners {
    ...LandingPageBanners
  }
  mainLogo
}
    ${ParentCategoryFragmentDoc}
${PredefinedSearchFragmentDoc}
${LandingPageImagesFragmentDoc}
${LandingPageBannersFragmentDoc}`;
export const TerrainTypeFragmentDoc = gql`
    fragment TerrainType on TerrainTypeNode {
  id
  name
}
    `;
export const ContractorInputFragmentDoc = gql`
    fragment ContractorInput on GeneralOrderContractorType {
  additionalInfo
  address
  categories {
    id
  }
  categoriesDetails {
    id
    value
    price {
      type
      value
    }
  }
  coordinates {
    latitude
    longitude
  }
  createdAt
  email
  name
  phone
  surname
  workingArea
}
    `;
export const FaqCategoryFragmentDoc = gql`
    fragment FAQCategory on FAQCategoryNode {
  name
  orderNum
  icon
}
    `;
export const ForumFragmentFragmentDoc = gql`
    fragment ForumFragment on ForumNode {
  id
  title
  slug
  threadCount
  description
  lastPost {
    id
    content
    createdAt
    author {
      id
      name
    }
  }
}
    `;
export const ForumCategoriesFragmentDoc = gql`
    fragment ForumCategories on ForumCategoryWithForumNode {
  id
  title
  orderNum
  forums {
    ...ForumFragment
  }
}
    ${ForumFragmentFragmentDoc}`;
export const ForumInfoFragmentFragmentDoc = gql`
    fragment ForumInfoFragment on ForumNode {
  id
  title
  slug
  description
  category {
    title
    id
    orderNum
  }
}
    `;
export const ForumThreadFragmentDoc = gql`
    fragment ForumThread on ForumThreadNode {
  createdAt
  content
  slug
  lastModified
  lastReply {
    author {
      name
      id
    }
    content
    id
    createdAt
  }
  replyCount
  author {
    name
    id
    isExpert
    expertDescription
  }
  id
  title
  forum {
    ...ForumInfoFragment
  }
  images {
    id
    image
    thumbnail
  }
}
    ${ForumInfoFragmentFragmentDoc}`;
export const ForumThreadListFragmentFragmentDoc = gql`
    fragment forumThreadListFragment on ForumThreadNode {
  createdAt
  content
  id
  title
  slug
  lastModified
  replyCount
  author {
    id
    name
  }
  lastReply {
    id
    createdAt
    content
    author {
      id
      name
    }
  }
}
    `;
export const ForumReplyNodeFragmentFragmentDoc = gql`
    fragment forumReplyNodeFragment on ForumReplyNode {
  id
  content
  createdAt
  author {
    name
    id
  }
}
    `;
export const ForumThreadReplyFragmentDoc = gql`
    fragment forumThreadReply on ForumReplyNode {
  createdAt
  content
  id
  author {
    name
    id
    isExpert
    expertDescription
  }
  replyTo {
    ...forumReplyNodeFragment
  }
  images {
    id
    image
    thumbnail
  }
}
    ${ForumReplyNodeFragmentFragmentDoc}`;
export const GeneralOrderFragmentDoc = gql`
    fragment GeneralOrder on GeneralOrderType {
  id
  category {
    ...Category
  }
  amount
  amountType
  amountParts
  paymentCondition
  name
  surname
  phone
  email
  coordinates {
    latitude
    longitude
  }
  address
  additionalInfo
  totalPrice
  updatedAt
  createdAt
}
    ${CategoryFragmentDoc}`;
export const GeneralOrderFetchResultFragmentDoc = gql`
    fragment GeneralOrderFetchResult on GeneralOrderNode {
  id
  category {
    ...Category
  }
  amount
  amountType
  amountParts
  paymentCondition
  coordinates {
    latitude
    longitude
  }
  totalPrice
  terrainType {
    id
    name
  }
}
    ${CategoryFragmentDoc}`;
export const GeneralOfferInputFragmentDoc = gql`
    fragment GeneralOfferInput on GeneralOfferType {
  generalOrder {
    id
  }
  email
  phone
  totalPrice
  paymentCondition
}
    `;
export const MyInvoiceFragmentDoc = gql`
    fragment MyInvoice on InvoiceNode {
  id
  number
  downloadUrl
  createdAt
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on NotificationNode {
  id
  level
  unread
  verb
  description
  timestamp
  relatedObjectSlug
  relatedObjectGraphqlId
}
    `;
export const OrderRatingFragmentDoc = gql`
    fragment OrderRating on OrderRatingNode {
  id
  createdAt
  order {
    id
    advert {
      id
      advertType
      company {
        id
        name
      }
    }
    company {
      id
      name
    }
  }
  reportedCompany {
    id
    name
  }
  rating
  description
}
    `;
export const StripeCardFragmentDoc = gql`
    fragment StripeCard on StripeCardNode {
  id
  gateway
  paymentMethodId
  last4
}
    `;
export const MyCompanyFragmentDoc = gql`
    fragment MyCompany on MyCompanyNode {
  id
  unreadNotificationsCount
  isActive
  inPrivateGroup
  company {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on UserNode {
  id
  email
  username
  activeCompany {
    ...MyCompany
  }
}
    ${MyCompanyFragmentDoc}`;
export const PreliminaryOrderFragmentDoc = gql`
    fragment PreliminaryOrder on PreliminaryOrderNode {
  user {
    ...UserFragment
  }
  startDate
  status
  advert {
    ...Advert
  }
  description
  orderId
  contactViaPlatform
  contactViaEmail
  contactViaPhone
  email
  phone
  id
}
    ${UserFragmentFragmentDoc}
${AdvertFragmentDoc}`;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const GetActivitiesDocument = gql`
    query getActivities {
  activities {
    edges {
      node {
        id
        name
        code
        sizeMeasureType
        orderNum
      }
    }
  }
}
    `;
export function useGetActivitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, baseOptions);
      }
export function useGetActivitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, baseOptions);
        }
export type GetActivitiesQueryHookResult = ReturnType<typeof useGetActivitiesQuery>;
export type GetActivitiesLazyQueryHookResult = ReturnType<typeof useGetActivitiesLazyQuery>;
export const AdvertEventDocument = gql`
    mutation advertEvent($input: AdvertEventMutationInput!) {
  advertEvent(input: $input) {
    advertEvent {
      id
      title
      description
      startDate
      endDate
      advert {
        id
      }
    }
  }
}
    `;
export type AdvertEventMutationFn = ApolloReactCommon.MutationFunction<AdvertEventMutation, AdvertEventMutationVariables>;
export function useAdvertEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvertEventMutation, AdvertEventMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvertEventMutation, AdvertEventMutationVariables>(AdvertEventDocument, baseOptions);
      }
export type AdvertEventMutationHookResult = ReturnType<typeof useAdvertEventMutation>;
export const AdvertLoanDocument = gql`
    mutation advertLoan($input: AdvertLoanMutationInput!) {
  advertLoan(input: $input) {
    clientMutationId
  }
}
    `;
export type AdvertLoanMutationFn = ApolloReactCommon.MutationFunction<AdvertLoanMutation, AdvertLoanMutationVariables>;
export function useAdvertLoanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvertLoanMutation, AdvertLoanMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvertLoanMutation, AdvertLoanMutationVariables>(AdvertLoanDocument, baseOptions);
      }
export type AdvertLoanMutationHookResult = ReturnType<typeof useAdvertLoanMutation>;
export const AdvertDocument = gql`
    mutation advert($input: AdvertMutationInput!) {
  advert(input: $input) {
    advert {
      id
      status
      title
    }
  }
}
    `;
export type AdvertMutationFn = ApolloReactCommon.MutationFunction<AdvertMutation, AdvertMutationVariables>;
export function useAdvertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvertMutation, AdvertMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvertMutation, AdvertMutationVariables>(AdvertDocument, baseOptions);
      }
export type AdvertMutationHookResult = ReturnType<typeof useAdvertMutation>;
export const AdvertViewCountDocument = gql`
    mutation advertViewCount($input: IncrementAdvertViewCountInput!) {
  advertViewCount(input: $input) {
    advert {
      id
      viewCount
    }
  }
}
    `;
export type AdvertViewCountMutationFn = ApolloReactCommon.MutationFunction<AdvertViewCountMutation, AdvertViewCountMutationVariables>;
export function useAdvertViewCountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvertViewCountMutation, AdvertViewCountMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvertViewCountMutation, AdvertViewCountMutationVariables>(AdvertViewCountDocument, baseOptions);
      }
export type AdvertViewCountMutationHookResult = ReturnType<typeof useAdvertViewCountMutation>;
export const DeleteAdvertEventMutationDocument = gql`
    mutation deleteAdvertEventMutation($input: DeleteAdvertEventInput!) {
  deleteAdvertEvent(input: $input) {
    clientMutationId
  }
}
    `;
export type DeleteAdvertEventMutationMutationFn = ApolloReactCommon.MutationFunction<DeleteAdvertEventMutationMutation, DeleteAdvertEventMutationMutationVariables>;
export function useDeleteAdvertEventMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAdvertEventMutationMutation, DeleteAdvertEventMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAdvertEventMutationMutation, DeleteAdvertEventMutationMutationVariables>(DeleteAdvertEventMutationDocument, baseOptions);
      }
export type DeleteAdvertEventMutationMutationHookResult = ReturnType<typeof useDeleteAdvertEventMutationMutation>;
export const DeleteAdvertImageDocument = gql`
    mutation deleteAdvertImage($input: DeleteAdvertImageInput!) {
  deleteAdvertImage(input: $input) {
    clientMutationId
  }
}
    `;
export type DeleteAdvertImageMutationFn = ApolloReactCommon.MutationFunction<DeleteAdvertImageMutation, DeleteAdvertImageMutationVariables>;
export function useDeleteAdvertImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAdvertImageMutation, DeleteAdvertImageMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAdvertImageMutation, DeleteAdvertImageMutationVariables>(DeleteAdvertImageDocument, baseOptions);
      }
export type DeleteAdvertImageMutationHookResult = ReturnType<typeof useDeleteAdvertImageMutation>;
export const FavoriteAdvertDocument = gql`
    mutation favoriteAdvert($input: FavoriteAdvertMutationInput!) {
  favoriteAdvert(input: $input) {
    advert {
      id
      isFavorited
    }
  }
}
    `;
export type FavoriteAdvertMutationFn = ApolloReactCommon.MutationFunction<FavoriteAdvertMutation, FavoriteAdvertMutationVariables>;
export function useFavoriteAdvertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FavoriteAdvertMutation, FavoriteAdvertMutationVariables>) {
        return ApolloReactHooks.useMutation<FavoriteAdvertMutation, FavoriteAdvertMutationVariables>(FavoriteAdvertDocument, baseOptions);
      }
export type FavoriteAdvertMutationHookResult = ReturnType<typeof useFavoriteAdvertMutation>;
export const ImportAdvertsDocument = gql`
    mutation importAdverts($csvFile: Upload!, $public: Boolean!) {
  importAdverts(csvFile: $csvFile, public: $public) {
    importedLines
    errors {
      ...AdvertError
    }
  }
}
    ${AdvertErrorFragmentDoc}`;
export type ImportAdvertsMutationFn = ApolloReactCommon.MutationFunction<ImportAdvertsMutation, ImportAdvertsMutationVariables>;
export function useImportAdvertsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportAdvertsMutation, ImportAdvertsMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportAdvertsMutation, ImportAdvertsMutationVariables>(ImportAdvertsDocument, baseOptions);
      }
export type ImportAdvertsMutationHookResult = ReturnType<typeof useImportAdvertsMutation>;
export const UpdateAdvertStatusDocument = gql`
    mutation updateAdvertStatus($input: UpdateAdvertStatusInput!) {
  updateAdvertStatus(input: $input) {
    advert {
      id
      status
    }
  }
}
    `;
export type UpdateAdvertStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateAdvertStatusMutation, UpdateAdvertStatusMutationVariables>;
export function useUpdateAdvertStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAdvertStatusMutation, UpdateAdvertStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAdvertStatusMutation, UpdateAdvertStatusMutationVariables>(UpdateAdvertStatusDocument, baseOptions);
      }
export type UpdateAdvertStatusMutationHookResult = ReturnType<typeof useUpdateAdvertStatusMutation>;
export const UploadAdvertImageDocument = gql`
    mutation uploadAdvertImage($input: UploadAdvertImageInput!) {
  uploadAdvertImage(input: $input) {
    advertImage {
      image
    }
  }
}
    `;
export type UploadAdvertImageMutationFn = ApolloReactCommon.MutationFunction<UploadAdvertImageMutation, UploadAdvertImageMutationVariables>;
export function useUploadAdvertImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadAdvertImageMutation, UploadAdvertImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadAdvertImageMutation, UploadAdvertImageMutationVariables>(UploadAdvertImageDocument, baseOptions);
      }
export type UploadAdvertImageMutationHookResult = ReturnType<typeof useUploadAdvertImageMutation>;
export const GetTopicalAdvertsDocument = gql`
    query getTopicalAdverts($amount: Int!) {
  topicalAds(adAmount: $amount) {
    ...Advert
  }
}
    ${AdvertFragmentDoc}`;
export function useGetTopicalAdvertsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTopicalAdvertsQuery, GetTopicalAdvertsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTopicalAdvertsQuery, GetTopicalAdvertsQueryVariables>(GetTopicalAdvertsDocument, baseOptions);
      }
export function useGetTopicalAdvertsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTopicalAdvertsQuery, GetTopicalAdvertsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTopicalAdvertsQuery, GetTopicalAdvertsQueryVariables>(GetTopicalAdvertsDocument, baseOptions);
        }
export type GetTopicalAdvertsQueryHookResult = ReturnType<typeof useGetTopicalAdvertsQuery>;
export type GetTopicalAdvertsLazyQueryHookResult = ReturnType<typeof useGetTopicalAdvertsLazyQuery>;
export const GetAdsByCategoryDocument = gql`
    query getAdsByCategory($limit: Int, $cursor: String, $categorySlug: String, $orderby: [String], $priceGte: Int, $priceLte: Int, $advertType: [AdvertType], $serviceType: [AdvertServiceType], $searchBy: String, $geoSearch: Coordinate) {
  allAdverts(first: $limit, after: $cursor, categorySlug: $categorySlug, priceGte: $priceGte, priceLte: $priceLte, advertType: $advertType, serviceType: $serviceType, searchBy: $searchBy, orderby: $orderby, geoSearch: $geoSearch) {
    edges {
      node {
        ...Advert
      }
    }
    totalCount
    pageInfo {
      ...PageInfo
    }
  }
}
    ${AdvertFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetAdsByCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdsByCategoryQuery, GetAdsByCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdsByCategoryQuery, GetAdsByCategoryQueryVariables>(GetAdsByCategoryDocument, baseOptions);
      }
export function useGetAdsByCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdsByCategoryQuery, GetAdsByCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdsByCategoryQuery, GetAdsByCategoryQueryVariables>(GetAdsByCategoryDocument, baseOptions);
        }
export type GetAdsByCategoryQueryHookResult = ReturnType<typeof useGetAdsByCategoryQuery>;
export type GetAdsByCategoryLazyQueryHookResult = ReturnType<typeof useGetAdsByCategoryLazyQuery>;
export const GetAdvertDocument = gql`
    query getAdvert($id: ID!) {
  advert(id: $id) {
    ...Advert
  }
}
    ${AdvertFragmentDoc}`;
export function useGetAdvertQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdvertQuery, GetAdvertQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdvertQuery, GetAdvertQueryVariables>(GetAdvertDocument, baseOptions);
      }
export function useGetAdvertLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdvertQuery, GetAdvertQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdvertQuery, GetAdvertQueryVariables>(GetAdvertDocument, baseOptions);
        }
export type GetAdvertQueryHookResult = ReturnType<typeof useGetAdvertQuery>;
export type GetAdvertLazyQueryHookResult = ReturnType<typeof useGetAdvertLazyQuery>;
export const GetAdvertScheduleDocument = gql`
    query getAdvertSchedule($advert: ID!, $startDate: Date!, $endDate: Date!) {
  advertSchedule(advert: $advert, startDate: $startDate, endDate: $endDate) {
    startDate
    endDate
  }
}
    `;
export function useGetAdvertScheduleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdvertScheduleQuery, GetAdvertScheduleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdvertScheduleQuery, GetAdvertScheduleQueryVariables>(GetAdvertScheduleDocument, baseOptions);
      }
export function useGetAdvertScheduleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdvertScheduleQuery, GetAdvertScheduleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdvertScheduleQuery, GetAdvertScheduleQueryVariables>(GetAdvertScheduleDocument, baseOptions);
        }
export type GetAdvertScheduleQueryHookResult = ReturnType<typeof useGetAdvertScheduleQuery>;
export type GetAdvertScheduleLazyQueryHookResult = ReturnType<typeof useGetAdvertScheduleLazyQuery>;
export const GetCompanyAdsByIdDocument = gql`
    query getCompanyAdsById($id: ID, $limit: Int, $cursor: String, $advertIds: [ID]) {
  allAdverts(company: $id, advertIds: $advertIds, first: $limit, after: $cursor) {
    edges {
      node {
        ...Advert
      }
    }
    totalCount
    pageInfo {
      ...PageInfo
    }
  }
}
    ${AdvertFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetCompanyAdsByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyAdsByIdQuery, GetCompanyAdsByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyAdsByIdQuery, GetCompanyAdsByIdQueryVariables>(GetCompanyAdsByIdDocument, baseOptions);
      }
export function useGetCompanyAdsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyAdsByIdQuery, GetCompanyAdsByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyAdsByIdQuery, GetCompanyAdsByIdQueryVariables>(GetCompanyAdsByIdDocument, baseOptions);
        }
export type GetCompanyAdsByIdQueryHookResult = ReturnType<typeof useGetCompanyAdsByIdQuery>;
export type GetCompanyAdsByIdLazyQueryHookResult = ReturnType<typeof useGetCompanyAdsByIdLazyQuery>;
export const GetFeaturedAdvertsDocument = gql`
    query getFeaturedAdverts($amount: Int!, $advertIds: [ID]) {
  featuredAds(adAmount: $amount, advertIds: $advertIds) {
    ...Advert
  }
}
    ${AdvertFragmentDoc}`;
export function useGetFeaturedAdvertsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFeaturedAdvertsQuery, GetFeaturedAdvertsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFeaturedAdvertsQuery, GetFeaturedAdvertsQueryVariables>(GetFeaturedAdvertsDocument, baseOptions);
      }
export function useGetFeaturedAdvertsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFeaturedAdvertsQuery, GetFeaturedAdvertsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFeaturedAdvertsQuery, GetFeaturedAdvertsQueryVariables>(GetFeaturedAdvertsDocument, baseOptions);
        }
export type GetFeaturedAdvertsQueryHookResult = ReturnType<typeof useGetFeaturedAdvertsQuery>;
export type GetFeaturedAdvertsLazyQueryHookResult = ReturnType<typeof useGetFeaturedAdvertsLazyQuery>;
export const GetFeaturedTabAdvertsDocument = gql`
    query getFeaturedTabAdverts($tabFeaturedType: TabFeaturedType!, $kind: String) {
  tabFeaturedAds(tabFeaturedType: $tabFeaturedType, kind: $kind) {
    ...Advert
  }
}
    ${AdvertFragmentDoc}`;
export function useGetFeaturedTabAdvertsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFeaturedTabAdvertsQuery, GetFeaturedTabAdvertsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFeaturedTabAdvertsQuery, GetFeaturedTabAdvertsQueryVariables>(GetFeaturedTabAdvertsDocument, baseOptions);
      }
export function useGetFeaturedTabAdvertsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFeaturedTabAdvertsQuery, GetFeaturedTabAdvertsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFeaturedTabAdvertsQuery, GetFeaturedTabAdvertsQueryVariables>(GetFeaturedTabAdvertsDocument, baseOptions);
        }
export type GetFeaturedTabAdvertsQueryHookResult = ReturnType<typeof useGetFeaturedTabAdvertsQuery>;
export type GetFeaturedTabAdvertsLazyQueryHookResult = ReturnType<typeof useGetFeaturedTabAdvertsLazyQuery>;
export const GetMarketplaceAdsDocument = gql`
    query getMarketplaceAds($limit: Int, $cursor: String, $orderby: [String], $priceGte: Int, $priceLte: Int, $pricingType: [AdvertPricingType], $advertType: [AdvertType], $serviceType: [AdvertServiceType], $categories: [ID], $searchBy: String, $geoSearch: Coordinate, $company: ID, $kind: String) {
  allAdverts(company: $company, first: $limit, after: $cursor, priceGte: $priceGte, priceLte: $priceLte, pricingType: $pricingType, advertType: $advertType, serviceType: $serviceType, categories: $categories, searchBy: $searchBy, orderby: $orderby, geoSearch: $geoSearch, kind: $kind) {
    edges {
      node {
        ...Advert
      }
    }
    totalCount
    pageInfo {
      ...PageInfo
    }
  }
}
    ${AdvertFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetMarketplaceAdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMarketplaceAdsQuery, GetMarketplaceAdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMarketplaceAdsQuery, GetMarketplaceAdsQueryVariables>(GetMarketplaceAdsDocument, baseOptions);
      }
export function useGetMarketplaceAdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMarketplaceAdsQuery, GetMarketplaceAdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMarketplaceAdsQuery, GetMarketplaceAdsQueryVariables>(GetMarketplaceAdsDocument, baseOptions);
        }
export type GetMarketplaceAdsQueryHookResult = ReturnType<typeof useGetMarketplaceAdsQuery>;
export type GetMarketplaceAdsLazyQueryHookResult = ReturnType<typeof useGetMarketplaceAdsLazyQuery>;
export const GetMyAdvertScheduleDocument = gql`
    query getMyAdvertSchedule($advert: ID!, $startDate: Date!, $endDate: Date!) {
  myAdvertSchedule(advert: $advert, startDate: $startDate, endDate: $endDate) {
    startDate
    endDate
  }
}
    `;
export function useGetMyAdvertScheduleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyAdvertScheduleQuery, GetMyAdvertScheduleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyAdvertScheduleQuery, GetMyAdvertScheduleQueryVariables>(GetMyAdvertScheduleDocument, baseOptions);
      }
export function useGetMyAdvertScheduleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyAdvertScheduleQuery, GetMyAdvertScheduleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyAdvertScheduleQuery, GetMyAdvertScheduleQueryVariables>(GetMyAdvertScheduleDocument, baseOptions);
        }
export type GetMyAdvertScheduleQueryHookResult = ReturnType<typeof useGetMyAdvertScheduleQuery>;
export type GetMyAdvertScheduleLazyQueryHookResult = ReturnType<typeof useGetMyAdvertScheduleLazyQuery>;
export const GetMyAdvertAvailabilityDocument = gql`
    query getMyAdvertAvailability($advert: ID!, $startDate: Date!, $endDate: Date!, $startDateTime: DateTime!, $endDateTime: DateTime!, $status: [String]) {
  myAdvertSchedule(advert: $advert, startDate: $startDate, endDate: $endDate) {
    startDate
    endDate
  }
  advertOrders(advert: $advert, startDate: $startDateTime, endDate: $endDateTime, status: $status) {
    id
    startDate
    endDate
    advert {
      id
      title
    }
  }
  me {
    myAdvertEvents(advert: $advert, startDate: $startDateTime, endDate: $endDateTime) {
      id
      startDate
      endDate
      advert {
        id
      }
      title
      description
    }
  }
}
    `;
export function useGetMyAdvertAvailabilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyAdvertAvailabilityQuery, GetMyAdvertAvailabilityQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyAdvertAvailabilityQuery, GetMyAdvertAvailabilityQueryVariables>(GetMyAdvertAvailabilityDocument, baseOptions);
      }
export function useGetMyAdvertAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyAdvertAvailabilityQuery, GetMyAdvertAvailabilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyAdvertAvailabilityQuery, GetMyAdvertAvailabilityQueryVariables>(GetMyAdvertAvailabilityDocument, baseOptions);
        }
export type GetMyAdvertAvailabilityQueryHookResult = ReturnType<typeof useGetMyAdvertAvailabilityQuery>;
export type GetMyAdvertAvailabilityLazyQueryHookResult = ReturnType<typeof useGetMyAdvertAvailabilityLazyQuery>;
export const GetCompanyAdsDocument = gql`
    query getCompanyAds($status: AdvertStatus, $limit: Int, $cursor: String, $orderby: [String]) {
  me {
    myAdverts(status: $status, first: $limit, after: $cursor, orderby: $orderby) {
      edges {
        node {
          ...Advert
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
}
    ${AdvertFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetCompanyAdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyAdsQuery, GetCompanyAdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyAdsQuery, GetCompanyAdsQueryVariables>(GetCompanyAdsDocument, baseOptions);
      }
export function useGetCompanyAdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyAdsQuery, GetCompanyAdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyAdsQuery, GetCompanyAdsQueryVariables>(GetCompanyAdsDocument, baseOptions);
        }
export type GetCompanyAdsQueryHookResult = ReturnType<typeof useGetCompanyAdsQuery>;
export type GetCompanyAdsLazyQueryHookResult = ReturnType<typeof useGetCompanyAdsLazyQuery>;
export const GetFavoriteAdsDocument = gql`
    query getFavoriteAds($limit: Int, $cursor: String, $orderby: [String]) {
  me {
    myFavoriteAdverts(first: $limit, after: $cursor, orderby: $orderby) {
      edges {
        node {
          ...Advert
          company {
            ...Company
          }
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
}
    ${AdvertFragmentDoc}
${CompanyFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetFavoriteAdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFavoriteAdsQuery, GetFavoriteAdsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFavoriteAdsQuery, GetFavoriteAdsQueryVariables>(GetFavoriteAdsDocument, baseOptions);
      }
export function useGetFavoriteAdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFavoriteAdsQuery, GetFavoriteAdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFavoriteAdsQuery, GetFavoriteAdsQueryVariables>(GetFavoriteAdsDocument, baseOptions);
        }
export type GetFavoriteAdsQueryHookResult = ReturnType<typeof useGetFavoriteAdsQuery>;
export type GetFavoriteAdsLazyQueryHookResult = ReturnType<typeof useGetFavoriteAdsLazyQuery>;
export const GetRelatedAdvertsDocument = gql`
    query getRelatedAdverts($advertId: ID!, $amount: Int!) {
  relatedAdverts(advert: $advertId, amount: $amount) {
    ...Advert
  }
}
    ${AdvertFragmentDoc}`;
export function useGetRelatedAdvertsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRelatedAdvertsQuery, GetRelatedAdvertsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRelatedAdvertsQuery, GetRelatedAdvertsQueryVariables>(GetRelatedAdvertsDocument, baseOptions);
      }
export function useGetRelatedAdvertsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRelatedAdvertsQuery, GetRelatedAdvertsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRelatedAdvertsQuery, GetRelatedAdvertsQueryVariables>(GetRelatedAdvertsDocument, baseOptions);
        }
export type GetRelatedAdvertsQueryHookResult = ReturnType<typeof useGetRelatedAdvertsQuery>;
export type GetRelatedAdvertsLazyQueryHookResult = ReturnType<typeof useGetRelatedAdvertsLazyQuery>;
export const LoadAdForDisplayDocument = gql`
    query loadAdForDisplay($slug: String!) {
  advertBySlug(slug: $slug) {
    ...Advert
  }
}
    ${AdvertFragmentDoc}`;
export function useLoadAdForDisplayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoadAdForDisplayQuery, LoadAdForDisplayQueryVariables>) {
        return ApolloReactHooks.useQuery<LoadAdForDisplayQuery, LoadAdForDisplayQueryVariables>(LoadAdForDisplayDocument, baseOptions);
      }
export function useLoadAdForDisplayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoadAdForDisplayQuery, LoadAdForDisplayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoadAdForDisplayQuery, LoadAdForDisplayQueryVariables>(LoadAdForDisplayDocument, baseOptions);
        }
export type LoadAdForDisplayQueryHookResult = ReturnType<typeof useLoadAdForDisplayQuery>;
export type LoadAdForDisplayLazyQueryHookResult = ReturnType<typeof useLoadAdForDisplayLazyQuery>;
export const CreateAuctionDocument = gql`
    mutation createAuction($input: CreateAuctionMutationInput!) {
  createAuction(input: $input) {
    auction {
      id
    }
  }
}
    `;
export type CreateAuctionMutationFn = ApolloReactCommon.MutationFunction<CreateAuctionMutation, CreateAuctionMutationVariables>;
export function useCreateAuctionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAuctionMutation, CreateAuctionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAuctionMutation, CreateAuctionMutationVariables>(CreateAuctionDocument, baseOptions);
      }
export type CreateAuctionMutationHookResult = ReturnType<typeof useCreateAuctionMutation>;
export const CreateAuctionBidDocument = gql`
    mutation createAuctionBid($input: CreateBidMutationInput!) {
  createBid(input: $input) {
    bid {
      id
    }
  }
}
    `;
export type CreateAuctionBidMutationFn = ApolloReactCommon.MutationFunction<CreateAuctionBidMutation, CreateAuctionBidMutationVariables>;
export function useCreateAuctionBidMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAuctionBidMutation, CreateAuctionBidMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAuctionBidMutation, CreateAuctionBidMutationVariables>(CreateAuctionBidDocument, baseOptions);
      }
export type CreateAuctionBidMutationHookResult = ReturnType<typeof useCreateAuctionBidMutation>;
export const DeleteAuctionDocument = gql`
    mutation deleteAuction($input: DeleteAuctionMutationInput!) {
  deleteAuction(input: $input) {
    auction {
      ...Auction
    }
  }
}
    ${AuctionFragmentDoc}`;
export type DeleteAuctionMutationFn = ApolloReactCommon.MutationFunction<DeleteAuctionMutation, DeleteAuctionMutationVariables>;
export function useDeleteAuctionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAuctionMutation, DeleteAuctionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAuctionMutation, DeleteAuctionMutationVariables>(DeleteAuctionDocument, baseOptions);
      }
export type DeleteAuctionMutationHookResult = ReturnType<typeof useDeleteAuctionMutation>;
export const DeleteAuctionImageDocument = gql`
    mutation deleteAuctionImage($input: DeleteAuctionImageInput!) {
  deleteAuctionImage(input: $input) {
    auctionImage {
      image
    }
  }
}
    `;
export type DeleteAuctionImageMutationFn = ApolloReactCommon.MutationFunction<DeleteAuctionImageMutation, DeleteAuctionImageMutationVariables>;
export function useDeleteAuctionImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAuctionImageMutation, DeleteAuctionImageMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAuctionImageMutation, DeleteAuctionImageMutationVariables>(DeleteAuctionImageDocument, baseOptions);
      }
export type DeleteAuctionImageMutationHookResult = ReturnType<typeof useDeleteAuctionImageMutation>;
export const UpdateAuctionDocument = gql`
    mutation updateAuction($input: UpdateAuctionMutationInput!) {
  updateAuction(input: $input) {
    auction {
      ...Auction
    }
  }
}
    ${AuctionFragmentDoc}`;
export type UpdateAuctionMutationFn = ApolloReactCommon.MutationFunction<UpdateAuctionMutation, UpdateAuctionMutationVariables>;
export function useUpdateAuctionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAuctionMutation, UpdateAuctionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAuctionMutation, UpdateAuctionMutationVariables>(UpdateAuctionDocument, baseOptions);
      }
export type UpdateAuctionMutationHookResult = ReturnType<typeof useUpdateAuctionMutation>;
export const UploadAuctionImageDocument = gql`
    mutation uploadAuctionImage($input: UploadAuctionImageInput!) {
  uploadAuctionImage(input: $input) {
    auctionImage {
      image
    }
  }
}
    `;
export type UploadAuctionImageMutationFn = ApolloReactCommon.MutationFunction<UploadAuctionImageMutation, UploadAuctionImageMutationVariables>;
export function useUploadAuctionImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadAuctionImageMutation, UploadAuctionImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadAuctionImageMutation, UploadAuctionImageMutationVariables>(UploadAuctionImageDocument, baseOptions);
      }
export type UploadAuctionImageMutationHookResult = ReturnType<typeof useUploadAuctionImageMutation>;
export const GetAuctionBidsDocument = gql`
    query getAuctionBids($auction: ID!, $limit: Int, $cursor: String, $orderby: [String]) {
  auctionBids(auction: $auction, first: $limit, after: $cursor, orderby: $orderby) {
    pageInfo {
      ...PageInfo
    }
    totalCount
    edges {
      cursor
      node {
        ...MyAuctionBid
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${MyAuctionBidFragmentDoc}`;
export function useGetAuctionBidsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuctionBidsQuery, GetAuctionBidsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAuctionBidsQuery, GetAuctionBidsQueryVariables>(GetAuctionBidsDocument, baseOptions);
      }
export function useGetAuctionBidsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuctionBidsQuery, GetAuctionBidsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAuctionBidsQuery, GetAuctionBidsQueryVariables>(GetAuctionBidsDocument, baseOptions);
        }
export type GetAuctionBidsQueryHookResult = ReturnType<typeof useGetAuctionBidsQuery>;
export type GetAuctionBidsLazyQueryHookResult = ReturnType<typeof useGetAuctionBidsLazyQuery>;
export const GetAuctionBySlugDocument = gql`
    query getAuctionBySlug($slug: String!) {
  auctionBySlug(slug: $slug) {
    ...Auction
  }
}
    ${AuctionFragmentDoc}`;
export function useGetAuctionBySlugQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuctionBySlugQuery, GetAuctionBySlugQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAuctionBySlugQuery, GetAuctionBySlugQueryVariables>(GetAuctionBySlugDocument, baseOptions);
      }
export function useGetAuctionBySlugLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuctionBySlugQuery, GetAuctionBySlugQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAuctionBySlugQuery, GetAuctionBySlugQueryVariables>(GetAuctionBySlugDocument, baseOptions);
        }
export type GetAuctionBySlugQueryHookResult = ReturnType<typeof useGetAuctionBySlugQuery>;
export type GetAuctionBySlugLazyQueryHookResult = ReturnType<typeof useGetAuctionBySlugLazyQuery>;
export const GetAuctionsDocument = gql`
    query getAuctions($limit: Int, $cursor: String, $orderby: [String], $filterOnlyUserAttending: Boolean) {
  auctions(first: $limit, after: $cursor, orderby: $orderby, filterOnlyUserAttending: $filterOnlyUserAttending) {
    edges {
      node {
        ...Auction
      }
      cursor
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${AuctionFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetAuctionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuctionsQuery, GetAuctionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAuctionsQuery, GetAuctionsQueryVariables>(GetAuctionsDocument, baseOptions);
      }
export function useGetAuctionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuctionsQuery, GetAuctionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAuctionsQuery, GetAuctionsQueryVariables>(GetAuctionsDocument, baseOptions);
        }
export type GetAuctionsQueryHookResult = ReturnType<typeof useGetAuctionsQuery>;
export type GetAuctionsLazyQueryHookResult = ReturnType<typeof useGetAuctionsLazyQuery>;
export const GetFeaturedAuctionsDocument = gql`
    query getFeaturedAuctions($amount: Int!) {
  featuredAuctions(auctionAmount: $amount) {
    ...Auction
  }
}
    ${AuctionFragmentDoc}`;
export function useGetFeaturedAuctionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFeaturedAuctionsQuery, GetFeaturedAuctionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFeaturedAuctionsQuery, GetFeaturedAuctionsQueryVariables>(GetFeaturedAuctionsDocument, baseOptions);
      }
export function useGetFeaturedAuctionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFeaturedAuctionsQuery, GetFeaturedAuctionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFeaturedAuctionsQuery, GetFeaturedAuctionsQueryVariables>(GetFeaturedAuctionsDocument, baseOptions);
        }
export type GetFeaturedAuctionsQueryHookResult = ReturnType<typeof useGetFeaturedAuctionsQuery>;
export type GetFeaturedAuctionsLazyQueryHookResult = ReturnType<typeof useGetFeaturedAuctionsLazyQuery>;
export const GetMyAuctionDocument = gql`
    query getMyAuction($slug: String!) {
  myAuctionBySlug(slug: $slug) {
    ...MyAuction
  }
}
    ${MyAuctionFragmentDoc}`;
export function useGetMyAuctionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyAuctionQuery, GetMyAuctionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyAuctionQuery, GetMyAuctionQueryVariables>(GetMyAuctionDocument, baseOptions);
      }
export function useGetMyAuctionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyAuctionQuery, GetMyAuctionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyAuctionQuery, GetMyAuctionQueryVariables>(GetMyAuctionDocument, baseOptions);
        }
export type GetMyAuctionQueryHookResult = ReturnType<typeof useGetMyAuctionQuery>;
export type GetMyAuctionLazyQueryHookResult = ReturnType<typeof useGetMyAuctionLazyQuery>;
export const GetMyAuctionsDocument = gql`
    query getMyAuctions($limit: Int, $cursor: String, $orderby: [String]) {
  me {
    myAuctions(first: $limit, after: $cursor, orderby: $orderby) {
      edges {
        node {
          ...MyAuction
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
}
    ${MyAuctionFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetMyAuctionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyAuctionsQuery, GetMyAuctionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyAuctionsQuery, GetMyAuctionsQueryVariables>(GetMyAuctionsDocument, baseOptions);
      }
export function useGetMyAuctionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyAuctionsQuery, GetMyAuctionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyAuctionsQuery, GetMyAuctionsQueryVariables>(GetMyAuctionsDocument, baseOptions);
        }
export type GetMyAuctionsQueryHookResult = ReturnType<typeof useGetMyAuctionsQuery>;
export type GetMyAuctionsLazyQueryHookResult = ReturnType<typeof useGetMyAuctionsLazyQuery>;
export const AcceptCompanyInvitationDocument = gql`
    mutation acceptCompanyInvitation($input: AcceptCompanyInvitationInput!) {
  acceptCompanyInvitation(input: $input) {
    clientMutationId
  }
}
    `;
export type AcceptCompanyInvitationMutationFn = ApolloReactCommon.MutationFunction<AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables>;
export function useAcceptCompanyInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptCompanyInvitationMutation, AcceptCompanyInvitationMutationVariables>(AcceptCompanyInvitationDocument, baseOptions);
      }
export type AcceptCompanyInvitationMutationHookResult = ReturnType<typeof useAcceptCompanyInvitationMutation>;
export const AuthWithAppleDocument = gql`
    mutation authWithApple($input: AppleLoginInput!) {
  appleLogin(input: $input) {
    token
  }
}
    `;
export type AuthWithAppleMutationFn = ApolloReactCommon.MutationFunction<AuthWithAppleMutation, AuthWithAppleMutationVariables>;
export function useAuthWithAppleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthWithAppleMutation, AuthWithAppleMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthWithAppleMutation, AuthWithAppleMutationVariables>(AuthWithAppleDocument, baseOptions);
      }
export type AuthWithAppleMutationHookResult = ReturnType<typeof useAuthWithAppleMutation>;
export const AuthWithFbDocument = gql`
    mutation authWithFb($input: FacebookLoginInput!) {
  facebookLogin(input: $input) {
    token
  }
}
    `;
export type AuthWithFbMutationFn = ApolloReactCommon.MutationFunction<AuthWithFbMutation, AuthWithFbMutationVariables>;
export function useAuthWithFbMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthWithFbMutation, AuthWithFbMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthWithFbMutation, AuthWithFbMutationVariables>(AuthWithFbDocument, baseOptions);
      }
export type AuthWithFbMutationHookResult = ReturnType<typeof useAuthWithFbMutation>;
export const AuthWithGoogleDocument = gql`
    mutation authWithGoogle($input: GoogleLoginInput!) {
  googleLogin(input: $input) {
    token
  }
}
    `;
export type AuthWithGoogleMutationFn = ApolloReactCommon.MutationFunction<AuthWithGoogleMutation, AuthWithGoogleMutationVariables>;
export function useAuthWithGoogleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthWithGoogleMutation, AuthWithGoogleMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthWithGoogleMutation, AuthWithGoogleMutationVariables>(AuthWithGoogleDocument, baseOptions);
      }
export type AuthWithGoogleMutationHookResult = ReturnType<typeof useAuthWithGoogleMutation>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    clientMutationId
  }
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export const LoginQueryDocument = gql`
    mutation loginQuery($input: ObtainJSONWebTokenInput!) {
  tokenAuth(input: $input) {
    token
  }
}
    `;
export type LoginQueryMutationFn = ApolloReactCommon.MutationFunction<LoginQueryMutation, LoginQueryMutationVariables>;
export function useLoginQueryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginQueryMutation, LoginQueryMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginQueryMutation, LoginQueryMutationVariables>(LoginQueryDocument, baseOptions);
      }
export type LoginQueryMutationHookResult = ReturnType<typeof useLoginQueryMutation>;
export const RegisterUserDocument = gql`
    mutation registerUser($input: RegisterUserInput!) {
  registerUser(input: $input) {
    token
  }
}
    `;
export type RegisterUserMutationFn = ApolloReactCommon.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;
export function useRegisterUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, baseOptions);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export const ChangePasswordDocument = gql`
    mutation changePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    errors {
      field
      message
    }
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export const VerifyUserDocument = gql`
    mutation verifyUser($input: VerifyUserInput!) {
  verifyUser(input: $input) {
    activationKey
  }
}
    `;
export type VerifyUserMutationFn = ApolloReactCommon.MutationFunction<VerifyUserMutation, VerifyUserMutationVariables>;
export function useVerifyUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyUserMutation, VerifyUserMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyUserMutation, VerifyUserMutationVariables>(VerifyUserDocument, baseOptions);
      }
export type VerifyUserMutationHookResult = ReturnType<typeof useVerifyUserMutation>;
export const GetUserDocument = gql`
    query getUser {
  me {
    info {
      ...UserFragment
    }
    myCompanies {
      ...MyCompany
    }
  }
}
    ${UserFragmentFragmentDoc}
${MyCompanyFragmentDoc}`;
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export const ValidateResetPasswordDocument = gql`
    query validateResetPassword($passwordKey: String!) {
  resetPassword(passwordKey: $passwordKey) {
    passwordKey
  }
}
    `;
export function useValidateResetPasswordQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidateResetPasswordQuery, ValidateResetPasswordQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidateResetPasswordQuery, ValidateResetPasswordQueryVariables>(ValidateResetPasswordDocument, baseOptions);
      }
export function useValidateResetPasswordLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidateResetPasswordQuery, ValidateResetPasswordQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidateResetPasswordQuery, ValidateResetPasswordQueryVariables>(ValidateResetPasswordDocument, baseOptions);
        }
export type ValidateResetPasswordQueryHookResult = ReturnType<typeof useValidateResetPasswordQuery>;
export type ValidateResetPasswordLazyQueryHookResult = ReturnType<typeof useValidateResetPasswordLazyQuery>;
export const VerifyInvitationKeyDocument = gql`
    query verifyInvitationKey($invitationKey: String!) {
  verifyInvitationKey(invitationKey: $invitationKey) {
    invitationKey
  }
}
    `;
export function useVerifyInvitationKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyInvitationKeyQuery, VerifyInvitationKeyQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyInvitationKeyQuery, VerifyInvitationKeyQueryVariables>(VerifyInvitationKeyDocument, baseOptions);
      }
export function useVerifyInvitationKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyInvitationKeyQuery, VerifyInvitationKeyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyInvitationKeyQuery, VerifyInvitationKeyQueryVariables>(VerifyInvitationKeyDocument, baseOptions);
        }
export type VerifyInvitationKeyQueryHookResult = ReturnType<typeof useVerifyInvitationKeyQuery>;
export type VerifyInvitationKeyLazyQueryHookResult = ReturnType<typeof useVerifyInvitationKeyLazyQuery>;
export const MyCompanyBankAccountDocument = gql`
    mutation myCompanyBankAccount($input: BankAccountMutationInput!) {
  myCompanyBankAccount(input: $input) {
    bankAccount {
      id
    }
  }
}
    `;
export type MyCompanyBankAccountMutationFn = ApolloReactCommon.MutationFunction<MyCompanyBankAccountMutation, MyCompanyBankAccountMutationVariables>;
export function useMyCompanyBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MyCompanyBankAccountMutation, MyCompanyBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<MyCompanyBankAccountMutation, MyCompanyBankAccountMutationVariables>(MyCompanyBankAccountDocument, baseOptions);
      }
export type MyCompanyBankAccountMutationHookResult = ReturnType<typeof useMyCompanyBankAccountMutation>;
export const GetAdvertCalendarItemsDocument = gql`
    query getAdvertCalendarItems($startDate: Date!, $endDate: Date!, $limit: Int, $cursor: String, $customEvents: Boolean, $orderStates: [String]) {
  me {
    myAdvertCalendarItems(startDate: $startDate, endDate: $endDate, itemTypeFilter: {customEvents: $customEvents, orderStates: $orderStates}, first: $limit, after: $cursor) {
      edges {
        node {
          ...AdvertCalendarItem
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${AdvertCalendarItemFragmentDoc}`;
export function useGetAdvertCalendarItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdvertCalendarItemsQuery, GetAdvertCalendarItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdvertCalendarItemsQuery, GetAdvertCalendarItemsQueryVariables>(GetAdvertCalendarItemsDocument, baseOptions);
      }
export function useGetAdvertCalendarItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdvertCalendarItemsQuery, GetAdvertCalendarItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdvertCalendarItemsQuery, GetAdvertCalendarItemsQueryVariables>(GetAdvertCalendarItemsDocument, baseOptions);
        }
export type GetAdvertCalendarItemsQueryHookResult = ReturnType<typeof useGetAdvertCalendarItemsQuery>;
export type GetAdvertCalendarItemsLazyQueryHookResult = ReturnType<typeof useGetAdvertCalendarItemsLazyQuery>;
export const GetCategoriesDocument = gql`
    query getCategories($id: [ID]) {
  categories(ids: $id) {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;
export function useGetCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, baseOptions);
      }
export function useGetCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, baseOptions);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export const GetCategoryTitleBySlugDocument = gql`
    query getCategoryTitleBySlug($slug: String) {
  categories(slug: $slug) {
    name
  }
}
    `;
export function useGetCategoryTitleBySlugQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCategoryTitleBySlugQuery, GetCategoryTitleBySlugQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCategoryTitleBySlugQuery, GetCategoryTitleBySlugQueryVariables>(GetCategoryTitleBySlugDocument, baseOptions);
      }
export function useGetCategoryTitleBySlugLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCategoryTitleBySlugQuery, GetCategoryTitleBySlugQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCategoryTitleBySlugQuery, GetCategoryTitleBySlugQueryVariables>(GetCategoryTitleBySlugDocument, baseOptions);
        }
export type GetCategoryTitleBySlugQueryHookResult = ReturnType<typeof useGetCategoryTitleBySlugQuery>;
export type GetCategoryTitleBySlugLazyQueryHookResult = ReturnType<typeof useGetCategoryTitleBySlugLazyQuery>;
export const AddOrderChatMessageFileDocument = gql`
    mutation addOrderChatMessageFile($input: ChatMessageFileUploadMutationInput!) {
  chatMessageFileUpload(input: $input) {
    chatMessage {
      ...OrderMessage
    }
  }
}
    ${OrderMessageFragmentDoc}`;
export type AddOrderChatMessageFileMutationFn = ApolloReactCommon.MutationFunction<AddOrderChatMessageFileMutation, AddOrderChatMessageFileMutationVariables>;
export function useAddOrderChatMessageFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddOrderChatMessageFileMutation, AddOrderChatMessageFileMutationVariables>) {
        return ApolloReactHooks.useMutation<AddOrderChatMessageFileMutation, AddOrderChatMessageFileMutationVariables>(AddOrderChatMessageFileDocument, baseOptions);
      }
export type AddOrderChatMessageFileMutationHookResult = ReturnType<typeof useAddOrderChatMessageFileMutation>;
export const AddOrderMessageDocument = gql`
    mutation addOrderMessage($input: ChatMessageMutationInput!) {
  chatMessage(input: $input) {
    chatMessage {
      ...OrderMessage
    }
  }
}
    ${OrderMessageFragmentDoc}`;
export type AddOrderMessageMutationFn = ApolloReactCommon.MutationFunction<AddOrderMessageMutation, AddOrderMessageMutationVariables>;
export function useAddOrderMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddOrderMessageMutation, AddOrderMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<AddOrderMessageMutation, AddOrderMessageMutationVariables>(AddOrderMessageDocument, baseOptions);
      }
export type AddOrderMessageMutationHookResult = ReturnType<typeof useAddOrderMessageMutation>;
export const GetOrderMessagesDocument = gql`
    query getOrderMessages($orderId: ID!) {
  chatMessages(orderId: $orderId) {
    ...OrderMessage
  }
}
    ${OrderMessageFragmentDoc}`;
export function useGetOrderMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderMessagesQuery, GetOrderMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderMessagesQuery, GetOrderMessagesQueryVariables>(GetOrderMessagesDocument, baseOptions);
      }
export function useGetOrderMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderMessagesQuery, GetOrderMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderMessagesQuery, GetOrderMessagesQueryVariables>(GetOrderMessagesDocument, baseOptions);
        }
export type GetOrderMessagesQueryHookResult = ReturnType<typeof useGetOrderMessagesQuery>;
export type GetOrderMessagesLazyQueryHookResult = ReturnType<typeof useGetOrderMessagesLazyQuery>;
export const OnChatMessageAddedDocument = gql`
    subscription onChatMessageAdded($input: ChatMessageSubscriptionInput!) {
  chatMessageCreated(input: $input) {
    ...OrderMessage
  }
}
    ${OrderMessageFragmentDoc}`;
export function useOnChatMessageAddedSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnChatMessageAddedSubscription, OnChatMessageAddedSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnChatMessageAddedSubscription, OnChatMessageAddedSubscriptionVariables>(OnChatMessageAddedDocument, baseOptions);
      }
export type OnChatMessageAddedSubscriptionHookResult = ReturnType<typeof useOnChatMessageAddedSubscription>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CreateCompanyMutationInput!) {
  createCompany(input: $input) {
    company {
      id
    }
  }
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export const InviteUserToMyCompanyDocument = gql`
    mutation inviteUserToMyCompany($input: InviteUserToCompanyMutationInput!) {
  inviteUserToMyCompany(input: $input) {
    invitationKey {
      email
    }
  }
}
    `;
export type InviteUserToMyCompanyMutationFn = ApolloReactCommon.MutationFunction<InviteUserToMyCompanyMutation, InviteUserToMyCompanyMutationVariables>;
export function useInviteUserToMyCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteUserToMyCompanyMutation, InviteUserToMyCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteUserToMyCompanyMutation, InviteUserToMyCompanyMutationVariables>(InviteUserToMyCompanyDocument, baseOptions);
      }
export type InviteUserToMyCompanyMutationHookResult = ReturnType<typeof useInviteUserToMyCompanyMutation>;
export const CompanyDocument = gql`
    mutation company($input: CompanyMutationInput!) {
  company(input: $input) {
    company {
      id
    }
  }
}
    `;
export type CompanyMutationFn = ApolloReactCommon.MutationFunction<CompanyMutation, CompanyMutationVariables>;
export function useCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyMutation, CompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyMutation, CompanyMutationVariables>(CompanyDocument, baseOptions);
      }
export type CompanyMutationHookResult = ReturnType<typeof useCompanyMutation>;
export const GetCompanyBySlugDocument = gql`
    query getCompanyBySlug($slug: String!) {
  companyBySlug(slug: $slug) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export function useGetCompanyBySlugQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyBySlugQuery, GetCompanyBySlugQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyBySlugQuery, GetCompanyBySlugQueryVariables>(GetCompanyBySlugDocument, baseOptions);
      }
export function useGetCompanyBySlugLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyBySlugQuery, GetCompanyBySlugQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyBySlugQuery, GetCompanyBySlugQueryVariables>(GetCompanyBySlugDocument, baseOptions);
        }
export type GetCompanyBySlugQueryHookResult = ReturnType<typeof useGetCompanyBySlugQuery>;
export type GetCompanyBySlugLazyQueryHookResult = ReturnType<typeof useGetCompanyBySlugLazyQuery>;
export const GetCompanyInfoDocument = gql`
    query getCompanyInfo($id: ID!) {
  company(id: $id) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export function useGetCompanyInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>(GetCompanyInfoDocument, baseOptions);
      }
export function useGetCompanyInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>(GetCompanyInfoDocument, baseOptions);
        }
export type GetCompanyInfoQueryHookResult = ReturnType<typeof useGetCompanyInfoQuery>;
export type GetCompanyInfoLazyQueryHookResult = ReturnType<typeof useGetCompanyInfoLazyQuery>;
export const GetCompanyReviewsDocument = gql`
    query getCompanyReviews {
  me {
    myOrderRatings {
      edges {
        node {
          ...OrderRating
        }
        cursor
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
}
    ${OrderRatingFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetCompanyReviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyReviewsQuery, GetCompanyReviewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyReviewsQuery, GetCompanyReviewsQueryVariables>(GetCompanyReviewsDocument, baseOptions);
      }
export function useGetCompanyReviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyReviewsQuery, GetCompanyReviewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyReviewsQuery, GetCompanyReviewsQueryVariables>(GetCompanyReviewsDocument, baseOptions);
        }
export type GetCompanyReviewsQueryHookResult = ReturnType<typeof useGetCompanyReviewsQuery>;
export type GetCompanyReviewsLazyQueryHookResult = ReturnType<typeof useGetCompanyReviewsLazyQuery>;
export const GetCompanyReviewsByIdDocument = gql`
    query getCompanyReviewsById($id: ID!, $limit: Int, $cursor: String) {
  orderRatings(company: $id, first: $limit, after: $cursor) {
    edges {
      node {
        ...OrderRating
      }
      cursor
    }
    totalCount
    pageInfo {
      ...PageInfo
    }
  }
}
    ${OrderRatingFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetCompanyReviewsByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyReviewsByIdQuery, GetCompanyReviewsByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyReviewsByIdQuery, GetCompanyReviewsByIdQueryVariables>(GetCompanyReviewsByIdDocument, baseOptions);
      }
export function useGetCompanyReviewsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyReviewsByIdQuery, GetCompanyReviewsByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyReviewsByIdQuery, GetCompanyReviewsByIdQueryVariables>(GetCompanyReviewsByIdDocument, baseOptions);
        }
export type GetCompanyReviewsByIdQueryHookResult = ReturnType<typeof useGetCompanyReviewsByIdQuery>;
export type GetCompanyReviewsByIdLazyQueryHookResult = ReturnType<typeof useGetCompanyReviewsByIdLazyQuery>;
export const GetMyCompanyUsersDocument = gql`
    query getMyCompanyUsers {
  me {
    myCompanyUsers {
      edges {
        node {
          email
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
}
    ${PageInfoFragmentDoc}`;
export function useGetMyCompanyUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyCompanyUsersQuery, GetMyCompanyUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyCompanyUsersQuery, GetMyCompanyUsersQueryVariables>(GetMyCompanyUsersDocument, baseOptions);
      }
export function useGetMyCompanyUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyCompanyUsersQuery, GetMyCompanyUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyCompanyUsersQuery, GetMyCompanyUsersQueryVariables>(GetMyCompanyUsersDocument, baseOptions);
        }
export type GetMyCompanyUsersQueryHookResult = ReturnType<typeof useGetMyCompanyUsersQuery>;
export type GetMyCompanyUsersLazyQueryHookResult = ReturnType<typeof useGetMyCompanyUsersLazyQuery>;
export const GetAppConfigDocument = gql`
    query getAppConfig($lang: String!, $adAmount: Int!, $auctionAmount: Int!) {
  appConfig(language: $lang, adAmount: $adAmount, auctionAmount: $auctionAmount) {
    ...AppConfig
  }
}
    ${AppConfigFragmentDoc}`;
export function useGetAppConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppConfigQuery, GetAppConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAppConfigQuery, GetAppConfigQueryVariables>(GetAppConfigDocument, baseOptions);
      }
export function useGetAppConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppConfigQuery, GetAppConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAppConfigQuery, GetAppConfigQueryVariables>(GetAppConfigDocument, baseOptions);
        }
export type GetAppConfigQueryHookResult = ReturnType<typeof useGetAppConfigQuery>;
export type GetAppConfigLazyQueryHookResult = ReturnType<typeof useGetAppConfigLazyQuery>;
export const GetGeneralOrderClassifiersDocument = gql`
    query GetGeneralOrderClassifiers($language: String, $slug: String, $ids: [ID]) {
  appConfig(language: $language) {
    generalOrderCategories(slug: $slug, ids: $ids) {
      ...Category
    }
    terrainTypes {
      ...TerrainType
    }
  }
}
    ${CategoryFragmentDoc}
${TerrainTypeFragmentDoc}`;
export function useGetGeneralOrderClassifiersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralOrderClassifiersQuery, GetGeneralOrderClassifiersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralOrderClassifiersQuery, GetGeneralOrderClassifiersQueryVariables>(GetGeneralOrderClassifiersDocument, baseOptions);
      }
export function useGetGeneralOrderClassifiersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralOrderClassifiersQuery, GetGeneralOrderClassifiersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralOrderClassifiersQuery, GetGeneralOrderClassifiersQueryVariables>(GetGeneralOrderClassifiersDocument, baseOptions);
        }
export type GetGeneralOrderClassifiersQueryHookResult = ReturnType<typeof useGetGeneralOrderClassifiersQuery>;
export type GetGeneralOrderClassifiersLazyQueryHookResult = ReturnType<typeof useGetGeneralOrderClassifiersLazyQuery>;
export const CreateContractorDocument = gql`
    mutation createContractor($input: CreateContractorMutationInput!) {
  createContractor(input: $input) {
    generalOrderContractor {
      ...ContractorInput
    }
  }
}
    ${ContractorInputFragmentDoc}`;
export type CreateContractorMutationFn = ApolloReactCommon.MutationFunction<CreateContractorMutation, CreateContractorMutationVariables>;
export function useCreateContractorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractorMutation, CreateContractorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractorMutation, CreateContractorMutationVariables>(CreateContractorDocument, baseOptions);
      }
export type CreateContractorMutationHookResult = ReturnType<typeof useCreateContractorMutation>;
export const GetExpertsDocument = gql`
    query getExperts {
  experts {
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        id
        name
        image
        thumbnail
        expertDescription
        isExpert
      }
    }
  }
}
    ${PageInfoFragmentDoc}`;
export function useGetExpertsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExpertsQuery, GetExpertsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExpertsQuery, GetExpertsQueryVariables>(GetExpertsDocument, baseOptions);
      }
export function useGetExpertsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExpertsQuery, GetExpertsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExpertsQuery, GetExpertsQueryVariables>(GetExpertsDocument, baseOptions);
        }
export type GetExpertsQueryHookResult = ReturnType<typeof useGetExpertsQuery>;
export type GetExpertsLazyQueryHookResult = ReturnType<typeof useGetExpertsLazyQuery>;
export const GetFaqDocument = gql`
    query getFAQ($language: String!) {
  faqs(language: $language) {
    category {
      ...FAQCategory
    }
    items {
      title
      text
      orderNum
    }
  }
}
    ${FaqCategoryFragmentDoc}`;
export function useGetFaqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFaqQuery, GetFaqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, baseOptions);
      }
export function useGetFaqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFaqQuery, GetFaqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFaqQuery, GetFaqQueryVariables>(GetFaqDocument, baseOptions);
        }
export type GetFaqQueryHookResult = ReturnType<typeof useGetFaqQuery>;
export type GetFaqLazyQueryHookResult = ReturnType<typeof useGetFaqLazyQuery>;
export const CreateForumThreadDocument = gql`
    mutation createForumThread($input: ForumThreadMutationInput!) {
  createForumThread(input: $input) {
    forumThread {
      id
      slug
    }
  }
}
    `;
export type CreateForumThreadMutationFn = ApolloReactCommon.MutationFunction<CreateForumThreadMutation, CreateForumThreadMutationVariables>;
export function useCreateForumThreadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateForumThreadMutation, CreateForumThreadMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateForumThreadMutation, CreateForumThreadMutationVariables>(CreateForumThreadDocument, baseOptions);
      }
export type CreateForumThreadMutationHookResult = ReturnType<typeof useCreateForumThreadMutation>;
export const CreateThreadReplyDocument = gql`
    mutation createThreadReply($input: ForumReplyMutationInput!) {
  createForumReply(input: $input) {
    forumReply {
      ...forumThreadReply
    }
  }
}
    ${ForumThreadReplyFragmentDoc}`;
export type CreateThreadReplyMutationFn = ApolloReactCommon.MutationFunction<CreateThreadReplyMutation, CreateThreadReplyMutationVariables>;
export function useCreateThreadReplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateThreadReplyMutation, CreateThreadReplyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateThreadReplyMutation, CreateThreadReplyMutationVariables>(CreateThreadReplyDocument, baseOptions);
      }
export type CreateThreadReplyMutationHookResult = ReturnType<typeof useCreateThreadReplyMutation>;
export const UploadReplyImageDocument = gql`
    mutation uploadReplyImage($input: ReplyImageMutationInput!) {
  uploadReplyImage(input: $input) {
    forumReplyImage {
      thumbnail
      image
      id
    }
  }
}
    `;
export type UploadReplyImageMutationFn = ApolloReactCommon.MutationFunction<UploadReplyImageMutation, UploadReplyImageMutationVariables>;
export function useUploadReplyImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadReplyImageMutation, UploadReplyImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadReplyImageMutation, UploadReplyImageMutationVariables>(UploadReplyImageDocument, baseOptions);
      }
export type UploadReplyImageMutationHookResult = ReturnType<typeof useUploadReplyImageMutation>;
export const UploadThreadImageDocument = gql`
    mutation uploadThreadImage($input: ThreadImageMutationInput!) {
  uploadThreadImage(input: $input) {
    forumThreadImage {
      thumbnail
      image
      id
    }
  }
}
    `;
export type UploadThreadImageMutationFn = ApolloReactCommon.MutationFunction<UploadThreadImageMutation, UploadThreadImageMutationVariables>;
export function useUploadThreadImageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadThreadImageMutation, UploadThreadImageMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadThreadImageMutation, UploadThreadImageMutationVariables>(UploadThreadImageDocument, baseOptions);
      }
export type UploadThreadImageMutationHookResult = ReturnType<typeof useUploadThreadImageMutation>;
export const ForumDocument = gql`
    query forum($slug: String!) {
  forum(slug: $slug) {
    ...ForumInfoFragment
  }
}
    ${ForumInfoFragmentFragmentDoc}`;
export function useForumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ForumQuery, ForumQueryVariables>) {
        return ApolloReactHooks.useQuery<ForumQuery, ForumQueryVariables>(ForumDocument, baseOptions);
      }
export function useForumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ForumQuery, ForumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ForumQuery, ForumQueryVariables>(ForumDocument, baseOptions);
        }
export type ForumQueryHookResult = ReturnType<typeof useForumQuery>;
export type ForumLazyQueryHookResult = ReturnType<typeof useForumLazyQuery>;
export const GetForumCategoriesDocument = gql`
    query getForumCategories {
  forumCategories {
    ...ForumCategories
  }
}
    ${ForumCategoriesFragmentDoc}`;
export function useGetForumCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetForumCategoriesQuery, GetForumCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetForumCategoriesQuery, GetForumCategoriesQueryVariables>(GetForumCategoriesDocument, baseOptions);
      }
export function useGetForumCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetForumCategoriesQuery, GetForumCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetForumCategoriesQuery, GetForumCategoriesQueryVariables>(GetForumCategoriesDocument, baseOptions);
        }
export type GetForumCategoriesQueryHookResult = ReturnType<typeof useGetForumCategoriesQuery>;
export type GetForumCategoriesLazyQueryHookResult = ReturnType<typeof useGetForumCategoriesLazyQuery>;
export const GetForumThreadDocument = gql`
    query getForumThread($slug: String!) {
  forumThread(slug: $slug) {
    ...ForumThread
  }
}
    ${ForumThreadFragmentDoc}`;
export function useGetForumThreadQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetForumThreadQuery, GetForumThreadQueryVariables>) {
        return ApolloReactHooks.useQuery<GetForumThreadQuery, GetForumThreadQueryVariables>(GetForumThreadDocument, baseOptions);
      }
export function useGetForumThreadLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetForumThreadQuery, GetForumThreadQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetForumThreadQuery, GetForumThreadQueryVariables>(GetForumThreadDocument, baseOptions);
        }
export type GetForumThreadQueryHookResult = ReturnType<typeof useGetForumThreadQuery>;
export type GetForumThreadLazyQueryHookResult = ReturnType<typeof useGetForumThreadLazyQuery>;
export const ForumThreadsDocument = gql`
    query forumThreads($forum: ID!, $cursor: String, $limit: Int) {
  forumThreads(forum: $forum, first: $limit, after: $cursor) {
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        ...forumThreadListFragment
      }
    }
    totalCount
  }
}
    ${PageInfoFragmentDoc}
${ForumThreadListFragmentFragmentDoc}`;
export function useForumThreadsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ForumThreadsQuery, ForumThreadsQueryVariables>) {
        return ApolloReactHooks.useQuery<ForumThreadsQuery, ForumThreadsQueryVariables>(ForumThreadsDocument, baseOptions);
      }
export function useForumThreadsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ForumThreadsQuery, ForumThreadsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ForumThreadsQuery, ForumThreadsQueryVariables>(ForumThreadsDocument, baseOptions);
        }
export type ForumThreadsQueryHookResult = ReturnType<typeof useForumThreadsQuery>;
export type ForumThreadsLazyQueryHookResult = ReturnType<typeof useForumThreadsLazyQuery>;
export const ForumThreadRepliesDocument = gql`
    query forumThreadReplies($thread: ID!, $limit: Int, $cursor: String) {
  forumThreadReplies(thread: $thread, last: $limit, before: $cursor) {
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        ...forumThreadReply
      }
    }
    totalCount
  }
}
    ${PageInfoFragmentDoc}
${ForumThreadReplyFragmentDoc}`;
export function useForumThreadRepliesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ForumThreadRepliesQuery, ForumThreadRepliesQueryVariables>) {
        return ApolloReactHooks.useQuery<ForumThreadRepliesQuery, ForumThreadRepliesQueryVariables>(ForumThreadRepliesDocument, baseOptions);
      }
export function useForumThreadRepliesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ForumThreadRepliesQuery, ForumThreadRepliesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ForumThreadRepliesQuery, ForumThreadRepliesQueryVariables>(ForumThreadRepliesDocument, baseOptions);
        }
export type ForumThreadRepliesQueryHookResult = ReturnType<typeof useForumThreadRepliesQuery>;
export type ForumThreadRepliesLazyQueryHookResult = ReturnType<typeof useForumThreadRepliesLazyQuery>;
export const CreateGeneralOrderDocument = gql`
    mutation createGeneralOrder($input: CreateGeneralOrderMutationInput!) {
  createGeneralOrder(input: $input) {
    generalOrder {
      ...GeneralOrder
    }
  }
}
    ${GeneralOrderFragmentDoc}`;
export type CreateGeneralOrderMutationFn = ApolloReactCommon.MutationFunction<CreateGeneralOrderMutation, CreateGeneralOrderMutationVariables>;
export function useCreateGeneralOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGeneralOrderMutation, CreateGeneralOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGeneralOrderMutation, CreateGeneralOrderMutationVariables>(CreateGeneralOrderDocument, baseOptions);
      }
export type CreateGeneralOrderMutationHookResult = ReturnType<typeof useCreateGeneralOrderMutation>;
export const GetGeneralOrderDocument = gql`
    query GetGeneralOrder($id: ID!) {
  generalOrder(id: $id) {
    ...GeneralOrderFetchResult
  }
}
    ${GeneralOrderFetchResultFragmentDoc}`;
export function useGetGeneralOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralOrderQuery, GetGeneralOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralOrderQuery, GetGeneralOrderQueryVariables>(GetGeneralOrderDocument, baseOptions);
      }
export function useGetGeneralOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralOrderQuery, GetGeneralOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralOrderQuery, GetGeneralOrderQueryVariables>(GetGeneralOrderDocument, baseOptions);
        }
export type GetGeneralOrderQueryHookResult = ReturnType<typeof useGetGeneralOrderQuery>;
export type GetGeneralOrderLazyQueryHookResult = ReturnType<typeof useGetGeneralOrderLazyQuery>;
export const CreateGeneralOfferDocument = gql`
    mutation createGeneralOffer($input: CreateGeneralOfferMutationInput!) {
  createGeneralOffer(input: $input) {
    generalOffer {
      ...GeneralOfferInput
    }
  }
}
    ${GeneralOfferInputFragmentDoc}`;
export type CreateGeneralOfferMutationFn = ApolloReactCommon.MutationFunction<CreateGeneralOfferMutation, CreateGeneralOfferMutationVariables>;
export function useCreateGeneralOfferMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGeneralOfferMutation, CreateGeneralOfferMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGeneralOfferMutation, CreateGeneralOfferMutationVariables>(CreateGeneralOfferDocument, baseOptions);
      }
export type CreateGeneralOfferMutationHookResult = ReturnType<typeof useCreateGeneralOfferMutation>;
export const MyCompanyInvoicingInfoDocument = gql`
    mutation myCompanyInvoicingInfo($input: InvoicingInfoMutationInput!) {
  myCompanyInvoicingInfo(input: $input) {
    invoicingInfo {
      id
    }
  }
}
    `;
export type MyCompanyInvoicingInfoMutationFn = ApolloReactCommon.MutationFunction<MyCompanyInvoicingInfoMutation, MyCompanyInvoicingInfoMutationVariables>;
export function useMyCompanyInvoicingInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MyCompanyInvoicingInfoMutation, MyCompanyInvoicingInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<MyCompanyInvoicingInfoMutation, MyCompanyInvoicingInfoMutationVariables>(MyCompanyInvoicingInfoDocument, baseOptions);
      }
export type MyCompanyInvoicingInfoMutationHookResult = ReturnType<typeof useMyCompanyInvoicingInfoMutation>;
export const GetInvoiceDocument = gql`
    query getInvoice {
  me {
    myCompanies {
      company {
        invoicingInfo {
          ...Invoice
        }
      }
    }
  }
}
    ${InvoiceFragmentDoc}`;
export function useGetInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
      }
export function useGetInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
        }
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export const GetMyInvoicesDocument = gql`
    query getMyInvoices {
  me {
    myInvoices {
      edges {
        node {
          ...MyInvoice
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
}
    ${MyInvoiceFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetMyInvoicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyInvoicesQuery, GetMyInvoicesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyInvoicesQuery, GetMyInvoicesQueryVariables>(GetMyInvoicesDocument, baseOptions);
      }
export function useGetMyInvoicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyInvoicesQuery, GetMyInvoicesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyInvoicesQuery, GetMyInvoicesQueryVariables>(GetMyInvoicesDocument, baseOptions);
        }
export type GetMyInvoicesQueryHookResult = ReturnType<typeof useGetMyInvoicesQuery>;
export type GetMyInvoicesLazyQueryHookResult = ReturnType<typeof useGetMyInvoicesLazyQuery>;
export const ChangeLanguageDocument = gql`
    mutation changeLanguage($input: ChangeLanguageInput!) {
  changeLanguage(input: $input) {
    user
    language {
      id
      code
    }
  }
}
    `;
export type ChangeLanguageMutationFn = ApolloReactCommon.MutationFunction<ChangeLanguageMutation, ChangeLanguageMutationVariables>;
export function useChangeLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeLanguageMutation, ChangeLanguageMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeLanguageMutation, ChangeLanguageMutationVariables>(ChangeLanguageDocument, baseOptions);
      }
export type ChangeLanguageMutationHookResult = ReturnType<typeof useChangeLanguageMutation>;
export const MarkReadDocument = gql`
    mutation markRead($input: ReadNotificationMutationInput!) {
  markRead(input: $input) {
    notification {
      description
      unread
    }
  }
}
    `;
export type MarkReadMutationFn = ApolloReactCommon.MutationFunction<MarkReadMutation, MarkReadMutationVariables>;
export function useMarkReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkReadMutation, MarkReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkReadMutation, MarkReadMutationVariables>(MarkReadDocument, baseOptions);
      }
export type MarkReadMutationHookResult = ReturnType<typeof useMarkReadMutation>;
export const GetNotificationsDocument = gql`
    query getNotifications($limit: Int, $cursor: String) {
  me {
    myNotifications(first: $limit, after: $cursor) {
      edges {
        node {
          ...Notification
        }
        cursor
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
}
    ${NotificationFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
      }
export function useGetNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, baseOptions);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export const GetUnreadNotificationsCountDocument = gql`
    query getUnreadNotificationsCount {
  me {
    myNotifications(unread: true) {
      totalCount
    }
  }
}
    `;
export function useGetUnreadNotificationsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUnreadNotificationsCountQuery, GetUnreadNotificationsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUnreadNotificationsCountQuery, GetUnreadNotificationsCountQueryVariables>(GetUnreadNotificationsCountDocument, baseOptions);
      }
export function useGetUnreadNotificationsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUnreadNotificationsCountQuery, GetUnreadNotificationsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUnreadNotificationsCountQuery, GetUnreadNotificationsCountQueryVariables>(GetUnreadNotificationsCountDocument, baseOptions);
        }
export type GetUnreadNotificationsCountQueryHookResult = ReturnType<typeof useGetUnreadNotificationsCountQuery>;
export type GetUnreadNotificationsCountLazyQueryHookResult = ReturnType<typeof useGetUnreadNotificationsCountLazyQuery>;
export const CreateOrderMutationDocument = gql`
    mutation createOrderMutation($input: CreateOrderMutationInput!) {
  createOrder(input: $input) {
    order {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}`;
export type CreateOrderMutationMutationFn = ApolloReactCommon.MutationFunction<CreateOrderMutationMutation, CreateOrderMutationMutationVariables>;
export function useCreateOrderMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderMutationMutation, CreateOrderMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderMutationMutation, CreateOrderMutationMutationVariables>(CreateOrderMutationDocument, baseOptions);
      }
export type CreateOrderMutationMutationHookResult = ReturnType<typeof useCreateOrderMutationMutation>;
export const CreateOrderRatingDocument = gql`
    mutation createOrderRating($input: OrderRatingMutationInput!) {
  orderRating(input: $input) {
    orderRating {
      ...OrderRating
    }
  }
}
    ${OrderRatingFragmentDoc}`;
export type CreateOrderRatingMutationFn = ApolloReactCommon.MutationFunction<CreateOrderRatingMutation, CreateOrderRatingMutationVariables>;
export function useCreateOrderRatingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrderRatingMutation, CreateOrderRatingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrderRatingMutation, CreateOrderRatingMutationVariables>(CreateOrderRatingDocument, baseOptions);
      }
export type CreateOrderRatingMutationHookResult = ReturnType<typeof useCreateOrderRatingMutation>;
export const MarkReadOrderNotificationsDocument = gql`
    mutation markReadOrderNotifications($input: ReadOrderNotificationsMutationInput!) {
  markReadOrderNotifications(input: $input) {
    order {
      id
    }
  }
}
    `;
export type MarkReadOrderNotificationsMutationFn = ApolloReactCommon.MutationFunction<MarkReadOrderNotificationsMutation, MarkReadOrderNotificationsMutationVariables>;
export function useMarkReadOrderNotificationsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkReadOrderNotificationsMutation, MarkReadOrderNotificationsMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkReadOrderNotificationsMutation, MarkReadOrderNotificationsMutationVariables>(MarkReadOrderNotificationsDocument, baseOptions);
      }
export type MarkReadOrderNotificationsMutationHookResult = ReturnType<typeof useMarkReadOrderNotificationsMutation>;
export const UpdateOrderMutationDocument = gql`
    mutation updateOrderMutation($input: UpdateOrderMutationInput!) {
  updateOrder(input: $input) {
    order {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}`;
export type UpdateOrderMutationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderMutationMutation, UpdateOrderMutationMutationVariables>;
export function useUpdateOrderMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderMutationMutation, UpdateOrderMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderMutationMutation, UpdateOrderMutationMutationVariables>(UpdateOrderMutationDocument, baseOptions);
      }
export type UpdateOrderMutationMutationHookResult = ReturnType<typeof useUpdateOrderMutationMutation>;
export const UpdateOrderStatusDocument = gql`
    mutation updateOrderStatus($input: UpdateOrderStatusInput!) {
  updateOrderStatus(input: $input) {
    order {
      id
      status
    }
  }
}
    `;
export type UpdateOrderStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>;
export function useUpdateOrderStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>(UpdateOrderStatusDocument, baseOptions);
      }
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>;
export const MyOrdersDocument = gql`
    query myOrders($status: OrderStatus, $limit: Int, $cursor: String, $orderby: [String], $orderType: OrderType) {
  me {
    myOrders(status: $status, first: $limit, after: $cursor, orderby: $orderby, orderType: $orderType) {
      edges {
        node {
          ...Order
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
}
    ${OrderFragmentDoc}
${PageInfoFragmentDoc}`;
export function useMyOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyOrdersQuery, MyOrdersQueryVariables>) {
        return ApolloReactHooks.useQuery<MyOrdersQuery, MyOrdersQueryVariables>(MyOrdersDocument, baseOptions);
      }
export function useMyOrdersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyOrdersQuery, MyOrdersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyOrdersQuery, MyOrdersQueryVariables>(MyOrdersDocument, baseOptions);
        }
export type MyOrdersQueryHookResult = ReturnType<typeof useMyOrdersQuery>;
export type MyOrdersLazyQueryHookResult = ReturnType<typeof useMyOrdersLazyQuery>;
export const GetOrderInfoDocument = gql`
    query getOrderInfo($id: ID!) {
  order(id: $id) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export function useGetOrderInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderInfoQuery, GetOrderInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderInfoQuery, GetOrderInfoQueryVariables>(GetOrderInfoDocument, baseOptions);
      }
export function useGetOrderInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderInfoQuery, GetOrderInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderInfoQuery, GetOrderInfoQueryVariables>(GetOrderInfoDocument, baseOptions);
        }
export type GetOrderInfoQueryHookResult = ReturnType<typeof useGetOrderInfoQuery>;
export type GetOrderInfoLazyQueryHookResult = ReturnType<typeof useGetOrderInfoLazyQuery>;
export const GetOrderRatingDocument = gql`
    query getOrderRating($id: ID!) {
  orderRating(orderId: $id) {
    ...OrderRating
  }
}
    ${OrderRatingFragmentDoc}`;
export function useGetOrderRatingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrderRatingQuery, GetOrderRatingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrderRatingQuery, GetOrderRatingQueryVariables>(GetOrderRatingDocument, baseOptions);
      }
export function useGetOrderRatingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderRatingQuery, GetOrderRatingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrderRatingQuery, GetOrderRatingQueryVariables>(GetOrderRatingDocument, baseOptions);
        }
export type GetOrderRatingQueryHookResult = ReturnType<typeof useGetOrderRatingQuery>;
export type GetOrderRatingLazyQueryHookResult = ReturnType<typeof useGetOrderRatingLazyQuery>;
export const AddCardDocument = gql`
    mutation addCard($input: AddCardInput!) {
  addCard(input: $input) {
    stripeCard {
      ...StripeCard
    }
  }
}
    ${StripeCardFragmentDoc}`;
export type AddCardMutationFn = ApolloReactCommon.MutationFunction<AddCardMutation, AddCardMutationVariables>;
export function useAddCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCardMutation, AddCardMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCardMutation, AddCardMutationVariables>(AddCardDocument, baseOptions);
      }
export type AddCardMutationHookResult = ReturnType<typeof useAddCardMutation>;
export const CreateStripePaymentDocument = gql`
    mutation createStripePayment($input: CreateStripePaymentInput!) {
  createStripePayment(input: $input) {
    stripePayment {
      order {
        id
      }
      status
    }
    clientSecret
    clientMutationId
  }
}
    `;
export type CreateStripePaymentMutationFn = ApolloReactCommon.MutationFunction<CreateStripePaymentMutation, CreateStripePaymentMutationVariables>;
export function useCreateStripePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStripePaymentMutation, CreateStripePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStripePaymentMutation, CreateStripePaymentMutationVariables>(CreateStripePaymentDocument, baseOptions);
      }
export type CreateStripePaymentMutationHookResult = ReturnType<typeof useCreateStripePaymentMutation>;
export const FetchPaymentSuccessStatusDocument = gql`
    mutation fetchPaymentSuccessStatus($input: StripePaymentSuccessStatusInput!) {
  fetchPaymentSuccessStatus(input: $input) {
    order {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}`;
export type FetchPaymentSuccessStatusMutationFn = ApolloReactCommon.MutationFunction<FetchPaymentSuccessStatusMutation, FetchPaymentSuccessStatusMutationVariables>;
export function useFetchPaymentSuccessStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FetchPaymentSuccessStatusMutation, FetchPaymentSuccessStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<FetchPaymentSuccessStatusMutation, FetchPaymentSuccessStatusMutationVariables>(FetchPaymentSuccessStatusDocument, baseOptions);
      }
export type FetchPaymentSuccessStatusMutationHookResult = ReturnType<typeof useFetchPaymentSuccessStatusMutation>;
export const RemoveCardDocument = gql`
    mutation removeCard($input: RemoveCardInput!) {
  removeCard(input: $input) {
    stripeCard {
      id
    }
  }
}
    `;
export type RemoveCardMutationFn = ApolloReactCommon.MutationFunction<RemoveCardMutation, RemoveCardMutationVariables>;
export function useRemoveCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCardMutation, RemoveCardMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCardMutation, RemoveCardMutationVariables>(RemoveCardDocument, baseOptions);
      }
export type RemoveCardMutationHookResult = ReturnType<typeof useRemoveCardMutation>;
export const GetMyCardsDocument = gql`
    query getMyCards($limit: Int, $cursor: String) {
  myCards(first: $limit, after: $cursor) {
    edges {
      node {
        ...StripeCard
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${StripeCardFragmentDoc}
${PageInfoFragmentDoc}`;
export function useGetMyCardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyCardsQuery, GetMyCardsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyCardsQuery, GetMyCardsQueryVariables>(GetMyCardsDocument, baseOptions);
      }
export function useGetMyCardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyCardsQuery, GetMyCardsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyCardsQuery, GetMyCardsQueryVariables>(GetMyCardsDocument, baseOptions);
        }
export type GetMyCardsQueryHookResult = ReturnType<typeof useGetMyCardsQuery>;
export type GetMyCardsLazyQueryHookResult = ReturnType<typeof useGetMyCardsLazyQuery>;
export const CreatePreliminaryOrderMutationDocument = gql`
    mutation createPreliminaryOrderMutation($input: CreatePreliminaryOrderMutationInput!) {
  createPreliminaryOrder(input: $input) {
    preliminaryOrder {
      ...PreliminaryOrder
    }
  }
}
    ${PreliminaryOrderFragmentDoc}`;
export type CreatePreliminaryOrderMutationMutationFn = ApolloReactCommon.MutationFunction<CreatePreliminaryOrderMutationMutation, CreatePreliminaryOrderMutationMutationVariables>;
export function useCreatePreliminaryOrderMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePreliminaryOrderMutationMutation, CreatePreliminaryOrderMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePreliminaryOrderMutationMutation, CreatePreliminaryOrderMutationMutationVariables>(CreatePreliminaryOrderMutationDocument, baseOptions);
      }
export type CreatePreliminaryOrderMutationMutationHookResult = ReturnType<typeof useCreatePreliminaryOrderMutationMutation>;
export const GetStaticPageDocument = gql`
    query getStaticPage($slug: String!) {
  pageTranslation(slug: $slug) {
    slug
    text
    title
  }
}
    `;
export function useGetStaticPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStaticPageQuery, GetStaticPageQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStaticPageQuery, GetStaticPageQueryVariables>(GetStaticPageDocument, baseOptions);
      }
export function useGetStaticPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStaticPageQuery, GetStaticPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStaticPageQuery, GetStaticPageQueryVariables>(GetStaticPageDocument, baseOptions);
        }
export type GetStaticPageQueryHookResult = ReturnType<typeof useGetStaticPageQuery>;
export type GetStaticPageLazyQueryHookResult = ReturnType<typeof useGetStaticPageLazyQuery>;
export const ChangeActiveCompanyDocument = gql`
    mutation changeActiveCompany($input: ChangeActiveCompanyInput!) {
  changeActiveCompany(input: $input) {
    user {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type ChangeActiveCompanyMutationFn = ApolloReactCommon.MutationFunction<ChangeActiveCompanyMutation, ChangeActiveCompanyMutationVariables>;
export function useChangeActiveCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeActiveCompanyMutation, ChangeActiveCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeActiveCompanyMutation, ChangeActiveCompanyMutationVariables>(ChangeActiveCompanyDocument, baseOptions);
      }
export type ChangeActiveCompanyMutationHookResult = ReturnType<typeof useChangeActiveCompanyMutation>;
export const DeleteUserDocument = gql`
    mutation deleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    clientMutationId
  }
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export const UpdateUserDataDocument = gql`
    mutation updateUserData($input: UpdateUserDataInput!) {
  updateUserData(input: $input) {
    user {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateUserDataMutationFn = ApolloReactCommon.MutationFunction<UpdateUserDataMutation, UpdateUserDataMutationVariables>;
export function useUpdateUserDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserDataMutation, UpdateUserDataMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserDataMutation, UpdateUserDataMutationVariables>(UpdateUserDataDocument, baseOptions);
      }
export type UpdateUserDataMutationHookResult = ReturnType<typeof useUpdateUserDataMutation>;