import qs from 'qs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import LoadingSpinner from '@Components/LoadingSpinner';
import { showSuccessModal } from '@Components/Modal/modalHelpers';
import { getGeneralOfferFormInput } from '@Components/Order/GeneralOrderAcceptance/generalOfferUtils';
import GeneralOrderAcceptanceForm from '@Components/Order/GeneralOrderAcceptance/GeneralOrderAcceptanceForm';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import {
  CreateGeneralOfferMutation,
  useCreateGeneralOfferMutation,
  useGetGeneralOrderQuery,
} from '@Graphql/graphqlTypes.generated';
import { useRouteParams } from '@Hooks/useRouteParams';
import { useTranslations } from '@Hooks/useTranslations';
import { getUserData } from '@Store/auth/auth.selector';
import { locationChange } from '@Store/navigation/navigation.actions';
import { GAAction, GACategory, sendGaEvent } from '@Utils/gaEvents';
import { callMutationForForm } from '@Utils/graphql';
import { GeneralOrderAcceptanceFormData } from '@Utils/types';

const GeneralOrderAcceptance = ({ onCancel }: { advertId?: string; onCancel?: () => void }) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const { item: user } = useSelector(getUserData);

  const { id: generalOrderId } = useRouteParams(RouteConfig.AcceptGeneralOrder);
  const location = useLocation();
  const searchData = qs.parse(location.search, { ignoreQueryPrefix: true });
  const providedEmail = typeof searchData?.email === 'string' ? searchData.email : undefined;
  const providedPhone = typeof searchData?.phone === 'string' ? searchData.phone : undefined;

  const { loading, data: generalOrderData } = useGetGeneralOrderQuery({ variables: { id: generalOrderId ?? '' } });

  const [acceptGeneralOrderAction] = useCreateGeneralOfferMutation({
    onCompleted: () => {
      dispatch(
        showSuccessModal(
          t(Messages.btnOk),
          () => {
            dispatch(locationChange({ path: RouteConfig.Marketplace.buildLink() }));
          },
          t('msg_general_offer_create_order_success'),
          t('msg_general_offer_create_order_success_subtext'),
        ),
      );
      sendGaEvent({
        category: GACategory.generalOffer,
        action: GAAction.click,
      });
    },
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!generalOrderData) {
    return <Navigate to={RouteConfig.NotFound.buildLink()} replace />;
  }

  const handleSubmit = async (formData: GeneralOrderAcceptanceFormData) => {
    const { data, type } = getGeneralOfferFormInput(formData, generalOrderData.generalOrder, t);
    if (type === 'ERROR') {
      return data;
    }

    return callMutationForForm<CreateGeneralOfferMutation>(
      acceptGeneralOrderAction({
        variables: {
          input: data,
        },
      }),
      formData,
    );
  };

  const initialValues: GeneralOrderAcceptanceFormData = {
    email: user?.info.email || providedEmail || '',
    phone: user?.info.activeCompany.company.phone || providedPhone || '',
    paymentCondition: '',
    offeredPrice: '',
    agreedWithPrice: 'YES',
    additionalInfo: '',
  };

  return (
    <GeneralOrderAcceptanceForm
      onSubmit={handleSubmit}
      onCancel={onCancel}
      initialValues={initialValues}
      generalOrder={generalOrderData.generalOrder}
    />
  );
};

export default GeneralOrderAcceptance;
