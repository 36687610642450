import { isSameDay, format } from 'date-fns';

import { CalendarViews, EventProps, EventTypes } from '@Components/Calendar';
import { Event, ScheduleItem } from '@Components/EventsCalendar/EventsCalendar';
import { EventType } from '@Components/Modal/CalendarEventModal';
import {
  ApplicationLanguages,
  MAX_ADVERT_IMAGES,
  MAX_AUCTION_IMAGES,
  orderAcceptedStatuses,
  orderEventTypesByCalendarEvent,
  orderPendingStatuses,
  TimeFormat,
} from '@Config/constants';
import { buildOrderStatusMessage } from '@Config/messages';
import {
  AdvertCalendarItemFragment,
  AdvertMutationInput,
  CreateAuctionMutationInput,
  DeliveryType,
  GetAdvertQuery,
  MyAuctionFragment,
  NotificationNodeEdge,
  OrderStatus,
  UpdateAuctionMutationInput,
} from '@Graphql/graphqlTypes.generated';
import {
  buildDateAndTime,
  formatDateOrTimeToString,
  getDateFromDateTime,
  getTimeFromDateTime,
  splitDateRangeToDays,
} from '@Utils/dates';
import { isFileOrBlob } from '@Utils/file';
import { convertCentsToMoney, convertMoneyToCents } from '@Utils/money';
import { Translator } from '@Utils/translation';
import {
  AdFormValues,
  AdSchedule,
  AuctionFormValues,
  CalendarEventType,
  CalendarNode,
  TreeLike,
  TreeNode,
} from '@Utils/types';

export const transformAdFormDataToMutationData = (formData: AdFormValues): AdvertMutationInput => {
  const data = { ...formData };

  const schedules = data.schedules
    ? data.schedules.map(scheduleItem => {
        const dateTimeFrom = buildDateAndTime(scheduleItem.startDate, scheduleItem.startTime);
        const dateTimeTo = buildDateAndTime(scheduleItem.endDate, scheduleItem.endTime);
        return {
          startDate: formatDateOrTimeToString(dateTimeFrom, TimeFormat.mutationDateTimeFormat),
          endDate: formatDateOrTimeToString(dateTimeTo, TimeFormat.mutationDateTimeFormat),
        };
      })
    : [];
  // If in payload we have file or blob we need to upload it,
  // if we have string, nothing changed so we do not send key.
  // If we want to remove we send null

  const mainImage = isFileOrBlob(data.mainImage)
    ? { mainImage: data.mainImage }
    : data.mainImage
      ? {}
      : { mainImage: null };

  delete data.mainImage;
  delete data.images;
  return {
    ...data,
    schedules,
    ...mainImage,
    price: convertMoneyToCents(data.price),
    deliveryPrice: convertMoneyToCents(data.deliveryPrice),
  };
};

export const transformAdDataToFormData = (
  advert: GetAdvertQuery['advert'],
  currentLanguage: ApplicationLanguages,
): AdFormValues => {
  const advertImagesForAdditionOPhotos = MAX_ADVERT_IMAGES - 1;

  const advertImages =
    advert.images.length < advertImagesForAdditionOPhotos
      ? [
          ...advert.images,
          ...Array(advertImagesForAdditionOPhotos - advert.images.length).fill({ image: '', thumbnail: '', id: '' }),
        ]
      : advert.images;

  const initialData: AdFormValues = {
    title: advert.title,
    pricingType: advert.pricingType,
    advertType: advert.advertType,
    description: advert.description,
    price: Number(convertCentsToMoney(advert.price)),
    id: advert.id,
    coordinates: advert.coordinates,
    isScheduled: advert.isScheduled,
    deliveryType: advert.deliveryType || DeliveryType.NoDelivery,
    mainImage: advert.mainImage,
    deliveryPrice: Number(convertCentsToMoney(advert.deliveryPrice)),
    serviceType: advert.serviceType,
    customFieldsData: advert.customFieldsData ? JSON.parse(advert.customFieldsData) : '',
    category: advert.category.id,
    schedules: advert.schedules
      ? advert.schedules.map(schedule => {
          const transformedSchedule: AdSchedule = {
            startDate: getDateFromDateTime(schedule.startDate, currentLanguage),
            endDate: getDateFromDateTime(schedule.endDate, currentLanguage),
            startTime: getTimeFromDateTime(schedule.startDate),
            endTime: getTimeFromDateTime(schedule.endDate),
          };

          return transformedSchedule;
        })
      : [],
    images: advertImages.map(image => image.image),
    status: advert.status,
    validUntil: advert.validUntil ? new Date(advert.validUntil) : undefined,
    ...(advert.address && { address: advert.address }),
  };

  return initialData;
};

export const transformAuctionDataToFormData = (auction: MyAuctionFragment): AuctionFormValues => {
  const auctionImagesForAdditionOPhotos = MAX_AUCTION_IMAGES - 1;

  const auctionItem = auction.auction;

  const auctionImages =
    auctionItem.images.length < auctionImagesForAdditionOPhotos
      ? [
          ...auction.auction.images,
          ...Array(auctionImagesForAdditionOPhotos - auctionItem.images.length).fill({
            image: '',
            id: '',
            thumbnail: '',
          }),
        ]
      : auctionItem.images;

  return {
    title: auctionItem.title,
    auctionType: auctionItem.auctionType,
    description: auctionItem.description,
    duration: auctionItem.duration,
    initialPrice: Number(convertCentsToMoney(auctionItem.initialPrice)),
    id: auctionItem.id,
    address: auctionItem.address,
    category: auctionItem.category.id,
    images: auctionImages.map(image => image.image),
    mainImage: auctionItem.mainImage,
    customFieldsData: auctionItem.customFieldsData,
    coordinates: auctionItem.coordinates,
  };
};

export const transformToTree = <T extends { id: string; parent?: T } | { id: string }>(
  items: TreeLike<T>[],
  sortBy?: (value: T) => number,
): TreeNode<T>[] => {
  const tree: TreeNode<T>[] = items.reduce((prev, curr) => {
    const parent = 'parent' in curr ? curr.parent : undefined;

    if (!parent?.id) {
      return prev.length
        ? prev
        : [
            {
              id: curr.id,
              element: curr,
              children: [],
            },
          ];
    }
    const currentParentCategory = prev.find(item => item.id === parent.id);

    if (!currentParentCategory) {
      return [
        ...prev,
        {
          id: parent.id,
          element: parent,
          children: [{ element: curr, id: curr.id, children: [] }],
        },
      ];
    }

    currentParentCategory.children.push({ element: curr, id: curr.id, children: [] });

    return prev;
  }, [] as TreeNode<T>[]);

  if (sortBy) {
    const sortFunction = (a: TreeNode<T>, b: TreeNode<T>) => sortBy(a.element) - sortBy(b.element);
    return tree.sort(sortFunction).map(item => {
      return {
        ...item,
        children: item.children.sort(sortFunction),
      };
    });
  }

  return tree;
};

const getEventDataObj = (event: CalendarNode, type?: EventTypes) => ({
  ...(event.id && { id: event.id }),
  ...(event.title && { title: event.title }),
  ...(event.description && { description: event.description }),
  ...(type && { type }),
});

export const transformDataToCalendarEvents = (nodes: CalendarNode[], view: string, type?: EventTypes): EventProps[] => {
  const dates: EventProps[] = [];
  nodes.forEach(item => {
    const start = new Date(item.startDate);
    const end = new Date(item.endDate);
    if (view === CalendarViews.month) {
      dates.push({
        start,
        end,
        ...getEventDataObj(item, type),
      });
    } else {
      const splitDates: EventType[] = splitDateRangeToDays(start, end).map(eventItem => {
        return {
          ...eventItem,
          ...getEventDataObj(item, type),
        };
      });

      dates.push(...splitDates);
    }
  });
  return dates;
};

export const getUniqueDatesFromNotifications = (notifications: NotificationNodeEdge[]) => {
  return notifications
    .map(item => ({ date: format(new Date(item.node.timestamp), TimeFormat.date) }))
    .filter((el, i, array) => array.map(({ date }) => date).indexOf(el.date) === i);
};

export const getGroupedNotificationsByDate = (notifications: NotificationNodeEdge[]) => {
  return getUniqueDatesFromNotifications(notifications).map(({ date }) => {
    const notification = notifications.filter(item => {
      return isSameDay(new Date(item.node.timestamp), new Date(date));
    });

    return { date, notifications: [...notification] };
  });
};

export const transformAuctionFormDataToCreateMutationData = (
  formData: AuctionFormValues,
): CreateAuctionMutationInput => {
  const data = { ...formData };

  const mainImage = isFileOrBlob(data.mainImage)
    ? { mainImage: data.mainImage }
    : data.mainImage
      ? {}
      : { mainImage: null };

  delete data.mainImage;
  delete data.images;
  return {
    ...data,
    ...mainImage,
    initialPrice: convertMoneyToCents(data.initialPrice),
  };
};

export const transformAuctionFormDataToUpdateMutationData = (
  formData: AuctionFormValues,
): UpdateAuctionMutationInput => {
  const data = { ...formData };

  const mainImage = isFileOrBlob(data.mainImage)
    ? { mainImage: data.mainImage }
    : data.mainImage
      ? {}
      : { mainImage: null };

  delete data.mainImage;
  delete data.images;
  return {
    id: data.id || '',
    ...data,
    ...mainImage,
    initialPrice: convertMoneyToCents(data.initialPrice),
  };
};

export const orderTypeToCalendarEventType = (orderStatus: OrderStatus): CalendarEventType => {
  if (orderPendingStatuses.includes(orderStatus)) {
    return CalendarEventType.PENDING;
  }
  if (orderAcceptedStatuses.includes(orderStatus)) {
    return CalendarEventType.ACCEPTED;
  }
  return CalendarEventType.DEFAULT;
};

export const calendarEventTypeToOrderTypes = (calendarEvent: CalendarEventType): OrderStatus[] => [
  ...orderEventTypesByCalendarEvent[calendarEvent],
];

export const calendarEventTypeListToOrderTypes = (calendarEvent: CalendarEventType[]): OrderStatus[] => [
  ...new Set(calendarEvent.flatMap(calendarEventTypeToOrderTypes)),
];

export const transformToCalendarEvents = (data: AdvertCalendarItemFragment, t: Translator): Event[] => {
  const customEvents = data.events.map(event => ({
    itemId: data.advert.id,
    title: event.title,
    description: event.description,
    eventId: event.id,
    eventType: CalendarEventType.CUSTOM,
    startDate: new Date(event.startDate),
    endDate: new Date(event.endDate),
  }));

  const orderEvents = data.orders.map(order => ({
    itemId: data.advert.id,
    orderId: order.id,
    title:
      orderPendingStatuses.includes(order.status) || orderAcceptedStatuses.includes(order.status)
        ? `${t(buildOrderStatusMessage(order.status))}: ${order.company.name}`
        : order.company.name,
    eventType: orderTypeToCalendarEventType(order.status),
    startDate: new Date(order.startDate),
    endDate: new Date(order.endDate),
  }));

  return [...customEvents, ...orderEvents];
};

export const transformToCalendarItems = (data: AdvertCalendarItemFragment, t: Translator): ScheduleItem => ({
  id: data.advert.id,
  name: data.advert.title,
  events: transformToCalendarEvents(data, t),
});
