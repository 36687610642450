import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import React, { useState } from 'react';

import LoadingSpinner from '@Components/LoadingSpinner';
import OrderItem from '@Components/Order/OrderItem';
import Typography from '@Components/Typography';
import { MAP_WINDOW_POSITION } from '@Config/constants';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import { OrderFragment, OrderType, useMyOrdersQuery, OrderStatus } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import redPin from '@Images/icons/map-pin-red.png';

import styles from './OrderResultsMap.module.scss';

const getBounds = (orderItemsWithLocation: OrderFragment[]) => {
  const bounds = new google.maps.LatLngBounds();
  orderItemsWithLocation.forEach(item => {
    const { latitude, longitude } = item.advert.coordinates;
    item.advert.coordinates && bounds.extend(new google.maps.LatLng(latitude, longitude));
  });
  return bounds;
};

interface MapMarkerProps {
  coordinates: { longitude: number; latitude: number };
  icon?: google.maps.Icon;
  id: string;
  orderItem: OrderFragment;
}

const MapMarker: React.FunctionComponent<MapMarkerProps> = ({ orderItem, id, coordinates, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const markerIcon = icon || {
    url: redPin,
    scaledSize: new google.maps.Size(28, 50),
  };

  const onMarkerClick = () => {
    window.open(RouteConfig.Order.buildLink({ id }));
  };

  const infoWindow = () => {
    return (
      <InfoWindow onCloseClick={onClose} options={{ disableAutoPan: true }} position={MAP_WINDOW_POSITION}>
        <div className={styles.orderItemCard}>
          <OrderItem order={orderItem} />
        </div>
      </InfoWindow>
    );
  };

  return (
    <Marker
      position={{ lat: coordinates.latitude, lng: coordinates.longitude }}
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
      onClick={onMarkerClick}
      icon={markerIcon}
    >
      {isOpen && infoWindow()}
    </Marker>
  );
};

interface OrderResultsMapProps {
  status: OrderStatus | undefined;
  orderType: OrderType;
}

const OrderResultsMap: React.FunctionComponent<OrderResultsMapProps> = ({ status, orderType }) => {
  const t = useTranslations();

  const { data, loading } = useMyOrdersQuery({
    variables: {
      status,
      orderType,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  const orderItems = data?.me.myOrders.edges.map(orderItem => orderItem.node) || [];

  if (!orderItems.length) {
    return <Typography msg={t(Messages.ordersMapNoResultsFound)} tag="h3" />;
  }

  const cords: google.maps.LatLngLiteral = { lat: 55.953143, lng: 23.2892977 };

  const orderItemsWithLocation = orderItems.filter(
    orderItem => orderItem.advert.coordinates?.longitude && orderItem.advert.coordinates?.latitude,
  );

  return (
    <GoogleMap
      zoom={8}
      center={cords}
      onLoad={map => {
        map.fitBounds(getBounds(orderItemsWithLocation));
      }}
      mapContainerStyle={{ height: '80vh' }}
    >
      {orderItemsWithLocation.map(orderItem => (
        <MapMarker
          orderItem={orderItem}
          coordinates={orderItem.advert.coordinates}
          id={orderItem.id}
          key={orderItem.id}
        />
      ))}
    </GoogleMap>
  );
};

export default OrderResultsMap;
