import React from 'react';

import Pill, { PillProps } from '@Components/Pill';
import { buildMessageKeyForEnum } from '@Config/messages';
import { OrderStatus } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

interface PillContainerProps {
  status: OrderStatus;
}

const getPillColor = (status: OrderStatus): PillProps['type'] => {
  switch (status) {
    case OrderStatus.Accepted:
    case OrderStatus.Active:
    case OrderStatus.Ended:
      return 'green';
    case OrderStatus.Created:
    case OrderStatus.Unfinished:
    case OrderStatus.PaymentPending:
      return 'orange';
    default:
      return 'gray';
  }
};

const OrderStatusDisplay: React.FunctionComponent<PillContainerProps> = ({ status }) => {
  const t = useTranslations();

  return <Pill width="auto" type={getPillColor(status)} msg={t(buildMessageKeyForEnum(`status_${status}`))} />;
};

export default OrderStatusDisplay;
