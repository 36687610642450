import * as React from 'react';

import Image from '@Components/Image';
import Row from '@Components/layout/Row';
import Navigation from '@Components/Slider/Navigation';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import defaultSlide from '@Images/defaultSlide.png';
import { GAAction, GACategory, sendGaEvent } from '@Utils/gaEvents';

import styles from './Slide.module.scss';

export interface SlideItem {
  text: string | null;
  title: string | null;
  image: string | null;
  buttonText?: string;
  navigationTitle?: string;
  url?: string;
}

export interface SlideProps {
  activeSlide: SlideItem;
  active: number;
  onItemClick: (index: number) => void;
  slides: SlideItem[];
}

const Slide: React.FunctionComponent<SlideProps> = ({ activeSlide, onItemClick, active, slides }) => {
  const t = useTranslations();

  const onSlideButtonClick = () => {
    sendGaEvent({
      category: GACategory.slideButton,
      action: GAAction.click,
    });
  };

  return (
    <>
      <Image
        src={activeSlide?.image ?? defaultSlide}
        alt={activeSlide?.title ?? 'banner'}
        className={styles.backgroundImage}
      />
      <Row constant column className={styles.heroSectionContentWrapper}>
        <div className={styles.textBox}>
          <div className={styles.textWrapper}>
            <Typography className={styles.title} msg={activeSlide?.title ?? ''} html tag="h1" color="white" />
            <Typography className={styles.text} msg={activeSlide?.text ?? ''} html tag="span" size="l" color="white" />
            {activeSlide?.url && (
              <div className={styles.linkAsButton}>
                <a href={activeSlide.url} onClick={onSlideButtonClick}>
                  <Typography msg={activeSlide.buttonText ?? t(Messages.btnSliderCta)} tag="span" />
                </a>
              </div>
            )}
          </div>
        </div>
        {slides.length > 1 && (
          <Navigation
            className={styles.paddingOnSides}
            navigationItemClick={onItemClick}
            slides={slides}
            active={active}
          />
        )}
      </Row>
    </>
  );
};

export default Slide;
