import { Messages } from '@Config/messages';
import {
  CreateGeneralOfferMutationInput,
  GeneralOfferPaymentCondition,
  GeneralOfferPriceDecision,
} from '@Graphql/graphqlTypes.generated';
import { isDefined } from '@Utils/tools';
import { Translator } from '@Utils/translation';
import { FieldError, GeneralOrderAcceptanceFormData, MutationInputResult } from '@Utils/types';

const paymentConditionAdjustments: Record<GeneralOfferPaymentCondition, (price: number) => number> = {
  [GeneralOfferPaymentCondition.FullPayment]: price => price * (1 - 0.09),
  [GeneralOfferPaymentCondition.FiftyFiftySplit]: price => price * (1 - 0.05),
  [GeneralOfferPaymentCondition.AfterThirtyDaysWithAdvance]: price => price * (1 - 0.025),
};

export const isPaymentCondition = (paymentCondition: string): paymentCondition is GeneralOfferPaymentCondition => {
  return Object.values(GeneralOfferPaymentCondition).includes(paymentCondition as GeneralOfferPaymentCondition);
};

export const getFinalPrice = ({
  paymentCondition,
  orderPrice,
  agreedWithPrice,
  offeredPrice,
}: Pick<GeneralOrderAcceptanceFormData, 'paymentCondition' | 'offeredPrice' | 'agreedWithPrice'> & {
  orderPrice: number | undefined | null;
}) => {
  if (!paymentCondition || !isDefined(orderPrice) || !isPaymentCondition(paymentCondition)) {
    return null;
  }

  const offerAgreementPrice = agreedWithPrice === 'NO' ? Math.round(Number.parseFloat(offeredPrice) * 100) : orderPrice;
  if (Number.isNaN(offerAgreementPrice) || offerAgreementPrice < 0) {
    return null;
  }

  return paymentConditionAdjustments[paymentCondition](offerAgreementPrice);
};

export const getGeneralOfferFormInput = (
  formData: GeneralOrderAcceptanceFormData,
  generalOrder: { id: string; totalPrice: number },
  t: Translator,
): MutationInputResult<CreateGeneralOfferMutationInput, GeneralOrderAcceptanceFormData> => {
  const toErrorResult = (errors: FieldError<GeneralOrderAcceptanceFormData>) => ({
    type: 'ERROR' as const,
    data: errors,
  });

  if (formData.agreedWithPrice === 'NO' && Number.isNaN(Number.parseFloat(formData.offeredPrice))) {
    return toErrorResult({ offeredPrice: t(Messages.errorInvalidAmountValue) });
  }

  const { offeredPrice, agreedWithPrice, paymentCondition, ...processableFormData } = formData;

  if (!isPaymentCondition(paymentCondition)) {
    return toErrorResult({ paymentCondition: t(Messages.errorInvalidValue) });
  }

  const totalPrice = getFinalPrice({
    orderPrice: generalOrder.totalPrice,
    paymentCondition: formData.paymentCondition,
    offeredPrice,
    agreedWithPrice,
  });

  if (totalPrice === null || totalPrice <= 0) {
    return toErrorResult({ offeredPrice: t(Messages.errorInvalidValue) });
  }

  return {
    type: 'INPUT' as const,
    data: {
      totalPrice,
      paymentCondition,
      generalOrder: generalOrder.id,
      priceDecision:
        agreedWithPrice === 'YES' ? GeneralOfferPriceDecision.Accepted : GeneralOfferPriceDecision.Suggested,
      ...processableFormData,
    } satisfies CreateGeneralOfferMutationInput,
  };
};
