import React from 'react';
import { FieldArray } from 'react-final-form-arrays';

import Button, { ButtonStyles } from '@Components/Buttons/Button';
import FieldArrayError from '@Components/forms/FieldArrayError';
import DateTimeInput from '@Components/forms/inputs/DateTimeInput';
import Icon, { IconSizes } from '@Components/Icon';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { AdFormFieldNames, ScheduleFields } from '@Routes/ads/shared/AdForm/AdForm';
import { validateRequired, validate } from '@Utils/form';
import { validateDate } from '@Utils/validate';

import styles from './ScheduleFieldsDisplay.module.scss';

const ScheduleFieldsDisplay: React.FC = () => {
  const t = useTranslations();

  return (
    <div className={styles.container}>
      <FieldArray name={AdFormFieldNames.schedule}>
        {({ fields, meta }) => {
          const addDefaultTimeValues = () => {
            fields.push({
              [ScheduleFields.startTime]: '',
              [ScheduleFields.endTime]: '',
            });
          };

          return (
            <>
              {fields.map((name, index) => (
                <div key={name} className={styles.fieldContainer}>
                  <div className={styles.fieldItem}>
                    <DateTimeInput
                      dateInputName={`${name}.${ScheduleFields.startDate}`}
                      dateInputValidate={validate(validateRequired(t), validateDate(t))}
                      datePlaceholder={Messages.createOrderDateFromPlaceholder}
                      timeInputName={`${name}.${ScheduleFields.startTime}`}
                      timeInputValidate={validateRequired(t)}
                      timePlaceholder={Messages.createOrderHoursPlaceholder}
                      showErrorIcon
                      className={{
                        container: styles.dateTimeInputContainer,
                        item: styles.dateTimeInputItem,
                      }}
                      bottomSpacing={false}
                    />
                  </div>
                  <div className={styles.fieldItem}>
                    <DateTimeInput
                      dateInputName={`${name}.${ScheduleFields.endDate}`}
                      dateInputValidate={validate(validateRequired(t), validateDate(t))}
                      datePlaceholder={Messages.createOrderDateToPlaceholder}
                      timeInputName={`${name}.${ScheduleFields.endTime}`}
                      timeInputValidate={validateRequired(t)}
                      timePlaceholder={Messages.createOrderHoursPlaceholder}
                      showErrorIcon
                      className={{
                        container: styles.dateTimeInputContainer,
                        item: styles.dateTimeInputItem,
                      }}
                      bottomSpacing={false}
                    />
                  </div>
                  <div className={styles.iconBlock}>
                    <Button onClick={() => fields.remove(index)} style={ButtonStyles.plain}>
                      <Icon size={IconSizes.s} icon="delete" />
                    </Button>
                  </div>
                </div>
              ))}
              <div>
                <Button onClick={addDefaultTimeValues} style={ButtonStyles.link} label={t(Messages.btnAddSchedule)}>
                  <Icon icon="plus" size={IconSizes.s} />
                </Button>
              </div>
              <FieldArrayError meta={meta} showErrorIcon />
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export default ScheduleFieldsDisplay;
