import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

import styles from './FloatingNavigation.module.scss';

export interface FloatingNavigationProps {
  className?: string;
  children?: React.ReactNode;
}

const FloatingNavigation: FunctionComponent<FloatingNavigationProps> = ({ children, className }) => {
  return <div className={classNames(styles.container, className)}>{children}</div>;
};

export default FloatingNavigation;
