import classNames from 'classnames';
import React from 'react';

import Typography, { TextColors } from '@Components/Typography/Typography';
import { Messages } from '@Config/messages';

import styles from './PageTitle.module.scss';

export interface PageTitleProps {
  msg: Messages | string;
  color?: TextColors;
  className?: string;
  html?: boolean;
}

const PageTitle: React.FunctionComponent<PageTitleProps> = ({ msg, color = 'black', className, html }) => {
  return (
    <Typography tag="h1" className={classNames(className, styles.pageTitle)} color={color} msg={msg} html={html} />
  );
};

export default PageTitle;
