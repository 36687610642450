import { ComponentType } from 'react';

import { RouterProperties } from '@Components/RoutesRenderer';
import { MessageKeysWithoutParams } from '@Utils/translation';

import ActivityRoutes from './activity/activityRoutes';
import AdsPrivateRoutes from './ads/adsPrivateRoutes';
import AdsRoutes from './ads/adsRoutes';
import AuctionRoutes from './auctions/auctionsRoutes';
import AuthRoutes from './auth/authRoutes';
import CalendarRoutes from './calendar/calendarRoutes';
import CategoryRoutes from './category/categoryRoutes';
import CompanyRoutes from './company/companyRoutes';
import ContactRoutes from './contact/contactRoutes';
import ForumRoutes from './forum/forumRoutes';
import MarketPlaceRoutes from './marketplace/marketplaceRoutes';
import OrderRoutes from './orders/orderRoutes';
import StaticRoutes from './static/staticRoutes';
import UserRoutes from './user/userRoutes';

export type RouteInfo = {
  path: string;
  component: ComponentType<RouterProperties> | ((p: RouterProperties) => JSX.Element | null);
  title: MessageKeysWithoutParams;
  exact?: boolean;
};

export const publicRoutes: RouteInfo[] = [
  ...CalendarRoutes,
  ...CompanyRoutes,
  ...OrderRoutes,
  ...StaticRoutes,
  ...AuthRoutes,
  ...MarketPlaceRoutes,
  ...AdsRoutes,
  ...ForumRoutes,
  ...ActivityRoutes,
  ...AuctionRoutes,
  ...ContactRoutes,
  ...CategoryRoutes,
];

export const privateRoutes: RouteInfo[] = [...UserRoutes];

export const privateRoutesWithBackNavigation: RouteInfo[] = [...AdsPrivateRoutes];
