import * as React from 'react';

import DisplayLabelWithIcon from '@Components/DisplayLabelWithIcon';
import Row from '@Components/layout/Row';
import { AdvertFragment } from '@Graphql/graphqlTypes.generated';

import styles from './AdvertCardAddressRow.module.scss';

export interface AdvertCardAddressRowProps {
  advert: AdvertFragment;
}

const AdvertCardAddressRow: React.FunctionComponent<AdvertCardAddressRowProps> = ({ advert: { address } }) => {
  return (
    <Row className={styles.container} constant>
      {address && (
        <div className={styles.address}>
          <DisplayLabelWithIcon className={styles.icon} textSize="s" icon="location" label={address} />
        </div>
      )}
    </Row>
  );
};

export default AdvertCardAddressRow;
