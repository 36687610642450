import * as React from 'react';
import { Form, FormRenderProps } from 'react-final-form';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Buttons/Button';
import FormField from '@Components/forms/FormField';
import DateTimeInput from '@Components/forms/inputs/DateTimeInput';
import TextAreaInput, { TextAreaInputProps } from '@Components/forms/inputs/TextAreaInput';
import TextInput, { TextInputProps } from '@Components/forms/inputs/TextInput/TextInput';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { validate, validateMaxLength, validateRequired, validateSelectedDayIsAfterYesterday } from '@Utils/form';
import { validateDate, validateDateAndTimeFieldsValues } from '@Utils/validate';

import styles from './CalendarEventForm.module.scss';

enum CalendarEventFormFields {
  startDate = 'startDate',
  timeFrom = 'timeFrom',
  endDate = 'endDate',
  timeTo = 'timeTo',
  title = 'title',
  description = 'description',
}

export interface CalendarEventFormData {
  startDate: string;
  endDate: string;
  timeFrom: string;
  timeTo: string;
  description?: string;
  title?: string;
}

interface CalendarEventFormProps {
  onSubmit: (values: CalendarEventFormData) => Promise<{}>;
  onCancel: () => void;
  initialValues?: CalendarEventFormData;
  isEditForm?: boolean;
  isEventRemovalAllowed?: boolean;
}

const renderForm = (
  { handleSubmit, submitting }: FormRenderProps<CalendarEventFormData>,
  onCancel: () => void,
  isEditForm?: boolean,
  isEventRemovalAllowed?: boolean,
): React.ReactNode => {
  const t = useTranslations();
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <DateTimeInput
        dateInputName={CalendarEventFormFields.startDate}
        dateInputValidate={validate(
          validateRequired(t),
          validateDate(t),
          isEditForm ? () => undefined : validateSelectedDayIsAfterYesterday(t),
        )}
        datePlaceholder={Messages.calendarFormEventStartDate}
        timeInputName={CalendarEventFormFields.timeFrom}
        timeInputValidate={validateRequired(t)}
        showErrorIcon
      />
      <DateTimeInput
        dateInputName={CalendarEventFormFields.endDate}
        dateInputValidate={validate(validateDate(t), validateRequired(t), validateSelectedDayIsAfterYesterday(t))}
        datePlaceholder={Messages.calendarFormEventEndDate}
        timeInputName={CalendarEventFormFields.timeTo}
        timeInputValidate={validateRequired(t)}
        showErrorIcon
      />
      <div className={styles.description}>
        <FormField<TextInputProps<string>>
          name={CalendarEventFormFields.title}
          component={TextInput}
          type="text"
          placeholder={Messages.calendarFormEventTitle}
          validate={validateMaxLength(t, 100)}
        />
        <FormField<TextAreaInputProps<string>>
          name={CalendarEventFormFields.description}
          component={TextAreaInput}
          placeholder={Messages.calendarFormEventDescription}
          validate={validateMaxLength(t, 300)}
        />
      </div>
      <div className={styles.actions}>
        <Button
          label={t(isEditForm ? Messages.calendarEventEdit : Messages.btnSubmit)}
          disabled={submitting}
          loading={submitting}
          size={ButtonSizes.fill}
          type={ButtonTypes.submit}
        />
        <Button
          loading={submitting}
          label={isEventRemovalAllowed ? t(Messages.calendarEventDelete) : t(Messages.calendarEventCancelChanges)}
          size={ButtonSizes.fill}
          style={isEventRemovalAllowed ? ButtonStyles.outlinedRed : ButtonStyles.outlinedGreen}
          disabled={submitting}
          onClick={onCancel}
        />
      </div>
    </form>
  );
};

const CalendarEventForm: React.FC<CalendarEventFormProps> = ({
  onSubmit,
  onCancel,
  isEditForm,
  initialValues,
  isEventRemovalAllowed,
}) => {
  const t = useTranslations();

  return (
    <Form<CalendarEventFormData>
      onSubmit={onSubmit}
      render={props => renderForm(props, onCancel, isEditForm, isEventRemovalAllowed)}
      initialValues={initialValues}
      validate={values =>
        validateDateAndTimeFieldsValues(
          {
            ...values,
            dateFrom: values.startDate,
            dateTo: values.endDate,
          },
          {
            dateFrom: CalendarEventFormFields.startDate,
            dateTo: CalendarEventFormFields.endDate,
            timeFrom: CalendarEventFormFields.timeFrom,
            timeTo: CalendarEventFormFields.timeTo,
          },
          t,
          false,
        )
      }
    />
  );
};

export default CalendarEventForm;
