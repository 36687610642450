import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import appReducer, { AppState } from './app/app.reducer';
import authReducer, { AuthState } from './auth/auth.reducer';

export interface RootState {
  router: any;
  auth: AuthState;
  app: AppState;
}

const rootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    auth: authReducer,
    app: appReducer,
  });

export default rootReducer;
