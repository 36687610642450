import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, Suggestion } from 'react-places-autocomplete';
import { useDispatch } from 'react-redux';

import Button, { ButtonStyles } from '@Components/Buttons/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { CoordinatesProps } from '@Components/Map/SpecifyLocationMap';
import { showSpecifyLocationModal } from '@Components/Modal/modalHelpers';
import { AUTO_SUGGESTION_COUNTRIES } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './LocationSelect.module.scss';

export interface LocationValue {
  lat: number | undefined;
  lng: number | undefined;
  address: string;
}

export interface LocationSelectProps {
  onChange: (values: LocationValue) => void;
  addressValue: string;
  error: string | undefined;
  inputName: string;
  preFillLocation?: boolean;
  specifyLocation?: boolean;
  coordinates: CoordinatesProps;
  placeholder?: string;
}

const LocationSelect: React.FunctionComponent<LocationSelectProps> = props => {
  const [addressValue, changeAddress] = useState<string>(props.addressValue);

  const t = useTranslations();
  const dispatch = useDispatch();
  const translatedPlaceholder = props.placeholder || t(Messages.createAdLocationFieldPlaceholder);

  const handleChange = async (address: string) => {
    changeAddress(address);
    const latLng = await geocodeByAddress(address).then(results => getLatLng(results[0]));
    props.onChange({ ...latLng, address });
  };

  const [isLoadingGeo, setIsLoadingGeo] = useState(false);

  const getGeoLocation = () => {
    if (!props.preFillLocation || !navigator.geolocation) {
      return;
    }

    setIsLoadingGeo(true);
    const geocoder = new google.maps.Geocoder();

    navigator.geolocation.getCurrentPosition(
      position => {
        const latLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK' && results?.[0]) {
            changeAddress(results[0].formatted_address);
            props.onChange({
              address: results[0].formatted_address,
              lng: position.coords.longitude,
              lat: position.coords.latitude,
            });
          }
        });
        setIsLoadingGeo(false);
      },
      () => setIsLoadingGeo(false),
      { enableHighAccuracy: true },
    );
  };

  useEffect(() => {
    changeAddress(props.addressValue);
  }, [props.addressValue]);

  const handleSelect = async (address: string) => {
    try {
      handleChange(address);
    } catch (e) {
      // TODO identify what to do on error
    }
  };

  const clearValue = () => {
    changeAddress('');
    props.onChange({ lat: undefined, lng: undefined, address: '' });
  };

  const preFillLocation = () => {
    getGeoLocation();
  };

  const handleSpecifyLocation = () => {
    dispatch(showSpecifyLocationModal(props.coordinates, props.onChange));
  };

  const isAddressOrPreFill = addressValue || props.preFillLocation;
  const isNoAddressAndPreFill = !addressValue && props.preFillLocation;
  const { specifyLocation = true } = props;

  return (
    <div className={styles.locationSelectContainer}>
      <PlacesAutocomplete
        value={addressValue}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={{
          types: ['geocode'],
          componentRestrictions: {
            country: AUTO_SUGGESTION_COUNTRIES,
          },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div className={styles.locationSelectInputContainer}>
              {isLoadingGeo && (
                <Row justifyCenter className={styles.loadingSpinner}>
                  <LoadingSpinner small />
                </Row>
              )}
              {!isLoadingGeo && (
                <input
                  {...getInputProps({
                    placeholder: translatedPlaceholder,
                    name: props.inputName,
                    className: styles.locationSelectInput,
                    onBlur: () => {
                      handleChange(props.addressValue);
                    },
                  })}
                />
              )}
              {!isLoadingGeo && translatedPlaceholder && (
                <label htmlFor={props.inputName} className={styles.label} data-content={translatedPlaceholder}>
                  <span className={styles.hidden}>{translatedPlaceholder}</span>
                </label>
              )}
              {!isLoadingGeo && (
                <div
                  className={classNames(styles.locationDropdown, {
                    [styles.visibleDropdown]: loading || suggestions.length,
                  })}
                >
                  {suggestions.map((suggestion: Suggestion, idx) => {
                    const className = classNames(styles.locationDropdownItem, { [styles.active]: suggestion.active });
                    return (
                      <div {...getSuggestionItemProps(suggestion, { className })} key={idx}>
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
      <div className={styles.locationFieldButtons}>
        {specifyLocation && addressValue && (
          <Button style={ButtonStyles.plain} onClick={handleSpecifyLocation}>
            <Icon icon="pin" size={IconSizes.s} />
          </Button>
        )}
        {isAddressOrPreFill && (
          <Button style={ButtonStyles.plain} onClick={!addressValue ? preFillLocation : clearValue}>
            <Icon icon={isNoAddressAndPreFill ? 'location' : 'clear'} size={IconSizes.s} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default LocationSelect;
