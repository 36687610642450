import React from 'react';
import { useField, useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import FieldGroup from '@Components/forms/Filter/FieldGroup';
import FormField from '@Components/forms/FormField';
import { FieldCondition, FieldSubscribe } from '@Components/forms/formUtils';
import Checkbox from '@Components/forms/inputs/Checkbox';
import DateInput from '@Components/forms/inputs/DateInput/DateInput';
import ImageField, { ImageStyle } from '@Components/forms/inputs/ImageInput';
import LocationInput from '@Components/forms/inputs/LocationInput';
import RichInput, { RichTextInputProps } from '@Components/forms/inputs/RichTextInput';
import SelectInput from '@Components/forms/inputs/SelectInput';
import AdCategoryTypeSelectInput from '@Components/forms/inputs/selects/AdCategorySelect/AdCategorySelect';
import AdPricingTypeSelectInput from '@Components/forms/inputs/selects/AdPricingTypeSelect';
import TextInput, { TextInputProps } from '@Components/forms/inputs/TextInput/TextInput';
import ToggleButton from '@Components/forms/inputs/ToggleButton/ToggleButton';
import SchemaFields from '@Components/forms/SchemaFields';
import ImageUploadField from '@Components/ImageUploadField/ImageUploadField';
import InfoMessage, { MessageTypes } from '@Components/InfoMessage';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import { Messages } from '@Config/messages';
import {
  AdvertMutationInput,
  AdvertPricingType,
  AdvertServiceType,
  AdvertStatus,
  DeliveryType,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { AdFormFieldNames, FormRowItem } from '@Routes/ads/shared/AdForm/AdForm';
import styles from '@Routes/ads/shared/AdForm/AdForm.module.scss';
import AdvertScheduleField from '@Routes/ads/shared/AdForm/ScheduleFieldsDisplay';
import { getUserData } from '@Store/auth/auth.selector';
import {
  normalizePrice,
  validate,
  validateImgFormat,
  validateMaxAmount,
  validateMaxImgSize,
  validateMaxLength,
  validateNumber,
  validateRequired,
} from '@Utils/form';
import { GAAction, GACategory, sendGaEvent } from '@Utils/gaEvents';
import { AdFormValues, Price } from '@Utils/types';

const AdFormSecondStep: React.FC = () => {
  const t = useTranslations();
  const user = useSelector(getUserData);
  const formState = useFormState<AdFormValues>();
  const form = useForm();
  const { input } = useField(AdFormFieldNames.serviceType);
  const { values, submitError } = formState;

  const statuses = [
    {
      label: t(Messages.adStatusActiveSelection),
      value: AdvertStatus.Active,
    },
    {
      label: t(Messages.adStatusPrivateSelection),
      value: AdvertStatus.Private,
    },
  ];

  React.useEffect(() => {
    sendGaEvent({
      category: GACategory.advertFormSecondStep,
      action: GAAction.render,
    });
  }, []);

  return (
    <>
      <Row>
        <InfoMessage className={styles.infoMessageSpacing} type={MessageTypes.error} message={submitError} />
      </Row>
      <FormRowItem label={t(Messages.createAdCategorySelect)} className={styles.label}>
        <FormField
          name={AdFormFieldNames.category}
          component={AdCategoryTypeSelectInput}
          type="text"
          serviceType={values.serviceType}
          placeholder={Messages.createAdCategorySelectPlaceholder}
          validate={validateRequired(t)}
        />
      </FormRowItem>
      <FormRowItem label={t(Messages.createAdDescriptionField)} className={styles.label}>
        <FormField<TextInputProps<AdvertMutationInput[AdFormFieldNames.advertType]>>
          name={AdFormFieldNames.title}
          component={TextInput}
          type="text"
          placeholder={Messages.createAdTitleFieldPlaceholder}
          validate={validate(validateRequired(t), validateMaxLength(t, 100))}
        />
      </FormRowItem>
      <FormRowItem description={t(Messages.createAdDescriptionFieldDescription)}>
        <FormField<RichTextInputProps<AdvertMutationInput[AdFormFieldNames.description]>>
          name={AdFormFieldNames.description}
          component={RichInput}
          placeholder={t('msg_advert_create_description_field_placeholder')}
          validate={validate(validateRequired(t))}
        />
      </FormRowItem>
      <FieldSubscribe
        when={AdFormFieldNames.category}
        onChange={() => {
          form.change(AdFormFieldNames.customFieldsData, {});
        }}
        render={category => (
          <SchemaFields name={AdFormFieldNames.customFieldsData} className={styles.label} categoryId={category} />
        )}
      />
      <FormRowItem label={t(Messages.createAdImagesField)} className={styles.label}>
        <Row constant wrap>
          <FormField
            name={AdFormFieldNames.mainImage}
            component={ImageField}
            validate={validate(validateMaxImgSize(t), validateImgFormat(t))}
            style={ImageStyle.displayAsMain}
            labelOnFile={Messages.labelUploadMain}
            description={Messages.labelUploadFileDescription}
            className={styles.marginRight}
          />
          <ImageUploadField
            fieldName={AdFormFieldNames.images}
            style={ImageStyle.displayAsSecondary}
            displayAsGrid
            bottomSpacing={false}
            description={Messages.labelUpload}
          />
        </Row>
      </FormRowItem>
      <FormRowItem label={t(Messages.createAdOtherInformation)} className={styles.label}>
        <FieldGroup className={styles.spacing}>
          {values.pricingType !== AdvertPricingType.NegotiablePrice &&
            values.pricingType !== AdvertPricingType.FreeItem && (
              <GridRow>
                <GridCol size={8}>
                  <FormField<TextInputProps<Price>>
                    name={AdFormFieldNames.price}
                    component={TextInput}
                    type="text"
                    parse={normalizePrice}
                    placeholder={Messages.createAdPriceFieldPlaceholder}
                    validate={validate(validateRequired(t), validateNumber(t), validateMaxAmount(t, 21474836))}
                    bottomSpacing={false}
                  />
                </GridCol>
                <GridCol size={4}>
                  <FormField
                    name={AdFormFieldNames.pricingType}
                    component={AdPricingTypeSelectInput}
                    localizeSelectLabels={true}
                    validate={validateRequired(t)}
                    serviceType={input.value}
                    key={input.value || 'noKey'}
                    placeholder={Messages.createAdPriceTypeSelectionPlaceholder}
                    bottomSpacing={false}
                  />
                </GridCol>
              </GridRow>
            )}
          <GridRow className={styles.marginTop}>
            <GridCol size={12}>
              <FormField
                name={AdFormFieldNames.pricingType}
                component={ToggleButton}
                pricingTypeValue={AdvertPricingType.NegotiablePrice}
                label={t(Messages.createAdPricingTypeNegotiablePrice)}
              />
            </GridCol>
          </GridRow>
          <GridRow className={styles.marginTop}>
            <GridCol size={12}>
              <FormField
                name={AdFormFieldNames.pricingType}
                component={ToggleButton}
                pricingTypeValue={AdvertPricingType.FreeItem}
                label={t(Messages.createAdPricingTypeFreeItem)}
              />
            </GridCol>
          </GridRow>
        </FieldGroup>
      </FormRowItem>
      <FormRowItem className={styles.label} label={t(Messages.changeAdDeliveryType)} textCenterMobile={false}>
        <Row>
          <FormField
            name={AdFormFieldNames.deliveryType}
            component={Checkbox}
            inline
            type="radio"
            label={Messages.adDeliveryTypeRequired}
            value={DeliveryType.Required}
          />
          <FormField
            name={AdFormFieldNames.deliveryType}
            component={Checkbox}
            inline
            type="radio"
            label={Messages.adDeliveryTypeOptional}
            value={DeliveryType.Optional}
          />
          <FormField
            name={AdFormFieldNames.deliveryType}
            component={Checkbox}
            inline
            type="radio"
            label={Messages.adDeliveryTypeNoDelivery}
            value={DeliveryType.NoDelivery}
          />
        </Row>
        {values.deliveryType === DeliveryType.Optional && (
          <Row>
            <Col size={5}>
              <FormField<TextInputProps<Price>>
                name={AdFormFieldNames.deliveryPrice}
                component={TextInput}
                type="text"
                parse={normalizePrice}
                placeholder={Messages.createAdDeliveryPriceFieldPlaceholder}
                validate={validate(validateRequired(t), validateNumber(t), validateMaxLength(t, 8))}
              />
            </Col>
          </Row>
        )}
      </FormRowItem>
      <FormRowItem className={styles.label} description={t(Messages.createAdLocationFieldExplanation)}>
        <LocationInput
          addressFieldName={AdFormFieldNames.address}
          coordinatesFieldName={AdFormFieldNames.coordinates}
          validate={values.serviceType === AdvertServiceType.Service ? validateRequired(t) : undefined}
        />
      </FormRowItem>
      <FormRowItem className={styles.label} description={t(Messages.createAdValidUntil)}>
        <FormField
          name={AdFormFieldNames.validUntil}
          component={DateInput}
          placeholder={Messages.createAdValidUntil}
          parse={date => (!date ? null : date)}
        />
      </FormRowItem>
      <FieldCondition when={AdFormFieldNames.serviceType} is={AdvertServiceType.Service}>
        <FormRowItem
          label={t(Messages.createAdScheduleEnabledField)}
          description={t(Messages.createAdScheduleEnabledFieldDescription)}
          className={styles.label}
          textCenterMobile={false}
        >
          <FormField
            name={AdFormFieldNames.isScheduled}
            label={Messages.createAdScheduleEnabledField}
            component={Checkbox}
            type="checkbox"
          />
          {values.isScheduled && <AdvertScheduleField />}
        </FormRowItem>
      </FieldCondition>
      {user.item?.info.activeCompany.inPrivateGroup && (
        <FormRowItem
          description={t(Messages.createAdStatusSelectionFieldDescription)}
          className={styles.label}
          textCenterMobile={false}
        >
          <FormField
            name={AdFormFieldNames.status}
            type="text"
            component={SelectInput}
            placeholder={Messages.createAdStatusSelectionPlaceholder}
            validate={validateRequired(t)}
            options={statuses}
          />
        </FormRowItem>
      )}
    </>
  );
};

export default AdFormSecondStep;
