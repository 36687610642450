import React, { ReactNode } from 'react';
import { Form, FormRenderProps } from 'react-final-form';

import Button, { ButtonSizes, ButtonTypes } from '@Components/Buttons/Button';
import FormField from '@Components/forms/FormField';
import TextInput, { TextInputProps } from '@Components/forms/inputs/TextInput';
import InfoMessage, { MessageTypes } from '@Components/InfoMessage';
import { Messages } from '@Config/messages';
import { InviteUserToCompanyMutationInput } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { validate, validateEmail, validateRequired } from '@Utils/form';

enum InviteUserFormFields {
  email = 'email',
}

interface InviteUserFormProps {
  onSubmit: (values: InviteUserToCompanyMutationInput) => void;
}

const renderForm = ({
  handleSubmit,
  submitting,
  submitError,
}: FormRenderProps<InviteUserToCompanyMutationInput>): ReactNode => {
  const t = useTranslations();
  return (
    <form onSubmit={handleSubmit}>
      <InfoMessage type={MessageTypes.error} message={submitError} />
      <FormField<TextInputProps<string>>
        name={InviteUserFormFields.email}
        type="email"
        component={TextInput}
        placeholder={Messages.labelEmail}
        validate={validate(validateEmail(t), validateRequired(t))}
      />
      <Button
        loading={submitting}
        label={t(Messages.inviteUserModalButton)}
        disabled={submitting}
        size={ButtonSizes.fill}
        type={ButtonTypes.submit}
      />
    </form>
  );
};

const InviteUserForm: React.FC<InviteUserFormProps> = ({ onSubmit }) => (
  <Form onSubmit={onSubmit} render={renderForm} />
);

export default InviteUserForm;
