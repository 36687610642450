import classNames from 'classnames';
import * as React from 'react';

import { MapT } from '@Utils/types';

import styles from './Typography.module.scss';

export type HeaderTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
export type TextTags = 'div' | 'p' | 'span';
export type TextAlignment = 'left' | 'center' | 'right' | 'justify';
export type TextColors = 'black' | 'primary' | 'gray' | 'white' | 'grayDark' | 'violet';
export type TextSizes = 'xs' | 's' | 'm' | 'l' | 'xl'; // TODO Conditionally disable this prop if tag type is HeaderTag

export interface TypographyProps {
  html?: boolean;
  params?: MapT<string | number>;
  msg: string;
  align?: TextAlignment;
  color?: TextColors;
  tag: TextTags | HeaderTags;
  size?: TextSizes;
  bold?: boolean;
  className?: string;
}

const Typography: React.FunctionComponent<TypographyProps> = ({
  tag: Tag,
  align,
  color,
  size,
  bold,
  msg,
  html,
  className,
}) => {
  const computedClass =
    classNames(className, {
      [styles[align as TextAlignment]]: align,
      [styles[color as TextColors]]: color,
      [styles[size as TextSizes]]: size,
      [styles.bold]: bold,
    }) || undefined;

  if (html) {
    return <Tag dangerouslySetInnerHTML={{ __html: msg }} className={classNames(computedClass, styles.htmlContent)} />;
  }

  return <Tag children={msg} className={computedClass} />;
};

export default Typography;
