import * as React from 'react';

import Button, { ButtonStyles } from '@Components/Buttons/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import SearchResultsMap from '@Components/Map/SearchResultsMap';
import { ModalProviderProps } from '@Components/Modal';
import { SearchBarData } from '@Components/Search/SearchBar';
import { AdvertFragment } from '@Graphql/graphqlTypes.generated';

import styles from './SearchResultsModal.module.scss';

interface SearchResultsModalProps {
  items: AdvertFragment[];
  location: SearchBarData;
}

const SearchResultsModal: React.FunctionComponent<ModalProviderProps<SearchResultsModalProps>> = ({
  modalData,
  closeModal,
}) => {
  return (
    <div className={styles.container}>
      <Row justifyEnd className={styles.button}>
        <Button style={ButtonStyles.plain} onClick={closeModal}>
          <Icon icon="menu-close-button" size={IconSizes.s} />
        </Button>
      </Row>
      <SearchResultsMap searchItems={modalData.items} location={modalData.location} />
    </div>
  );
};

export default SearchResultsModal;
