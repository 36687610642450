import { Messages } from '@Config/messages';
import { CreateGeneralOrderMutationInput, GeneralOrderPaymentCondition } from '@Graphql/graphqlTypes.generated';
import { convertToFloatNumber, formatToFixedPositionIfDecimal } from '@Utils/convertions';
import { isDefined } from '@Utils/tools';
import { Translator } from '@Utils/translation';
import { FieldError, GeneralOrderFormData } from '@Utils/types';

const paymentConditionAdjustments: Record<GeneralOrderPaymentCondition, (price: number) => number> = {
  [GeneralOrderPaymentCondition.FullPayment]: price => price,
  [GeneralOrderPaymentCondition.FiftyFiftySplit]: price => price * 1.05,
  [GeneralOrderPaymentCondition.AfterThirtyDaysWithAdvance]: price => price * 1.09,
};

export const isPaymentCondition = (paymentCondition: string): paymentCondition is GeneralOrderPaymentCondition => {
  return Object.values(GeneralOrderPaymentCondition).includes(paymentCondition as GeneralOrderPaymentCondition);
};

export const getFinalPrice = ({
  paymentCondition,
  amount,
  marketPrice,
}: {
  paymentCondition: GeneralOrderPaymentCondition | '';
  amount: string | number;
  marketPrice: number | undefined | null;
}) => {
  if (!paymentCondition || !isDefined(marketPrice) || !amount) {
    return null;
  }
  const amountNumber = typeof amount === 'number' ? amount : Number.parseFloat(amount);
  if (Number.isNaN(amountNumber) || amountNumber < 0 || !isPaymentCondition(paymentCondition)) {
    return null;
  }
  if (amountNumber === 0 || marketPrice === 0) {
    return 0;
  }

  const normalisedAmount = Math.round(amountNumber * 10) / 10;

  return paymentConditionAdjustments[paymentCondition](marketPrice * normalisedAmount);
};

export const getGeneralOrderFormInput = (
  formData: GeneralOrderFormData,
  t: Translator,
):
  | { type: 'INPUT'; data: CreateGeneralOrderMutationInput }
  | { type: 'ERROR'; data: FieldError<GeneralOrderFormData> } => {
  const toErrorResult = (errors: FieldError<GeneralOrderFormData>) => ({ type: 'ERROR' as const, data: errors });

  const { paymentCondition } = formData;
  if (!isPaymentCondition(paymentCondition)) {
    return toErrorResult({ paymentCondition: t(Messages.errorInvalidValue) });
  }

  const amountParts = formData.amountParts.map(part => convertToFloatNumber(part));
  const amount = amountParts.reduce((acc, part) => acc + part, 0);

  const totalPrice = getFinalPrice({
    paymentCondition,
    amount,
    marketPrice: formData.marketPriceSelection,
  });

  if (totalPrice === null) {
    return toErrorResult({ marketPriceSelection: t(Messages.errorInvalidValue) });
  }

  return {
    type: 'INPUT' as const,
    data: {
      category: formData.category,
      amount: formatToFixedPositionIfDecimal(amount), // Floating point numbers formatted to address BE issue for floating point numbers
      amountType: formData.amountType,
      amountParts: amountParts.map(part => formatToFixedPositionIfDecimal(part)),
      paymentCondition,
      totalPrice,
      startDate: formData.startDate,
      ...(formData.endDate ? { endDate: formData.endDate } : {}),
      name: formData.name,
      surname: formData.surname,
      email: formData.email,
      phone: formData.phone,
      coordinates: formData.coordinates,
      address: formData.address,
      additionalInfo: formData.description,
    } satisfies CreateGeneralOrderMutationInput,
  };
};
