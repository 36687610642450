import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { HeaderLink } from '@Components/Header';
import HeaderFavorite from '@Components/HeaderFavorite';
import HeaderNotifications from '@Components/HeaderNotifications/HeaderNotifications';
import LanguageDropdown from '@Components/LanguageDropdown';
import UserHeaderDropdown from '@Components/UserHeaderDropdown/UserHeaderDropdown';
import { getEnabledLanguages } from '@Store/app/app.selectors';
import { getUserData, getUserIsLogged } from '@Store/auth/auth.selector';
import { UserData } from '@Store/auth/auth.types';

import styles from './AdditionalHeaderLinks.module.scss';

export interface AdditionalHeaderLinksProps {
  links: HeaderLink[];
  transparentHeader: boolean;
  isMobileMenuOpen: boolean;
  onClick?: () => void;
  displayAsDropDown?: boolean;
}

const AdditionalHeaderLinks: FunctionComponent<AdditionalHeaderLinksProps> = ({
  links,
  transparentHeader,
  isMobileMenuOpen,
  onClick,
  displayAsDropDown,
}) => {
  const user: UserData = useSelector(getUserData);
  const enabledLanguages = useSelector(getEnabledLanguages);
  const userIsLoggedIn = useSelector(getUserIsLogged);
  const displayLanguagePicker = enabledLanguages.length > 1;

  if (!userIsLoggedIn && !displayLanguagePicker) {
    return null;
  }

  return (
    <div className={styles.container}>
      {displayLanguagePicker && (
        <LanguageDropdown user={user} transparentHeader={transparentHeader || isMobileMenuOpen} />
      )}
      {userIsLoggedIn && (
        <>
          <UserHeaderDropdown
            white={transparentHeader || isMobileMenuOpen}
            position={isMobileMenuOpen ? 'bottom' : 'bottom-start'}
            links={links}
            onClick={onClick}
            displayAsDropDown={displayAsDropDown}
          />
          <HeaderFavorite white={transparentHeader || isMobileMenuOpen} />
          <HeaderNotifications white={transparentHeader || isMobileMenuOpen} />
        </>
      )}
    </div>
  );
};

export default AdditionalHeaderLinks;
