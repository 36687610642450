import classNames from 'classnames';
import deepEqual from 'deep-equal';
import { FormApi } from 'final-form';
import React, { useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useDebounce } from 'use-debounce';

import Button, { ButtonSizes, ButtonStyles, ButtonTypes } from '@Components/Buttons/Button';
import FilterBlock from '@Components/forms/Filter/FilterBlock';
import FormField from '@Components/forms/FormField';
import Checkbox from '@Components/forms/inputs/Checkbox';
import LocationInput from '@Components/forms/inputs/LocationInput';
import RangeInput, { RangeNames } from '@Components/forms/inputs/RangeInput';
import { RangeInputValueProps } from '@Components/forms/inputs/RangeInput/RangeInput';
import RangeSelectInput from '@Components/forms/inputs/RangeSelectInput';
import AdCategoryTypeSelectInput from '@Components/forms/inputs/selects/AdCategorySelect/AdCategorySelect';
import { AllCategoriesProps } from '@Components/forms/inputs/selects/CategorySelect';
import { TextInputProps } from '@Components/forms/inputs/TextInput';
import TextInput from '@Components/forms/inputs/TextInput/TextInput';
import InfoMessage, { MessageTypes } from '@Components/InfoMessage';
import Col from '@Components/layout/Col';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Row from '@Components/layout/Row';
import { Messages } from '@Config/messages';
import { AdvertServiceType, AdvertType, GetMarketplaceAdsQueryVariables } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { FormRowItem } from '@Routes/ads/shared/AdForm/AdForm';
import { toFiniteNumberOrNull } from '@Utils/convertions';
import { validateMinLength } from '@Utils/form';
import { MapT, Nil, OptionalProps, WithPromiseResponse } from '@Utils/types';

import styles from './AdFilters.module.scss';

export enum PriceRangeNames {
  priceGrater = 'priceGte',
  priceLower = 'priceLte',
}

export enum AdFilterDataKeys {
  priceGte = 'priceGte',
  priceLte = 'priceLte',
  advertType = 'advertType',
  serviceType = 'serviceType',
  categories = 'categories',
  searchBy = 'searchBy',
  geoSearch = 'geoSearch',
}

export enum AdFiltersFieldNames {
  priceRange = 'priceRange',
  distance = 'distance',
  advertType = 'advertType',
  categories = 'categories',
  searchBy = 'searchBy',
  geoSearch = 'geoSearch',
  address = 'address',
  serviceType = 'serviceType',
}

export enum DistanceFilterValues {
  min = 0,
  max = 100,
  stepLength = 10,
  startValue = 50,
}

export type AdFilterFields = Pick<GetMarketplaceAdsQueryVariables, AdFilterDataKeys>;

export type AdFiltersInitialValues = OptionalProps<AdFilterFields> | undefined;

export type OnAdFiltersChange = WithPromiseResponse<AdFilterFields, any>;

interface AdFiltersProps {
  onChange: OnAdFiltersChange;
  onClick?: (values: FormApi<AdFilterFormSubmitValues>) => void;
  initialValues?: AdFiltersInitialValues;
  externalSubmit?: string;
  showDistanceField?: boolean;
  submitOnChange?: boolean;
  noCategories?: boolean;
}

export type AdFilterFormSubmitValues = Pick<
  GetMarketplaceAdsQueryVariables,
  'categories' | 'priceLte' | 'priceGte' | 'geoSearch' | 'searchBy'
> & {
  [AdFiltersFieldNames.priceRange]: RangeInputValueProps;
  advertType?: AdvertType[] | undefined | null;
  serviceType?: AdvertServiceType[] | undefined | null;
};

type FormInitialValues = AdFilterFormSubmitValues | undefined;

const transformToFormInitialValues = (initial: OptionalProps<AdFilterFields> | undefined): FormInitialValues => {
  if (initial === undefined) {
    return undefined;
  }

  return {
    ...initial,
    advertType: initial.advertType || null,
    serviceType: initial.serviceType || null,
    [AdFiltersFieldNames.priceRange]: {
      [RangeNames.from]: toFiniteNumberOrNull(initial[PriceRangeNames.priceGrater]),
      [RangeNames.to]: toFiniteNumberOrNull(initial[PriceRangeNames.priceLower]),
    },
  };
};

const renderForm = (
  props: FormRenderProps<AdFilterFormSubmitValues>,
  onClick?: (values: FormApi<AdFilterFormSubmitValues>) => void,
  submitOnChange?: boolean,
  noCategories?: boolean,
) => {
  const t = useTranslations();
  const { handleSubmit, form, initialValues, values } = props;
  const initialSearchFieldsVisibility = !!values.geoSearch || !!values.searchBy;
  const [isLocationBarVisible, showLocationBar] = useState(initialSearchFieldsVisibility);

  const formState = form.getState();
  const formHasChanged = formState.dirtyFields || formState.dirty;
  const [formStateValue] = useDebounce(formHasChanged, 500);

  React.useEffect(() => {
    if (formStateValue && submitOnChange) {
      handleSubmit();
    }
  }, [formStateValue, formState.errors, submitOnChange]);

  return (
    <FilterBlock>
      <InfoMessage type={MessageTypes.error} message={props.submitError} />
      <form onSubmit={handleSubmit} className={styles.adsFilter}>
        <GridRow className={styles.bottomSpacing}>
          <GridCol size={12}>
            <FormField<TextInputProps<string>>
              name={AdFiltersFieldNames.searchBy}
              type="text"
              component={TextInput}
              placeholder={Messages.searchBarPlaceholder}
              validate={validateMinLength(t, 3)}
              bottomSpacing={false}
            />
          </GridCol>
        </GridRow>

        <GridRow className={styles.bottomSpacing}>
          <GridCol size={8}>
            {!noCategories ? (
              <FormField
                name={AdFiltersFieldNames.categories}
                component={AdCategoryTypeSelectInput}
                placeholder={Messages.createAdCategorySelectPlaceholder}
                serviceType={AllCategoriesProps.allCategories}
                key={`unique_select_key__${values.categories}`}
                isMulti
                bottomSpacing={false}
              />
            ) : (
              <LocationInput
                addressFieldName={AdFiltersFieldNames.address}
                coordinatesFieldName={AdFiltersFieldNames.geoSearch}
                preFillLocation
                bottomSpacing={false}
              />
            )}
          </GridCol>
          <GridCol size={4}>
            <FormField
              name={AdFiltersFieldNames.priceRange}
              component={RangeInput}
              initialPriceLte={initialValues?.priceLte}
              initialPriceGte={initialValues?.priceGte}
              //we use key to handle form reset for controlled component here
              key={`unique_range_input_key__${initialValues?.priceLte}${initialValues?.priceGte}`}
              bottomSpacing={false}
            />
          </GridCol>
        </GridRow>
        <GridRow className={classNames([styles.bottomSpacing, styles.leftSpacing])}>
          <GridCol size={6}>
            <FormRowItem label={t(Messages.createAdType)} textCenterMobile={false} className={styles.radioLabel}>
              <div className={styles.radios}>
                <FormField
                  name={AdFiltersFieldNames.advertType}
                  component={Checkbox}
                  inline
                  type="radio"
                  label={Messages.filtersAdTypeAll}
                  value={null}
                  bottomSpacing={false}
                />
                <FormField
                  name={AdFiltersFieldNames.advertType}
                  component={Checkbox}
                  inline
                  type="radio"
                  label={Messages.filtersAdTypeSale}
                  value={AdvertType.Sale}
                  bottomSpacing={false}
                />
                <FormField
                  name={AdFiltersFieldNames.advertType}
                  component={Checkbox}
                  inline
                  type="radio"
                  label={Messages.filtersAdTypePurchase}
                  value={AdvertType.Purchase}
                  bottomSpacing={false}
                />
              </div>
            </FormRowItem>
          </GridCol>
          <GridCol size={6}>
            <FormRowItem label={t(Messages.createAdServiceType)} textCenterMobile={false} className={styles.radioLabel}>
              <div className={styles.radios}>
                <FormField
                  name={AdFiltersFieldNames.serviceType}
                  component={Checkbox}
                  inline
                  type="radio"
                  label={Messages.filtersAdServiceTypeAll}
                  value={null}
                  bottomSpacing={false}
                />
                <FormField
                  name={AdFiltersFieldNames.serviceType}
                  component={Checkbox}
                  inline
                  type="radio"
                  label={Messages.filtersAdServiceTypeGood}
                  value={AdvertServiceType.Good}
                  bottomSpacing={false}
                />
                <FormField
                  name={AdFiltersFieldNames.serviceType}
                  component={Checkbox}
                  inline
                  type="radio"
                  label={Messages.filtersAdServiceTypeService}
                  value={AdvertServiceType.Service}
                  bottomSpacing={false}
                />
              </div>
            </FormRowItem>
          </GridCol>
        </GridRow>
        {isLocationBarVisible && !noCategories && (
          <GridRow className={styles.bottomSpacing}>
            <GridCol size={12}>
              <LocationInput
                addressFieldName={AdFiltersFieldNames.address}
                coordinatesFieldName={AdFiltersFieldNames.geoSearch}
                preFillLocation
                bottomSpacing={false}
              />
            </GridCol>
          </GridRow>
        )}
        {isLocationBarVisible && values.geoSearch && !Object.values(values.geoSearch).includes(undefined) && (
          <FormField
            name={AdFiltersFieldNames.distance}
            component={RangeSelectInput}
            min={DistanceFilterValues.min}
            max={DistanceFilterValues.max}
            stepLength={DistanceFilterValues.stepLength}
            startValue={DistanceFilterValues.startValue}
          />
        )}
        <Row justifyEnd>
          <Col size={6}>
            <Row justifyBetween alignCenter>
              {!noCategories ? (
                <Button
                  onClick={() => showLocationBar(!isLocationBarVisible)}
                  style={ButtonStyles.plainWithUnderline}
                  label={t(isLocationBarVisible ? Messages.btnHideLocationBar : Messages.btnShowLocationBar)}
                  className={classNames(styles.showLocationButton)}
                  size={ButtonSizes.unset}
                />
              ) : (
                <div />
              )}
              <Button
                onClick={() => onClick && onClick(form)}
                style={ButtonStyles.plainWithUnderline}
                label={t(Messages.btnClearFilters)}
                size={ButtonSizes.unset}
              />
            </Row>
          </Col>
        </Row>
      </form>
      {!submitOnChange && (
        <Row className={styles.submitButton}>
          <Button type={ButtonTypes.submit} label={t(Messages.btnSubmitFilters)} onClick={handleSubmit} />
        </Row>
      )}
    </FilterBlock>
  );
};

const AdFilters: React.FunctionComponent<AdFiltersProps> = ({
  submitOnChange,
  onClick,
  onChange,
  initialValues,
  noCategories,
}) => {
  const handleOnSubmit = ({ priceRange, ...values }: AdFilterFormSubmitValues) => {
    const priceRangeInput: MapT<number | Nil> = {
      [PriceRangeNames.priceLower]: priceRange[RangeNames.to],
      [PriceRangeNames.priceGrater]: priceRange[RangeNames.from],
    };

    if (!Object.keys(values).includes(AdFiltersFieldNames.address)) {
      // eslint-disable-next-line no-param-reassign
      delete values[AdFiltersFieldNames.geoSearch];
    }

    const payload: AdFilterFields = {
      ...values,
      advertType: values.advertType ?? undefined,
      serviceType: values.serviceType ?? undefined,
      ...priceRangeInput,
    };

    if (deepEqual(payload, initialValues)) {
      return Promise.resolve({});
    }

    return onChange(payload);
  };

  const formInitialValues = React.useMemo(() => transformToFormInitialValues(initialValues), [initialValues]);

  return (
    <Form<AdFilterFormSubmitValues>
      onSubmit={handleOnSubmit}
      render={props => renderForm(props, onClick, submitOnChange, noCategories)}
      initialValues={formInitialValues}
    />
  );
};

export default AdFilters;
