import classNames from 'classnames';
import * as React from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import Icon, { IconName, IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography, { TextColors } from '@Components/Typography';
import { hasFieldError } from '@Utils/form';

import InputContainer from '../InputContainer';

import styles from './CheckboxWithImg.module.scss';

export interface CheckboxWithImgProps extends FormFieldRenderProps<any> {
  required?: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  color?: TextColors;
  inline?: boolean;
  bottomSpacing?: boolean;
  iconTitle: IconName;
}

const CheckboxWithImg: React.FunctionComponent<CheckboxWithImgProps> = props => {
  const {
    input,
    iconTitle,
    input: { name, type, checked },
    label,
    color = 'gray',
    meta,
    disabled,
    required,
    inline,
    bottomSpacing,
  } = props;

  const hasError = hasFieldError(meta);

  return (
    <InputContainer meta={meta} required={required} inline={inline} bottomSpacing={bottomSpacing}>
      <Row
        alignCenter
        justifyCenter
        column
        className={classNames(styles.adServiceTypeSelectionWrapper, { [styles.selected]: checked })}
      >
        <Icon icon={iconTitle} size={IconSizes.l} className={styles.image} />
        <label className={classNames(styles.checkbox, { [styles.disabled]: disabled })}>
          <input tabIndex={0} {...input} name={name} disabled={disabled} type={type || 'radio'} checked={checked} />
          <span className={classNames(styles.inputBox, { [styles.error]: hasError })} />
        </label>
        <Typography className={styles.label} html msg={label} tag="span" size="s" color={color} />
      </Row>
    </InputContainer>
  );
};

export default CheckboxWithImg;
