import classNames from 'classnames';
import * as React from 'react';
import Dropzone, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import InputContainer from '@Components/forms/inputs/InputContainer/InputContainer';
import Icon, { IconSizes } from '@Components/Icon';
import Image from '@Components/Image';
import Typography from '@Components/Typography';
import { acceptedImageTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { isFileOrBlob } from '@Utils/file';
import { MessageKeysWithoutParams } from '@Utils/translation';

import styles from './ImageInput.module.scss';

export enum ImageStyle {
  displayDefault = 'displayDefault',
  displayAsSecondary = 'displayAsSecondary',
  displayAsMain = 'displayAsMain',
}

interface ImageInputProps extends FormFieldRenderProps<File> {
  multiple?: boolean;
  className?: string;
  style?: ImageStyle;
  bottomSpacing?: boolean;
  description?: MessageKeysWithoutParams;
  labelOnFile?: MessageKeysWithoutParams;
  label?: string;
  required?: boolean;
  inline?: boolean;
}

type ImageUploadBlockProps = {
  getRootProps: () => DropzoneRootProps;
  getInputProps: () => DropzoneInputProps;
  labelOnFile?: MessageKeysWithoutParams;
  description?: MessageKeysWithoutParams;
};

type ImageDisplayProps = { url: string | File; onRemoveClick: () => void };

export const getImageUrl = (value: any): string => {
  return isFileOrBlob(value) ? URL.createObjectURL(value) : value;
};

const ImageDisplay: React.FunctionComponent<ImageDisplayProps> = ({ url, onRemoveClick }) => {
  const t = useTranslations();

  return (
    <div className={classNames(styles.resultDisplay, styles.wrapperBlock)}>
      <Image src={getImageUrl(url)} alt={t(Messages.labelUploadedImage)} />
      <button className={styles.removeAction} onClick={onRemoveClick}>
        <Icon icon="delete" size={IconSizes.s} />
      </button>
    </div>
  );
};

const ImageUploadBlock: React.FunctionComponent<ImageUploadBlockProps> = ({
  getRootProps,
  getInputProps,
  labelOnFile,
  description,
}) => {
  const t = useTranslations();

  return (
    <section className={classNames(styles.imageInput, styles.wrapperBlock)} {...getRootProps()}>
      <input {...getInputProps()} />
      <Icon icon="plus" size={IconSizes.m} />
      {labelOnFile && <Typography tag="div" className={styles.label} msg={t(labelOnFile)} />}
      {description && <Typography tag="div" msg={t(description)} size="s" />}
    </section>
  );
};

class ImageField extends React.PureComponent<ImageInputProps> {
  render() {
    const {
      input,
      multiple = false,
      label,
      required = false,
      meta,
      inline,
      className,
      style = ImageStyle.displayDefault,
      bottomSpacing,
      labelOnFile,
      description,
    } = this.props;

    return (
      <InputContainer
        label={label}
        meta={meta}
        required={required}
        className={className}
        inline={inline}
        showErrorIcon
        bottomSpacing={bottomSpacing}
        showErrorIconTooltip
        containerClassName={styles.inputContainer}
        errorDisplayClassName={styles.errorContainer}
      >
        <Dropzone
          onDrop={acceptedFiles => input.onChange(acceptedFiles[0])}
          multiple={multiple}
          accept={acceptedImageTypes}
        >
          {({ getRootProps, getInputProps }) => (
            <div className={styles[style]}>
              {input.value && <ImageDisplay url={input.value} onRemoveClick={() => input.onChange(null)} />}
              {!input.value && (
                <ImageUploadBlock
                  getInputProps={getInputProps}
                  getRootProps={getRootProps}
                  labelOnFile={labelOnFile}
                  description={description}
                />
              )}
            </div>
          )}
        </Dropzone>
      </InputContainer>
    );
  }
}

export default ImageField;
