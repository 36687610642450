import * as React from 'react';

import { MetaWrapper } from '@Components/Meta';
import { Messages } from '@Config/messages';
import { Translator } from '@Utils/translation';

export interface DocumentTitleProps {
  title: Messages | string | undefined;
  children: React.ReactChild | undefined;
}

export const buildTitle = (text: Messages | string, t: Translator) => {
  const root = t(Messages.websiteTitle) || '';
  return `${text.charAt(0).toUpperCase()}${text.slice(1)} | ${root || ''}`;
};

const DocumentTitle: React.FunctionComponent<DocumentTitleProps> = ({ title, children }) => {
  const originalTitle = document.title;
  return <MetaWrapper meta={{ title: title || originalTitle }}>{children}</MetaWrapper>;
};

export default React.memo(DocumentTitle);
