import { FieldMetaState } from 'react-final-form';

import { TemplateSchemaPropertyType } from '@Components/forms/SchemaFields/SchemaFields';
import { ApplicationLanguages, MAX_FILE_SIZE, MAX_IMG_SIZE } from '@Config/constants';
import { Messages } from '@Config/messages';
import { isDefined } from '@Utils/tools';
import { Translator, MessageKeysWithoutParams } from '@Utils/translation';
import { Price } from '@Utils/types';

import { selectedDayIsAfterYesterday, selectedDayIsNotBefore } from './dates';

export type Validator = (value: any) => Partial<string> | undefined;

// Takes any number of validator functions and returns first error there is.
export const validate =
  (...validators: Validator[]) =>
  (value: string | number | undefined | File) =>
    validators.reduce((error, validator) => error || (validator(value) as any), undefined);

export const REGEX = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  LT_PHONE: /^(8|\+370)[1-9]\d{7}$/,
  PL_PHONE: /^\+48\d{9}$/,
  FLOAT_NUMBER: /^[0-9]*[.]?[0-9]*$/,
  ONLY_LETTERS_AND_SPACE: /^[a-z][a-z\s]*$/,
  LETTERS_AND_NUMBERS: /^[a-zA-Z0-9]+$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[0-9]).{5,}$/,
  PHONE_IN_TEXT: /(8|\+370)[1-9]\d{7}/,
  LITHUANIAN_VAT_CODE: /^LT\d{11}$/,
  ONLY_WHOLE_NUMBERS: /^[0-9]*$/,
  NUMBERS: /^([0-9]*[.,])?[0-9]*$/,
  USERNAME: /^[\w.@+-]{4,}$/,
  PRICE: /^\d+(\.\d{1,2})?$/,
  MAX_PRICE: /^\d{1,8}(\.\d{1,2})?$/,
  IBAN: /^[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?$/,
  HTML: /(<([^>]+)>)/gi,
};

export const validateRequired =
  (t: Translator): Validator =>
  value => {
    if (!isDefined(value) || (Array.isArray(value) && !value.length) || value === '') {
      return t(Messages.errorFieldRequired);
    }
    return undefined;
  };

export const validateSelectedDayIsAfterYesterday =
  (t: Translator): Validator =>
  value =>
    !value || selectedDayIsAfterYesterday(new Date(value))
      ? undefined
      : t(Messages.errorOrderSelectedDateIsBeforeToday);

export const validateSelectedDayIsNotBefore =
  (t: Translator, targetDate: string): Validator =>
  value => {
    if (!value || !targetDate) {
      return undefined;
    }
    return selectedDayIsNotBefore(new Date(value), new Date(targetDate))
      ? undefined
      : t('msg_error_end_time_is_before_start_time');
  };

export const validateRequiredNumber =
  (t: Translator): Validator =>
  value =>
    value || value === 0 ? undefined : t(Messages.errorFieldRequired);

/** Not used
export const validateEqual = (
  values: { [ key: string ]: string },
  field: string,
  fieldToMatch: string,
  errMsg = Messages.errorFieldsDoNotMatch,
): {} => {
  const errors = {} as any;
  if (values[ field ] !== values[ fieldToMatch ]) {
    errors[ fieldToMatch ] = errMsg;
  }
  return errors;
};
*/

export const validateNotEqual = (
  values: { [key: string]: string },
  field: string,
  fieldToMatch: string,
  t: Translator,
  errMsg: MessageKeysWithoutParams,
) => {
  const errors = {} as any;
  if (values[field] === values[fieldToMatch]) {
    errors[fieldToMatch] = t(errMsg);
  }
  return errors;
};

/** Not used
export const validateVAT = (value: string) => {
  return !value ? undefined : REGEX.LITHUANIAN_VAT_CODE.test(value) ? undefined : Messages.errorVatFormat;
};
*/

export const validateIBAN =
  (t: Translator): Validator =>
  (value: string) => {
    return !value || REGEX.IBAN.test(value) ? undefined : t(Messages.errorInvalidIBAN);
  };

export const validateEmail =
  (t: Translator): Validator =>
  (value: string) => {
    return !value || REGEX.EMAIL.test(value) ? undefined : t(Messages.errorInvalidEmail);
  };

export const validateUsername =
  (t: Translator): Validator =>
  (value: string) => {
    return !value || REGEX.USERNAME.test(value) ? undefined : t(Messages.errorInvalidUsername);
  };

export const validatePhone =
  (t: Translator, language: ApplicationLanguages): Validator =>
  (value: string | undefined) => {
    const phoneRegex = language === 'pl' ? REGEX.PL_PHONE : REGEX.LT_PHONE;
    return !value || phoneRegex.test(value) ? undefined : t(Messages.errorInvalidPhone);
  };

// Password must contain at least one number,special symbol,uppercase letter and be 10 character length.
export const validatePassword =
  (t: Translator): Validator =>
  (value: string) => {
    return !value || REGEX.PASSWORD.test(value) ? undefined : t(Messages.errorPasswordFormat);
  };

export const validateMaxLength =
  (t: Translator, maxLen: number): Validator =>
  (value: string) => {
    return value && value.length > maxLen ? t(Messages.errorOverMaxLength, { maxLen }) : undefined;
  };

export const validateNoNumberInText =
  (t: Translator): Validator =>
  (value: string) => {
    return !value || !REGEX.PHONE_IN_TEXT.test(value.replace(/ /g, ''))
      ? undefined
      : t(Messages.errorTextContainsNumber);
  };

export const validateMaxSize =
  (t: Translator, maxSize: number): Validator =>
  (value: string) => {
    try {
      const valueNum = Number(value);
      return value && valueNum >= maxSize ? t(Messages.errorOverMaxSize, { maxSize }) : undefined;
    } catch (e) {
      return t(Messages.errorOverMaxSize, { maxSize });
    }
  };

export const validateMaxIntAmount =
  (t: Translator, maxInt: number): Validator =>
  (value: string) => {
    try {
      const valueNum = Number(value);
      const isInt = valueNum % 1 === 0;
      const isCorrectAmount = valueNum <= maxInt;
      if (value && !isCorrectAmount && isInt) {
        return t(Messages.errorOverMaxAmount, { maxAmount: maxInt });
      }
      if (value && isCorrectAmount && !isInt) {
        return t(Messages.errorOnlyWholeNumbersAllowed);
      }
      return undefined;
    } catch (e) {
      return t(Messages.errorWrongNumber);
    }
  };

export const validateMaxAmount =
  (t: Translator, maxAmount: number): Validator =>
  (value: string) => {
    try {
      const valueNum = Number(value);
      const isCorrectAmount = valueNum <= maxAmount;
      if (value && !isCorrectAmount) {
        return t(Messages.errorOverMaxPriceAmount);
      }
      return undefined;
    } catch (e) {
      return t(Messages.errorWrongNumber);
    }
  };

export const validateMinAmount =
  (t: Translator, minAmount: number): Validator =>
  (value: string) => {
    try {
      if (!value) return;
      const valueNum = Number(value);
      const isCorrectAmount = valueNum >= minAmount;
      return valueNum && isCorrectAmount ? undefined : t(Messages.errorMinAmount);
    } catch (e) {
      return t(Messages.errorMinAmount);
    }
  };

export const validateMinLength =
  (t: Translator, minLen: number): Validator =>
  (value: string) => {
    return value && value.length < minLen ? t(Messages.errorMinLength, { minLen }) : undefined;
  };

export function hasFieldError<T>(meta: FieldMetaState<T>) {
  const { touched, error = null, submitError } = meta;
  return touched && (error || submitError);
}

export const validateOnlyWholeNumbers = (value?: string) => {
  return !value || REGEX.ONLY_WHOLE_NUMBERS.test(value) ? undefined : Messages.errorOnlyWholeNumbersAllowed;
};

export const validateNumber =
  (t: Translator): Validator =>
  (value?: string) => {
    return !value || REGEX.NUMBERS.test(value) ? undefined : t(Messages.errorOnlyNumbersAllowed);
  };

export const validateFloat =
  (t: Translator): Validator =>
  (value?: string) => {
    return !value || REGEX.FLOAT_NUMBER.test(value) ? undefined : t(Messages.errorOnlyNumbersAllowed);
  };

export const validateFractionDigitsLength = (t: Translator, fractionDigitsMaxLength: number): Validator => {
  return (value?: string) => {
    return (value?.split('.')?.at(-1)?.length || 0) <= fractionDigitsMaxLength
      ? undefined
      : t('msg_error_over_max_fraction_digit_length', { maxLen: fractionDigitsMaxLength });
  };
};

export const validateMaxImgSize =
  (t: Translator): Validator =>
  (value: File) => {
    return value && value.size / 1000 / 1000 > MAX_IMG_SIZE ? t(Messages.errorImageSizeTooLarge) : undefined;
  };

export const validateMaxFileSize =
  (t: Translator): Validator =>
  (value: File) => {
    return value && value.size / 1000 / 1000 > MAX_FILE_SIZE ? t(Messages.errorFileSizeTooLarge) : undefined;
  };

export const validateImgFormat =
  (t: Translator): Validator =>
  (value: File) => {
    const validExtensions = ['png', 'jpg', 'jpeg', 'gif'];
    const fileExtension = value && value.name ? value.name.toLocaleLowerCase().split('.').pop() : null;
    if (fileExtension) {
      const isFormatValid = validExtensions.includes(fileExtension);
      return value && isFormatValid ? undefined : t(Messages.errorWrongImageFormat);
    }
    return undefined;
  };

export const normalizePrice = (value: Price): string => {
  if (String(value).includes(',')) {
    return String(value).replace(/,/g, '.');
  }
  return String(value);
};

export const normalizeNumber = (value: string, type: TemplateSchemaPropertyType) => {
  let num;

  if (type === TemplateSchemaPropertyType.number) {
    num = parseFloat(value);
  } else {
    num = parseInt(value, 10);
  }

  return isNaN(num) ? value : num;
};

export const validateLettersAndNumbers =
  (t: Translator): Validator =>
  (value: string) => {
    return !value || REGEX.LETTERS_AND_NUMBERS.test(value) ? undefined : t(Messages.errorOnlyLettersAndNumbers);
  };

export const validatePrice =
  (t: Translator, maxLen = 8): Validator =>
  (value?: string) => {
    if (!value) {
      return undefined;
    }
    if (!REGEX.PRICE.test(value)) {
      return t(Messages.errorInvalidPriceFormat);
    }
    return REGEX.MAX_PRICE.test(value) ? undefined : t(Messages.errorOverMaxLength, { maxLen });
  };

// Validate that value is over provided amount
export const validateGreaterThan =
  (t: Translator, amount: number): Validator =>
  (value: string) => {
    const valueNumber = Number(value);
    if (!valueNumber) {
      return undefined;
    }
    return valueNumber > amount ? undefined : t(Messages.errorSmallerNumber);
  };

// Validate that value is less than provided amount
export const validateLessThan =
  (t: Translator, amount: number): Validator =>
  (value: string) => {
    const valueNumber = Number(value);
    if (!valueNumber) {
      return undefined;
    }
    return valueNumber < amount ? undefined : t(Messages.errorGreaterNumber);
  };
