import * as React from 'react';

import { GeneralContractorPriceType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { MessageKeysWithoutParams } from '@Utils/translation';

import SelectInput, { SelectInputProps } from '../SelectInput';

type CategoryPriceTypeSelectProps = SelectInputProps;

const conditions = {
  [GeneralContractorPriceType.EurPerHour]: 'msg_contractor_field_category_price_type_eur_per_hour_placeholder',
  [GeneralContractorPriceType.EurPerHectare]: 'msg_contractor_field_category_price_type_eur_per_hectare_placeholder',
} satisfies Record<GeneralContractorPriceType, MessageKeysWithoutParams>;

const CategoryPriceTypeSelect = (props: CategoryPriceTypeSelectProps) => {
  const t = useTranslations();

  const options = Object.entries(conditions).map(([value, msgKey]) => ({
    value,
    label: t(msgKey),
  }));

  return <SelectInput options={options} {...props} />;
};

export default CategoryPriceTypeSelect;
