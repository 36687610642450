import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showSuccessModal } from '@Components/Modal/modalHelpers';
import GeneralOrderForm from '@Components/Order/GeneralOrder/GeneralOrderForm';
import { getGeneralOrderFormInput } from '@Components/Order/GeneralOrder/GeneralOrderForm/generalOrderUtils';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import {
  CreateGeneralOrderMutation,
  GeneralOrderAmountType,
  useCreateGeneralOrderMutation,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { getUserData } from '@Store/auth/auth.selector';
import { locationChange } from '@Store/navigation/navigation.actions';
import { GAAction, GACategory, sendGaEvent } from '@Utils/gaEvents';
import { callMutationForForm } from '@Utils/graphql';
import { GeneralOrderFormData } from '@Utils/types';

const GeneralOrderFormContainer = ({ onCancel }: { onCancel?: () => void }) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  const { item: user } = useSelector(getUserData);

  // react final form contains the issue that it fails comparing initial values with nested objects
  const initialValues: GeneralOrderFormData = useMemo(
    () => ({
      category: '',
      amount: '',
      amountType: GeneralOrderAmountType.Hectare,
      amountParts: [],
      marketPriceSelection: null,
      paymentCondition: '',
      price: '',
      name: '',
      surname: '',
      email: user?.info.email || '',
      phone: user?.info.activeCompany.company.phone || '',
      description: '',
      startDate: '',
      endDate: '',
      address: '',
      coordinates: { lat: undefined, lng: undefined },
      numberOfFields: '1',
    }),
    [user],
  );

  const [createOrderAction] = useCreateGeneralOrderMutation({
    onCompleted: () => {
      dispatch(
        showSuccessModal(
          t(Messages.btnOk),
          () => {
            dispatch(locationChange({ path: RouteConfig.Marketplace.buildLink() }));
          },
          t(Messages.generalOrderCreateSuccess),
          t(Messages.generalOrderCreateSuccessSubtext),
        ),
      );
      sendGaEvent({
        category: GACategory.generalOrder,
        action: GAAction.click,
      });
    },
  });

  const handleSubmit = async (formData: GeneralOrderFormData) => {
    const { data, type } = getGeneralOrderFormInput(formData, t);
    if (type === 'ERROR') {
      return data;
    }

    return callMutationForForm<CreateGeneralOrderMutation>(
      createOrderAction({
        variables: {
          input: data,
        },
      }),
      formData,
    );
  };

  return <GeneralOrderForm onSubmit={handleSubmit} onCancel={onCancel} initialValues={initialValues} />;
};

export default GeneralOrderFormContainer;
