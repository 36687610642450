import { Cookies } from 'react-cookie';

export const COOKIE_MINUTE = 60;

export function getCookie(cookieName: string) {
  const cookies = new Cookies();
  return cookies.get(cookieName);
}

export function getCookieBooleanValue(cookieName: string): boolean | null {
  return getCookie(cookieName) ? getCookie(cookieName) === 'true' : null;
}
export function getCookieBooleanValueWithDefault(cookieName: string, defaultValue: boolean): boolean {
  const value = getCookieBooleanValue(cookieName);
  return value === null ? defaultValue : value;
}
export function setCookie(cookieName: string, value: string | object | boolean, options: { maxAge?: number } = {}) {
  const cookies = new Cookies();
  return cookies.set(cookieName, value, { path: '/', ...options });
}

export function removeCookie(cookieName: string) {
  const cookies = new Cookies();
  return cookies.remove(cookieName, { path: '/' });
}
