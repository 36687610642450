import classNames from 'classnames';
import * as React from 'react';

import Container from '@Components/layout/Container';
import Row from '@Components/layout/Row';

import styles from './PageHeader.module.scss';

interface PageHeaderProps {
  children?: React.ReactElement;
  className?: string;
}

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({ children, className }) => {
  return (
    <Row column alignCenter justifyCenter className={classNames(styles.pageHeader, className)}>
      <Container>{children}</Container>
    </Row>
  );
};

export default PageHeader;
