import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import lazyImport from '@Utils/lazyImport';

import { RouteInfo } from '../appRoutes';

const AdvertPage = lazyImport(() => import('./AdPage'));

const adsPrivateRoutes: RouteInfo[] = [
  {
    path: RouteConfig.AdvertAcceptance.template,
    component: AdvertPage,
    title: Messages.advertPageTitle,
    exact: false,
  },
];

export default adsPrivateRoutes;
