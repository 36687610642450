import { LOCATION_CHANGE } from 'connected-react-router';
import * as dotProp from 'dot-prop-immutable';
import { Step } from 'react-joyride';
import { Action, Success } from 'typescript-fsa';

import { ModalParams, ModalType } from '@Components/Modal';
import { ApplicationLanguages, DEFAULT_LANG, LocalStorage } from '@Config/constants';
import { getDefaultLanguage } from '@Config/localization';
import { AppConfigFragment, GetAppConfigQuery } from '@Graphql/graphqlTypes.generated';
import { getObjectFromLocalStorage } from '@Utils/localStorage';
import { createReducer } from '@Utils/redux';

import { changeLanguage, getAppConfig, hideModal, showModal, showUserTour } from './app.actions';
import { ChangeLanguagePayload, ShowModalPayload } from './app.types';

export type ModalState = {
  isVisible: boolean;
  modalType: null | ModalType;
  modalParams: ModalParams;
};

const modalInitialState = {
  isVisible: false,
  modalType: null,
  modalParams: {},
};

export type AppState = {
  config: AppConfigFragment;
  modal: ModalState;
  userTour: Step[];
  isReady: boolean;
  isApiDead: boolean;
  readonly currentLanguage: ApplicationLanguages;
};

const buildAppConfig = (): AppConfigFragment => {
  return {
    version: '',
    facebookAppId: '',
    googleClientId: '',
    appleClientId: '',
    appleRedirectUri: '',
    enabledLanguages: [],
    defaultLanguage: DEFAULT_LANG,
    contact: {},
    aboutUs: {},
    rentPage: {},
    terms: {},
    privacy: {},
    googleTagManagerId: '',
    isAuctionEnabled: false,
    isForumEnabled: false,
    isPaymentEnabled: false,
    isOldOrderFormEnabled: false,
    predefinedSearch: [],
    landingPageImages: [],
    landingPageBanners: [],
    stripePublishableKey: '',
    mainLogo: '',
    parentCategories: [],
    advertLoanInterestRate: 0,
    maxAdvertLoanAmount: 0,
    maxAdvertLoanDuration: 0,
    ...getObjectFromLocalStorage<AppConfigFragment>(LocalStorage.appConfig),
  };
};

export const initialState: AppState = {
  config: buildAppConfig(),
  isReady: !!buildAppConfig().version,
  isApiDead: false,
  modal: modalInitialState,
  userTour: [],
  currentLanguage: getDefaultLanguage(),
};

const appReducer = createReducer<AppState>(initialState, {
  [getAppConfig.done.type]: (state: AppState, action: Action<Success<{}, GetAppConfigQuery>>) => {
    const newState = dotProp.set(state, 'config', action.payload.result);
    return dotProp.set(newState, 'isReady', true);
  },
  [getAppConfig.failed.type]: (state: AppState) => {
    const newState = dotProp.set(state, 'isReady', false);
    return dotProp.set(newState, 'isApiDead', true);
  },
  [changeLanguage.type]: (state: AppState, action: Action<ChangeLanguagePayload>) => {
    return dotProp.set(state, 'currentLanguage', action.payload.lang);
  },
  [showModal.type]: (state: AppState, action: Action<ShowModalPayload<any>>) => {
    return dotProp.set(state, 'modal', {
      isVisible: true,
      modalType: action.payload.type,
      modalParams: action.payload.params,
    });
  },
  [LOCATION_CHANGE]: (state: AppState) => {
    if (state.modal.isVisible && state.modal.modalType !== ModalType.successModal) {
      return dotProp.set(state, 'modal', modalInitialState);
    }
    return state;
  },
  [hideModal.type]: (state: AppState) => {
    return dotProp.set(state, 'modal', modalInitialState);
  },
  [showUserTour.type]: (state: AppState, action: Action<Step[]>) => {
    return dotProp.set(state, 'userTour', action.payload);
  },
});

export default appReducer;
