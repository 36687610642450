import * as React from 'react';
import { FieldMetaState } from 'react-final-form';

import Icon, { IconSizes } from '@Components/Icon';
import Typography from '@Components/Typography/Typography';
import { useTranslations } from '@Hooks/useTranslations';
import { hasFieldError } from '@Utils/form';
import { MessageKeysWithoutParams } from '@Utils/translation';

import styles from './FieldArrayError.module.scss';

export interface FieldArrayErrorProps {
  meta: FieldMetaState<{}>;
  showErrorIcon?: boolean;
}

const FieldArrayError: React.FunctionComponent<FieldArrayErrorProps> = ({ meta, showErrorIcon }) => {
  const hasError = hasFieldError(meta);
  const t = useTranslations();

  if (!hasError || typeof meta.error !== 'string') {
    return null;
  }

  return (
    <div className={styles.container}>
      <Typography tag="span" msg={t(meta.error as MessageKeysWithoutParams)} className={styles.error} />
      {showErrorIcon && (
        <div>
          <Icon icon="warning-sign" size={IconSizes.ss} />
        </div>
      )}
    </div>
  );
};

export default FieldArrayError;
