import FileSaver from 'file-saver';
import { all, ForkEffect, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { InvoiceTypes } from '@Config/constants';
import { callApiGet } from '@Utils/sagas';

import { downloadInvoice } from './invoice.actions';
import { DownloadInvoiceActionPayload, DownloadInvoiceActionSuccess } from './invoice.types';

export function* downloadInvoiceStartedSaga(action: Action<DownloadInvoiceActionPayload>) {
  yield callApiGet(action.payload.url, action, downloadInvoice);
}

export function* downloadInvoiceDoneSaga(action: Action<DownloadInvoiceActionSuccess>) {
  const blob = new Blob([action.payload.result], { type: InvoiceTypes.PDF });
  const filename = action.payload.params.number;
  FileSaver.saveAs(blob, filename);
}

export function* invoiceSagas() {
  const started: ForkEffect<never> = yield takeLatest(downloadInvoice.started, downloadInvoiceStartedSaga);
  const done: ForkEffect<never> = yield takeLatest(downloadInvoice.done, downloadInvoiceDoneSaga);
  yield all([started, done]);
}
