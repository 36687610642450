import React from 'react';

import Row from '@Components/layout/Row';
import PageTitle from '@Components/Page/PageTitle';
import { useGetStaticPageQuery } from '@Graphql/graphqlTypes.generated';
import { hasData } from '@Utils/graphql';

import styles from './StaticPageContent.module.scss';

const StaticPageContent = ({ slug, withTitle = false }: { slug: string; withTitle?: boolean }) => {
  const { loading, data, error } = useGetStaticPageQuery({
    variables: { slug },
    fetchPolicy: 'network-only',
  });

  if (error || !hasData(data, loading, 'pageTranslation') || !data) {
    return null;
  }

  return (
    <div>
      {withTitle && (
        <Row justifyCenter alignCenter className={styles.title}>
          <PageTitle msg={data.pageTranslation.title} />
        </Row>
      )}

      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: data.pageTranslation.text }} />
    </div>
  );
};

export default StaticPageContent;
