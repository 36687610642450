import classNames from 'classnames';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';

import Typography from '@Components/Typography/Typography';
import { OptionalProps } from '@Utils/types';

import styles from './Tooltip.module.scss';

export enum TooltipPosition {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export interface TooltipProps {
  title?: string;
  message?: string;
  position?: TooltipPosition;
  children: React.ReactNode;
  className?: OptionalProps<typeof styles>;
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  children,
  title,
  message,
  position = TooltipPosition.bottom,
  className,
}) => {
  const [refEl, setRefEl] = useState<HTMLElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const { attributes, styles: popperStyles } = usePopper(refEl, popperEl, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 20] } },
    ],
    placement: position,
  });

  const renderContent = (
    <div className={classNames(styles.text, className?.text)}>
      {title && <Typography tag="div" color="primary" size="s" msg={title} />}
      {message && <Typography tag="p" color="black" size="s" html msg={message} />}
    </div>
  );

  return (
    <div
      className={classNames(styles.tooltipContainer, className?.tooltipContainer)}
      ref={setRefEl}
      onClick={() => setOpen(false)}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {children}
      {open && (message || title) && (
        <div
          ref={setPopperEl}
          className={classNames(styles.tooltip, className?.tooltip)}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          {renderContent}
          <div
            ref={setArrowElement}
            className={classNames(styles.arrow, className?.arrow)}
            style={popperStyles.arrow}
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
