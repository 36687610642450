import classNames from 'classnames';
import React from 'react';

import Button, { ButtonSizes, ButtonStyles } from '@Components/Buttons/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';

import styles from './FieldGroup.module.scss';

export interface FieldGroupProps {
  label?: string;
  className?: string;
  expandable?: boolean;
  children?: React.ReactNode;
}

const FieldGroup: React.FC<FieldGroupProps> = ({ label, children, className, expandable }) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const fieldGroupChildren = <div className={className}>{children}</div>;

  const arrow = (
    <div>
      <Icon
        size={IconSizes.s}
        icon="arrow-down"
        className={classNames({ [styles.rotateArrow]: isExpanded }, styles.iconColor)}
      />
    </div>
  );

  const fieldLabel = label && (
    <div className={styles.label}>
      <Typography msg={label} tag="span" bold color="black" />
    </div>
  );

  return (
    <div className={styles.fieldGroup}>
      {expandable ? (
        <Row justifyBetween constant alignCenter>
          <Button
            style={ButtonStyles.withoutStyling}
            size={ButtonSizes.fill}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {fieldLabel}
            {arrow}
          </Button>
        </Row>
      ) : (
        fieldLabel
      )}
      {expandable ? isExpanded && fieldGroupChildren : fieldGroupChildren}
    </div>
  );
};

export default FieldGroup;
