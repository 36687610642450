import classNames from 'classnames';
import React from 'react';

import Typography from '@Components/Typography';

import styles from './NotificationBox.module.scss';

interface NotificationBoxProps {
  count: number;
  onClick?: () => void;
  className?: string;
}

const NotificationBox: React.FC<NotificationBoxProps> = ({ className, count, onClick = () => {} }) => {
  return (
    <div className={classNames(className, styles.count, { [styles.big]: count > 9 })} onClick={onClick}>
      <Typography msg={count > 99 ? '99+' : String(count)} tag="span" color="white" size="s" />
    </div>
  );
};

export default NotificationBox;
