import { LocalStorage, WebViewMessagesTypes } from '@Config/constants';
import { deleteFromLocalStorage, setToLocalStorage } from '@Utils/localStorage';
import { postMessageToWebView } from '@Utils/mobileWebView';

export const saveUserToken = (token: string) => {
  postMessageToWebView(WebViewMessagesTypes.userToken, {
    token,
  });
  setToLocalStorage(LocalStorage.userToken, token);
};

export const removeUserToken = () => {
  postMessageToWebView(WebViewMessagesTypes.logout);
  deleteFromLocalStorage(LocalStorage.userToken);
};
