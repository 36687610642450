import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import lazyImport from '@Utils/lazyImport';

import { RouteInfo } from '../appRoutes';

const RegisterSuccessPage = lazyImport(() => import('./RegisterSuccessPage'));
const UserPage = lazyImport(() => import('./UserPage'));

const routes: RouteInfo[] = [
  {
    path: RouteConfig.User.template,
    component: UserPage,
    title: Messages.userPage,
  },
  {
    path: RouteConfig.RegisterSuccess.template,
    component: RegisterSuccessPage,
    title: Messages.registerSuccessPage,
  },
];

export default routes;
