import { FieldValidator } from 'final-form';
import * as React from 'react';
import { useSelector } from 'react-redux';

import FormField from '@Components/forms/FormField';
import DateInput from '@Components/forms/inputs/DateInput/DateInput';
import TimeSelect from '@Components/forms/inputs/selects/TimeSelect/TimeSelect';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import { normalizeDate } from '@Utils/dateMoment';
import { selectedDayIsAfterYesterday } from '@Utils/dates';
import { MessageKeysWithoutParams } from '@Utils/translation';

export interface DateTimeInputProps {
  dateInputName: string;
  dateInputValidate: FieldValidator<string>;
  datePlaceholder: MessageKeysWithoutParams;
  timeInputName: string;
  timeInputValidate: FieldValidator<string>;
  timePlaceholder?: MessageKeysWithoutParams;
  showErrorIcon?: boolean;
  className?: {
    container?: string;
    item?: string;
  };
  bottomSpacing?: boolean;
}

const DateTimeInput: React.FunctionComponent<DateTimeInputProps> = ({
  dateInputName,
  datePlaceholder,
  timeInputName,
  timeInputValidate,
  showErrorIcon,
  className,
  bottomSpacing,
  dateInputValidate,
}) => {
  const currentLanguage = useSelector(getCurrentLanguage);

  return (
    <Row className={className?.container} justifyBetween>
      <Col size={6} className={className?.item}>
        <FormField
          name={dateInputName}
          component={DateInput}
          placeholder={datePlaceholder}
          parse={date => normalizeDate(date, currentLanguage)}
          isValidDate={selectedDayIsAfterYesterday}
          validate={dateInputValidate}
          showErrorIcon={showErrorIcon}
          bottomSpacing={bottomSpacing}
        />
      </Col>
      <Col size={5} className={className?.item}>
        <FormField
          name={timeInputName}
          component={TimeSelect}
          localizeSelectLabels={false}
          validate={timeInputValidate}
          bottomSpacing={bottomSpacing}
        />
      </Col>
    </Row>
  );
};

export default DateTimeInput;
