import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@Components/Buttons/Button';
import Container from '@Components/layout/Container';
import Layout, { LayoutBackgrounds } from '@Components/layout/Layout';
import Row from '@Components/layout/Row';
import PageTitle from '@Components/Page/PageTitle';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './InvitationKeyNotFoundPage.module.scss';

const InvitationKeyNotFoundPage: React.FC = () => {
  const t = useTranslations();

  return (
    <Layout background={LayoutBackgrounds.grayBackground}>
      <Container className={styles.invitationKeyNotFoundPageWrapper}>
        <PageTitle msg={t(Messages.invitationKeyNotFoundPage)} />
        <Row justifyCenter constant>
          <Link to={RouteConfig.Marketplace.buildLink()}>
            <Button label={t(Messages.invitationKeyNotFoundButton)} />
          </Link>
        </Row>
      </Container>
    </Layout>
  );
};

export default InvitationKeyNotFoundPage;
