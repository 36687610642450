import classNames from 'classnames';
import * as React from 'react';

import { FormFieldRenderProps } from '@Components/forms/FormField';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { AdvertPricingType } from '@Graphql/graphqlTypes.generated';

import styles from './ToggleButton.module.scss';

export interface CheckboxInputProps extends FormFieldRenderProps<string> {
  pricingTypeValue: AdvertPricingType;
  className?: string;
  label: string;
}

const ToggleButton: React.FunctionComponent<CheckboxInputProps> = props => {
  const { input, pricingTypeValue, label, className } = props;

  const isSelected = pricingTypeValue === input.value;

  const setValue = () => {
    input.onChange(pricingTypeValue);
  };

  const resetValue = () => {
    input.onChange('');
  };

  return (
    <Row className={classNames(styles.toggle, className)} constant>
      <button
        className={classNames(styles.buttonInToggle, styles.inputBox, { [styles.selected]: isSelected })}
        type="button"
        onClick={isSelected ? resetValue : setValue}
      >
        {isSelected && <Icon icon="check" size={IconSizes.ss} className={styles.checkIcon} />}
      </button>
      <Typography html msg={label} tag="span" size="s" color="gray" />
    </Row>
  );
};

export default ToggleButton;
