import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Button, { ButtonSizes, ButtonStyles } from '@Components/Buttons/Button';
import Icon, { IconSizes } from '@Components/Icon';
import CalendarEventModal from '@Components/Modal/CalendarEventModal';
import CompanyFormModal from '@Components/Modal/CompanyFormModal';
import ConfirmationModal from '@Components/Modal/ConfirmationModal';
import CookieModal from '@Components/Modal/CookieModal';
import OrderResultsModal from '@Components/Modal/OrderResultsModal';
import SpecifyLocationModal from '@Components/Modal/SpecifyLocationModal';
import Paper, { PaperWidth } from '@Components/Paper';
import PaperBody, { PaperBodyOverflow, PaperBodySpacing } from '@Components/PaperBody';
import PaperHeader from '@Components/PaperHeader';
import { MediaQueries } from '@Config/constants';
import { hideModal } from '@Store/app/app.actions';
import { ModalState } from '@Store/app/app.reducer';
import { getModalState } from '@Store/app/app.selectors';
import { RootState } from '@Store/reducers';

import AvailabilityPreviewModal from './AvailabilityPreviewModal';
import FiltersModal from './FiltersModal';
import InviteUserFormModal from './InviteUserFormModal';
import styles from './Modal.module.scss';
import SearchResultsModal from './SearchResultsModal';
import SuccessModalBody from './SuccessModalBody';

export interface ModalParams<T = any> {
  data?: T;
  title?: string;
  subtitle?: string;
  modalSize?: PaperWidth;
  modalSpacing?: PaperBodySpacing;
  modalOverflow?: PaperBodyOverflow;
  aboveCookieBar?: boolean;
  onSubmitButtonClick?: () => void;
  withCloseButton?: boolean;
  onMobileOnly?: boolean;
}

export enum ModalType {
  successModal = 'success-modal',
  availabilityPreviewModal = 'availability-preview-modal',
  searchResultsModal = 'search-results-modal',
  filtersModal = 'filters-modal',
  calendarEventModal = 'calendar-event-modal',
  companyFormModal = 'company-form-modal',
  cookieModal = 'cookie-modal',
  confirmationModal = 'confirmation-modal',
  inviteUserFormModal = 'invite-user-modal',
  specifyLocationModal = 'specify-location-modal',
  orderResultsModal = 'order-results-modal',
}

const modalBodyElements = {
  [ModalType.successModal]: SuccessModalBody,
  [ModalType.availabilityPreviewModal]: AvailabilityPreviewModal,
  [ModalType.searchResultsModal]: SearchResultsModal,
  [ModalType.filtersModal]: FiltersModal,
  [ModalType.calendarEventModal]: CalendarEventModal,
  [ModalType.companyFormModal]: CompanyFormModal,
  [ModalType.cookieModal]: CookieModal,
  [ModalType.confirmationModal]: ConfirmationModal,
  [ModalType.inviteUserFormModal]: InviteUserFormModal,
  [ModalType.specifyLocationModal]: SpecifyLocationModal,
  [ModalType.orderResultsModal]: OrderResultsModal,
};

export interface ModalProviderProps<T = any> {
  closeModal: () => void;
  modalData: T;
}

const Modal: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: MediaQueries.mobile });

  const closeModal = () => dispatch(hideModal({}));

  const {
    modalType,
    modalParams: {
      withCloseButton,
      title,
      subtitle,
      data,
      modalSize = PaperWidth.m,
      modalSpacing,
      modalOverflow,
      onMobileOnly,
      aboveCookieBar,
    },
    isVisible,
  } = useSelector((state: RootState): ModalState => getModalState(state));

  React.useEffect(() => {
    if (isVisible) document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'initial';
    };
  }, [isVisible]);

  React.useEffect(() => {
    if (onMobileOnly && !isMobile) {
      closeModal();
    }
  }, [onMobileOnly, isMobile]);

  if (!isVisible || !modalType) {
    return null;
  }

  const ModalBody: React.ComponentType<ModalProviderProps> = modalBodyElements[modalType];

  if (!ModalBody) {
    throw Error(`No modal body is found by ${modalType} type`);
  }

  return (
    <div className={classNames(styles.container, { [styles.cookieBannerModal]: aboveCookieBar })}>
      <Paper width={modalSize} className={classNames(styles.modalPaper, styles.cookieBannerModal)}>
        {title && <PaperHeader title={title} subtitle={subtitle} />}
        {withCloseButton && (
          <Button className={styles.closeButton} style={ButtonStyles.plain} size={ButtonSizes.s} onClick={closeModal}>
            <Icon icon="close" size={IconSizes.ss} />
          </Button>
        )}
        <PaperBody className={classNames(styles.body)} spacing={modalSpacing} overflow={modalOverflow}>
          <ModalBody closeModal={closeModal} modalData={data} />
        </PaperBody>
      </Paper>
      <div className={styles.backdrop} onClick={closeModal} />
    </div>
  );
};

export default Modal;
