import * as React from 'react';
import { useSelector } from 'react-redux';

import DisplayLabelWithIcon from '@Components/DisplayLabelWithIcon';
import DateTimeInput from '@Components/forms/inputs/DateTimeInput/DateTimeInput';
import Row from '@Components/layout/Row';
import { OldOrderFormFields } from '@Components/Order/OldOrderFormContainer/OldOrderForm';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import { validate, validateRequired, validateSelectedDayIsAfterYesterday } from '@Utils/form';
import { validateDate } from '@Utils/validate';

import styles from '../../OldOrderForm.module.scss';

interface TimeFrameProps {
  isScheduled?: boolean;
  onClickCalendarIcon?: () => void;
}

const TimeFrameField: React.FunctionComponent<TimeFrameProps> = ({ isScheduled, onClickCalendarIcon }) => {
  const t = useTranslations();
  const currentLanguage = useSelector(getCurrentLanguage);
  return (
    <div className={styles.orderSection}>
      <Typography msg={t(Messages.orderChoosePreliminaryDates)} tag="h5" className={styles.createOrderSpacing} />
      <Row className={styles.createOrderSpacing}>
        {isScheduled && (
          <div className={styles.adScheduleDisplay} onClick={onClickCalendarIcon}>
            <DisplayLabelWithIcon label={t(Messages.labelSchedulesDisplay)} icon="calendar" />
          </div>
        )}
      </Row>
      <div className={styles.fields}>
        <DateTimeInput
          dateInputName={OldOrderFormFields.dateFrom}
          dateInputValidate={validate(
            validateRequired(t),
            validateDate(t, currentLanguage),
            validateSelectedDayIsAfterYesterday(t),
          )}
          datePlaceholder={Messages.createOrderFrom}
          timeInputName={OldOrderFormFields.timeFrom}
          timeInputValidate={validateRequired(t)}
          showErrorIcon
        />
      </div>
      <div className={styles.fields}>
        <DateTimeInput
          dateInputName={OldOrderFormFields.dateTo}
          dateInputValidate={validate(
            validateRequired(t),
            validateDate(t, currentLanguage),
            validateSelectedDayIsAfterYesterday(t),
          )}
          datePlaceholder={Messages.createOrderTo}
          timeInputName={OldOrderFormFields.timeTo}
          timeInputValidate={validateRequired(t)}
          showErrorIcon
        />
      </div>
    </div>
  );
};

export default TimeFrameField;
