import * as React from 'react';

import { OptionItem } from '@Components/Select';
import { buildMessageKeyForEnum } from '@Config/messages';
import { AdvertPricingType, AdvertServiceType } from '@Graphql/graphqlTypes.generated';

import SelectInput, { SelectInputProps } from '../SelectInput';

const relationships = {
  [AdvertServiceType.Good]: [
    AdvertPricingType.EurPerItem,
    AdvertPricingType.EurPerKilogram,
    AdvertPricingType.EurPerLiter,
    AdvertPricingType.EurPerTon,
  ],
  [AdvertServiceType.Service]: [
    AdvertPricingType.EurPerHour,
    AdvertPricingType.EurPerHectare,
    AdvertPricingType.EurPerMeter,
    AdvertPricingType.EurPerNight,
  ],
};

const pricingTypeSelectOptions = (adServiceType: AdvertServiceType | undefined): OptionItem[] => {
  const options = Object.values(AdvertPricingType).map(
    value => value && { value, label: buildMessageKeyForEnum(value) },
  );

  if (!adServiceType) {
    return options;
  }
  return options.filter(option => relationships[adServiceType].includes(option.value));
};

export type AdPricingTypeSelectProps = {
  serviceType: AdvertServiceType;
} & SelectInputProps;

const AdPricingTypeSelect: React.FC<AdPricingTypeSelectProps> = props => (
  <SelectInput {...props} options={pricingTypeSelectOptions(props.serviceType)} />
);

export default AdPricingTypeSelect;
