import * as React from 'react';

import Button, { ButtonStyles } from '@Components/Buttons/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import OrderResultsMap from '@Components/Map/OrderResultsMap';
import { ModalProviderProps } from '@Components/Modal';
import { OrderType, OrderStatus } from '@Graphql/graphqlTypes.generated';

import styles from './OrderResultsModal.module.scss';

interface OrderResultsModalProps {
  status: OrderStatus | undefined;
  orderType: OrderType;
}

const OrderResultsModal: React.FunctionComponent<ModalProviderProps<OrderResultsModalProps>> = ({
  modalData,
  closeModal,
}) => {
  return (
    <div className={styles.container}>
      <Row justifyEnd>
        <Button style={ButtonStyles.plain} onClick={closeModal}>
          <Icon icon="menu-close-button" size={IconSizes.s} />
        </Button>
      </Row>
      <OrderResultsMap status={modalData.status} orderType={modalData.orderType} />
    </div>
  );
};

export default OrderResultsModal;
