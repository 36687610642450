import classNames from 'classnames';
import React from 'react';

import { ReactComponent as AdFormGoodIcon } from '@Images/icons/ad-form-good.svg';
import { ReactComponent as AdFormServiceIcon } from '@Images/icons/ad-form-service.svg';
import { ReactComponent as AddPaymentIcon } from '@Images/icons/add-payment.svg';
import { ReactComponent as AmexIcon } from '@Images/icons/amex.svg';
import { ReactComponent as AppleButtonIcon } from '@Images/icons/apple-button.svg';
import { ReactComponent as ArrowBackIcon } from '@Images/icons/arrow-back.svg';
import { ReactComponent as ArrowDownIcon } from '@Images/icons/arrow-down.svg';
import { ReactComponent as ArrowForwardIcon } from '@Images/icons/arrow-forward.svg';
import { ReactComponent as ArrowLeftIcon } from '@Images/icons/arrow-left.svg';
import { ReactComponent as ArrowTrendingUpIcon } from '@Images/icons/arrow-trending-up.svg';
import { ReactComponent as ArrowUpIcon } from '@Images/icons/arrow-up.svg';
import { ReactComponent as BellIcon } from '@Images/icons/bell.svg';
import { ReactComponent as CalendarDaysIcon } from '@Images/icons/calendar-days.svg';
import { ReactComponent as CalendarIcon } from '@Images/icons/calendar.svg';
import { ReactComponent as CameraIcon } from '@Images/icons/camera.svg';
import { ReactComponent as CheckCircleIcon } from '@Images/icons/check-circle.svg';
import { ReactComponent as CheckIcon } from '@Images/icons/check.svg';
import { ReactComponent as ChevronDownIcon } from '@Images/icons/chevron-down.svg';
import { ReactComponent as ClearIcon } from '@Images/icons/clear.svg';
import { ReactComponent as CloseIcon } from '@Images/icons/close.svg';
import { ReactComponent as CommentIcon } from '@Images/icons/comment.svg';
import { ReactComponent as CreditCardIcon } from '@Images/icons/credit-card.svg';
import { ReactComponent as CurrencyEuroOutlined } from '@Images/icons/currency-euro-outlined.svg';
import { ReactComponent as CurrencyEuro } from '@Images/icons/currency-euro.svg';
import { ReactComponent as DeleteIcon } from '@Images/icons/delete.svg';
import { ReactComponent as EnvelopeIcon } from '@Images/icons/envelope.svg';
import { ReactComponent as ErrorIcon } from '@Images/icons/error.svg';
import { ReactComponent as EuLogoIcon } from '@Images/icons/eu-logo.svg';
import { ReactComponent as EyeDisabledIcon } from '@Images/icons/eye-disabled.svg';
import { ReactComponent as EyeForumIcon } from '@Images/icons/eye-forum.svg';
import { ReactComponent as EyeIcon } from '@Images/icons/eye.svg';
import { ReactComponent as FacebookIcon } from '@Images/icons/facebook.svg';
import { ReactComponent as FarmerIcon } from '@Images/icons/farmer.svg';
import { ReactComponent as FavoriteIcon } from '@Images/icons/favorite.svg';
import { ReactComponent as FbButtonIcon } from '@Images/icons/fb-button.svg';
import { ReactComponent as FieldIcon } from '@Images/icons/field.svg';
import { ReactComponent as FileIcon } from '@Images/icons/file.svg';
import { ReactComponent as FilterIcon } from '@Images/icons/filter.svg';
import { ReactComponent as GoogleButtonIcon } from '@Images/icons/google-button.svg';
import { ReactComponent as GreenCheckCircleIcon } from '@Images/icons/green-check-circle.svg';
import { ReactComponent as GreenSejicoLogoIcon } from '@Images/icons/green-sejico-logo.svg';
import { ReactComponent as GridIcon } from '@Images/icons/grid.svg';
import { ReactComponent as HandWithPlantIcon } from '@Images/icons/hand-with-plant.svg';
import { ReactComponent as HeartEmptyIcon } from '@Images/icons/heart-empty.svg';
import { ReactComponent as HeartFilledIcon } from '@Images/icons/heart-filled.svg';
import { ReactComponent as InfoIcon } from '@Images/icons/info.svg';
import { ReactComponent as LabelIcon } from '@Images/icons/label.svg';
import { ReactComponent as ListIcon } from '@Images/icons/list.svg';
import { ReactComponent as LocationWithQuestionMarkIcon } from '@Images/icons/location-question-mark.svg';
import { ReactComponent as LocationIcon } from '@Images/icons/location.svg';
import { ReactComponent as LockFilledIcon } from '@Images/icons/lock-filled.svg';
import { ReactComponent as LockIcon } from '@Images/icons/lock.svg';
import { ReactComponent as MaestroIcon } from '@Images/icons/maestro.svg';
import { ReactComponent as MarketplaceIcon } from '@Images/icons/marketplace.svg';
import { ReactComponent as MastercardIcon } from '@Images/icons/mastercard.svg';
import { ReactComponent as MenuCloseButtonIcon } from '@Images/icons/menu-close-button.svg';
import { ReactComponent as MinusButtonIcon } from '@Images/icons/minus-button.svg';
import { ReactComponent as MinusIcon } from '@Images/icons/minus.svg';
import { ReactComponent as MobileIcon } from '@Images/icons/mobile.svg';
import { ReactComponent as OpenItemArrowIcon } from '@Images/icons/open-item-arrow.svg';
import { ReactComponent as PencilIcon } from '@Images/icons/pencil.svg';
import { ReactComponent as PersonIcon } from '@Images/icons/person.svg';
import { ReactComponent as PinIcon } from '@Images/icons/pin.svg';
import { ReactComponent as PlusButtonIcon } from '@Images/icons/plus-button.svg';
import { ReactComponent as PlusIcon } from '@Images/icons/plus.svg';
import { ReactComponent as PreviewIcon } from '@Images/icons/preview.svg';
import { ReactComponent as ScrollTopIcon } from '@Images/icons/scroll-top.svg';
import { ReactComponent as SearchIcon } from '@Images/icons/search.svg';
import { ReactComponent as SejicoAgroLogo } from '@Images/icons/sejico-agro-logo.svg';
import { ReactComponent as SejicoConstructionLogo } from '@Images/icons/sejico-construction-logo.svg';
import { ReactComponent as SendMessageIcon } from '@Images/icons/send-message.svg';
import { ReactComponent as SpinnerIcon } from '@Images/icons/spinner.svg';
import { ReactComponent as StarEmptyIcon } from '@Images/icons/star-empty.svg';
import { ReactComponent as StarIcon } from '@Images/icons/star.svg';
import { ReactComponent as TagIcon } from '@Images/icons/tag.svg';
import { ReactComponent as TractorIcon } from '@Images/icons/tractor.svg';
import { ReactComponent as UploadFileIcon } from '@Images/icons/upload-file.svg';
import { ReactComponent as UserDropdownIcon } from '@Images/icons/user-dropdown.svg';
import { ReactComponent as UserIcon } from '@Images/icons/user.svg';
import { ReactComponent as VectorIcon } from '@Images/icons/vector.svg';
import { ReactComponent as VisaIcon } from '@Images/icons/visa.svg';
import { ReactComponent as WarningSignIcon } from '@Images/icons/warning-sign.svg';
import { ReactComponent as WarningIcon } from '@Images/icons/warning.svg';
import { ReactComponent as WhiteSejicoLogoIcon } from '@Images/icons/white-sejico-logo.svg';

import styles from './Icon.module.scss';

export enum IconSizes {
  xs = 'xs',
  ss = 'ss',
  s = 's',
  m = 'm',
  l = 'l',
  logo = 'logo',
  logoBig = 'logoBig',
  auto = 'auto',
  paymentCard = 'paymentCard',
  // Use this if styling using css
  none = 'none',
}

export const sizes = {
  [IconSizes.xs]: {
    width: 10,
    height: 10,
  },
  [IconSizes.ss]: {
    width: 15,
    height: 15,
  },
  [IconSizes.s]: {
    width: 20,
    height: 20,
  },
  [IconSizes.m]: {
    width: 25,
    height: 25,
  },
  [IconSizes.l]: {
    width: 100,
    height: 100,
  },
  [IconSizes.logo]: {
    width: 127,
    height: 36,
  },
  [IconSizes.logoBig]: {
    width: 156,
    height: 45,
  },
  [IconSizes.paymentCard]: {
    width: 90,
    height: 30,
  },
  [IconSizes.auto]: {
    width: '100%',
    height: '100%',
  },
  [IconSizes.none]: {},
};

export interface IconProps {
  icon: IconName;
  size: IconSizes;
  className?: string;
  spin?: boolean;
  tabIndex?: number;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
}

export type IconName = keyof typeof IconMap;

const IconMap = {
  'ad-form-good': AdFormGoodIcon,
  'ad-form-service': AdFormServiceIcon,
  'add-payment': AddPaymentIcon,
  amex: AmexIcon,
  'apple-button': AppleButtonIcon,
  'arrow-back': ArrowBackIcon,
  'arrow-down': ArrowDownIcon,
  'arrow-forward': ArrowForwardIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-up': ArrowUpIcon,
  bell: BellIcon,
  calendar: CalendarIcon,
  camera: CameraIcon,
  check: CheckIcon,
  'chevron-down': ChevronDownIcon,
  clear: ClearIcon,
  close: CloseIcon,
  comment: CommentIcon,
  'credit-card': CreditCardIcon,
  'currency-euro': CurrencyEuro,
  delete: DeleteIcon,
  envelope: EnvelopeIcon,
  error: ErrorIcon,
  'eu-logo': EuLogoIcon,
  'eye-disabled': EyeDisabledIcon,
  'eye-forum': EyeForumIcon,
  eye: EyeIcon,
  facebook: FacebookIcon,
  farmer: FarmerIcon,
  favorite: FavoriteIcon,
  'fb-button': FbButtonIcon,
  field: FieldIcon,
  file: FileIcon,
  filter: FilterIcon,
  'google-button': GoogleButtonIcon,
  'green-sejico-logo': GreenSejicoLogoIcon,
  'green-check-circle': GreenCheckCircleIcon,
  grid: GridIcon,
  'heart-empty': HeartEmptyIcon,
  'heart-filled': HeartFilledIcon,
  label: LabelIcon,
  list: ListIcon,
  location: LocationIcon,
  'location-with-question-mark': LocationWithQuestionMarkIcon,
  'lock-filled': LockFilledIcon,
  lock: LockIcon,
  maestro: MaestroIcon,
  marketplace: MarketplaceIcon,
  mastercard: MastercardIcon,
  'menu-close-button': MenuCloseButtonIcon,
  minus: MinusIcon,
  mobile: MobileIcon,
  'open-item-arrow': OpenItemArrowIcon,
  pencil: PencilIcon,
  person: PersonIcon,
  pin: PinIcon,
  plus: PlusIcon,
  preview: PreviewIcon,
  'scroll-top': ScrollTopIcon,
  search: SearchIcon,
  'send-message': SendMessageIcon,
  spinner: SpinnerIcon,
  'star-empty': StarEmptyIcon,
  star: StarIcon,
  tag: TagIcon,
  'upload-file': UploadFileIcon,
  'user-dropdown': UserDropdownIcon,
  user: UserIcon,
  vector: VectorIcon,
  visa: VisaIcon,
  warning: WarningIcon,
  'warning-sign': WarningSignIcon,
  'white-sejico-logo': WhiteSejicoLogoIcon,
  'sejico-agro-logo': SejicoAgroLogo,
  'sejico-construction-logo': SejicoConstructionLogo,
  'plus-button': PlusButtonIcon,
  'minus-button': MinusButtonIcon,
  'hand-with-plant': HandWithPlantIcon,
  tractor: TractorIcon,
  'arrow-trending-up': ArrowTrendingUpIcon,
  'calendar-days': CalendarDaysIcon,
  'check-circle': CheckCircleIcon,
  'currency-euro-outlined': CurrencyEuroOutlined,
  info: InfoIcon,
};

const Icon: React.FunctionComponent<IconProps> = props => {
  const { icon, tabIndex = -1, size = 'none', className = null, spin = false, onClick } = props;
  const SvgIcon = IconMap[icon];

  return (
    <SvgIcon
      {...sizes[size]}
      onClick={onClick}
      tabIndex={tabIndex}
      className={classNames(styles.icon, `icon-${size}`, { [styles.spin]: spin }, className)}
    />
  );
};

export default Icon;
