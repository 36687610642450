import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonStyles } from '@Components/Buttons/Button';
import NotificationBox from '@Components/forms/NotificationBox';
import Row from '@Components/layout/Row';
import { MyCompanyFragment, useChangeActiveCompanyMutation } from '@Graphql/graphqlTypes.generated';
import { authActions } from '@Store/actions';

import styles from './CompaniesDisplay.module.scss';

interface UserCompanies {
  companies: MyCompanyFragment[];
  displayAsDropDown?: boolean;
}

const CompaniesDisplay: React.FC<UserCompanies> = ({ displayAsDropDown, companies }) => {
  const dispatch = useDispatch();

  const [changeCompany] = useChangeActiveCompanyMutation({
    onCompleted: () => {
      dispatch(authActions.getUserData.started({}));
      window.location.reload();
    },
  });

  return (
    <div className={displayAsDropDown ? styles.companiesDropdownDisplay : styles.companiesDisplay}>
      {companies.map(item => (
        <Row
          justifyBetween
          alignCenter
          constant
          className={displayAsDropDown ? styles.companyDropdownItemWrapper : styles.companyItemWrapper}
          key={item.id}
        >
          <Button
            style={ButtonStyles.plain}
            className={classNames(styles.companyItem, { [styles.active]: item.isActive })}
            onClick={() => changeCompany({ variables: { input: { company: item.id } } })}
          >
            {item.company.name}
          </Button>
          {!!item.unreadNotificationsCount && !item.isActive && (
            <div>
              <NotificationBox count={item.unreadNotificationsCount} />
            </div>
          )}
        </Row>
      ))}
    </div>
  );
};

export default CompaniesDisplay;
