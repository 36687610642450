import * as React from 'react';

import Button, { ButtonSizes, ButtonStyles } from '@Components/Buttons/Button';
import Icon, { IconName, IconSizes } from '@Components/Icon';
import Typography from '@Components/Typography';

import styles from './NavigationButton.module.scss';

export interface NavigationButtonProps {
  icon: IconName;
  text?: string;
  iconOnLeft?: boolean;
  onClick?: () => void;
}

const NavigationButton: React.FunctionComponent<NavigationButtonProps> = ({ icon, text, iconOnLeft, onClick }) => {
  return (
    <div className={styles.navigationButtonsWrapper}>
      <Button
        size={ButtonSizes.s}
        style={ButtonStyles.withoutStyling}
        className={styles.navigationArrow}
        onClick={onClick}
      >
        {iconOnLeft && <Icon icon={icon} size={IconSizes.s} className={styles.marginRight} />}
        {text && <Typography msg={text} tag="span" color="white" size="s" bold className={styles.navigationText} />}
        {!iconOnLeft && <Icon icon={icon} size={IconSizes.s} className={styles.marginLeft} />}
      </Button>
    </div>
  );
};

export default NavigationButton;
