import * as dotProp from 'dot-prop-immutable';
import { Action, Success } from 'typescript-fsa';

import { GetUserQuery } from '@Graphql/graphqlTypes.generated';
import { singleItemReducer, singleItemReducerInitialState } from '@Utils/reducers';
import { createReducer } from '@Utils/redux';
import { ActionErrorType } from '@Utils/types';

import { getUserData, linkUserToCompany, login, logout, removeInvitationKey, storeInvitationKey } from './auth.actions';
import { InvitationKeyPayload, UserData } from './auth.types';

export type AuthState = {
  readonly userData: UserData;
  invitationKey: string | null;
};

export const initialState: AuthState = {
  userData: singleItemReducerInitialState,
  invitationKey: null,
};

const authReducer = createReducer<AuthState>(initialState, {
  [login.started.type]: (state: AuthState) => {
    return dotProp.set(state, 'user', null);
  },
  [logout.started.type]: (state: AuthState) => {
    return dotProp.set(state, 'userData', singleItemReducerInitialState);
  },
  ...singleItemReducer<{}, GetUserQuery, ActionErrorType>(getUserData, 'userData'),
  [getUserData.done.type]: (state: AuthState, action: Action<Success<{}, GetUserQuery>>) => {
    const userData: UserData = { item: action.payload.result.me, isFetching: false };
    return dotProp.set(state, 'userData', userData);
  },
  [storeInvitationKey.type]: (state: AuthState, action: Action<InvitationKeyPayload>) => {
    return dotProp.set(state, 'invitationKey', action.payload);
  },
  [removeInvitationKey.type]: (state: AuthState, action: Action<InvitationKeyPayload>) => {
    return dotProp.set(state, 'invitationKey', action.payload);
  },
  [linkUserToCompany.done.type]: (state: AuthState) => {
    return dotProp.set(state, 'invitationKey', null);
  },
  [linkUserToCompany.failed.type]: (state: AuthState) => {
    return dotProp.set(state, 'invitationKey', null);
  },
});

export default authReducer;
