import * as React from 'react';
import { useSelector } from 'react-redux';

import DisplayLabelWithIcon from '@Components/DisplayLabelWithIcon';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import OrderStatusDisplay from '@Components/Order/OrderStatusDisplay';
import OrderTypeDisplay from '@Components/Order/OrderTypeDisplay';
import PriceDisplay from '@Components/PriceDisplay';
import Typography from '@Components/Typography';
import { SEARCH_ITEM_TITLE_LENGTH } from '@Config/constants';
import { Messages } from '@Config/messages';
import { AdvertStatus, OrderFragment } from '@Graphql/graphqlTypes.generated';
import { useCurrency } from '@Hooks/useCurrency';
import { useTranslateObject } from '@Hooks/useTranslateObject';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import { languageBasedDate } from '@Utils/dates';
import { convertCentsToMoney } from '@Utils/money';
import { truncate } from '@Utils/string';

import styles from './OrderItem.module.scss';

export interface OrderItemProps {
  order: OrderFragment;
}

const OrderItem: React.FunctionComponent<OrderItemProps> = ({
  order: {
    advert: {
      pricingType,
      price,
      address,
      status: advertStatus,
      title,
      company: { name },
      category,
    },
    status,
    orderType,
    totalPrice,
    createdAt,
  },
}) => {
  const t = useTranslations();
  const selectTranslation = useTranslateObject();
  const currentLanguage = useSelector(getCurrentLanguage);

  const createdOrderDate = languageBasedDate(createdAt, currentLanguage);
  const currency = useCurrency();

  return (
    <div className={styles.orderItemWrapper}>
      <Row constant wrap>
        <Typography
          size="s"
          color="gray"
          className={styles.marginRight}
          msg={t(Messages.orderItemOrderDate)}
          tag="span"
        />
        <Typography color="primary" msg={createdOrderDate} tag="span" />
      </Row>
      <Row constant wrap>
        <Typography
          size="s"
          color="gray"
          className={styles.marginRight}
          msg={t(Messages.orderItemTotalOrderPrice)}
          tag="span"
        />
        <Typography color="primary" msg={`${convertCentsToMoney(totalPrice)} ${currency}`} tag="span" />
      </Row>
      <OrderStatusDisplay status={status} />
      <OrderTypeDisplay type={orderType} />
      <Row alignCenter constant>
        {advertStatus === AdvertStatus.Private && (
          <Icon className={styles.marginRight} icon="lock-filled" size={IconSizes.s} />
        )}
        <Typography tag="span" color="black" msg={truncate(title, SEARCH_ITEM_TITLE_LENGTH)} size="m" bold />
      </Row>
      <DisplayLabelWithIcon labelClassName={styles.overflow} textSize="s" icon="location" label={address} />
      <DisplayLabelWithIcon labelClassName={styles.overflow} textSize="s" icon="person" label={name} />
      <Row justifyBetween alignCenter constant className={styles.priceWithCategory}>
        <Col size={6}>
          <PriceDisplay
            tag="span"
            color="gray"
            priceColor="primary"
            priceSize="l"
            size="s"
            price={price}
            pricingType={pricingType}
            className={styles.price}
            priceBold
          />
        </Col>
        <Col size={6} className={styles.category}>
          <Typography msg={selectTranslation(category.name)} size="s" tag="span" color="violet" />
        </Col>
      </Row>
    </div>
  );
};

export default OrderItem;
