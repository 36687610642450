import classNames from 'classnames';
import React from 'react';

import FormWizard from '@Components/forms/FormWizard';
import Col from '@Components/layout/Col';
import Container from '@Components/layout/Container';
import Row from '@Components/layout/Row';
import PageTitle from '@Components/Page/PageTitle';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import { RouteConfig } from '@Config/routes';
import { AdvertPricingType, DeliveryType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import AdFormFirstStep from '@Routes/ads/shared/AdForm/AdFormFirstStep';
import AdFormSecondStep from '@Routes/ads/shared/AdForm/AdFormSecondStep';
import AdFormTab from '@Routes/ads/shared/AdForm/AdFormTab';
import { MessageKeysWithoutParams } from '@Utils/translation';
import { AdFormValues } from '@Utils/types';
import { resetFocus } from '@Utils/util';
import { validateAdForm } from '@Utils/validate';

import styles from './AdForm.module.scss';

interface AdFormProps {
  onSubmit: (values: AdFormValues) => Promise<{}>;
  initialValues?: AdFormValues;
  title: MessageKeysWithoutParams;
}

interface FormRowItemProps {
  label?: string;
  description?: string;
  className?: string;
  marginTop?: boolean;
  textCenterMobile?: boolean;
  children?: React.ReactNode;
}

export enum AdFormFieldNames {
  title = 'title',
  advertType = 'advertType',
  deliveryPrice = 'deliveryPrice',
  isScheduled = 'isScheduled',
  category = 'category',
  description = 'description',
  price = 'price',
  pricingType = 'pricingType',
  schedule = 'schedules',
  images = 'images',
  mainImage = 'mainImage',
  address = 'address',
  coordinates = 'coordinates',
  serviceType = 'serviceType',
  customFieldsData = 'customFieldsData',
  deliveryType = 'deliveryType',
  status = 'status',
  validUntil = 'validUntil',
}

export enum ScheduleFields {
  startDate = 'startDate',
  startTime = 'startTime',
  endDate = 'endDate',
  endTime = 'endTime',
}

const onSubmitForm = (data: AdFormValues, onSubmit: (values: AdFormValues) => Promise<{}>) => {
  return onSubmit({
    ...data,
    ...(AdFormFieldNames.customFieldsData && { customFieldsData: JSON.stringify(data.customFieldsData) }),
    ...([AdvertPricingType.NegotiablePrice, AdvertPricingType.FreeItem].includes(data.pricingType) && { price: 0 }),
    ...((!data.deliveryPrice || data.deliveryType === DeliveryType.NoDelivery) && { deliveryPrice: 0 }),
  });
};

export const FormRowItem: React.FC<FormRowItemProps> = ({
  marginTop = true,
  children,
  label,
  description,
  textCenterMobile = true,
  className,
}) => {
  return (
    <>
      {label && (
        <Row size={12} className={classNames(className, { [styles.marginTop]: marginTop })} constant>
          <Typography msg={label} tag="span" color="gray" className={styles.label} />
        </Row>
      )}
      {description && <Typography msg={description} tag="p" color="gray" size="s" className={styles.marginBottom} />}
      <Col size={12} textCenterMobile={textCenterMobile}>
        {children}
      </Col>
    </>
  );
};

const AdForm: React.FC<AdFormProps> = ({ onSubmit, initialValues, title }) => {
  const t = useTranslations();
  const tabs = [Messages.createAdTabGeneralInformation, Messages.createAdTabAdditionalInformation] as const;

  const renderTopElement = (changeStep: (index: number) => void, activePage: number) => {
    return (
      <Row justifyCenter>
        {tabs.map((tab, index) => {
          return (
            <AdFormTab
              tab={tab}
              key={index}
              num={index}
              activePage={activePage}
              onClick={e => {
                resetFocus(e);
                changeStep(index);
              }}
            />
          );
        })}
      </Row>
    );
  };

  return (
    <Container className={styles.formWrapper}>
      <Row justifyCenter alignCenter>
        <PageTitle msg={t(title)} color="primary" />
      </Row>
      <FormWizard
        onSubmit={values => onSubmitForm(values, onSubmit)}
        initialValues={initialValues}
        validate={validateAdForm(t)}
        renderTopElement={renderTopElement}
        redirectOnCancelClickTo={RouteConfig.MyAdverts.buildLink()}
      >
        <FormWizard.Page>
          <AdFormFirstStep initialValues={initialValues} />
        </FormWizard.Page>
        <FormWizard.Page>
          <AdFormSecondStep />
        </FormWizard.Page>
      </FormWizard>
    </Container>
  );
};

export default AdForm;
