import * as Sentry from '@sentry/browser';

import config, { NodeEnvEnum } from '@Config/config';

export function logError(message: string) {
  if (config.NODE_ENV === NodeEnvEnum.development) {
    // eslint-disable-next-line no-console
    console.info(message);
  } else if (config.NODE_ENV === NodeEnvEnum.production) {
    Sentry.captureMessage(message, 'error');
  }
}
