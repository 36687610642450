import { OldOrderFormFields } from '@Components/Order/OldOrderFormContainer/OldOrderForm/OldOrderForm';
import { ORDER_MIN_DIFF } from '@Config/constants';
import { Messages } from '@Config/messages';
import { AdvertPricingType } from '@Graphql/graphqlTypes.generated';
import { buildDateAndTime } from '@Utils/dates';
import { Translator } from '@Utils/translation';
import { OldOrderFormData, Shape } from '@Utils/types';
import {
  validateDateDiffCorrectInNights,
  validateStartDateTimeIsBeforeEndDateTime,
  validateDateAndTimeFieldsValues,
} from '@Utils/validate';

export const validateTimeFields = (values: OldOrderFormData, t: Translator) => {
  const { dateFrom, dateTo, timeFrom, timeTo } = values;

  if (dateFrom && dateTo && timeFrom && timeTo) {
    return {
      ...validateDateAndTimeFieldsValues(values, OldOrderFormFields, t),
    };
  }
  return undefined;
};

export const validateTimeFieldsByNight = (values: OldOrderFormData, t: Translator) => {
  const { dateFrom, dateTo, timeFrom, timeTo } = values;

  if (dateFrom && dateTo && timeFrom && timeTo) {
    const dateTimeFrom = buildDateAndTime(dateFrom, timeFrom);
    const dateTimeTo = buildDateAndTime(dateTo, timeTo);

    return {
      ...validateStartDateTimeIsBeforeEndDateTime(dateTimeFrom, dateTimeTo, OldOrderFormFields.dateFrom, t),
      ...validateDateDiffCorrectInNights(dateTimeFrom, dateTimeTo, t),
    };
  }
  return undefined;
};

const validateMinimumAmount = (values: OldOrderFormData, t: Translator) => {
  const { amount } = values;

  if (amount) {
    const amountNum = Number(amount);
    const isCorrectAmount = amountNum && amountNum >= ORDER_MIN_DIFF;
    return isCorrectAmount ? undefined : { [OldOrderFormFields.amount]: t(Messages.errorInvalidMinAmount) };
  }
  return undefined;
};

export const validateTimeItemFields = (values: OldOrderFormData, t: Translator) => {
  return {
    ...validateMinimumAmount(values, t),
    ...validateDateAndTimeFieldsValues(values, OldOrderFormFields, t),
  };
};

const validateByPriceByTime = (data: OldOrderFormData, t: Translator) => validateTimeFields(data, t);

const validateServiceByTimeByAmount = (data: OldOrderFormData, t: Translator) => validateTimeItemFields(data, t);

const validateByPriceByNight = validateTimeFieldsByNight;

const validateByPriceByItem = (data: OldOrderFormData, t: Translator) => validateTimeFields(data, t);

export type validationsByPricingType = {
  [key in AdvertPricingType]: (data: OldOrderFormData, t: Translator) => Shape | undefined;
};

export const validationsByPricingType: validationsByPricingType = {
  [AdvertPricingType.EurPerMeter]: validateServiceByTimeByAmount,
  [AdvertPricingType.EurPerLiter]: validateByPriceByItem,
  [AdvertPricingType.EurPerTon]: validateByPriceByItem,
  [AdvertPricingType.EurPerNight]: validateByPriceByNight,
  [AdvertPricingType.EurPerHectare]: validateServiceByTimeByAmount,
  [AdvertPricingType.EurPerHour]: validateByPriceByTime,
  [AdvertPricingType.EurPerItem]: validateByPriceByItem,
  [AdvertPricingType.EurPerKilogram]: validateByPriceByItem,
  [AdvertPricingType.NegotiablePrice]: validateByPriceByItem,
  [AdvertPricingType.FreeItem]: validateByPriceByItem,
};
