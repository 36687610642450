import * as React from 'react';
import { TooltipRenderProps } from 'react-joyride';

import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { Messages } from '@Config/messages';
import { useTranslations } from '@Hooks/useTranslations';

import styles from './UserTourTooltip.module.scss';

const UserTourTooltip: React.FunctionComponent<TooltipRenderProps> = ({
  index,
  step,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
}) => {
  const t = useTranslations();
  const currentStep = index + 1;
  return (
    <div {...tooltipProps} className={styles.tooltip}>
      <Row justifyEnd className={styles.iconContainer} constant>
        <div {...closeProps}>
          <Icon icon="close" size={IconSizes.ss} />
        </div>
      </Row>
      <Row className={styles.tooltipBody} constant>
        <Col size={2} constant>
          <span className={styles.bubble}>{currentStep}</span>
        </Col>
        <Col size={9} className={styles.contentContainer} constant>
          <span>{step.content}</span>
        </Col>
      </Row>
      <Row className={styles.actions} justifyEnd constant>
        <span {...primaryProps}>{`${t(Messages.userTourNextBtn)} (${currentStep}/${size})`}</span>
      </Row>
    </div>
  );
};

export default UserTourTooltip;
