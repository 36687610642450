import classNames from 'classnames';
import React from 'react';
import { Form, FormRenderProps, useField } from 'react-final-form';
import { useSelector } from 'react-redux';

import Button, { ButtonSizes, ButtonTypes, ButtonStyles } from '@Components/Buttons/Button';
import FormField from '@Components/forms/FormField';
import LocationInput from '@Components/forms/inputs/LocationInput';
import CategoryPriceTypeSelect from '@Components/forms/inputs/selects/CategoryPriceTypeSelect';
import CategorySelect from '@Components/forms/inputs/selects/CategorySelect';
import TextAreaInput, { TextAreaInputProps } from '@Components/forms/inputs/TextAreaInput';
import TextInput, { TextInputProps } from '@Components/forms/inputs/TextInput';
import InfoMessage, { MessageTypes } from '@Components/InfoMessage';
import GridCol from '@Components/layout/Grid/GridCol';
import GridRow from '@Components/layout/Grid/GridRow';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';
import {
  CategoryKind,
  CategoryServiceType,
  useGetCategoriesQuery,
  useGetGeneralOrderClassifiersQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslateObject } from '@Hooks/useTranslateObject';
import { useTranslations } from '@Hooks/useTranslations';
import { getCurrentLanguage } from '@Store/app/app.selectors';
import {
  validate,
  validateEmail,
  validateFloat,
  validateFractionDigitsLength,
  validateMaxIntAmount,
  validateMaxSize,
  validateNumber,
  validatePhone,
  validateRequired,
} from '@Utils/form';
import { ContractFormData } from '@Utils/types';

import styles from './ContractorForm.module.scss';

interface ContractorFormProps {
  onSubmit: (values: ContractFormData) => Promise<{}>;
  onCancel?: () => void;
  initialValues: ContractFormData;
}

const MAX_AMOUNT = 9_999_999 as const;
const MAX_WORKING_ARRAY_RAY = 99_999_999_999 as const;

const formFields = {
  categories: 'categories',
  categoriesDetails: 'categoriesDetails',
  address: 'address',
  coordinates: 'coordinates',
  workingAreaRay: 'workingAreaRay',
  name: 'name',
  surname: 'surname',
  email: 'email',
  phone: 'phone',
  additionalInfo: 'additionalInfo',
} satisfies { [key in keyof ContractFormData]: key };

const FormBody = ({
  handleSubmit,
  submitting,
  submitError,
  onCancel,
}: FormRenderProps<ContractFormData> & { onCancel?: () => void }): JSX.Element => {
  const t = useTranslations();
  const classifierTranslator = useTranslateObject();
  const currentLanguage = useSelector(getCurrentLanguage);

  const categoriesField = useField<ContractFormData['categories']>(formFields.categories);

  const { data } = useGetGeneralOrderClassifiersQuery({});

  const { loading: categoriesLoading, data: categoriesData } = useGetCategoriesQuery({});
  const agroCategories = categoriesData?.categories.filter(category => category.kind === CategoryKind.Agro);

  const getCategoryLabel = (id: string) => {
    const category = data?.appConfig.generalOrderCategories.find(category => category.id === id);
    return category ? classifierTranslator(category.name) : '';
  };

  return (
    <form onSubmit={handleSubmit} className={styles.orderContainer}>
      <p className={classNames(styles.spacingBetweenSections, styles.mainInfoBlock)}>
        <Typography msg={t('msg_contractor_form_general_info')} tag="span" />
      </p>

      <InfoMessage type={MessageTypes.error} message={submitError} />

      <FormField
        name={formFields.categories}
        component={CategorySelect}
        categories={agroCategories}
        isLoading={categoriesLoading}
        type="text"
        isMulti
        serviceType={CategoryServiceType.Service}
        placeholder={Messages.createAdCategorySelectPlaceholder}
        validate={validateRequired(t)}
      />

      {categoriesField.input.value.map(categoryId => (
        <div key={categoryId} className={styles.categoryDetailsBlock}>
          <div className={styles.marginBottom}>
            <Typography tag="span" color="black" size="m" msg={getCategoryLabel(categoryId)} />
          </div>
          <FormField<TextAreaInputProps<string>>
            name={`${formFields.categoriesDetails}.${categoryId}.details`}
            placeholder={t('msg_contractor_field_category_details_placeholder')}
            component={TextAreaInput}
            rows={2}
            className={styles.textAreaWrapperAutoHeight}
            inputClassName={styles.textAreaInputAutoHeight}
            bottomSpacing={true}
            validate={validate(validateRequired(t))}
          />
          <GridRow className={styles.noRowGap}>
            <GridCol size={6}>
              <FormField<TextInputProps<string>>
                name={`${formFields.categoriesDetails}.${categoryId}.price.value`}
                component={TextInput}
                type="text"
                placeholder={t('msg_contractor_field_category_price_placeholder')}
                validate={validate(
                  validateRequired(t),
                  validateFloat(t),
                  validateFractionDigitsLength(t, 1),
                  validateMaxSize(t, MAX_AMOUNT),
                )}
              />
            </GridCol>
            <GridCol size={6}>
              <FormField
                name={`${formFields.categoriesDetails}.${categoryId}.price.type`}
                component={CategoryPriceTypeSelect}
                type="text"
                placeholder={t('msg_contractor_field_category_price_type_placeholder')}
                validate={validateRequired(t)}
              />
            </GridCol>
          </GridRow>
        </div>
      ))}

      <LocationInput
        addressFieldName={formFields.address}
        coordinatesFieldName={formFields.coordinates}
        bottomSpacing={true}
        preFillLocation={true}
        specifyLocation={true}
      />

      <FormField<TextInputProps<string>>
        name={formFields.workingAreaRay}
        component={TextInput}
        type="text"
        placeholder={t('msg_contractor_field_working_area_ray_placeholder')}
        validate={validate(validateRequired(t), validateNumber(t), validateMaxIntAmount(t, MAX_WORKING_ARRAY_RAY))}
      />

      <GridRow className={styles.noRowGap}>
        <GridCol size={6}>
          <FormField<TextInputProps<string>>
            name={formFields.name}
            component={TextInput}
            type="text"
            placeholder={t('msg_general_order_field_name')}
            validate={validate(validateRequired(t))}
            bottomSpacing={true}
          />
        </GridCol>
        <GridCol size={6}>
          <FormField<TextInputProps<string>>
            name={formFields.surname}
            component={TextInput}
            type="text"
            placeholder={t('msg_general_order_field_surname')}
            validate={validate(validateRequired(t))}
            bottomSpacing={true}
          />
        </GridCol>
      </GridRow>
      <FormField<TextInputProps<string>>
        name={formFields.phone}
        component={TextInput}
        placeholder={Messages.preliminaryOrderPhone}
        validate={validate(validatePhone(t, currentLanguage), validateRequired(t))}
      />
      <FormField<TextInputProps<string>>
        name={formFields.email}
        component={TextInput}
        placeholder={Messages.preliminaryOrderEmail}
        validate={validate(validateEmail(t), validateRequired(t))}
      />
      <FormField
        name={formFields.additionalInfo}
        placeholder={t('msg_general_order_field_additional_info')}
        component={TextAreaInput}
      />
      <div className={styles.orderActions}>
        <Button
          loading={submitting}
          label={t(Messages.submitPreliminaryOrder)}
          size={ButtonSizes.fill}
          type={ButtonTypes.submit}
          disabled={submitting}
        />
        {onCancel && (
          <Button
            loading={submitting}
            label={t(Messages.cancelPreliminaryOrder)}
            size={ButtonSizes.fill}
            type={ButtonTypes.button}
            disabled={submitting}
            onClick={onCancel}
            style={ButtonStyles.outlinedGreen}
          />
        )}
      </div>
    </form>
  );
};

const ContractorForm = ({ onSubmit, initialValues, onCancel }: ContractorFormProps) => {
  return (
    <Form<ContractFormData>
      onSubmit={onSubmit}
      render={props => <FormBody {...props} onCancel={onCancel} />}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    />
  );
};

export default ContractorForm;
