import { GraphQLError } from 'graphql';

import { CalendarFiltersStatuses } from '@Components/CalendarFilters';
import { AdFilterFields } from '@Components/forms/Filter/AdFilters';
import { SearchBarData } from '@Components/Search/SearchBar';
import { ApplicationLanguages, ErrorCodes } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  AdvertMutationInput,
  AuctionBidFragment,
  CategoryFragment,
  CompanyCompanyType,
  CreateAuctionMutationInput,
  ForumThreadReplyFragment,
  GeneralContractorCategoryPriceType,
  GeneralOfferPaymentCondition,
  GeneralOrderAmountType,
  GeneralOrderPaymentCondition,
  InvoicingInfoMutationInput,
  Maybe,
  MyAuctionBidFragment,
  NotificationNodeEdge,
  ParentCategoryTreeFragment,
  PaymentType,
  Scalars,
} from '@Graphql/graphqlTypes.generated';
import { AppleAuthResponse } from '@Store/auth/auth.types';

export type Nil = null | undefined;
export type MapT<V> = { [key: string]: V };
export type Optional<T> = T | undefined;
export type URLParameterMapT = MapT<URLParamValue>;
export type URLParamValue = string | number;
export type QueryT = MapT<URLParamValue | URLParamValue[]>;
export type OptionalProps<T> = {
  [P in keyof T]?: Optional<T[P]>;
};

export interface Shape<T = unknown> {
  // instead of any
  [keyS: string]: T;
  [keyN: number]: T;
}

export type IdType = string | number;

export type TreeNode<T> = {
  id: string;
  element: T;
  children: TreeNode<T>[];
};
export type TreeLike<T extends { id: string; parent?: T } | { id: string }> = T;

export type Category = CategoryFragment | ParentCategoryTreeFragment;
export type CategoriesTree = TreeNode<Category>[];

export const getCategoryParent = (category: Category) => {
  return 'parent' in category ? category.parent : undefined;
};

export type PayloadWithPromises = {
  resolve: (argument: any) => void;
  reject: (argument: any) => void;
};

export type PayloadWithParams<P = URLParameterMapT, R = any, Q = QueryT> = {
  // Extra params for payload
  _params?: P;
  // Params for current action
  _request?: R;
  // Params for url query
  _query?: Q;
};

export interface LoadableItem<T = any> {
  item: T | null;
  isFetching: boolean;
}

export interface LoadableItemWithValue<T> extends LoadableItem {
  item: T;
}

export type LoadableItems<T = any> = {
  list: T[];
  isFetching: boolean;
};

export type PaginatedItems<T = any> = {
  items: T[];
  isFetching: boolean;
  count: number;
  currentPage: number;
  offset: number;
  totalPages: number;
};

export interface ActionErrorType {
  status: number;
  response: object;
}

// Errors
export type NonFieldErrors = {
  response: {
    non_field_errors: string[];
    detail: string;
  };
};
export type HOC<PWrapped, PHoc> = React.ComponentClass<PWrapped & PHoc> | React.FunctionComponent<PWrapped & PHoc>;
export interface SejicoGraphqlError extends GraphQLError {
  code?: ErrorCodes;
}

export interface CustomGraphqlError extends GraphQLError {
  customField: null | string;
  field: string;
  params: null | unknown[];
}

export type FormFieldErrors = { [key: string]: string };

export interface AdSchedule {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

export interface AdFormValues extends AdvertMutationInput {
  id?: string;
  schedules: Maybe<AdSchedule[]>;
  images: Maybe<Scalars['UploadType']>;
}

export interface BillingFormValues extends InvoicingInfoMutationInput {
  companyType?: CompanyCompanyType;
}

export interface AuctionFormValues extends CreateAuctionMutationInput {
  id?: string;
  images: Maybe<Scalars['UploadType']>;
}

export type DateRange = { startDate: Date; endDate: Date };

export type OldOrderFormData = {
  dateFrom?: string;
  dateTo?: string;
  timeFrom?: string;
  timeTo?: string;
  description?: string;
  amount?: string;
  paymentType?: PaymentType;
  deliveryIsRequired: boolean;
};

export type PreliminaryOrderFormData = {
  startDate: string;
  description?: string;
  contactType: 'phone' | 'email' | 'platform';
  email?: string;
  phone?: string;
};

export type GeneralOrderFormData = {
  category: Scalars['ID'];
  amount: string;
  amountType: GeneralOrderAmountType;
  amountParts: string[];
  numberOfFields: string;
  marketPriceSelection: number | null;
  paymentCondition: GeneralOrderPaymentCondition | '';
  price: string;
  startDate: string;
  endDate: string;
  description?: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  address: string;
  coordinates: { lat: number | undefined; lng: number | undefined };
};

export type GeneralOrderAcceptanceFormData = {
  email: string;
  phone: string;
  paymentCondition: GeneralOfferPaymentCondition | '';
  offeredPrice: string;
  additionalInfo: string;
  agreedWithPrice: 'YES' | 'NO';
};

export type ContractFormData = {
  name: string;
  surname: string;
  email: string;
  phone: string;
  address: string;
  coordinates: { lat: number | undefined; lng: number | undefined };
  workingAreaRay: string;
  categories: string[];
  categoriesDetails: Record<
    string,
    { price: { type: GeneralContractorCategoryPriceType | ''; value: string }; details: string }
  >;
  additionalInfo: string;
};

/* tslint:disable: prefer-array-literal */
export type Unpack<A> = A extends Array<infer E> ? E : A;
/* tslint:enable */

export type WithPromiseResponse<T, R> = (t: T, ...args: any[]) => Promise<R>;

export type NotNil = string | number | boolean | symbol | object;

export type ExactKey<T extends Shape, R extends keyof T> = R;

export type Key<T extends Shape> = keyof T;

export type LinkData = Shape & {
  titleKey: Messages;
  link: string;
};

export type OnReplayCallback = (message: ForumThreadReplyFragment) => void;

export type CallBack = () => void;

export type TranslationSelector = (name: {
  [key in ApplicationLanguages]: string;
}) => string;

export type LocationReferer = { referer?: string };

export type Images = (string | File | Blob)[];
export type DateRangeList = { start: Date; end: Date }[];
export type Price = string;

export interface DatesWithNotifications {
  date: string;
  notifications: NotificationNodeEdge[];
}

export interface CalendarNode {
  id?: string;
  title?: string;
  description?: string;
  startDate: string;
  endDate: string;
}

export type AuctionBidGenericFragment = AuctionBidFragment | MyAuctionBidFragment;

export const isMyAuctionBidFragment = (auction: AuctionBidGenericFragment): auction is MyAuctionBidFragment =>
  auction.__typename === 'MyBidNode';

export const defaultSearchValues: SearchBarData = {
  searchBy: undefined,
  geoSearch: undefined,
};

export interface SearchParamsHolder {
  search?: SearchBarData;
  filter?: AdFilterFields;
  sort?: Shape;
}
export interface URLSearchParamsHolder {
  search?: string;
  filter?: string;
  sort?: string;
}

// todo parser array structure
export const urlDefaultParametersPicker = (searchParams: qs.ParsedQs): URLSearchParamsHolder => ({
  search: searchParams?.search as string | undefined,
  filter: searchParams?.filter as string | undefined,
  sort: searchParams?.sort as string | undefined,
});

export enum CalendarEventType {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  CUSTOM = 'custom',
  DEFAULT = 'default',
}

export interface CalendarFilterFields extends CalendarFiltersStatuses {
  startDate: Date;
  endDate: Date;
}

export enum UserUrlTypes {
  company = 'company',
  reviews = 'reviews',
  profile = 'profile',
  companyUsers = 'company-users',
  cards = 'cards',
  billing = 'billing',
  invoices = 'invoices',
  transfer = 'transfer',
}

export type AppleAuthOptions = {
  clientId: string;
  scope: string;
  redirectURI: string;
  usePopup?: boolean;
};

declare global {
  interface Window {
    AppleID: {
      auth: {
        init: (options: AppleAuthOptions) => void;
        signIn: () => Promise<AppleAuthResponse>;
      };
    };
    allowAnalyticsTracking?: boolean;
  }
}

export type FieldError<FormData> = Partial<Record<keyof FormData, string>>;

export type MutationInputResult<MutationInput, FormData> =
  | { type: 'INPUT'; data: MutationInput }
  | { type: 'ERROR'; data: FieldError<FormData> };

export interface RCSliderMarks {
  [number: number]: JSX.Element | string | { style: any; label: string | JSX.Element };
}
