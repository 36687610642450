import typescriptFsa from 'typescript-fsa';

import {
  AppleLoginPayload,
  FacebookLoginPayload,
  GetUserQuery,
  GoogleLoginPayload,
  ObtainJsonWebTokenPayload,
  RegisterUserMutation,
} from '@Graphql/graphqlTypes.generated';
import { ActionErrorType } from '@Utils/types';

import {
  AppleLoginInputPayload,
  FacebookLoginInputPayload,
  GoogleLoginInputPayload,
  InvitationKeyPayload,
  LoginActionPayload,
  RegisterActionPayload,
} from './auth.types';

const actionCreator = typescriptFsa();

export const login = actionCreator.async<LoginActionPayload, ObtainJsonWebTokenPayload>('LOGIN');
export const logout = actionCreator.async<{}, {}>('LOGOUT');
export const register = actionCreator.async<RegisterActionPayload, RegisterUserMutation, {}>('REGISTER');
export const getUserData = actionCreator.async<{}, GetUserQuery, ActionErrorType>('GET_USER_DATA');
export const storeInvitationKey = actionCreator<InvitationKeyPayload>('STORE_INVITATION_KEY');
export const removeInvitationKey = actionCreator<null>('REMOVE_INVITATION_KEY');

export const linkUserToCompany = actionCreator.async<InvitationKeyPayload, {}, ActionErrorType>('LINK_USER_TO_COMPANY');

export const loginWithFacebook = actionCreator.async<FacebookLoginInputPayload, FacebookLoginPayload, ActionErrorType>(
  'LOGIN_WITH_FB',
);
export const loginWithGoogle = actionCreator.async<GoogleLoginInputPayload, GoogleLoginPayload, ActionErrorType>(
  'LOGIN_WITH_GOOGLE',
);
export const loginWithApple = actionCreator.async<AppleLoginInputPayload, AppleLoginPayload, ActionErrorType>(
  'LOGIN_WITH_APPLE',
);
