import * as React from 'react';

import DisplayLabelWithIcon from '@Components/DisplayLabelWithIcon';
import PriceDisplay from '@Components/PriceDisplay';
import { AdvertFragment } from '@Graphql/graphqlTypes.generated';

import styles from './AdvertCardPriceRow.module.scss';

export interface AdvertCardPriceRowProps {
  advert: AdvertFragment;
}

const AdvertCardPriceRow: React.FunctionComponent<AdvertCardPriceRowProps> = ({ advert: { price, pricingType } }) => {
  return (
    <div className={styles.container}>
      <DisplayLabelWithIcon className={styles.icon} textSize="s" icon="currency-euro">
        <PriceDisplay
          tag="span"
          color="gray"
          priceSize="s"
          size="s"
          price={price}
          pricingType={pricingType}
          className={styles.price}
          priceBold
        />
      </DisplayLabelWithIcon>
    </div>
  );
};

export default AdvertCardPriceRow;
