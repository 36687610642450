import { FieldSubscription } from 'final-form';
import React, { ReactNode, useState } from 'react';
import { useField } from 'react-final-form';

import FormField from './FormField';

export type FieldConditionType = {
  when: string;
  is: any;
  children?: ReactNode;
};

export type FieldOnChangeProps = {
  when: string;
  onChange: (value: any) => void;
};

export type FieldSubscribeProps = {
  when: string;
  onChange?: (value: any) => void;
  subscription?: FieldSubscription;
  render?: (value: any) => ReactNode | null;
};

export const FieldCondition: React.FC<FieldConditionType> = ({ when, is, children }) => (
  <FormField name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </FormField>
);

export const FieldSubscribe: React.FC<FieldSubscribeProps> = ({
  when,
  onChange,
  subscription = { value: true },
  render,
}) => {
  const { input } = useField(when, { subscription });
  const [value, setValue] = useState(input.value);

  if (onChange !== undefined && input.value !== value) {
    setValue(input.value);
    onChange(input.value);
  }
  return render ? <>{render(value)}</> : null;
};
