import classNames from 'classnames';
import React from 'react';

import Icon, { IconSizes } from '@Components/Icon';
import Typography from '@Components/Typography/Typography';

import styles from './InfoMessage.module.scss';

export enum MessageTypes {
  warning = 'warning',
  error = 'error',
}

export interface InfoMessageProps {
  type: MessageTypes;
  message: string | string[];
  className?: string;
}

const InfoMessage: React.FunctionComponent<InfoMessageProps> = ({ className, type, message }) => {
  if (!message) {
    return null;
  }
  const msgToDisplay = Array.isArray(message) ? message[0] : message;

  return (
    <div className={classNames(styles.messageContainer, className, styles[type])}>
      <Icon icon={type} size={IconSizes.s} className={styles.icon} />
      <Typography tag="span" color="black" size="s" msg={msgToDisplay} className={styles.text} />
    </div>
  );
};

export default InfoMessage;
