import { StaticPages } from '@Config/constants';
import { RouteConfig } from '@Config/routes';
import { ModalState } from '@Store/app/app.reducer';
import { FeatureToggle } from '@Store/app/app.types';
import { RootState } from '@Store/reducers';
import { getDisabledLinks } from '@Utils/featureToggle';
import { LinkData } from '@Utils/types';

export const isAppReady = (state: RootState): boolean => state.app.isReady;
export const isApiDead = (state: RootState): boolean => state.app.isApiDead;
export const getFbAppId = (state: RootState): string => state.app.config.facebookAppId;
export const getGoogleAppId = (state: RootState): string => state.app.config.googleClientId;
export const getAppleAppId = (state: RootState): string => state.app.config.appleClientId;
export const getAppleRedirectUri = (state: RootState): string => state.app.config.appleRedirectUri;
export const getGoogleTagManagerId = (state: RootState): string => state.app.config.googleTagManagerId;
export const getModalState = (state: RootState): ModalState => state.app.modal;
export const getUserTourState = (state: RootState) => state.app.userTour;
export const getEnabledLanguages = (state: RootState) => state.app.config.enabledLanguages;
export const getCurrentLanguage = (state: RootState) => state.app.currentLanguage;
export const getStaticPageContent = (staticPage: StaticPages) => (state: RootState) => state.app.config[staticPage];
export const getPageCurrentSlugForPage = (staticPage: StaticPages) => (state: RootState) =>
  getStaticPageContent(staticPage)(state)[getCurrentLanguage(state)];

export const getContactUsPage = (state: RootState) => state.app.config.contact[getCurrentLanguage(state)];
export const getRentPage = (state: RootState) => state.app.config.rentPage[getCurrentLanguage(state)];
export const getAllCurrentSlugs = (state: RootState) =>
  Object.values(StaticPages).map(staticPage => getPageCurrentSlugForPage(staticPage)(state));

export const getSlugLinksData = (state: RootState): LinkData[] =>
  getAllCurrentSlugs(state).reduce<LinkData[]>((result, slug) => {
    if (!slug) {
      return result;
    }
    return [...result, { titleKey: slug.title, link: RouteConfig.StaticPage.buildLink({ slug: slug.slug }) }];
  }, []);

export const getCurrentModal = (state: RootState) => state.app.modal.modalType;
export const getFeatureToggles = (state: RootState): FeatureToggle => ({
  auction: state.app.config.isAuctionEnabled,
  payment: state.app.config.isPaymentEnabled,
  forum: state.app.config.isForumEnabled,
  oldOrderForm: state.app.config.isOldOrderFormEnabled,
});

export const getFeatureToggle =
  (feature: keyof FeatureToggle) =>
  (state: RootState): boolean =>
    getFeatureToggles(state)[feature];

export const getFeatureToggleDisabledLinks = (state: RootState) => getDisabledLinks(getFeatureToggles(state));
export const getPredefinedSearches = (state: RootState) => state.app.config.predefinedSearch;
export const getLandingPageBanners = (state: RootState) => state.app.config.landingPageBanners;
export const getMainLogo = (state: RootState) => state.app.config.mainLogo;
export const getStripePublishableKey = (state: RootState): string => state.app.config.stripePublishableKey;

export const getAppConfig = (state: RootState) => state.app.config;
