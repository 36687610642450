import * as React from 'react';

import Button, { ButtonStyles } from '@Components/Buttons/Button';
import Icon, { IconSizes } from '@Components/Icon';
import Row from '@Components/layout/Row';
import { LocationValue } from '@Components/LocationSelect';
import SpecifyLocationMap, { CoordinatesProps } from '@Components/Map/SpecifyLocationMap';
import { ModalProviderProps } from '@Components/Modal';

import styles from './SpecifyLocationModal.module.scss';

interface SpecifyLocationModalProps {
  location: CoordinatesProps;
  onChange: (values: LocationValue) => void;
}

const SpecifyLocationModal: React.FunctionComponent<ModalProviderProps<SpecifyLocationModalProps>> = ({
  modalData,
  closeModal,
}) => {
  return (
    <div className={styles.container}>
      <Row justifyEnd>
        <Button style={ButtonStyles.plain} onClick={closeModal}>
          <Icon icon="menu-close-button" size={IconSizes.s} />
        </Button>
      </Row>
      <SpecifyLocationMap onChange={modalData.onChange} location={modalData.location} />
    </div>
  );
};

export default SpecifyLocationModal;
